import { ChangeDetectionStrategy, Component, Input, OnInit,NO_ERRORS_SCHEMA,NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";
import { FormsModule, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuariosService } from 'src/app/service/usuarios.service';
import { URL_HOSTNAME } from '../../../store/config';
import { Publicacion } from '../../../models/publicacion';
import { PublicacionesService } from '../../../service/publicaciones.service';
import { Carrusel } from '../../../models/carrusel';
import { CarruselService } from '../../../service/carrusel.service';

@Component({
  selector: 'app-form-carrusel',
  templateUrl: './form-carrusel.component.html',
  styleUrls: ['./form-carrusel.component.css']
})
export class FormCarruselComponent implements OnInit {
  @Input() context: String;
  public showForm: boolean = true;
  public publicacion: Publicacion = new Publicacion();

  public carrusel: Carrusel = new Carrusel();

  public titulo: String = "Registro nuevo Carrusel";
  public file: File;
  public fileName: string = null;
  public fileSelected: Boolean = false;
  public showFileButton: Boolean = true;
  
  public myForm: FormGroup = new FormGroup({
    file: new FormControl('', [Validators.required]),
  })

  constructor(private publicacionService: PublicacionesService,
    private carruselService: CarruselService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activateRoute.params.subscribe(
      (params) => {
        let id = params['id']
        if (id) {
          this.titulo = "Editar Carrusel";
          this.cargarCarrusel(id);
        } else {
          this.titulo = "Alta de Carrusel";
        }
      }
    )
  }

  cargarCarrusel(id: string){
    //this.titulo ="Editar dependencia"
    this.carruselService.obtenerCarrusels(id)
      .subscribe((carrusel) => {
        // console.log(carrusel)
        carrusel.fechaPublicacion = carrusel.fechaPublicacion.substring(0,10);
        carrusel.contenido = URL_HOSTNAME+carrusel.contenido;
        this.showFileButton = false;
        this.carrusel = carrusel;
      });
  }

  create(){
    if(!this.fileSelected){
      swal.fire('Advertencia',`Seleccione una Imagen` ,'warning' );
      return false;
    }
    swal.fire('Guardando',`Espere un momento` ,'info' );
    this.carrusel.contenido = this.fileName;
    this.carrusel.tipoPublicacion = "carrusel";
    // console.log(this.carrusel);
    this.carruselService.createCarrusel(this.carrusel).subscribe((carrusel => {
      // console.log(carrusel);
      this.carrusel = carrusel;
      console.log(this.carrusel.contenido);
      this.carruselService.subirArchivo(this.file, this.carrusel.id, this.carrusel.contenido).subscribe(
        (status) => {
          // console.log(">>>C");
          // console.log(status);
          swal.fire('Nuevo Carrusel', `Carrusel: "${this.carrusel.titulo}" guardada con exito!`, 'success');
          this.router.navigate(['/publicaciones']);
        }
      );
    }))
  }

  update(){
    //swal.fire('Actualizando',`Espere un momento` ,'info' );
    if(this.fileSelected){
      this.carrusel.contenido = this.fileName;
      console.log(this.carrusel.contenido);
    }else{
      this.carrusel.contenido = "no cambiar";
    }
    this.carruselService.createCarrusel(this.carrusel).subscribe((publicacion => {
      // console.log(publicacion);
      this.carrusel = publicacion;
      if(this.fileSelected){
        this.carruselService.subirArchivo(this.file, this.carrusel.id, this.carrusel.contenido).subscribe(
          (status) => {
            swal.fire('Carrsel actualizada', `Carrusel: "${this.carrusel.titulo}" actualizado con exito!`, 'success');
            this.router.navigate(['/publicaciones']);
          }
        );
      }else{
        swal.fire('Carrusel actualizada', `Carrusel: "${this.carrusel.titulo}" actualizado con exito!`, 'success');
        this.router.navigate(['/publicaciones']);
      }
    }))
  }



  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.fileSelected = true;
        this.fileName = this.file.name;
        // console.log(this.file.name)
      };
    }
  }

  showFileButtonEvent():void {
    this.showFileButton = this.showFileButton ? false : true;
  }
}
