import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.css']
})
export class ConveniosComponent implements OnInit {

  constructor(private activateRoute: ActivatedRoute) { }
  public activeFiles: Object[] = [];
  public showVideo: Boolean;
  public showImg: Boolean;
  public showDoc: Boolean;
  public showPre: Boolean;
  public uno: Boolean;
  public dos: Boolean;
  public tres: Boolean;
  public cuatro: Boolean;
  
    // se modifico
  public paginaActiva: Number = 1;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    // se modifico
    this.paginaActiva = 1;
    this.activateRoute.params.subscribe(
      (params) => {
        let id = params['id'];
        switch (id) {
          case '1':
            this.activeFiles.push(
              { pill: "active", tab: true },
              { pill: "", tab: "" });
            break;
          case '2':
            this.activeFiles.push(
              { pill: "", tab: "" },
              { pill: "active", tab: true });
            break;
        }
      });
  }

  toogleDocs($event: any): void {
        // se modifico
        this.paginaActiva = 1;
    switch ($event.currentTarget.id) {
      case "vid":
        this.showVideo === true ? this.showVideo = false : this.showVideo = true;
        this.showImg = false;
        this.showDoc = false;
        this.showPre = false;
        break;

      case "img":
        this.showImg === true ? this.showImg = false : this.showImg = true;
        this.showVideo = false;
        this.showDoc = false;
        this.showPre = false;
        break;

      case "doc":
        this.showDoc === true ? this.showDoc = false : this.showDoc = true;
        this.showVideo = false;
        this.showImg = false;
        this.showPre = false;
        break;
      case "pre":
        this.showPre === true ? this.showPre = false : this.showPre = true;
        this.showVideo = false;
        this.showImg = false;
        this.showDoc = false;
        break;
    }
  }

  tooglePagi($event: any): void {
    this.paginaActiva = $event.currentTarget.id;
    $("ul.pagination > li.nav-item").each(function(){  
      $(this).removeClass("active");
    });
    $("#"+$event.currentTarget.id).parent().addClass("active");

 /*   switch ($event.currentTarget.id) {
      case "1":
        this.uno === true ? this.uno = true : this.uno = true;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        break;

      case "2":
        this.dos === true ? this.dos = true : this.dos = true;
        this.uno = false;
        this.tres = false;
        this.cuatro = false;
        break;

      case "3":
        this.tres === true ? this.tres = true : this.tres = true;
        this.uno = false;
        this.dos = false;
        this.cuatro = false;
        break;

      case "4":
        this.cuatro === true ? this.cuatro = true : this.cuatro = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        break;

      default:
        this.uno === true ? this.uno = true : this.uno = true;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        break;
    }
  */
  }
}
