import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Noticias } from '../../models/noticias';
import { NoticiaService } from './../../service/noticias.service';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'app-noticias-detail',
  templateUrl: './noticias-detail.component.html',
  styleUrls: ['./noticias-detail.component.css']
})
export class NoticiasDetailComponent implements OnInit {

  constructor(private noticiaService: NoticiaService,
    private activateRoute: ActivatedRoute,
    public loginService: LoginService) { }

  showAbril: boolean;
  showMayo: boolean;
  noticias: Noticias[];

  ngOnInit(): void {
    this.activateRoute.params.subscribe(
      (params) => {
        let mes = params['mes'];
        this.noticiaService.getNoticiaMesAnno(mes).subscribe((response) => {
          this.noticias = response as Noticias[];
        });
      }
    )
  }

  color(id: number): String{
    switch(id % 3){
      case 0:
        return "greenSpan";
      case 1:
        return "orangeSpan";
      case 2:
        return "purpleSpan";
    }
  }

  fechaFormato(fecha: string): String {
    // console.log(fecha);
    let conversor = new Date(fecha);
    let mes = conversor.toLocaleString("es-MX", {month: 'long'});
    //console.log(conversor.getDate()+"-"+mes+"-"+conversor.getFullYear());
    return (conversor.getDate() )+"-"+mes+"-"+conversor.getFullYear();
  }

}
