<div class="container" id="content">
  <div class="panel panel-success">
    <div class="panel-heading">
      <h3 class="panel-title">Mi cuenta</h3>
    </div>
    <div class="panel-body">
      <form>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Nombre:</label>
              <div>
                <input type="text" name="nombre" value="" class="form-control" [(ngModel)]="usuario.nombre">
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Apellido:</label>
              <div>
                <input type="text" name="apellido" value="" class="form-control" [(ngModel)]="usuario.apellido">
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Correo electrónico:</label>
              <div>
                <input type="text" name="email" value="" class="form-control" [(ngModel)]="usuario.email">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Contraseña:</label>
              <div>
                <input type="password" name="password" value="" class="form-control" [(ngModel)]="password">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Repetir contraseña:</label>
              <div>
                <input type="password" name="password2" value="" class="form-control" [(ngModel)]="password2">
              </div>
            </div>
          </div>
      </form>
    </div>
    <div class="panel-footer">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <div class="form-group" style="margin-right: 15px;">
            <button type="button" name="button" class="btn btn-primary" [routerLink]="['/bienvenida']">Cancelar</button>
          </div>
          <div class="form-group">        
            <button type="button" name="button" class="btn btn-success" (click)="create()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
