<div class="container " id="addTemas">
	<h3 class="mt-5">{{ titulo }}</h3>
	<hr class="red">
	<div class="jumbotron-fluid">
		<div>
			<div class="alert alert-danger alert-dismissible" role="alert"
				*ngIf="nombre.invalid && (nombre.dirty || nombre.touched)">
				<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
						aria-hidden="true">&times;</span></button>
				<div *ngIf="nombre.errors.required">
					<strong>Tema de especialidad: </strong> es requerido
				</div>
				<div *ngIf="nombre.errors.minlength">
					<strong>Tema de especialidad: </strong> debe tener por lo menos 4 caracteres
				</div>
			</div>			
		</div>
		
		<div class="col-md-12">
			<form #formTemas="ngForm">
				<div class="form-group col-md-12 mt-5">
					<label for="nombre">Tema de especialidad: <span class="obligatorio">*</span> </label>
					<div class="mt-5">
						<input type="text" class="form-control" [(ngModel)]="tema.nombre" name="nombre" required
							minlength="4" #nombre="ngModel">
					</div>
				</div>									

				<div class="col-md-12 mb-5 mt-5">
						<button class="btn btn-success float-right m-2" role="button" (click)='create()' *ngIf="!tema.id else elseBlock" [disabled]="!formTemas.form.valid">
							Guardar
						</button>
						<ng-template #elseBlock>
							<button class="btn btn-warning float-right m-2" role="button" (click)='update()' [disabled]="!formTemas.form.valid">Guardar</button>
						</ng-template>
						<button type="button" class="btn btn-rounded btn-primary m-2 float-right" [routerLink]="['/temas']">Cancelar</button>
				</div>
			</form>
		</div>
	</div>

</div>