<div class="container p-0">
    <div class="form-group col-md-2 pl-0">
        <label for="estado">Estado:</label>
        <div>
            <select [compareWith]="compararEstados" (change)="cargarMunicipios()" class="form-control" name="entidad" [(ngModel)]="estado">
              <option [ngValue]="undefined">Seleccionar</option>
              <option *ngFor="let item of estados" [ngValue]="item">
                {{ item.nombre }}
              </option>
            </select>
        </div>
    </div>
    <div class="form-group col-md-2">
        <label for="municipio">Municipio: </label>
        <div>
            <select class="form-control" [compareWith]="compararMunicipios" name="municipio" [(ngModel)]="this.municipio">
        <option  [ngValue]="undefined">Seleccionar</option>
        <option *ngFor="let municipio of municipios" [ngValue]="municipio">
          {{ municipio.nombre }}
        </option>
      </select>
        </div>
    </div>
    <div class="form-group col-md-4">
        <label> Tema: </label>
        <div class="form-group">
            <select class="form-control" name="tema" [(ngModel)]="this.tema">
            <option  [ngValue]="undefined">Seleccionar</option>
            <option *ngFor="let tema of temas" [ngValue]="tema">
              {{ tema.nombre }}
            </option>
          </select>
            <!-- <input list="Temas" class="w-100 p-2" />
            <datalist id="Temas" name="tema">
              <option *ngFor="let tema of temas" [(ngModel)]="tema"  [ngValue]="tema" >
              </option>
            </datalist> -->
        </div>
    </div>
    <form [formGroup]="nombreForm">
        <div class="form-group col-md-4">
            <label> Nombre: </label>
            <div class="form-group">
                <input list="Organizaciones" formControlName="nombre" class="w-100 p-2" />
                <datalist id="Organizaciones">
          <option
            *ngFor="let organizacion of organizaciones"
            [ngValue]="organizacion"
          >
            {{ organizacion.nombre }}
          </option>
        </datalist>
            </div>
        </div>

        <div class="col-md-12 p-0">
          <div class="col-md-8"></div>
          <div class="form-group col-md-2">
            <br>
            <button type="button" class="btn btn-info btn-block" (click)="buscar()">
              <span class="glyphicon glyphicon-search" aria-hidden="true"></span>Buscar
            </button>
          </div>
          <div class="form-group col-md-2 pull-rigth">
            <br>
            <button class="btn btn-primary btn-block" (click)="limpiar()">
              <span class="glyphicon glyphicon-refresh"></span> Limpiar
            </button>
          </div>
        </div>
    </form>
</div>
