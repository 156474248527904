<!--  <nav class="navbar navbar-expand-lg navbar-dark sub-navbar navbar-inverse sub-navbar2 navbar-fixed-top">
	<div class="container ">
		<button type="button" class="navbar-toggler mt-5 navbar-inverse" data-toggle="collapse" data-target="#subNavBarDropdown" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
			 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-border-width" viewBox="0 0 16 16">
				<path d="M0 3.5A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-2zm0 5A.5.5 0 0 1 .5 8h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1zm0 4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z"/>
			  </svg>
	    </button>
		<a class="navbar-brand sub-navbar navbar-inverse" href="#"><img src="assets/img/vinculacion_y_alianzas.png" alt="" class=" mt-n3 w-80 "/></a>

	<div class="collapse navbar-collapse sub-navbar navbar-inverse" id="subNavBarDropdown">
		
			<ul class="navbar-nav" *ngIf="loginService.isAuthenticated(); else elseNav">

				<li class="nav-item">
					<a class="nav-link subnav-link bg-white" routerLink="/"><i class="bi bi-card-list"></i>Inicio</a>
				</li>
				
				<li class="nav-item">
					<a class="nav-link subnav-link bg-white"  routerLink="/organizaciones/1/OSC">Osc</a>
				</li>
	
				
				<li class="nav-item">
					<a class="nav-link subnav-link bg-white" routerLink="/organizaciones/2/IP">Ip</a>
				</li>
	
				
				<li class="nav-item">
					<a class="nav-link subnav-link bg-white" routerLink="/organizaciones/3/IES">Ies</a>
				</li>
				
				<li class="nav-item">
					<a class="nav-link subnav-link bg-white" routerLink="/contactos">Contacto</a>
				</li>
				
					<li class="nav-item">
						<a class="nav-link subnav-link bg-white" routerLink="/login">Login</a>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link subnav-link dropdown-toggle bg-white" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Catálogos
						</a>
						<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
							<a class="dropdown-item bg-white" routerLink="/temas" *ngIf="loginService.hasRoles('ROLE_ADMIN')">Temas de especialidad</a>
							<a class="dropdown-item bg-white" routerLink="/usuarios" *ngIf="loginService.hasRoles('ROLE_ADMIN')">Usuarios</a>
							<a class="dropdown-item bg-white" routerLink="/dependencias">Dependencias</a>
						</div>
					</li>
					<li class="nav-item dropdown" *ngIf="loginService.isAuthenticated()">
						<a class="nav-link subnav-link dropdown-toggle bg-white" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							{{ loginService.usuario.nombre }}
						</a>
						<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
						<a class="dropdown-item bg-white" [routerLink]="['/usuarios/cuenta', loginService.usuario.id]">Mi cuenta</a>
						<li><a (click)="cerrarSesion()">Salir</a></li>
						</div>
					</li>
			</ul>
			<ng-template #elseNav>
				<ul class="navbar-nav text-right">
					<li class="nav-item">
						<a class="nav-link subnav-link bg-white" routerLink="/"><i class="bi bi-card-list"></i>Inicio</a>
					</li>
					
					<li class="nav-item">
						<a class="nav-link subnav-link bg-white"  routerLink="/organizaciones/1/OSC">Osc</a>
					</li>
		
					
					<li class="nav-item">
						<a class="nav-link subnav-link bg-white" routerLink="/organizaciones/2/IP">Ip</a>
					</li>
		
					
					<li class="nav-item">
						<a class="nav-link subnav-link bg-white" routerLink="/organizaciones/3/IES">Ies</a>
					</li>
					
					<li class="nav-item">
						<a class="nav-link subnav-link bg-white" routerLink="/contactos">Contacto</a>
					</li>
					
						<li class="nav-item">
							<a class="nav-link subnav-link bg-white" routerLink="/login">Login</a>
						</li>	
				</ul>
			</ng-template>
		
	</div>
  </div>
  </nav>  -->

<nav class="navbar navbar-expand-lg navbar-dark sub-navbar navbar-fixed-top sub-navbar2 navbar-inverse">
	<div class="row" style="width: 100%;">
		<div class="col-12 space">
			<div class="fix"></div>
		</div>
		<div class="col-12">
			<div class="container">
				<button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#subNavBarDropdown" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
				<button type="button" class="navbar-toggler mt-5 navbar-inverse mx-auto" data-toggle="collapse" data-target="#subNavBarDropdown" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-border-width" viewBox="0 0 16 16">
					   <path d="M0 3.5A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-2zm0 5A.5.5 0 0 1 .5 8h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1zm0 4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z"/>
					 </svg>
			   </button>
				</button>
		
				<a class="sub-navbar2 navbar-inverse" href="#">
					<img src="assets/img/logo_delineado.png" class="img img-fluid d-block mx-0" alt="" style="height: 65px; width: auto; margin-top: 1.5px; margin-bottom: 1.5px;" />
				</a>
		
				<div class="collapse navbar-collapse" id="subNavBarDropdown">
		
		
					<ul class="navbar-nav" *ngIf="loginService.isAuthenticated(); else elseNav">
		
						<li class="nav-item ">
							<a class="nav-link subnav-link active bg-white p-3" routerLink="/">Inicio</a>
						</li>
		
						<li class="nav-item ">
							<a class="nav-link subnav-link active bg-white p-3" routerLink="/publicaciones">Publicaciones</a>
						</li>
		
						<li class="nav-item dropdown">
							<a class="nav-link subnav-link dropdown-toggle bg-white p-3" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								Catálogos
							</a>
							<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
								<a class="dropdown-item bg-white p-3" routerLink="/temas" *ngIf="loginService.hasRoles('ROLE_ADMIN')">Temas de especialidad</a>
								<a class="dropdown-item bg-white p-3" routerLink="/usuarios" *ngIf="loginService.hasRoles('ROLE_ADMIN')">Usuarios</a>
								<a class="dropdown-item bg-white p-3" routerLink="/dependencias">Dependencias</a>
							</div>
						</li>
		
						<li class="nav-item dropdown" *ngIf="loginService.isAuthenticated()">
							<a class="nav-link subnav-link dropdown-toggle bg-white p-3" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								{{ loginService.usuario.nombre }}
							</a>
							<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
								<a class="dropdown-item bg-white p-3" [routerLink]="['/usuarios/cuenta', loginService.usuario.id]">Mi cuenta</a>
								<li><a (click)="cerrarSesion()">Salir</a></li>
							</div>
						</li>
		
					</ul>
					<ng-template #elseNav>
						<ul class="navbar-nav d-flex justify-content-end">
							<li class="nav-item">
								<a class="nav-link subnav-link bg-white p-3" routerLink="/">Inicio</a>
							</li>
		
							<li class="nav-item">
								<a class="nav-link subnav-link bg-white p-3" [routerLink]="['inicio', 'vinculacionesyalianzas']">Vinculación y Alianzas</a>
							</li>
		
							<li class="nav-item">
								<a class="nav-link subnav-link bg-white p-3" [routerLink]="['inicio', 'blog']">Blog</a>
							</li>
		
							<li class="nav-item">
								<a class="nav-link subnav-link bg-white p-3" [routerLink]="['inicio', 'multimedia']">Multimedia</a>
							</li>
							<!-- B.C. Se agregó el atributo (click)="movementElementTop()"
							<a class="nav-link subnav-link bg-white p-3" [routerLink]="['/registro']">Registro</a> -->
							<li class="nav-item">
								<a class="nav-link subnav-link bg-white p-3" [routerLink]="['/registro']" (click)="movementElementTop()">Registro</a>
							</li>
							<!-- B.C. Se agregó el atributo (click)="movementElementTop()"
							<a class="nav-link subnav-link bg-white p-3" [routerLink]="['/registro']">Registro</a> -->
							<li class="nav-item">
								<a class="nav-link subnav-link bg-white p-3" routerLink="/contactos" (click)="movementElementTop()">Contacto</a>
							</li>
							<!-- B.C. Se agregó el atributo (click)="movementElementTop()"
							<a class="nav-link subnav-link bg-white p-3" [routerLink]="['/registro']">Registro</a> -->
							<li class="nav-item">
								<a class="nav-link subnav-link bg-white p-3" routerLink="/login" (click)="movementElementTop()">Login</a>
							</li>
						</ul>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</nav>