import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { URL_CONFIG } from '../store/config';
import swal from "sweetalert2";
import { Injectable } from '@angular/core';
import { Estado } from '../models/estados';
import { Municipio } from '../models/municipios';
import { Observable, throwError } from 'rxjs';
import { Categoria } from '../models/categorias';

@Injectable()
export class NoticiaService {

  private urlEndPoint: string = URL_CONFIG + '/api/';
  public data: Object[];
  //private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, private router: Router) {}
  
  getNoticiasByFiltro(estado: Estado, categoria: Categoria, municipio : Municipio, nombre: string): Observable<any> {
    this.data = [estado , categoria  ,municipio, nombre];
    return this.http.post(this.urlEndPoint + 'noticias/byFiltro', this.data).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
          }
          return throwError(e);
        }
      )
    );
  }

  getMesesNoticia(): Observable<any>{ 
    return this.http.get(this.urlEndPoint + 'noticias/mes').pipe(catchError( (e) => {
          if(e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
          }
          return throwError(e);
        }));
  }

  getNoticiaMesAnno(periodo: String): Observable<any>{ 
    return this.http.get(this.urlEndPoint + 'noticias/buscar/' + periodo).pipe(catchError( (e) => {
          if(e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
          }
          return throwError(e);
        }));
  }

}