import { ChangeDetectionStrategy, Component, Input, OnInit,NO_ERRORS_SCHEMA, NgModule, ViewChild, ElementRef } from '@angular/core';
import { FormsModule, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { PublicacionesService } from '../../../service/publicaciones.service';
import { Publicacion } from '../../../models/publicacion';
import { VinculacionContenido } from '../../../models/vinculacion-contenido';
import { Router, ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { URL_HOSTNAME } from '../../../store/config';
import { Categoria } from '../../../models/categorias';
import { DependenciaService } from '../../../service/dependencias.service';
import swal from "sweetalert2";
import * as $ from 'jquery';

@Component({
  selector: 'app-form',
  templateUrl: './publicacionformvinculacion.component.html',
  styleUrls: ['./publicacionformvinculacion.component.css']
})
export class PublicacionformvinculacionComponent implements OnInit {
  @Input() context: String;
  @Input() categoria: Categoria;
  public showForm: boolean = true;
  public publicacion: Publicacion = new Publicacion();
  public vinculacioncontenido: VinculacionContenido = new VinculacionContenido;
  public titulo: String;
  public file: File;
  public files: File[];
  public imgSrc: String;
  public Editor = ClassicEditor;
  public contenido: string;
  public archivosSeleccionados: Object;
  
  public fileName: string = null;
  public fileSelected: Boolean = false;
  public showFileButton: Boolean = true;

  public jsonLista = [];
  public guardarArchivos: boolean = false;
  public revisarMIME: boolean = true;

  
  public categorias = [];

  public myForm: FormGroup = new FormGroup({
    file: new FormControl('', [Validators.required]),
  })

  constructor(private publicacionService: PublicacionesService,
    private dependeciaService: DependenciaService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.publicacion.tipoPublicacion = "vinculacion";
    this.files = [];
    this.cargarAllCategorias();
    this.activateRoute.params.subscribe((params) => {
      let id = params['id'];
      if(id){
        this.titulo = "Editar la Vinculacion ";
        this.publicacion.id = id;
        this.publicacionService.obtenerVinculacion(id).subscribe((publicacion) => {
          this.publicacion = publicacion;
          this.vinculacioncontenido = JSON.parse(this.publicacion.contenido);
          // console.log(this.blogcontenido);
          this.jsonLista =  this.vinculacioncontenido.archivos;
          for(let i of this.vinculacioncontenido.archivos){
            // console.log(window.location.origin + i.ruta);
            fetch(window.location.origin + i.ruta)
            .then(res => res.blob())
            .then(blob => {
              let objectURL = URL.createObjectURL(blob);              
              /**console.log( i.ruta.substring( i.ruta.lastIndexOf('/') + 1 ) );*/
              this.files.push(new File([blob], i.ruta.split("/").pop(), {
                type: blob.type,
              }));
            });
          }
          this.contenido = this.vinculacioncontenido.texto;
          let fecha = this.publicacion.fechaPublicacion.substring(0,10); 
          this.publicacion.fechaPublicacion = fecha;
          /**console.log(this.publicacion.id);*/
          this.showFileButton = false;
          console.log(this.files);
        });
      }else{
        this.titulo = "Registrar una Nueva Vinculación";
        /**console.log(this.publicacion.id);*/
      }
    });    
  }

  cargarAllCategorias(): void {
    this.dependeciaService.getCategorias().subscribe((categorias) => {
      this.categorias = categorias;
      // console.log(categorias);
      // console.log(this.categorias);
    })
    
  }

  create() {
    if(!this.fileSelected){
      swal.fire('Advertencia',`Seleccione un documento` ,'warning' );
      return false;
    }
    swal.fire('Guardando',`Espere un momento` ,'info' );
    this.vinculacioncontenido.imagen_principal = this.publicacion.imagen;
    this.vinculacioncontenido.subtitulo = "";
    this.vinculacioncontenido.texto = this.contenido;
    this.vinculacioncontenido.archivos = this.jsonLista;
    this.publicacion.contenido = JSON.stringify(this.vinculacioncontenido);

    this.publicacionService.createNoticia(this.publicacion).subscribe((publicacion) => {
      this.publicacion = publicacion; 
      for(let file of this.files){
        this.publicacionService.subirArchivo(file, this.publicacion.id, "/assets/Mesas-de-dialogo/<carpetas>/"+file.name).subscribe((http_code) => {
          console.log(http_code);
        });
      }
      this.publicacionService.subirArchivo(this.file, this.publicacion.id, this.publicacion.imagen).subscribe(
        (status) => {
          console.log(">>>C");
          console.log(status);                 
          this.publicacionService.correcionRutas(this.publicacion.id).subscribe((reset) => {});
          swal.fire('Nueva Vinculación', `Vinculación: "${this.publicacion.titulo}" guardada con exito!`, 'success');       
          this.router.navigate(['/publicaciones']);
        }
      );
    });
  }

  update(): void {
    swal.fire('Actualizando',`Espere un momento` ,'info' );
    
    this.vinculacioncontenido.imagen_principal = this.publicacion.imagen;
    this.vinculacioncontenido.subtitulo = "";
    this.vinculacioncontenido.texto = this.contenido;
    this.vinculacioncontenido.archivos = this.jsonLista; 
    this.publicacion.contenido = JSON.stringify(this.vinculacioncontenido);
    
    this.publicacionService.createNoticia(this.publicacion).subscribe((publicacion) => {
      console.log("***********************************")
      console.log(publicacion)   
      this.publicacionService.correcionRutas(this.publicacion.id).subscribe((reset) => {});        
      if(this.files.length){
        for(let file of this.files){            
          this.publicacionService.subirArchivo(file, this.publicacion.id, "/assets/Mesas-de-dialogo/<carpetas>/"+file.name).subscribe((http_code) => {
            console.log(http_code);
          });
        }
        this.publicacionService.subirArchivo(this.file, publicacion.id, this.publicacion.imagen).subscribe((status) => {
          if(this.publicacion){
            swal.fire('Vunculación actualizada', `Vinculación: "${this.publicacion.titulo}" guardado con exito!`, 'success');
          }else{
            swal.fire('Nueva Vinculación', `Vinculación: "${this.publicacion.titulo}" guardado con exito!`, 'success');
          }
          this.router.navigate(['/publicaciones']);
        });        
      }
      if(this.file){
        this.publicacionService.subirArchivo(this.file, this.publicacion.id, this.publicacion.imagen).subscribe((http_code) => {
          console.log(http_code);
        });
      }
      if(this.publicacion){
        swal.fire('Vinculación actualizado', `Vinculación: "${this.publicacion.titulo}" guardado con exito!`, 'success');
      }else{
        swal.fire('Nuevo Vinculación', `Vinculación: "${this.publicacion.titulo}" guardado con exito!`, 'success');
      }
      this.router.navigate(['/publicaciones']);
    });
  }

  cancelar(): void{
    this.router.navigate(['/publicaciones']);
  }


  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      //const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsDataURL(this.file);
      
      reader.onload = () => {
        this.fileSelected = true;
        this.fileName = this.file.name;

        if(this.publicacion.tipoPublicacion == "vinculacion"){
          this.publicacion.imagen = "/assets/Mesas-de-dialogo/PORTADA/" + this.fileName;
        }

        /*this.myForm.patchValue({
          fileSource: reader.result
        });*/
      };
    }
  }

  showFileButtonEvent():void {
    //this.showFileButton = this.showFileButton ? false : true;
    this.showFileButton = !this.showFileButton;
  }

  mostrarCargaArchivos(): void{    
    document.getElementById("multiFile").click();
  }

  mostrarCargaEnlace(): void{
    this.jsonLista.push({});
    this.guardarArchivos = true;
  }

  cambiosRealizados(): void{
    this.guardarArchivos = true;
  }

  cambiarRevision(): void{    
    this.revisarMIME = true;
  }

  comparacionMIME(nombre): boolean{
    var encontrado = false;
    for(let file of this.files){
      if(file.name == nombre){
        encontrado = /^image/.test(file.type);
        break;
      }
    }
    return encontrado;
  }

  agregarMultiplesArchivos(event): void{
    const lector = new FileReader();
    var tamannoAnterior = this.files.length;
    if(event.target.files){
      for(let file of event.target.files){
        this.files.push(file);
      }
      var archivoJSON = {};  
      for(var index = tamannoAnterior; index < this.files.length; index++){
        archivoJSON = {};
        archivoJSON["ruta"] = "/assets/Mesas-de-dialogo/<carpetas>/"+this.files[index].name;
        archivoJSON["name"] = this.files[index].name;
        if(/^image/.test(this.files[index].type)){
          archivoJSON["tipo"] = "imagenes";
        }else if(/^video/.test(this.files[index].type)){
          archivoJSON["tipo"] = "video";
        }else if(/^application\/pdf/.test(this.files[index].type)){
          archivoJSON["tipo"] = "documentos";
        }else{
          archivoJSON["tipo"] = "presentacion";
        }
        this.jsonLista.push(archivoJSON);
      }
      this.guardarArchivos = this.jsonLista.length > 0;
    }
  }
  
  eliminarMultiplesArchivos():void{
    var listaArchivos = document.getElementsByClassName("lista-archivos")[0] as HTMLSelectElement;
    var listaSeleccionado = 0;
    while(listaSeleccionado != -1){      
      listaSeleccionado = listaArchivos.selectedIndex;
      listaArchivos.children[listaSeleccionado].remove();
      this.jsonLista.splice(listaSeleccionado,1);
    }
  }

  guardarJSON(): void{
    var archivoJSON = {};
    var nuevoJSONLista = [];
    for(var index = 0; index < $("#archivo").children().length - 1; index ++){
      if($("#nombre_archivo_"+index).val()){
        archivoJSON["ruta"] = "/assets/Mesas-de-dialogo/<carpeta>/" + $("#nombre_archivo_"+index).val();
        archivoJSON["name"] = $("#nombre_archivo_"+index).val();     
      }else{
        archivoJSON["ruta"] = $("#direccion_archivo_"+index).val();
      }
      archivoJSON["tipo"] = $("#tipo_archivo_"+index).val();
      archivoJSON["titulo"] = $("#titulo_archivo_"+index).val();
      nuevoJSONLista.push(archivoJSON);
      archivoJSON = {};
    }
    this.jsonLista = nuevoJSONLista;
    $("#cerrar").click();
  }  

}
