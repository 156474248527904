<p>galery works!</p>
<div class="container">
    <div class="col-md-12 contentBlog">
        <div class="panel py-0 m-0">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
                <li class="breadcrumb-item"><a routerLink="/inicio/blog">Multimedia</a></li>
                <li class="breadcrumb-item active">Publicación</li>
            </ol>
        </div>

        <a routerLink="/inicio/multimedia" class="btn-flotante">Regresar</a>
        <br />

        <div class="container" id="pills-primer">
            <div class="container" data-toggle="modal" data-target="#lightbox">
                <div class="circles circles-pills mt-5">
                    <div class="col-md-4">
                        <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                            <div class="d-flex flex-column">
                                <div class="rounded-circle purple mx-auto p-3">
                                    <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                        style="font-size: 55px; color: #ffffff;"></span>
                                </div>
                                <div class="text-center font-weight-bold text-purple p-2">
                                    <p>VIDEOS</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-md-4">
                        <a class="circles ml-n5" id="img" (click)="toogleDocs($event)">
                            <div class="d-flex flex-column">
                                <div class="rounded-circle green mx-auto p-3">
                                    <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                        style="font-size: 55px; color: #ffffff;"></span>
                                </div>
                                <div class="text-center">
                                    <p class="text-green font-weight-bold p-2">FOTOS</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-md-4">
                        <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                            <div class="d-flex flex-column">
                                <div class="rounded-circle orange mx-auto p-3">
                                    <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                        style="font-size: 55px; color: #ffffff;"></span>
                                </div>
                                <div class="text-center font-weight-bold text-orange p-2">
                                    <p>PUBLICACIONES</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="col-md-12" *ngIf="showVideo">
                        <h3 class="text-left">Videos</h3>
                        <hr class="red"
                            style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                        <div class="row d-flex flex-wrap align-items-start" data-toggle="modal" data-target="#lightbox">
                            <div class="col-12 col-md-6" *ngFor="let archivo of video; let i = index"
                                [attr.data-index]="i">
                                <div>
                                    <div class="col-sm-12 my-5 text-center">
                                        <iframe [src]="URLseguro(archivo['ruta'])" width="560" height="314" style='
                                    border: none;
                                    overflow: hidden;
                                    width: 100%;
                                    height: 270px;
                                    background: black; margin: 1vw; 
                                    border-radius: 15px; 
                                    box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);'
                                            scrolling='no' frameborder='0' allowfullscreen='true'
                                            allow='clipboard-write; encrypted-media; picture-in-picture; web-share'
                                            class="embed-responsive-item">
                                        </iframe>
                                        {{archivo["titulo"]}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="showImg">
                        <h3 class="text-left">Imagenes</h3>
                        <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;"><br>
                    
                        <div class="d-flex flex-wrap align-items-center" data-toggle="modal" data-target="#lightbox">
                            <div class="d-flex flex-column contfotos" *ngFor="let archivo of image; let i = index" [attr.data-index]="i">
                                <div *ngIf="TieneImagenes(archivo['id'])">
                                    <div class="font-weight-bold text-purple p-2">
                                        <span class="glyphicon glyphicon-folder-open p-3" data-bs-toggle="collapse" [attr.data-bs-target]="'#i' + i"
                                            aria-expanded="false" aria-controls="i" style="font-size: 100px; color: #283c7f"></span>
                                    </div>
                                    {{ archivo['titulo'] }}
                    
                                    <div class="font-weight-bold text-purple p-2">
                                        <button class="btn btn-primary dropdown-toggle aumentar-tamano align-items-center" data-toggle="collapse"
                                            [attr.data-target]="'#imagenes' + i" aria-expanded="false" type="button"
                                            (click)="mostrarImagenes(archivo['id'])">
                                            Mostrar Imágenes
                                        </button>
                                    </div>
                                    <div class="col-md-12 imagen-con-margen collapse" [attr.id]="'imagenes' + i">
                                        <div class="row">
                                            <div class="col-md-6 p-2">
                                                <!-- Primera columna de imágenes -->
                                                <div class="d-flex flex-column">
                                                    <div class="imagen-con-margen">
                                                        <img *ngFor="let rutaImagen of rutasImagenes.slice(0, rutasImagenes.length / 2)" [src]="rutaImagen"
                                                        alt="Imagen" class="img-fluid img-custom imagen-con-margen">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 p-2">
                                                <!-- Segunda columna de imágenes -->
                                                <div class="d-flex flex-column">
                                                    <div class="imagen-con-margen">
                                                        <img *ngFor="let rutaImagen of rutasImagenes.slice(rutasImagenes.length / 2)" [src]="rutaImagen"
                                                        alt="Imagen" class="img-fluid img-custom imagen-con-margen">
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-12" *ngIf="showPre">
                        <h3 class="text-left">Publicaciones</h3>
                        <hr class="red"
                            style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                        <div class="col-sm-3 my-5 text-center" *ngFor="let archivo of presenta; let i = index"
                            [attr.data-index]="i">
                            <div>
                                <a href="{{archivo}}" target="_blank">
                                    <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                        style="font-size: 140px; color: #4D92DF;"></span>
                                </a><br>
                                <a href="{{archivo['ruta']}}" target="_blank">
                                    {{archivo['titulo']}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
        </div>
    </div>
</div>