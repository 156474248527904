import { Component, OnInit } from '@angular/core';
import { Dependencia } from '../../models/dependecia';
import { Estado } from '../../models/estados';
import { DependenciaService } from './../../service/dependencias.service';
import { LoginService } from '../../service/login.service';
import { ModalService } from './detalle/modal.service';
import { ActivatedRoute } from '@angular/router';
//import { tap } from 'rxjs/operators';
import swal from "sweetalert2";


@Component({
  selector: 'app-dependencias',
  templateUrl: './dependencias.component.html',
  styleUrls: ['./dependencias.component.css']
})
export class DependenciasComponent implements OnInit {

  dependencias: Dependencia[];
  estados: Estado[];
  paginador: any;
  dependeciaSeleccionado: Dependencia;
  busqueda: string;

  constructor(private dependeciaService: DependenciaService,
    public modalService: ModalService,
    private activateRoute: ActivatedRoute,
    public loginService: LoginService) { }

  ngOnInit(){
    this.activateRoute.paramMap.subscribe( params => {
      let page: number = +params.get('page'); // El signo + convierte el valor de params de string a number
      if(!page){
        page = 0;
      }
      this.dependeciaService.getDependencias(page).
      /*pipe(
        tap( response => {
            (response.content as Dependencia[]).forEach( cliente =>{
              //console.log(cliente.nombre);
            })
        })
      ).*/
      subscribe(
        (response) => {
          this.dependencias = response.content as Dependencia[];
          this.paginador = response;
          console.log(this.dependencias);
        }
      );
    })

    this.modalService.notificarUpload.subscribe(dependencia=>{
      this.dependencias = this.dependencias.map(dependenciaOriginal =>{
        if(dependencia.id == dependenciaOriginal.id){
          dependenciaOriginal.foto = dependencia.foto;
        }
        return dependenciaOriginal;
      });
    })
  }

  delete(dependecia: Dependencia): void{
    swal.fire({
      title: 'Eliminando Dependencia',
      text: `¿Seguro que desea eliminar la dependecia: "${dependecia.nombre}" ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dependeciaService.delete(dependecia.id).subscribe(
          () => {
            this.dependencias = this.dependencias.filter((cli) => cli != dependecia)
            swal.fire(
              'Dependencia eliminada!',
              `Dependencia ""${dependecia.nombre}" eliminado con éxito!`,
              'success'
            )
          }
        )
      }
    })
  }

  abrilModal(dependencia: Dependencia){
    this.dependeciaSeleccionado = dependencia;
    this.modalService.abrilModal();
  }

  buscar(){
    this.dependeciaService.getDependenciasByNombre(this.busqueda).subscribe((dependencias)=>{
      console.log(this.busqueda);
      if(dependencias.length > 0) {
        this.dependencias = dependencias;
      }
    })
  }

}
