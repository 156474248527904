<div class="container mt-4" style="padding-top: 18vh;">

    <div class="col-lg-4 col-md-12 mb-4" *ngFor="let publicacion of publicaciones;">
        <div class="card">
            <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                <img src={{publicacion.imagen}} class="img-fluid"
                    [ngStyle]="{'height': indice != 0 ? '230px' : 'auto'}" />
            </div>
            <div class="card-body">
                <p class="card-text">{{ publicacion.fechaPublicacion | date: 'longDate'
                    }}</p>
                <h5 class="card-title" style="height: 100px;">{{ publicacion.titulo }}</h5>
                <a routerLink="/publicacion/1" class="btn btn-primary">continuar leyendo</a>
            </div>
        </div>
    </div>

</div>