<div class="row login">
  <div class="container jumbotron">
    <form action="">
    <div class="panel col-md-4 col-md-offset-4">
      <div class="panel-heading">
        <img src="assets/img/vinculacion_y_alianzas.png" alt="" class="img-responsive">
      </div>
      <div class="panel-body">
          <div class="form-group">
            <label for="">Correo electrónico:</label>
            <input [(ngModel)]="usuario.email" type="email" name="email" value="" required class="form-control" placeholder="correo@gmail.com" autofocus>
          </div>
          <div class="form-group">
            <label for="">Contraseña:</label>
            <input [(ngModel)]="usuario.password" type="password" class="form-control" required name="password" placeholder="********">
          </div>
      </div>
      <div class="panel-footer">
        <div class="text-center">
          <button (click)="login()" type="submit" name="button" class="btn btn-lg btn-success">Iniciar Sesión</button>
          <br>
          <a class="btn btn-link" href="#">Olvide mi contraseña</a>
        </div>
      </div>
    </div>
    </form>
  </div>
</div>
