<div class="container-fluid">
    <div class="container bienvenida">
        <div class="p-5">
            <div class="card-body p-3 text-center">
                <h5 class="m-3 p-2 font-italic">Sistema de vinculación y alianzas</h5>
                <p class="font-italic m-2">Módulo de administración</p>
                <ul class="text-left m-2">
                    <li class="p-2 font-weight-bold">Catálogos: </li>
                    <ul>
                        <li class="p-2 font-weight-light">Temas de especialidad: En este apartado se podrán gestionar los temas de especialidad que pueden seleccionar las dependencias </li>
                        <li class="p-2 font-weight-light">Usuarios: En este apartado se podrán gestionar los usuarios de esta plataforma</li>
                        <li class="p-2 font-weight-light">Dependencias: En este apartado se podrán gestionar las dependencias que pertenecen a esta dependencia</li>
                    </ul>
                </ul>

                <ul class="text-left m-2">
                    <li class="p-2 font-weight-bold">Menú con su nombre: </li>
                    <ul>
                        <li class="p-2 font-weight-light">Mi cuenta: En este apartado podrá gestionar su cuenta</li>
                        <li class="p-2 font-weight-light">Salir: Este botón cerrará  la sesión en el sistema</li>
                    </ul>
                </ul>
            </div>
        </div>
    </div>
</div>