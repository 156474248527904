export class colors {
    private static purple: string = "#59287f";
    private static green: string = "#a0bf38";
    private static orage: string = "#db8f1b";
    private static colors: Array<string> = ["#59287f",
                                             "#a0bf38",
                                             "#db8f1b"];

    public static getRandomColor():string {
      return colors.colors[Math.floor(Math.random()* (3 - 0))];
    }

    public static getPurple(){
      return this.purple;
    }

    public static getGreen(){
      return this.green;
    }

    public static getOrange(){
      return this.orage;
    }
}

