import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Publicacion } from 'src/app/models/publicacion';
import { PublicacionesService } from 'src/app/service/publicaciones.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-publicacion-vinculacion',
  templateUrl: './publicacion-vinculacion.component.html',
  styleUrls: ['./publicacion-vinculacion.component.css']
})
export class PublicacionVinculacionComponent implements OnInit {

  publicacion: Publicacion;
  json: JSON;

  constructor(private activateRoute: ActivatedRoute, private publicacionesService: PublicacionesService, private sanitizer: DomSanitizer) { }
  public activeVinculacion: Object[] = [];
  public showVideo: Boolean;
  public showImg: Boolean;
  public showDoc: Boolean;
  public showPre: Boolean;

  ngOnInit(): void {
    /*this.publicacionesService.obtenerPublicacion(this.activatedRoute.snapshot.paramMap.get('id')).subscribe(publicacion => {
      this.publicacion = publicacion;
    });*/
    window.scrollTo(0, 0);
    this.activateRoute.params.subscribe(
      (params) => {
        let id = params['id'];
        this.publicacionesService.obtenerVinculacion(id).subscribe(publicacion => {
          this.publicacion = publicacion;
          this.json = JSON.parse(publicacion.contenido);
        });
      });
    //  console.log(document.getElementsByName("document"));
// script no-autoplay
$("div > iframe").each(function(){$(this).contents().find["video"].click();});
  }

  toogleDocs($event: any): void {
    switch($event.currentTarget.id) {
      case "vid":
      this.showVideo === true ? this.showVideo = false : this.showVideo = true ;
      this.showImg = false;
      this.showDoc = false;
      this.showPre = false;
      break;

      case "img":
        this.showImg === true ? this.showImg = false : this.showImg = true ;
        this.showVideo = false;
        this.showDoc = false;
        this.showPre = false;
      break;

      case "doc":
        this.showDoc === true ? this.showDoc = false : this.showDoc = true ;
        this.showVideo = false;
        this.showImg = false;
        this.showPre = false;
      break;

      case "pre":
        this.showPre === true ? this.showPre = false : this.showPre = true ;
        this.showVideo = false;
        this.showImg = false;
        this.showDoc = false;
      break;
    }

  }

  URLseguro(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
