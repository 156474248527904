<div class="container" id="addUser">
    <h3>{{ titulo }}</h3>
    <hr class="red">
    <div class="row">

        <div class="col-md-12">
            <ul class="wizard-steps" *ngIf="nivelCargaDependencia()">
                <li class="completed">
                    <h5>Paso 1</h5>
                    <span>Aviso de privacidad</span>
                </li>
                <li [ngClass]="avisoPrivacidad ? 'completed': ''">
                    <h5>Paso 2</h5>
                    <span>Código de conducta</span>
                </li>
                <li [ngClass]="terminosUtilizacion ? 'completed': '' ">
                    <h5>Paso 3</h5>
                    <span>Registro</span>
                </li>
                <li class="{{classFin}}">
                    <i class="glyphicon glyphicon-ok-circle "></i>
                </li>
            </ul>

            <div class="container">
                <div class="row">
                    <!-- Aviso de privacidad -->
                    <div class="col-md-12 p-0 text-center" *ngIf="!avisoPrivacidad">

                        <h3>Aviso de privacidad</h3>
                        <p>El Instituto Nacional de las Mujeres (INMUJERES) es el responsable del tratamiento de los datos personales que se proporcionen. Los datos personales serán utilizados con fines de registro y, en su caso, para establecer comunicación
                            con las y los representantes de las instituciones participantes. Asimismo, se informa que no se realizarán transferencias de datos personales, salvo aquéllas que sean necesarias para atender requerimientos de información de
                            una autoridad competente, que estén debidamente fundados y motivados. Usted podrá consultar el aviso de privacidad integral <a href="http://web.inmujeres.gob.mx/SIPOT/INMAPI202150100014.pdf" target="_blank">Aquí</a>
                        </p>
                        <br><br> 
                        <!-- B.C. Se agregó el atributo (click)="movementElementTop()"
                        <input type="checkbox" name="avisoPrivacidad" [(ngModel)]="avisoPrivacidad"><b> Acepto los términos del aviso de privacidad</b> -->
                        <form #privacidad="ngForm">
                            <input type="checkbox" name="avisoPrivacidad" [(ngModel)]="avisoPrivacidad" (click)="movementElementTop()"><b> Acepto los términos del aviso de privacidad</b>
                        </form>
                        <br><br><br>
                    </div>
                    <!-- Términos de utilización del micrositio -->
                    <div class="col-md-12 text-center" *ngIf="!terminosUtilizacion && avisoPrivacidad">
                        <h3>Términos de utilización del micrositio</h3>
                        <ol>
                            <li class="text-left">Conducirse de manera honesta, cordial, respetuosa y profesional, sin excepción.</li>
                            <li class="text-left">Compartir y/o enviar al Inmujeres, información original, veraz y verificable. Una vez compartida con el Inmujeres, se da la autorización para que esta sea compartida para su difusión, salvo pacto en contrario. </li>
                            <li class="text-left">No realizar ningún acto o emitir discursos que atenten contra la dignidad humana y/o derechos humanos de las personas o sectores de la sociedad, a saber, descalificaciones, humillaciones, acoso u hostigamiento sexuales, discriminación,
                                entre otros.</li>
                            <li class="text-left">Procurar la participación, cooperación, colaboración y buen trato entre las y los participantes en todos los espacios de coincidencia: físicos y virtuales.</li>
                            <li class="text-left">Fortalecer los vínculos entre diferentes actores para propiciar condiciones y acciones que favorezcan la igualdad sustantiva y los derechos humanos de las mujeres, adolescentes y niñas.</li>
                            <li class="text-left">Guardar la debida confidencialidad de los datos o información de la que se tenga acceso o se conozca, en el marco de las actividades de vinculación y alianzas que promueva el Inmujeres.</li>
                            <li class="text-left">Informar sobre los posibles conflictos de interés que pudieran presentarse en virtud de las actividades de vinculación y alianzas que promueva el Inmujeres.</li>
                            <li class="text-left">Propiciar espacios de colaboración y trabajo libres de violencia de género.</li>
                            <li class="text-left">Impulsar actividades y/o difundir información que favorezca la igualdad sustantiva y derechos humanos de las mujeres, de conformidad con los principios de derechos humanos establecidos en la Constitución Política de los Estados
                                Unidos Mexicanos.</li>
                            <li class="text-left">Establecer relaciones armónicas y constructivas con el Inmujeres.</li>
                        </ol>
          <!--              <div class="col-md-12"> -->
                            <p class="text-left">**El Inmujeres procurará la máxima difusión de la información enviada por las participantes, siempre que estas sean compatibles con el punto 9 del presente, tengan una relevancia social y cuyas características técnicas lo hagan
                                viable. El Inmujeres tendrá la potestad de decidir si dichos contenidos serán o no publicados. </p>
                            <p class="text-left">**En caso de incumplir cualquiera de las reglas anteriores, el Inmujeres tendrá la facultad indelegable de dar de baja y dejar de compartir las actividades de estas organizaciones. </p>
          <!--              </div> -->
                        <!-- B.C. Se agregó el atributo (click)="movementElementTop()"
                        <input type="checkbox" name="terminosUtilizacion" [(ngModel)]="terminosUtilizacion"> <b> Acepto los términos de utilización del micrositio </b> -->
                        <br><br>
                        <form #privacidad="ngForm">
                            <input type="checkbox" name="terminosUtilizacion" [(ngModel)]="terminosUtilizacion" (click)="movementElementTop()"> <b> Acepto los términos de utilización del micrositio </b>
                        </form>
                        <br><br><br>
                    </div>
                    <!-- form registro -->
                    <div>
                        <form #formCliente="ngForm" *ngIf="avisoPrivacidad  && terminosUtilizacion">

                            <div class="col-md-12 p-0">
                                <div class="form-group col-md-6">
                                    <label for="categoria">Categoría: <span class="obligatorio">*</span></label>
                                    <div>
                                        <select class="form-control" name="categoria" [(ngModel)]="dependencia.categoria" required>
                                            <option [ngValue]="undefined">Seleccionar una categoría</option>
                                            <option *ngFor="let categoria of categorias" [ngValue]="categoria">{{categoria.nombre}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group col-md-6">
                                <label for="nombre">Nombre de su Organización/Institución: <span class="obligatorio">*</span> </label>
                                <div class="">
                                    <input type="text" class="form-control" [(ngModel)]="dependencia.nombre" name="nombre" required minlength="4" #nombre="ngModel">
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="paginaWeb">Página web: </label>
                                <div>
                                    <input type="text" class="form-control" [(ngModel)]="dependencia.paginaWeb" name="paginaWeb" #paginaWeb="ngModel">
                                </div>
                            </div>


                            <div class="col-md-12 p-0">
                                <h4 class="px-4">Directora/or general</h4>

                                <div class="form-group col-md-4 ">
                                    <label for="director">Nombre/s:  <span class="obligatorio">*</span> </label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.nombreDirector" name="directorName" required minlength="4" #director="ngModel">
                                    </div>
                                </div>

                                <div class="form-group col-md-4 ">
                                    <label for="director">Primer apellido:  <span class="obligatorio">*</span> </label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.primerApellidoDirector" name="directorApellidoPat" required minlength="4" #directorApellidoPat="ngModel">
                                    </div>
                                </div>

                                <div class="form-group col-md-4 ">
                                    <label for="director">Segundo apellido: </label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.segundoApellidoDirector" name="directorApellidoMat" minlength="4" #directorApellidoMat="ngModel">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="email">Correo institucional: <span class="obligatorio">*</span> </label>
                                <div class="">
                                    <input type="text" class="form-control" [(ngModel)]="dependencia.correo" name="correo" required email #correo="ngModel">
                                </div>
                            </div>

                            <div class="form-group col-md-6 ">
                                <label for="telefono">Teléfono de contacto:  <span class="obligatorio">*</span></label>
                                <div>
                                    <input type="text" class="form-control" [(ngModel)]="dependencia.telefono" name="telefono" #telefono="ngModel">
                                </div>
                            </div>

                            <div class="col-md-12 p-0">

                                <h4 class="px-4">Domicilio</h4>

                                <div class="form-group col-md-6">
                                    <label for="domicilio">Calle:  <span class="obligatorio">*</span></label>
                                    <div class="">
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.calle" name="calle" #domicilio="ngModel">
                                    </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="domicilio">Número exterior:  <span class="obligatorio">*</span></label>
                                    <div class="">
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.numeroExterior" name="numeroExterior" #domicilio="ngModel">
                                    </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="domicilio">Número interior: </label>
                                    <div class="">
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.numeroInterior" name="numeroInterior">
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="estado">Entidad federativa: <span class="obligatorio">*</span></label>
                                    <div>
                                        <select [compareWith]="compararEstatdos" (change)="cargarMunicipios()" class="form-control" required name="estado" [(ngModel)]="dependencia.estado">
                                            <option [ngValue]="undefined">Seleccionar un estado</option>
                                            <option *ngFor="let item of estados" [ngValue]="item"> {{item.nombre}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="municipio">Municipio o alcaldía: <span class="obligatorio">*</span></label>
                                    <div>
                                        <select [compareWith]="compararMunicipios" (change)="cargarLocalidades()" class="form-control" name="municipio" [(ngModel)]="dependencia.municipio" required>
                                            <option [ngValue]="undefined">Seleccionar un municipio</option>
                                            <option *ngFor="let municipio of municipios" [ngValue]="municipio">{{municipio.nombre}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="municipio">Colonia: <span class="obligatorio">*</span></label>
                                    <div>
                                        <select [compareWith]="compararLocalidades" class="form-control" name="localidad" [(ngModel)]="dependencia.localidades" required>
                                            <option [ngValue]="undefined">Seleccionar una colonia</option>
                                            <option *ngFor="let localidad of localidades" [ngValue]="localidad">{{localidad.nombre}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="col-md-4 p-0" *ngIf="this.dependencia.localidades">
                                        <label for="domicilio">Código Postal: <span class="obligatorio">*</span></label>
                                        <div class="">
                                            <input type="text" class="form-control" [(ngModel)]="this.dependencia.localidades.cp" name="cp" disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12 p-0">

                                <h4 class="px-4">Contacto</h4>

                                <div class="form-group col-md-4">
                                    <label for="nombre">Nombre/s: <span class="obligatorio">*</span> </label>
                                    <div class="">
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.nombreContacto" name="nombreContacto" required minlength="4">
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="nombre">Primer apellido: <span class="obligatorio">*</span> </label>
                                    <div class="">
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.primerApellidoContacto" name="apellidoPatContacto" required minlength="4">
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="nombre">Segundo apellido:  </label>
                                    <div class="">
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.segundoApellidoContacto" name="apellidoMatContacto" minlength="4">
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="email2">Correo electrónico de contacto: <span class="obligatorio">*</span> </label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.correoContacto" name="correoContacto" required email>
                                    </div>
                                </div>

                                <div class="form-group col-md-6 ">
                                    <label for="telefono">Teléfono de contacto:  <span class="obligatorio">*</span></label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.numeroContacto" name="telefonoContacto">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-12 p-0">
                                <h4 class="px-4">Representante legal</h4>
                                <div class="col-md-4 ">
                                    <label for="representanteLegal">Nombre/s: </label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.nombreRepresentante" name="representanteLegalNombre">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label for="representanteLegal">Primer apellido: </label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.primerApellidoRepresentante" name="representanteLegalApellidoPat">
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label for="representanteLegal">Segundo apellido:</label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.segundoApellidoRepresentante" name="representanteLegalApellidoMat">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-12" *ngIf="this.dependencia.categoria.id == 1">
                                <div class=" pl-0 col-md-6">
                                    <label for="cluni">CLUNI: </label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="dependencia.cluni" name="cluni">
                                    </div>
                                </div>
                                <div class="col-md-6"></div>
                            </div>                            

                            <div class="form-group col-md-12 mt-4" *ngIf="this.dependencia.categoria.id == 1">
                                <label for="2">¿Es donataria autorizada?: </label><br>
                                <div class="col-md-4">
                                    <div class="col-md-6">
                                        Si <input type="checkbox" (change)="eventDonataria($event)" [checked]="isDonataria()" name="isDonataria" id="2" value="Y">
                                    </div>
                                    <div class="col-md-6">
                                        No <input type="checkbox" (change)="eventDonataria($event)" [checked]="isNotDonataria()" name="isDonataria" id="2" value="N">
                                    </div>
                                </div>

                            </div>

                            <div class="form-group col-md-12 mt-4">
                                <label for="2">¿Considera que su trabajo contribuye a la igualdad entre mujeres y hombres?:  <span class="obligatorio">*</span></label> <br/>
                                <div class="col-md-4">
                                    <div class="col-md-6">
                                        Si <input type="checkbox" (change)="eventContribuyente($event)" [checked]="isContribuyente()" name="contribuyenteIgualdad" id="2" value="Y">
                                    </div>
                                    <div class="col-md-6">
                                        No <input type="checkbox" (change)="eventContribuyente($event)" [checked]="isNotContribuyente()" name="contribuyenteIgualdad" id="2" id="2" value="N">
                                    </div>
                                </div>

                            </div>


                            <div class="col-md-12">
                                <label for="">¿Cuál es el tema o temas de su especialidad?:  <span class="obligatorio">*</span></label>
                                <div class="panel panel-default">
                                    <div class="panel-body">
                                        <div class="col-md-4" *ngFor="let tema of checkBoxTemas; let i = index ">
                                            <div class="checkbox">
                                                <label>
                                                    <input type="checkbox" id="{{i}}" name="tema{{i}}" value="{{tema.id}}"
                                                    (change)="changeSelection()" [(ngModel)]= "tema.isSelected" >
                                                    <!-- [(ngModel)]="dependencia.temas.i" -->
                                                    {{tema.nombre}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="nombre">¿Cuántos años tiene de trabajar en este/estos tema/s? <span class="obligatorio">*</span> </label>
                                <div class="">
                                    <input type="number" class="form-control" [(ngModel)]="dependencia.timeTemas" name="timeTemas" required #timeTemas="ngModel">
                                </div>
                            </div>



                            <!-- <div class="form-group mb-4">
                                <div class="col-md-8">
                                    <label for="">Imagen</label>
                                    <form [formGroup]="myForm">
                                        <input type="file" class="form-control" id="file" formControlName="file" requiredFileType="image/png" (change)="onFileSelected($event)">
                                    </form>
                                </div>
                                <div class="col-md-4">
                                    <img [src]="this.imgSrc" *ngIf="this.imgSrc" class="img-thumbnail" style="width: 80px; ">
                                </div>
                            </div> -->

                            <!--  <div class="form-group col-md-12 mt-4">
                                <label for="objetoSocial">Objeto Social:</label>
                                <div class="">
                                    <textarea name="objetoSocial" class="form-control" rows="8" cols="80" [(ngModel)]="dependencia.objetoSocial"></textarea>
                                </div>
                            </div> -->

                            <!--  <div class="form-group col-md-12">
                                <label for="estado">Usuario: <span class="obligatorio">*</span></label>
                                <div>
                                    <select class="form-control" [compareWith]="compararUsuarios" required name="usuario" [(ngModel)]="this.usuario ">
                                        <option [ngValue]="undefined">Selecciona un usuario</option>
                                        <option *ngFor="let item of usuarios" [ngValue]="item">{{item.nombre + " "+ item.apellido }}</option>
                                    </select>
                                </div>
                            </div> -->

                            <div class="form-group">
                                <div class="col-md-12">
                                    <button class="btn btn-success m-2 float-right" role="button" (click)='create()' *ngIf="!dependencia.id else elseBlock" [disabled]="!formCliente.form.valid" [routerLink]="['/inicio']">Guardar</button>
                                    <ng-template #elseBlock>
                                        <button class="btn btn-warning m-2 float-right" role="button" (click)='update()'>Guardar</button>
                                    </ng-template>
                                    <button type="button" class="btn btn-primary m-2 float-right" [routerLink]="['/dependencias']">Cancelar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Fin form -->
                </div>
            </div>
        </div>
    </div>
</div>
