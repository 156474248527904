import { Localidades } from './localidades';
import { Municipio } from './municipios';
import { Categoria } from './categorias';
import { Estado } from './estados';
import { Tema } from './tema';

export class Dependencia {
  
  id: number;
  nombre: string;
  paginaWeb: string;

  nombreDirector: string;
  primerApellidoDirector: string;
  segundoApellidoDirector: string;
  correo: string;
  telefono: string;

  calle: string;
  numeroExterior: string ;
  numeroInterior: string;
  estado: Estado;
  municipio: Municipio;
  localidad: Localidades;

  nombreContacto: string;
  primerApellidoContacto: string;
  segundoApellidoContacto: string;
  correoContacto: string;
  numeroContacto: string;

  nombreRepresentante: string;
  primerApellidoRepresentante: string;
  segundoApellidoRepresentante: string;

  cluni: string;
  categoria: Categoria = new Categoria();
  donataria: boolean;
  contributyenteIguadad: boolean;

  timeTemas: number;
  temas: Tema[]=[];

  createAt: string;
  objetoSocial: string;
  anioCreacion: string;
  foto: string;
  color: String;
}
