import { ChangeDetectionStrategy, Component, Input, OnInit,NO_ERRORS_SCHEMA,NgModule } from '@angular/core';
import { Publicacion } from '../../../models/publicacion';
import { PublicacionesService } from '../../../service/publicaciones.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";
import { FormsModule, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Usuario } from 'src/app/models/usuario';
import { UsuariosService } from 'src/app/service/usuarios.service';
import { Localidades } from 'src/app/models/localidades';
import { URL_HOSTNAME } from '../../../store/config';

@Component({
  selector: 'app-form',
  templateUrl: './publicacionformnoticia.component.html',
  styleUrls: ['../publicacion.component.css']
})
export class PublicacionFormNoticiaComponent implements OnInit {
  @Input() context: String;
  public showForm: boolean = true;
  public publicacion: Publicacion = new Publicacion();
  public titulo: String = "Registro nueva Noticia";
  public file: File;
  public fileName: string = null;
  public fileSelected: Boolean = false;
  public showFileButton: Boolean = true;
  
  public myForm: FormGroup = new FormGroup({
    file: new FormControl('', [Validators.required]),
  })

  constructor(private publicacionService: PublicacionesService,
    private usuariosService: UsuariosService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activateRoute.params.subscribe(
      (params) => {
        let id = params['id']
        if (id) {
          this.titulo = "Actualiación de Noticia ";
          this.cargarNoticia(id);
        } else {
          this.titulo = "Registro de Noticia ";
        }
      }
    )
  }

  cargarNoticia(id: string){
    //this.titulo ="Editar dependencia"
    this.publicacionService.obtenerPublicacion(id)
      .subscribe((publicacion) => {
        console.log(publicacion)
        publicacion.fechaPublicacion = publicacion.fechaPublicacion.substring(0,10);
        publicacion.contenido = URL_HOSTNAME+publicacion.contenido;
        this.showFileButton = false;
        this.publicacion = publicacion;
      });
  }

  create(){
    if(!this.fileSelected){
      swal.fire('Advertencia',`Seleccione un documento` ,'warning' );
      return false;
    }
    swal.fire('Guardando',`Espere un momento` ,'info' );
    this.publicacion.contenido = this.fileName;
    this.publicacion.tipoPublicacion = "noticias";
    this.publicacion.categoria = null;
    this.publicacionService.createNoticia(this.publicacion).subscribe((publicacion => {
      console.log(publicacion);
      this.publicacion = publicacion;
      this.publicacionService.subirArchivo(this.file, this.publicacion.id, this.publicacion.contenido).subscribe(
        (status) => {
          console.log(">>>C");
          console.log(status);
          swal.fire('Nueva noticia', `Noticia: "${this.publicacion.titulo}" guardada con exito!`, 'success');
          this.router.navigate(['/publicaciones']);
        }
      );
    }))
  }

  update(){
    //swal.fire('Actualizando',`Espere un momento` ,'info' );
    if(this.fileSelected){
      this.publicacion.contenido = this.fileName;
    }else{
      this.publicacion.contenido = "no cambiar";
    }
    this.publicacionService.createNoticia(this.publicacion).subscribe((publicacion => {
      console.log(publicacion);
      this.publicacion = publicacion;
      this.publicacion.categoria = null;
      if(this.fileSelected){
        this.publicacionService.subirArchivo(this.file, this.publicacion.id, this.publicacion.contenido).subscribe(
          (status) => {
            swal.fire('Noticia actualizada', `Noticia: "${this.publicacion.titulo}" actualizada con exito!`, 'success');
            this.router.navigate(['/publicaciones']);
          }
        );
      }else{
        swal.fire('Noticia actualizada', `Noticia: "${this.publicacion.titulo}" actualizada con exito!`, 'success');
        this.router.navigate(['/publicaciones']);
      }
    }))
  }



  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      //const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsDataURL(this.file);
      
      reader.onload = () => {
        this.fileSelected = true;
        this.fileName = this.file.name;
        /*this.myForm.patchValue({
          fileSource: reader.result
        });*/
      };
    }
  }

  showFileButtonEvent():void {
    this.showFileButton = this.showFileButton ? false : true;
  }
}
