import { Categoria } from "./categorias";

export class Publicacion {
    id: number;
    titulo: string;
    contenido: string;
    imagen: string;
    autor: string;
    fechaPublicacion: string;
    tipoPublicacion: string;
    categoria: string = "Seleccione el tipo de Organización";
}
