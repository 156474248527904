<div class="container">
    <div class="row">
        <div class="col-sm-12 contentMesa">
         <div class="container text-center">
                <h2> Mujer Exporta</h2><br>
            </div>
            <a routerLink="/inicio/vinculacionesyalianzas" class="btn-flotante">Regresar</a>
            <br />

            <div class="container" *ngIf="this.activeFiles['0'].tab" id="pills-primer">
                <div class="container" data-toggle="modal" data-target="#lightbox">
                    <div class="container-fluid text-center" style="background-color: #59287f; color: white;">
                        <h3>Mujer Exporta</h3>
                    </div><br>
                    <div class="row">
                        <div class="container-fluid">
                            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                                </ol>
                                <div class="carousel-inner">

                                    <div class="carousel-item active">
                                        <img class="d-block w-100" src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/mujerExporta.jpg" alt="...">
                                    </div>

                                    <div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/2.jpg" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/3.jpg" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/4.jpg" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/5.jpg" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div><br><br>
                    <div class="container" style="text-align: justify;">
                        <p class="font-weight-bold">Objetivo: 
                            <br><br>
                            <br><br>
                        </p>
                    </div><br>

                    <!--Circles-->
                    <div class="circles circles-pills mt-5">
                        <div class="col-md-3">
                            <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                                <div class="d-flex flex-column">
                                    <div class="rounded-circle purple mx-auto p-3">
                                        <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                    </div>
                                    <div class="text-center font-weight-bold text-purple p-2">
                                        <p>VIDEOS</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <a class="circles ml-n5" id="img" (click)="toogleEvent($event)" aria-selected="true" (click)="toogleDocs($event)">
                                <div class="d-flex flex-column">
                                    <div class="rounded-circle orange mx-auto p-3">
                                        <span class="glyphicon glyphicon-picture p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                    </div>
                                    <div class="text-center">
                                        <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                <div class="d-flex flex-column">
                                    <div class="rounded-circle green mx-auto p-3">
                                        <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                    </div>
                                    <div class="text-center">
                                        <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                <div class="d-flex flex-column">
                                    <div class="rounded-circle purple mx-auto p-3">
                                        <span class="glyphicon glyphicon-file p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                    </div>
                                    <div class="text-center font-weight-bold text-purple p-2">
                                        <p>PRESENTACIONES</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!-- fin circles -->
                    <div class="col-md-12">
                        <div class="col-md-12" *ngIf="showVideo">
                            <h3 class="text-left">Videos</h3>
                            <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                            <br>
                            <div class="col-12 col-md-6">
                                <iframe _ngcontent-gjh-c65="" scrolling="no" frameborder="0" allowfullscreen="true" 
                                allow="clipboard-write; encrypted-media; picture-in-picture; web-share" 
                                style="border: none; overflow: hidden; width: 100%; height: 300px;" 
                                src="https://www.facebook.com/plugins/video.php?height=314&amp;href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F3443990115828561%2F&amp;show_text=false&amp;width=560&amp;t=0">                                
                                </iframe>
                                <p> Original: economías creativas y arte popular mexicano </p>

                            </div>
                        </div>

                        <div class="col-md-12" *ngIf="showImg">
                            <h3 class="text-left">Imagenes</h3>
                            <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;"><br>
                            <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center" data-toggle="modal" data-target="#lightbox">
                            <!--
                            <div class="col-md-12" *ngIf="paginaActiva == 1">
                                <div class="col-md-6">
                                    <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/1.jpg" class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2" data-target="#indicators" data-slide-to="0" alt=""/>
                                </div>
                                <div class="col-md-6">
                                    <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/2.jpg" class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2" data-target="#indicators" data-slide-to="0" alt="" />
                                </div>
                            </div>
                          
                            <div class="col-md-12" *ngIf="paginaActiva == 2">
                                <div class="col-md-6">
                                    <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/3.jpg" class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2" data-target="#indicators" data-slide-to="0" alt="" />
                                </div>
                                <div class="col-md-6">
                                    <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/4.jpg" class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2" data-target="#indicators" data-slide-to="0" alt="" />
                                </div>
                            </div>
                        -->

                           <!--se modifico---> 

                            <div class="row justify-content-center" style="justify-content: center;">
                       <!--         <nav aria-label="..." class="align-items-center align-content-center col-12 col-md-12 col-lg-12" style="align-items: center;">
                                <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center" style="align-content: center;" id="pills-tab">
                                        <li class="nav-item active text-center">
                                            <a class="nav-link" id="1" (click)="toogleEvent($event)" aria-selected="true">
                                                <br> 1
                                            </a>
                                        </li>
                                        <li class="nav-item text-center">
                                            <a class="nav-link" id="2" (click)="toogleEvent($event)" aria-selected="false">
                                                <br> 2
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            -->
                            </div>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="showDoc">
                            <h3 class="text-left">Documentos</h3>
                            <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                            <div class="row d-flex flex-wrap align-items-center" data-toggle="modal" data-target="#lightbox">
                             <!--   <div class="col-sm-3  my-5 text-center">
                                    <a href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Programa/Programa Tema 1 Violencia de género y acceso a la justicia. 4 d e Oct..pdf" target="_blank">
                                        <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #4D92DF;"></span>
                                    </a><br>
                                    <a href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Programa/Programa Tema 1 Violencia de género y acceso a la justicia. 4 d e Oct..pdf" target="_blank">
                                        Programa Tema 1 Violencia y acceso a la justicia
                                    </a>
                                </div>
                            -->
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="showPre">
                            <h3 class="text-left">Presentaciones</h3>
                            <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                            <div class="row d-flex flex-wrap align-items-center" data-toggle="modal" data-target="#lightbox">
                             <!--   <div class="col-sm-3  my-5 text-center">
                                    <a href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Brigada callejera. ELVIRA MADRID.pdf" target="_blank">
                                        <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #db8f1b;"></span>
                                    </a><br>
                                    <a href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Brigada callejera. ELVIRA MADRID.pdf" target="_blank">
                                        Brigada callejera. ELVIRA MADRID
                                    </a>
                                </div>
                            -->
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
        </div>
    </div>
</div>

<div class="col-md-12">
</div>