import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from '../models/usuario';
import { URL_CONFIG } from '../store/config';

@Injectable({
  providedIn: 'root'
})


export class LoginService {
  private _usuario: Usuario;

  private _token: string;

  constructor(private http: HttpClient) { }

  public get usuario():Usuario{
    if(this._usuario != null){
      return this._usuario;
    }else if(this._usuario == null && sessionStorage.getItem('usuario') != null){
      this._usuario = JSON.parse(sessionStorage.getItem('usuario')) as Usuario;
      return this._usuario;
    }

    return new Usuario();
  }

  public get token():string{
    if(this._token != null){
      return this._token;
    }else if(this._token == null && sessionStorage.getItem('token') != null){
      this._token = sessionStorage.getItem('token');
      return this._token;
    }
    return null;
  }

  login(usuario: Usuario):Observable<any>{
    const urlEndPoint = URL_CONFIG + '/oauth/token';
    const credenciales = btoa('angularapp'+':'+'1234');
    const httpHeaders = new HttpHeaders({'Content-type':'application/x-www-form-urlencoded',
    'Authorization':'Basic ' + credenciales});
    let params = new URLSearchParams();
    params.set('grant_type','password');
    params.set('username', usuario.email);
    params.set('password',usuario.password);
    return this.http.post<any>(urlEndPoint, params.toString(), {headers:httpHeaders});
  }

  guardarUsuario(response: any):void{
      let payload = this.obtenerDatosToken(response.access_token);
      console.log(payload);
      this._usuario = new Usuario();
      this._usuario.nombre = payload.nombre;
      this._usuario.apellido = payload.apellido;
      this._usuario.email = payload.email;
      this._usuario.username = payload.user_name;
      this._usuario.roles = payload.authorities;
      this._usuario.id = payload.id;
      sessionStorage.setItem('usuario', JSON.stringify(this._usuario));
  }

  guardarToken(token:string):void{
      this._token = token;
      sessionStorage.setItem('token', token);
  }

  obtenerDatosToken(access_token:string):any{
    if(access_token != null){
      this.guardarToken(access_token);
      return JSON.parse(atob(access_token.split(".")[1]));
    }
    return null;
  }

  isAuthenticated(): boolean{
    let payload = this.usuario;    
    if(payload != null && payload.nombre && payload.nombre.length>0){
      return true;
    }
    return false;
  }

  hasRoles(role: string): boolean{
    if(this.usuario.roles.includes(role)){
      return true;
    }
    return false;
  }

  logout():void{
    this._token = null;
    this._usuario = null;
    sessionStorage.clear();
  }

}
