<div class="container dep mb-0" (scroll)='checkthis($event)'>
    <div class="panel py-0 m-2 ">
        <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
            <li class="breadcrumb-item"> <a routerLink="/inicio/vinculacionesyalianzas">Vinculaciones y alianzas</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{titulo}}</li>
        </ol>
    </div>

    <a routerLink="/inicio/vinculacionesyalianzas" class="btn-flotante">Regresar</a>
    <br />

    <div class="container osc text-center mb-5">
        <div class="row">
            <div class="col-md-12">
                <h3>{{titulo}}</h3>
            </div>
        </div>
    </div>



    <!--  <div class="row">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">Lista de {{titulo}}</h3>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-sm-6 col-md-6" *ngFor="let dependencia of dependencias; let i = index">
            <div class="thumbnail">
              <img src="./assets/img/large_electas2.jpg" alt="..." class="img img-responsive">
              <div class="caption">
                <div class="">
                  <em><strong>{{dependencia.nombre}}</strong></em>
                  <p></p>
                </div>
                <div class="">
                  <small><span class="glyphicon glyphicon-pushpin" aria-hidden="true"></span> <strong> Ubicación:</strong>  {{dependencia.municipio.estado.nombre}} - {{dependencia.municipio.nombre}}</small>
                  <p></p>
                </div>
                <div class="">
                  <p><span class="glyphicon glyphicon-tags" aria-hidden="true"></span> <strong> Objeto social:</strong>  {{dependencia.tema | slice:0:200 }}...</p>
                </div>
                <div class="">
                  <a href="#">Continuar leyendo...</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <app-buscador [(categoria)]="this.categoria" (avisos)="setAvisos($event)" (eventos)="setEventos($event)" (dependencias)="setDependencias($event)" (noticias)="setNoticias($event)"></app-buscador>
    <app-cards-dependencias (defult)="false" [(dependencias)]="this.dependencias" [(categoria)]="this.categoria" [page]="this.page">
    </app-cards-dependencias>

    <div class="container p-0">
        <div class="row py-5">
            <ng-container *ngIf="!this.dependencias; else sinRegistros">
                <div class="col-md-12" *ngIf="!this.dependencias">
                    <div class="jumbotron">
                        <h6 class="display-6">Por favor filtre por Estado, Municipio, Nombre o Tematica para mostrar la información.</h6>
                    </div>
                </div>
            </ng-container>
            <ng-template #sinRegistros>
                <div class="col-md-12" *ngIf="this.dependencias.length === 0">
                    <div class="jumbotron">
                        <h6 class="display-6">Sin registros</h6>
                    </div>
                </div>
            </ng-template>




            <div class="col-md-12" *ngIf="this.dependencias">
                <div class="col-md-12" *ngFor="let dependencia of dependencias">
                    <div>
                        <div class="card green">
                            <div class="card-body text-center">
                                <a data-toggle="collapse" href="#collapse{{dependencia.id}}" role="button" aria-expanded="false" aria-controls="collapse{{dependencia.id}}">
                                    <h5 class="card-title  text-white">Organización: {{dependencia.nombre}}</h5>
                                </a>
                            </div>
                        </div>

                        <div class="col-md-12 mt-5 p-0" *ngIf="this.dependencias">
                            <div class="collapse" id="collapse{{dependencia.id}}">
                                <div class="col-md-4">
                                    <div class="card green">
                                        <img src="assets/img/inmujeres.png" class="card-img-top" alt="...">
                                        <div class="card-body text-center text-white" style="min-height: 250px;">
                                            <p class="card-title">Información de contacto </p>
                                            <div class="card-text">
                                                <p>{{dependencia.nombre}}</p>
                                                <p>{{dependencia.nombreContacto != null ? (dependencia.nombreContacto + " " + (dependencia.primerApellidoContacto != null ? dependencia.primerApellidoContacto : "") + " "+ (dependencia.segundoApellidoContacto != null ? dependencia.segundoApellidoContacto : "")): ""}}</p>
                                                <p>{{dependencia.numeroContacto != null ? dependencia.numeroContacto : "" }}</p>
                                                <p>{{dependencia.correoContacto != null ? dependencia.correoContacto : "" }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card orange">
                                        <img src="assets/img/inmujeres.png" class="card-img-top" alt="...">
                                        <div class="card-body text-center text-white" style="min-height: 250px;">
                                            <p class="card-title">Principales Actividades</p>
                                            <div class="card-text p-2">
                                                <p *ngFor="let tema of dependencia.temas"> {{tema.nombre}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="card purple">
                                        <img src="assets/img/inmujeres.png" class="card-img-top" alt="...">
                                        <div class="card-body text-center text-white" style="min-height: 250px;">
                                            <p class="card-title">Eventos y Fotos</p>
                                            <div class="card-text p-2" *ngIf="this.eventos">
                                                <p *ngFor="let evento of eventos"> {{evento.nombre}}</p>
                                            </div>
                                            <ng-container *ngIf="eventos; else elseEventos">
                                                <div class="card-text p-2" *ngIf="this.eventos">
                                                    <p *ngFor="let evento of eventos"> {{evento.nombre}}</p>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseEventos>
                                                <div class="card-text p-2">
                                                    <p></p>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
