<div class="container" id="addUser">
    <h3>{{ titulo }}</h3>
    <hr class="red">
    <div class="row">

        <div class="col-md-12">
            <div class="container">
                <div class="col-md-12">
                    <!-- form registro -->
                    <div>
                        <form>
                            <div class="form-group col-md-6">
                                <label for="telefono">Titulo:  <span class="obligatorio">*</span></label>
                                <div>
                                    <input type="text" class="form-control"  name="telefono">
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label for="telefono">Imagen de portada:  <span class="obligatorio">*</span></label>
                                <button type="button" class="btn btn-info btn-block">Subir</button>
                            </div>
                            <div class="form-group col-md-3">
                                <label for="date">Fecha:  <span class="obligatorio">*</span></label>
                                <div>
                                    <input type="date" class="form-control"  name="date">
                                </div>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="telefono">Contenido:  <span class="obligatorio">*</span></label>
                                <!-- Aqui va el editor -->
                            </div>
                            <div class="form-group col-md-6">
                            </div>
                            <div class="form-group col-md-3">
                                <button type="button" class="btn btn-primary btn-block">Cancelar</button>
                            </div>
                            <div class="form-group col-md-3">
                                <button type="button" class="btn btn-success btn-block">Guardar</button>
                            </div>
                        </form>
                    </div>
                    <!-- Fin form -->
                </div>
            </div>
        </div>
    </div>
</div>
