import { ChangeDetectionStrategy, Component, Input, OnInit,NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { PublicacionesService } from '../../../service/publicaciones.service';
import { Publicacion } from '../../../models/publicacion';
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";

@Component({
  selector: 'app-form',
  templateUrl: './publicacionformevento.component.html',
  styleUrls: ['../publicacion.component.css']
})

export class PublicacionFormEventoComponent implements OnInit {
  @Input() context: String;
  public showForm: boolean = true;
  public publicacion: Publicacion = new Publicacion();
  public titulo: String;
  public file: File;
  public fileName: string = null;
  public fileSelected: Boolean = false;
  public showFileButton: Boolean = false;
  public showImg = false;
  
  public myForm: FormGroup = new FormGroup({
    file: new FormControl('', [Validators.required]),
  })

  constructor(private publicacionService: PublicacionesService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activateRoute.params.subscribe((params) => {
      let id = params['id'];
      if(id){
        this.showFileButton = false;
        this.titulo = "Editar evento";
        this.publicacion.id = id;
        this.publicacionService.obtenerEvento(id).subscribe((publicacion) => {
          this.publicacion = publicacion;          
          let fecha = this.publicacion.fechaPublicacion.substring(0,10); 
          this.publicacion.fechaPublicacion = fecha;
          console.log(this.publicacion.id);
        });
      }else{
        this.showFileButton = true;
        this.titulo = "Registrar nuevo evento";
        this.publicacion = new Publicacion();
        this.publicacion.tipoPublicacion = "eventos";
        console.log(this.publicacion.id);
      }
    });    
  }

  create(): void {
    this.publicacion.categoria = null;
    this.publicacionService.createNoticia(this.publicacion).subscribe((publicacion) => {
      this.publicacionService.subirArchivo(this.file, publicacion.id, this.fileName).subscribe((status) => {
        swal.fire('Nuevo evento', `Evento: "${this.publicacion.titulo}" guardada con exito!`, 'success');
        this.router.navigate(['/publicaciones']);
      });
    });
  }

  update(): void {
    console.log(this.fileName ? "ok" : "a")
    if(this.fileName == null){
      console.log("1---------------------------------")
      console.log(this.publicacion)
      this.publicacion.categoria = null;
      this.publicacionService.createNoticia(this.publicacion).subscribe((publicacion) => {
        console.log("1---------------------------------")
        console.log(publicacion)
        swal.fire('Evento actualizado', `Evento: "${this.publicacion.titulo}" guardada con exito!`, 'success');
        this.router.navigate(['/publicaciones']);
      });
    }else{
      console.log("2---------------------------------")
      console.log(this.publicacion)
      this.publicacionService.createNoticia(this.publicacion).subscribe((publicacion) => {
        console.log("2---------------------------------")
        console.log(publicacion)
        this.publicacionService.subirArchivo(this.file, publicacion.id, this.fileName).subscribe((status) => {
          swal.fire('Evento actualizado', `Evento: "${this.publicacion.titulo}" guardada con exito!`, 'success');
          this.router.navigate(['/publicaciones']);
        });
      });
    }
  }

  cancelar(): void{
    this.router.navigate(['/publicaciones/2/0']);
  }

  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      //const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsDataURL(this.file);
      
      reader.onload = () => {
        this.fileSelected = true;
        this.fileName = "/assets/img/" + this.file.name;

        if(this.publicacion.tipoPublicacion == "eventos"){
          this.publicacion.imagen = this.fileName;
        }
      };
    }
  }

  showFileButtonEvent():void {
    this.showFileButton = !this.showFileButton;
  }

  loadImg():void{
    this.showImg = !this.showImg;
  }

}
