import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { Categoria } from '../../models/categorias';
import { Dependencia } from '../../models/dependecia';
import { DependenciaService } from '../../service/dependencias.service';
import { colors } from '../util/colors';
import { trigger, style, animate, transition } from '@angular/animations';
import { DomSanitizer } from "@angular/platform-browser";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { Publicacion } from 'src/app/models/publicacion';
import { PublicacionesService } from '../../service/publicaciones.service';

import { AlianzaContenido } from '../../models/alianza-contenido';
 

@Component({
  selector: 'app-cards-dependencias',
  animations: [
    trigger('rigthEase', [
      transition(':enter', [
        style({ transform: 'translateX(80%)' }),
        animate('.8s', style({ transform: 'translateX(0%)' })),
      ]),
    ]),
    trigger('leftEase', [
      transition(':enter', [
        style({ transform: 'translateX(-80%)' }),
        animate('.3s', style({ transform: 'translateX(0%)' })),
      ]),
    ]),
  ],
  templateUrl: './cards-dependencias.component.html',
  styleUrls: ['./cards-dependencias.component.css'],
})
export class CardsDependenciasComponent implements OnInit {
  /*  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport; */

  @Input() dependencias: Dependencia[];
  @Input() defult: Boolean;
  @Input() categoria: Categoria;
  @Input() page: number;
  private maxPage: number = 0;
  public color: colors;
  public titulo: String;
  public categorias: Categoria[];
  public finCards: Boolean = false;

  publicacionesOSC: Publicacion[];
  publicacionesIP: Publicacion[];
  publicacionesIES: Publicacion[];
  publicacionesDisponibles: number;

  alianzaIP: Publicacion[];
  alianzaIES: Publicacion[];

  public alianzacontenido: AlianzaContenido = new AlianzaContenido();
  public consultivoPublicacion = [];
  public socialPublicacion = [];

  constructor(
    private dependeciaService: DependenciaService,
    private sanitizer: DomSanitizer,
    private spinner: MatProgressSpinnerModule,
    private publicacionesService: PublicacionesService
  ) {}

  ngOnInit(): void {
    if (this.defult === true) {
      console.log('-->' + this.defult);
      this.cargarAllCategorias();
      this.cargarAllAlianzas();
    }
    this.publicacionesService
      .obtenerPublicacionesLandingsPages()
      .subscribe((respuesta) => {
        let osc = [],
          ip = [],
          ies = [];
        for (let res of respuesta) {
          // console.log(res.categoria);
          if (res.categoria === 1) {
            osc.push(res);
            // console.log(osc);
            this.publicacionesDisponibles = respuesta.totalElements;
          }
          if (res.categoria === 2) {
            ip.push(res);
            // console.log(ip);
            this.publicacionesDisponibles = respuesta.totalElements;
          }
          if (res.categoria === 3) {
            ies.push(res);
            // console.log(ies);
            this.publicacionesDisponibles = respuesta.totalElements;
          }
        }
        this.publicacionesOSC = osc;
        // console.log(this.publicacionesOSC);
        this.publicacionesIP = ip;
        // console.log(this.publicacionesIP);
        this.publicacionesIES = ies;
        // console.log(this.publicacionesIES);
      });
  }

  cargarAllCategorias(): void {
    this.dependeciaService.getCategorias().subscribe((categorias) => {
      this.categorias = categorias;
    });
  }

  cargarAllAlianzas(): void {
    this.publicacionesService
      .obtenerPublicacionesLandingsPage()
      .subscribe((respuesta) => {
        let osc = [],
          ip = [],
          ies = [];
        for (let res of respuesta) {
          // console.log(res.categoria);
          if (res.categoria === 2) {
            ip.push(res);
            // console.log(ip);
            this.publicacionesDisponibles = respuesta.totalElements;
          }
          if (res.categoria === 3) {
            ies.push(res);
            // console.log(ies);
            this.publicacionesDisponibles = respuesta.totalElements;
          }
        }
        this.alianzaIP = ip;
        // console.log(this.alianzaIP);
        this.alianzaIES = ies;
        // console.log(this.alianzaIES);
        
        this.publicacionesDisponibles = respuesta.totalElements;
      });
  }

  mesAnno(fecha: string, conector: string): String {
    let conversor = new Date(fecha);
    return (
      ' (' + (conector != null ? conector : '') + conversor.getFullYear() + ')'
    );
  }
}

