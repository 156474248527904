<div class="container contact" id="contacto">
  <div class="row mt-5">
    <div class="col-md-12">
      <div class="panel py-0 m-0">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
          <li class="breadcrumb-item active"> <a routerLink="/contactos">Contacto</a></li>
        </ol>
      </div>
    </div>
    <div class="col-md-4 col-sm-8 col-xs-12 bottom-buffer">
      <div class="conteiner">
        <div class="row">
          <div class="col-md-12 pb-2">
            <div id="news" class="collapse collapse-horizontal
              {{showFormulario}}" style="display: block !important;">
              <div class="col-md-12">
                <h4>Conocer tu opinión</h4>
                <form #formCliente="ngForm" method="post">
                  <label for="organizacion">Organización: <span class="obligatorio">*</span></label>
                  <input type="text" class="form-control"
                    [(ngModel)]="contacto.organizacion" name="organizacion"
                    required minlength="4" #organizacion="ngModel" required>

                  <label for="nombre">Nombre: <span class="obligatorio">*</span></label>
                  <input type="text" class="form-control"
                    [(ngModel)]="contacto.nombre" name="nombre"
                    required minlength="4" #nombre="ngModel" required>

                  <label for="apellidoPaterno">Primer Apellido: <span class="obligatorio">*</span></label>
                  <input type="text" class="form-control"
                    [(ngModel)]="contacto.apellidoPaterno"
                    name="apellidoPaterno"
                    required minlength="4" #apellidoPaterno="ngModel" required>

                    <!--
      <label for="apellidoMaterno">Segundo Apellido: <span class="obligatorio">*</span></label>
      <input type="text" class="form-control"
        [(ngModel)]="contacto.apellidoMaterno"
        name="apellidoMaterno"
        #apellidoMaterno="ngModel" required>
                    -->
      <!-- se modifico no fuera requerido-->    
      <label for="apellidoMaterno">Segundo Apellido:</label>
      <input type="text" class="form-control"
        [(ngModel)]="contacto.apellidoMaterno"
        name="apellidoMaterno"
        #apellidoMaterno="ngModel">

                  <label for="correo">Correo Electronico: <span class="obligatorio">*</span></label>
                  <input type="email" class="form-control"
                    [(ngModel)]="contacto.correo" name="correo"
                    required minlength="4" #correo="ngModel" required>
                  <label for="estado">Estado: <span class="obligatorio">*</span></label>
                  <div>
                    <select [compareWith]="compararEstatdos"
                      (change)="cargarMunicipios()"
                      class="form-control" required name="entidad"
                      [(ngModel)]="estado" #entidad="ngModel" required>
                      <option [ngValue]="undefined">Seleccionar un
                        estado</option>
                      <option *ngFor="let item of estados"
                        [ngValue]="item"> {{item.nombre}}</option>
                    </select>
                  </div>
                  <label for="municipio">Municipio o alcaldía: <span class="obligatorio">*</span></label>
                  <div>
                    <select [compareWith]="compararMunicipios"
                      class="form-control" name="municipio"
                      [(ngModel)]="contacto.municipio" required
                      #municipio="ngModel">
                      <option [ngValue]="undefined">Seleccionar un
                        municipio</option>
                      <option *ngFor="let municipio of municipios"
                        [ngValue]="municipio">{{municipio.nombre}}
                      </option>
                    </select>
                  </div>

                  <label for="2">Genero:</label><br>
                  <div class="form-check form-check-inline">
                    <input type="radio" (change)="eventGenero($event)"
                      name="isGenero" id="2" value="f"
                      class="form-check-input">
                    <label for="exampleRadios1"
                      class="form-check-label">
                      Mujer
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" (change)="eventGenero($event)"
                      name="isGenero" id="2" value="m"
                      class="form-check-input">
                    <label for="exampleRadios1"
                      class="form-check-label">
                      Hombre
                    </label>
                  </div>
                  <!--se modifico otro-->
                  <div class="form-check form-check-inline">
                    <input type="radio" (change)="eventGenero($event)"
                      name="isGenero" id="2" value="o"
                      class="form-check-input">
                    <label for="exampleRadios1"
                      class="form-check-label">
                      Otro
                    </label>
                  </div>
              
                  <br><br>

                  <div class="form-group">
                    <label for="propuesta">Comentario: <span class="obligatorio">*</span></label>
                    <textarea id="exampleFormControlTextarea1" rows="6"
                      class="form-control"
                      [(ngModel)]="contacto.propuesta"
                      name="propuesta"
                      required minlength="4" #propuesta="ngModel" maxlength="1000" required></textarea>
                    <p style="width: 100%; text-align: right;">{{propuesta.value != null ? propuesta.value.length : 0 }} / 1000 Caracteres</p><br><br>
                    <p><span class="obligatorio">*</span> Ingresar los datos en los campos obligatorios para habilitar el botón</p>
                  </div>

                  <div class="form-group">
                    <div class="col-md-12">
                      <button class="btn btn-success m-2 float-right"
                        role="button" (click)='create()'
                        [disabled]="!formCliente.form.valid">Guardar</button>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-8 col-xs-12 bottom-buffer">
      <h3>Contacto</h3>
      <address>Barranca del Muerto 209,
        <br>San José Insurgentes, Ciudad de México. C.P. 03900
        <br><br>Teléfono: (55) 5322-4200
        <br> Extensiones: 1205
        <!--<br>Lourdes Eugenia Mondragón Padilla <br>-->
        <div id="respuestaMail">
          <a href="mailto:micrositiovinculacionyalianzas@inmujeres.gob.mx">micrositiovinculacionyalianzas@inmujeres.gob.mx</a>
        </div>
      </address>
    </div>
    <div class="col-md-4 col-sm-8 col-xs-12">
      <a href="https://maps.google.com/?q=19.3620449083407,-99.1835725307465"
        target="_blank" rel="noopener" title="Enlace a mapas de google con la
        ubicación de las oficinas en nueva ventana">
        <div id="googleMap" class="small-bottom-buffer" style="border-radius:
          6px; overflow: hidden; width:100%; height:250px; position: relative;
          background:
          url(https://framework-gb.cdn.gob.mx/maps/placeholder-mapa.jpg)
          no-repeat center; background-size: cover;">
          <p style="text-align: center; margin-top: 40%; font-size: 1.2em;
            font-weight: 600; color: white;">Da clic para ver la ubicación</p>
        </div>
      </a>
    </div>
  </div>
</div>
