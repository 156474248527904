import { Component, OnInit } from '@angular/core';
import { Publicacion } from 'src/app/models/publicacion';
import { PublicacionesService } from 'src/app/service/publicaciones.service';

@Component({
  selector: 'app-lista-publicaciones',
  templateUrl: './lista-publicaciones.component.html',
  styleUrls: ['./lista-publicaciones.component.css']
})
export class ListaPublicacionesComponent implements OnInit {

  publicaciones: Publicacion[];

  constructor(private publicacionesService: PublicacionesService) { }

  ngOnInit(): void {
    this.publicacionesService.obtenerPublicaciones().subscribe(respuesta => {
      this.publicaciones = respuesta;
    });
  }

}
