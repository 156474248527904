<detalle-cliente *ngIf="dependeciaSeleccionado" [dependecia]="dependeciaSeleccionado"></detalle-cliente>
<div class="container content">
    <h3>Catálogo de dependencias</h3>
    <hr class="red">
    <div class="row">
        <div class="container p-0'">
            <div class="col-md-6">
                <input type="text" [(ngModel)]="busqueda" class="mt-2 form-control input-group-lg">
            </div>

            <div class="col-md-3">
                <button type="button" class="btn btn-info btn-block" (click)="buscar()">Buscar</button>
            </div>

            <div class="col-md-3">
                <button *ngIf="loginService.hasRoles('ROLE_ADMIN')" type="button" class="btn btn-rounded btn-success" [routerLink]="['/dependencias/form']">Agregar dependencia</button>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-12">
            <div class="alert alert-info" *ngIf="dependencias?.length == 0">
                No hay registros en la base de datos!
            </div>
            <div class="table-responsive">                
                <table class="table table-bordered" *ngIf="dependencias?.length>0">
                    <thead>
                        <tr class="table table-bordered">
                            <!--<th>#</th>-->
                            <th>Nombre</th>
                            <th>Correo electrónico</th>
                            <th>Estado</th>
                            <th>Municipio</th>
                            <th>Categoría</th>
                            <th colspan="2">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let dependencia of dependencias; let i = index" [attr.data-index]="i">
                            <!--[routerLink]="['/clientes/ver',cliente.id]"-->
                            <!--<td>
                  {{ (i + 1) }}
                  <!--<img *ngIf="cliente?.foto" (click)="abrilModal(cliente)" src="http://localhost:8080/api/uploads/img/{{cliente.foto}}"
                  alt="{{cliente.foto}}" class="img-thumbnail rounded" style="width: 64px; cursor: pointer;">
                  <img *ngIf="!cliente?.foto" (click)="abrilModal(cliente)" src="http://localhost:8080/images/no-usuario.png"
                  alt="Sin foto" class="img-thumbnail rounded" style="width: 64px; cursor: pointer;">
                </td>-->
                            <td>{{ dependencia.nombre }}</td>
                            <td>{{ dependencia.correo }}</td>
                            <td>{{ dependencia.municipio?.estado?.nombre }}</td>
                            <td>{{ dependencia.municipio?.nombre }}</td>
                            <td>{{ dependencia.categoria.nombre }}</td>
                            <!--<td *ngIf="dependencia.categoria else elseBlock">{{ dependencia.categoria.nombre }}</td>
                            <ng-template #elseBlock>
                                <td></td>
                            </ng-template>-->
                            <td class="text-center">
                                <button type="button" name="button" [routerLink]="['/dependencias/form',dependencia.id]" class="btn btn-warning btn-sm">
                                    <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="loginService.hasRoles('ROLE_ADMIN')">
                                <button type="button" name="button" (click)="delete(dependencia)" class="btn btn-primary btn-sm">
                                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <paginator-nav *ngIf="paginador" [paginador]="paginador"></paginator-nav>
        </div>
    </div>
</div>
