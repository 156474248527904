<div class="container mt-5">
    <div class="row">
        <div class="col-md-12 mt-5 mb-3">
            <h3 class="text-center text-white p-4 green">GÉNERO EN LOS MEDIOS</h3>
        </div>
        <a routerLink="/inicio/vinculacionesyalianzas" class="btn-flotante">Regresar</a>
        <br />

        <div id="news" class="col-md-12 mt-5">
            <div class="col-md-3 text-center" *ngFor="let noticia of noticias; let i = index" [attr.data-index]="i">
                <a href="../../..{{noticia.contenido}}" target="_blank">
                    <span class="glyphicon glyphicon-book p-3 {{color(i)}}" aria-hidden="true" style="font-size: 100px;"></span>
                </a>
                <p class="text-center">{{noticia.titulo}}<br/>
                    <a href="../../..{{noticia.contenido}}" target="_blank">{{fechaFormato(noticia.fechaPublicacion)}}</a> </p>
            </div>
        </div>

    </div>
</div>