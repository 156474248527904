import { Component, OnInit } from '@angular/core';
import { Publicacion } from 'src/app/models/publicacion';
import { PublicacionesService } from '../../service/publicaciones.service';

@Component({
  selector: 'app-blog',
  templateUrl: './card-blog.component.html',
  styleUrls: ['./card-blog.component.css']
})
export class CardsBlogComponent implements OnInit {

  publicaciones: Publicacion[];
  publicacionesDisponibles: number;

  constructor(private publicacionesService: PublicacionesService) { }

  ngOnInit(): void {
    this.publicacionesService.obtenerPublicacionesLandingPage().subscribe(respuesta => {
      this.publicaciones = respuesta;
      console.log(this.publicaciones);
      this.publicacionesDisponibles = respuesta.totalElements;
    });
  }

}
