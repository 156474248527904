import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Publicacion } from 'src/app/models/publicacion';
import { PublicacionesService } from 'src/app/service/publicaciones.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Categoria } from '../../models/categorias';
import { Dependencia } from '../../models/dependecia';
import { DependenciaService } from '../../service/dependencias.service';

import { colors } from '../util/colors';

import { AlianzaContenido } from '../../models/alianza-contenido';

@Component({
  selector: 'app-alianzas',
  templateUrl: './alianzas.component.html',
  styleUrls: ['./alianzas.component.css'],
})
export class AlianzasComponent implements OnInit {
  public activeFiles: Object[] = [];
  public temaUno: Boolean;
  public temaDos: Boolean;
  public temaTres: Boolean;
  public temaCuatro: Boolean;
  public temaCinco: Boolean;
  public temaSeis: Boolean;
  public showVideo: Boolean;
  public showImg: Boolean;
  public showDoc: Boolean;
  public showPre: Boolean;
  public uno: Boolean;
  public dos: Boolean;
  public tres: Boolean;
  public cuatro: Boolean;
  public cinco: Boolean;
  public seis: Boolean;
  public siete: Boolean;
  public ocho: Boolean;
  public nueve: Boolean;
  public diez: Boolean;
  //
  public paginaActiva: Number = 1;
  publicacion: Publicacion;
  json: JSON;
  @Input() dependencias: Dependencia[];
  @Input() defult: Boolean;
  @Input() categoria: Categoria;
  @Input() page: number;

  constructor(
    private dependeciaService: DependenciaService,
    private sanitizer: DomSanitizer,
    private activateRoute: ActivatedRoute,
    private publicacionesService: PublicacionesService
  ) {}

  public activeAlianza: Object[] = [];

  public color: colors;
  public titulo: String;
  public categorias: Categoria[];
  public finCards: Boolean = false;

  publicacionesOSC: Publicacion[];
  publicacionesDisponibles: number;

  public alianzacontenido: AlianzaContenido = new AlianzaContenido();
  public consultivoPublicacion = [];
  public socialPublicacion = [];

  ngOnInit(): void {
    window.scrollTo(0, 0);
    // se modifico

    if (this.defult === true) {
      console.log('-->' + this.defult);
      this.cargarAllCategorias();
    }
    this.publicacionesService
      .obtenerPublicacionesLandingsPage()
      .subscribe((respuesta) => {

        let osc=[],ip=[],ies=[];
      for (let res of respuesta) {
        // console.log(res.categoria);
        if (res.categoria === 1) {
          osc.push(res);
          // console.log(osc);
          this.publicacionesDisponibles = respuesta.totalElements;
        }
      }
      this.publicacionesOSC = osc;
        // this.publicacionesOSC = respuesta;
        console.log(this.publicacionesOSC);
        this.publicacionesDisponibles = respuesta.totalElements;

        this.publicacionesOSC.map((element) => {
          this.alianzacontenido = JSON.parse(element.contenido);
          // console.log(this.alianzacontenido);
          if (this.alianzacontenido.tipoAlianza === '1') {
            this.consultivoPublicacion.push(element);
            // console.log(this.consultivoPublicacion);
          } else if (this.alianzacontenido.tipoAlianza === '2') {
            this.socialPublicacion.push(element);
            // console.log(this.socialPublicacion);
          }
        });
        console.log(this.socialPublicacion);
        console.log(this.consultivoPublicacion);
      });

    this.paginaActiva = 1;
    this.activateRoute.params.subscribe((params) => {
      let id = params['id'];
      switch (id) {
        case '1':
          this.activeFiles.push(
            { pill: 'active', tab: true },
            { pill: '', tab: '' }
          );
          break;
        case '2':
          this.activeFiles.push(
            { pill: '', tab: '' },
            { pill: 'active', tab: true }
          );
          break;
      }
    });
  }

  cargarAllCategorias(): void {
    this.dependeciaService.getCategorias().subscribe((categorias) => {
      this.categorias = categorias;
    });
  }

  toogleTemas($event: any): void {
    // se modifico
    this.paginaActiva = 1;
    switch ($event.currentTarget.id) {
      // case "1":
      case 'unoTema':
        this.temaUno === true ? (this.temaUno = true) : (this.temaUno = true);
        this.temaDos = false;
        this.temaTres = false;
        this.temaCuatro = false;
        this.temaCinco = false;
        this.temaSeis = false;
        break;

      case 'dosTema':
        //this.temaDos === true ? (this.temaDos = false) : (this.temaDos = true);
        this.temaDos === true ? (this.temaDos = true) : (this.temaDos = true);
        this.temaUno = false;
        this.temaTres = false;
        this.temaCuatro = false;
        this.temaCinco = false;
        this.temaSeis = false;
        break;

      case 'tresTema':
        //this.temaTres === true ? (this.temaTres = false) : (this.temaTres = true);
        this.temaTres === true
          ? (this.temaTres = true)
          : (this.temaTres = true);
        this.temaUno = false;
        this.temaDos = false;
        this.temaCuatro = false;
        this.temaCinco = false;
        this.temaSeis = false;
        break;

      case 'cuatroTema':
        //this.temaCuatro === true ? (this.temaCuatro = false) : (this.temaCuatro = true);
        this.temaCuatro === true
          ? (this.temaCuatro = true)
          : (this.temaCuatro = true);
        this.temaUno = false;
        this.temaDos = false;
        this.temaTres = false;
        this.temaCinco = false;
        this.temaSeis = false;
        break;

      case 'cincoTema':
        //this.temaCinco === true ? (this.temaCinco = false) : (this.temaCinco = true);
        this.temaCinco === true
          ? (this.temaCinco = true)
          : (this.temaCinco = true);
        this.temaUno = false;
        this.temaDos = false;
        this.temaTres = false;
        this.temaCuatro = false;
        this.temaSeis = false;
        break;

      case 'seisTema':
        //this.temaCinco === true ? (this.temaCinco = false) : (this.temaCinco = true);
        this.temaSeis === true
          ? (this.temaSeis = true)
          : (this.temaSeis = true);
        this.temaUno = false;
        this.temaDos = false;
        this.temaTres = false;
        this.temaCuatro = false;
        this.temaCinco = false;
        break;
    }
  }

  toogleDocs($event: any): void {
    // se modifico
    this.paginaActiva = 1;
    switch ($event.currentTarget.id) {
      case 'vid':
        this.showVideo === true
          ? (this.showVideo = false)
          : (this.showVideo = true);
        this.showImg = false;
        this.showDoc = false;
        this.showPre = false;
        break;

      case 'img':
        this.showImg === true ? (this.showImg = false) : (this.showImg = true);
        this.showVideo = false;
        this.showDoc = false;
        this.showPre = false;
        break;

      case 'doc':
        this.showDoc === true ? (this.showDoc = false) : (this.showDoc = true);
        this.showVideo = false;
        this.showImg = false;
        this.showPre = false;
        break;

      case 'pre':
        this.showPre === true ? (this.showPre = false) : (this.showPre = true);
        this.showVideo = false;
        this.showImg = false;
        this.showDoc = false;
        break;
    }
  }

  tooglePagi($event: any): void {
    this.paginaActiva = $event.currentTarget.id;
    $('ul.pagination > li.nav-item').each(function () {
      $(this).removeClass('active');
    });
    $('#' + $event.currentTarget.id)
      .parent()
      .addClass('active');
  }

  mesAnno(fecha: string, conector: string): String {
    let conversor = new Date(fecha);
    return (
      ' (' + (conector != null ? conector : '') + conversor.getFullYear() + ')'
    );
  }
}
