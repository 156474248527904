<p>prensa works!</p>

<div class="container prensa">
    <div class="panel py-0 m-0 ">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink ="/inicio">Inicio</a></li>
            <li class="breadcrumb-item"> <a routerLink="/inicio/prensa">Prensa</a></li>
            <li class="breadcrumb-item active" aria-current="page">Noticia N</li>
          </ol>
      </div>
    <div class="row">
        <div class="col-md-12">
            <img src="assets/img/protesta.png" alt="..." class="w-100 h-100 mb-n5">
        </div>
        <div class="col-md-12">
            <h3> INTEGRANTES DE
                LA RED MUJERES
                CONSTRUCTORAS
                DE PAZ DE LA ALCALDÍA
                VENUSTIANO CARRANZA
                TOMAN PROTESTA</h3>
                <p class="text-right">Ciudad de mexico, 18/11/2021</p>

                <p class="text-left"> Con el fin de brindar refugio temporal, servicios médicos, asesoría legal y psicológica, la alcaldía Venustiano Carranza anunció la creación de la “Casa Violeta”, lugar que próximamente atenderá a las mujeres que padecen violencia intrafamiliar</p>
                    <p>“Tendrá personal especializado como psicólogas, trabajadoras sociales, abogadas y personal médico femenino que atenderá a las vecinas que padezcan este problema, así como a sus hijos, les daremos un servicio integral para que se sientan protegidas y darles la ayuda necesaria para que no vuelvan a ser violentadas, por ejemplo podrán pernoctar para su seguridad por 72 horas en sus instalaciones”, indicó el alcalde, Julio Cesar Moreno.</p>
        </div>
    </div>    
</div>