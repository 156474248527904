import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Multimedia } from 'src/app/models/multimedia';
import { MultimediaService } from 'src/app/service/multimedia.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-galery',
  templateUrl: './galery.component.html',
  styleUrls: ['./galery.component.css'],
})
export class GaleryComponent implements OnInit {
  publicacion: Multimedia;
  json: JSON;
  imagenes: Multimedia[] = [];

  constructor(
    private activateRoute: ActivatedRoute,
    private publicacionesService: MultimediaService,
    private sanitizer: DomSanitizer
  ) {}
  public activeBlog: Object[] = [];
  public showVideo: Boolean;
  public showImg: Boolean;
  public showDoc: Boolean;
  public showPre: Boolean;
  public video = [];
  public image = [];
  public selectedImagenes = [];
  public presenta = [];
  public rutasImagenes = [];
  public rutasImagenesPorRegistro: { [key: number]: any[] } = [];
  public info: {
    [id: string]: { videos: any[]; imagenes: any[]; publicaciones: any[] };
  } = {};

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.obtenerMultimedia();
    this.obtenerVideos();
    $('div > iframe').each(function () {
      $(this).contents().find['video'].click();
    });
  }

  obtenerMultimedia(): void {
    this.publicacionesService.obtenerMultimedias().subscribe((multimedia) => {
      this.image = multimedia;

      let contenido = [];
      let videos = [];
      let imagenes = [];
      let publicaciones = [];
      let numerosArrays = [];

      const archivosPorElemento: {
        [id: string]: { videos: any[]; imagenes: any[]; publicaciones: any[] };
      } = {};

      for (let multi of multimedia) {
        const contenido = JSON.parse(multi.contenido);

        if (!archivosPorElemento[multi.id]) {
          archivosPorElemento[multi.id] = {
            videos: [],
            imagenes: [],
            publicaciones: [],
          };
        }

        contenido.archivos.forEach((arc) => {
          if (arc.tipo == 'video') {
            archivosPorElemento[multi.id].videos.push(arc);
          }
          if (arc.tipo == 'imagenes') {
            archivosPorElemento[multi.id].imagenes.push(arc);
          }
          if (arc.tipo == 'publicacion') {
            archivosPorElemento[multi.id].publicaciones.push(arc);
          }
        });
      }
      numerosArrays = Object.keys(archivosPorElemento);
      this.info = archivosPorElemento;

    });
  }

  obtenerVideos(): void {
    this.publicacionesService.obtenerMultimedias().subscribe((multimedia) => {
      let contenido = [];
      let videos = [];
      let imagenes = [];
      let publicaciones = [];

      for (let multi of multimedia) {
        contenido.push(JSON.parse(multi.contenido));
      }

      contenido.forEach((item) => {
        item.archivos.forEach((arc) => {
          if (arc.tipo == 'video') {
            videos.push(arc);
          }
          if (arc.tipo == 'publicacion') {
            publicaciones.push(arc);
          }
        });
      });

      this.video = videos;
      this.selectedImagenes = imagenes;
      this.presenta = publicaciones;
    });
  }

  toogleDocs($event: any): void {
    switch ($event.currentTarget.id) {
      case 'vid':
        this.showVideo === true
          ? (this.showVideo = false)
          : (this.showVideo = true);
        this.showImg = false;
        this.showDoc = false;
        this.showPre = false;
        break;

      case 'img':
        
        this.showImg === true ? (this.showImg = false) : (this.showImg = true);
        this.showVideo = false;
        this.showDoc = false;
        this.showPre = false;
        break;

      case 'doc':
        this.showDoc === true ? (this.showDoc = false) : (this.showDoc = true);
        this.showVideo = false;
        this.showImg = false;
        this.showPre = false;
        break;

      case 'pre':
        this.showPre === true ? (this.showPre = false) : (this.showPre = true);
        this.showVideo = false;
        this.showImg = false;
        this.showDoc = false;
        break;
    }
  }

  URLseguro(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  TieneImagenes(id: string){
    return this.info[id].imagenes.length>0;
  }

  mostrarImagenes(id: string): void {
    this.rutasImagenes = this.info[id].imagenes.map((imagen) => imagen.ruta);
  }
}