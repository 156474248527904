let auth_url_config = '';
let app_hostname = '';

if (window.location.hostname === 'vinculacionyalianzas.inmujeres.gob.mx') {
    auth_url_config = 'https://api.vinculacionyalianzas.inmujeres.gob.mx';    
    app_hostname = 'https://vinculacionyalianzas.inmujeres.gob.mx';
} else if (window.location.hostname === 'vinculacionyalianzasqa.inmujeres.gob.mx') {
    auth_url_config = 'http://api.vinculacionyalianzasqa.inmujeres.gob.mx';    
    app_hostname = 'http://vinculacionyalianzasqa.inmujeres.gob.mx';
} else if (window.location.hostname === 'localhost') {
    auth_url_config = 'http://localhost:8080';
    app_hostname = 'http://localhost:4200/';
}

export const URL_CONFIG = auth_url_config;
export const URL_HOSTNAME = app_hostname;