<div class="container-fluid inm-fade">
    <div class="container">
        <div class="row" style="margin-top: 3vw;">
            <div class="col-md-12 p-0">
                <div>
                    <img _ngcontent-vwg-c127="" src="assets/img/logo_delineado.png" alt="..." class="img img-fluid mx-auto d-block" style="width: 50%;">
                    <br>
                </div>
                <div id="videoExampleIndicators" class="carousel carousel-video slide carousel-fade" data-ride="carousel"
                    data-interval="10000">
                    <ol class="carousel-indicators">
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active" *ngFor="let video of videosInicio; let indice = index;"
                            [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                            <iframe [src]="video.contenido | safeUrl" class="carousel-inner"
                                title="YouTube video player" frameborder="0" style='
                                    border: none;
                                    overflow: hidden;
                                    width: 100%;
                                    height: 600px; 
                                    background: black; 
                                    border-radius: 15px; 
                                    box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);'
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen>
                            </iframe>
                        </div>

                        <div class="carousel-item" *ngFor="let video of videos; let indice = index;"
                            [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                            <iframe [src]="video.contenido | safeUrl" class="carousel-inner"
                                title="YouTube video player" frameborder="0" style='
                                border: none;
                                overflow: hidden;
                                width: 100%;
                                height: 524px;
                                background: black; 
                                border-radius: 15px; 
                                box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);'
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen>
                            </iframe>
                        </div>

                    </div>
                    <a class="carousel-control-prev" href="#videoExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#videoExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container contenido">
    <div class="row">
        <div class="col-md-12 p-0">
            <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-ride="carousel"
                data-interval="5000">
                <ol class="carousel-indicators">
                </ol>
                <div class="carousel-inner">

                    <!-- #ADMINISTRACION DE MICROSITIO -->
                    <!-- INICIO  -->
                    <div class="carousel-item active" *ngFor="let carrusel of carruselesInicio; let indice = index;"
                        [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                        <img src="{{carrusel.contenido}}" class="d-block w-100" alt="{{carrusel.titulo}}" />
                    </div>

                    <div class="carousel-item" *ngFor="let carrusel of carruseles; let indice = index;"
                        [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                        <img src="{{carrusel.contenido}}" class="d-block w-100" alt="{{carrusel.titulo}}" />
                    </div>

                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>

    <div id="inicio" class="container">
        <div class="p-5">
            <div class="card-body p-3 text-center">
                <h3 class="text-center m-5 p-2 font-bienvenida text-orange">B i e n v e n i d a s</h3>
                <p class="font-italic font-weight-bold font-big m-5 p-2 text-green">
                    VINCULACIÓN Y ALIANZAS INMUJERES
                </p>
                <p class="text-purple font-italic font-big">
                    Con el micrositio “Vinculación y Alianzas”, el Instituto Nacional de las Mujeres abre un espacio de
                    comunicación y de contacto permanente especialmente con aquellas organizaciones de la sociedad
                    civil, instituciones académicas y representantes del sector
                    privado que trabajan en la promoción y defensa de los derechos humanos de las mujeres, niñas y
                    adolescentes y por la igualdad sustantiva entre mujeres y hombres en nuestro país. Se espera que
                    “Vinculación y Alianzas”, contribuya a
                    mantener un diálogo y un intercambio activos, que permitan a su vez, establecer una relación de
                    colaboración que abone al fortalecimiento mutuo de quienes participen. !!Les invitamos a sumarse a
                    este espacio!! Su participación activa
                    enriquecerá el intercambio de experiencias y conocimientos en beneficio del bienestar de las
                    mujeres, las niñas y las adolescentes.
                </p>
            </div>
        </div>
    </div>

    <div id="vinculacionesyalianzas" class="container mt-2 px-0" style="padding-top: 12.5vh">
        <div class="container vinculacion text-center mb-5 inm_fade_vinculacion">
            <h3 style="font-size: 40px;">VINCULACIÓN Y ALIANZAS</h3>
        </div>
        <app-eventos-destacados></app-eventos-destacados>
        <app-cards-dependencias [defult]="true"></app-cards-dependencias>
    </div>

    <div id="blog" class="container mt-2 px-0" style="padding-top: 12.5vh">
        <div class="container blog text-center">
            <h3>BLOG</h3>
        </div>
        <app-blog></app-blog>
    </div>

    <!-- Contenido Multimedia -->
    <div id="multimedia" class="container px-0" style="padding-top: 12.5vh">
        <div class="container green text-white text-center mb-5">
            <h3>CONTENIDO MULTIMEDIA</h3>
        </div>
        <div class="card purple border-0 mb-12">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <img src="assets/img/multimedia.jpeg" class="img-fluid rounded-start" alt="...">
                </div>
                <div class="col-md-6 justify-content-center">
                    <div class="card-body align-self-center pl-0 my-4 text-center text-white">

                        <br><br><br><br>
                        <button type="button" class="boton purp font-weight-bold ng-tns-c123-1" tabindex="0"
                            routerLink="/galery">
                            VER MÁS
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br><br><br><br><br><br><br><br>
</div>