import { Dependencia } from './dependecia';
import { Role } from './role';
export class Usuario {
  id: number;
  username: string;
  password: string;
  password2: string;
  nombre: string;
  apellido: string;
  email: string;
  dependencia: Dependencia;
  roles: string[] = [];
}
