<div class="row mt-4">
    <div class="col-md-4 mb-4" *ngFor="let publicacion of publicaciones; let indice = index;" [ngClass]="{'col-lg-4': indice == 0, 'col-lg-4': indice != 0}">
        <div class="card">
            <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                <img src="{{ publicacion.imagen }}" class="img-fluid" style="width: 100%; height: 170px; object-fit: cover;" />
            </div>
            <div class="card-body">
                <p class="card-text">{{ publicacion.fechaPublicacion | date: 'longDate' }}</p>
                <h5 class="card-title" style="min-height: 39.6px; max-height: 40px; text-overflow: ellipsis ellipsis; text-size-adjust: auto; white-space: pre-wrap; overflow: hidden;">{{ publicacion.titulo }}</h5>
                <!--se modifico-->
                <a routerLink="/publicacion/{{ publicacion.id }}" class="btn btn-primary">VER MÁS</a>
            </div>
        </div>
    </div><!--
    <div class="col-md-4 mb-4" *ngFor="let publicacion of publicaciones; let indice = index;" [ngClass]="{'col-lg-4': indice == 0, 'col-lg-4': indice != 0}">
        <div class="card">
            <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                <img src="assets/Blog/Evento-del-Aborto/FOTO/Evento sobre el aborto.jpeg" class="img-fluid" [ngStyle]="{'height': indice != 0 ? '230px' : 'auto'}" />
            </div>
            <div class="card-body">
                <p class="card-text">{{ publicacion.fechaPublicacion | date: 'longDate' }}</p>
                <h5 class="card-title">Evento del Aborto</h5>
                <a routerLink="/publicacion/2" class="btn btn-primary">VER MÁS</a>
            </div>
        </div>
    </div>
    <div class="col-md-4 mb-4" *ngFor="let publicacion of publicaciones; let indice = index;" [ngClass]="{'col-lg-4': indice == 0, 'col-lg-4': indice != 0}">
        <div class="card">
            <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                <img src="assets/Blog/Evento-Martha-Sánchez-Néstor/FOTO/Familia de Martha.jpeg" class="img-fluid" [ngStyle]="{'height': indice != 0 ? '230px' : 'auto'}" />
            </div>
            <div class="card-body">
                <p class="card-text">{{ publicacion.fechaPublicacion | date: 'longDate' }}</p>
                <h5 class="card-title">Evento Martha Sánchez Néstor</h5>
                <a routerLink="/publicacion/3" class="btn btn-primary">VER MÁS</a>
            </div>
        </div>
    </div>
    <div class="col-md-4 mb-4" *ngFor="let publicacion of publicaciones; let indice = index;" [ngClass]="{'col-lg-4': indice == 0, 'col-lg-4': indice != 0}">
        <div class="card">
            <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                <img src="assets/Blog/Libro-sobre-mujeres-indígenas/FOTOS/Invitación .jpg" class="img-fluid" [ngStyle]="{'height': indice != 0 ? '230px' : 'auto'}" />
            </div>
            <div class="card-body">
                <p class="card-text">{{ publicacion.fechaPublicacion | date: 'longDate' }}</p>
                <h5 class="card-title">Libro sobre mujeres indígenas</h5>
                <a routerLink="/publicacion/4" class="btn btn-primary">VER MÁS</a>
            </div>
        </div>
    </div>-->
</div>
