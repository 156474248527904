<div class="container" id="addUser">
    <h3>{{titulo}}</h3><!-- @TODO hacerlo dinamico al editar -->
    <hr class="red">
    <div class="row">

        <div class="col-md-12">
            <div class="container">
                <div class="col-md-12">
                    <!-- form registro -->
                    <div>
                        <form>
                            <div class="form-group col-md-6">
                                <label for="telefono">Título:  <span class="obligatorio">*</span></label>
                                <div>
                                    <input type="text" class="form-control" [(ngModel)]="publicacion.titulo" name="titulo">
                                </div>
                            </div>                            
                            <div class="form-group col-md-6">
                                <label for="date">Fecha:  <span class="obligatorio">*</span></label>
                                <div>
                                    <input type="date" class="form-control" [(ngModel)]="publicacion.fechaPublicacion" name="fechaPublicacion">
                                </div>
                            </div>                            
                            <div class="form-group col-md-6">                                
                                <label for="">Imagen de Portada</label>
                                <form [formGroup]="myForm" *ngIf="showFileButton else elseBlockButton">
                                    <input type="file" class="form-control" id="file" formControlName="file" requiredFileType="image/jpg" (change)="onFileSelected($event)">
                                </form>
                                <ng-template #elseBlockButton> 
                                    <div class="form-group">
                                        <div class="col-md-6">
                                            <a (click)="loadImg()" class="btn btn-info btn-block">Ver archivo</a>
                                        </div>
                                        <div class="col-md-6">
                                            <button class="btn btn-warning btn-block" (click)='showFileButtonEvent()' target="_blank">Cambiar archivo</button>
                                        </div>
                                    </div>
                                    <div class="col-12" style="margin-top: 50px;" *ngIf="showImg"><img src="{{publicacion.imagen}}" alt="img" style="width: 100%; height: auto;"></div>
                                </ng-template>
                            </div>                            
                            <div class="form-group col-md-12">
                                <label for="telefono">Enlace:  <span class="obligatorio">*</span></label>
                                <div>
                                    <input type="text" class="form-control" [(ngModel)]="publicacion.contenido" name="contenido" placeholder="https://inmujeres.com.mx">
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                            </div>
                            <div class="form-group col-md-3">
                                <button type="button" class="btn btn-primary btn-block" (click)="cancelar()">Cancelar</button>
                            </div>
                            <div class="form-group col-md-3" *ngIf="publicacion.id == undefined">
                                <button type="button" class="btn btn-success btn-block" (click)="create()">Guardar</button>
                            </div>
                            <div class="form-group col-md-3" *ngIf="publicacion.id != undefined">
                                <button type="button" class="btn btn-warning btn-block" (click)="update()">Actualizar</button>
                            </div>
                        </form>
                    </div>
                    <!-- Fin form -->
                </div>
            </div>
        </div>
    </div>
</div>
