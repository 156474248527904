import {Municipio} from './municipios';
export class Contacto {
    id: number;
    organizacion: string;
    nombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    correo: string;
    genero: number;
    municipio: Municipio;
    propuesta: string;

}