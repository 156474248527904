<div class="container" id="addUser">
    <h3>{{this.titulo}}</h3><!-- @TODO hacerlo dinamico al editar -->
    <hr class="red">
    <div class="row">

        <div class="col-md-12">
            <div class="container">
                <div class="col-md-12">
                    <!-- form registro -->
                    <div>
                        <form #formCliente="ngForm">
                            <div class="row">
                                <div class="form-group col-md-8">
                                    <label for="blog">Nombre del blog: <span class="obligatorio">*</span></label>
                                    <div>
                                        <input type="text" class="form-control" [(ngModel)]="publicacion.titulo"
                                            name="titulo">
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="date">Fecha: <span class="obligatorio">*</span></label>
                                    <div>
                                        <input type="date" class="form-control"
                                            [(ngModel)]="publicacion.fechaPublicacion" name="fechaPublicacion">
                                    </div>
                                </div>
                            </div>

                            <div class="row" style="padding-left: 0px;">
                                <div class="col-md-12" style="padding-right: 0px; height: 50vh;">
                                    <div class="form-group col-md-12" style="padding: 0;">
                                        <input id="multiFile" type="file"
                                            style="visibility: hidden; display: none; height: 50vh;" multiple
                                            (change)="agregarMultiplesArchivos($event)">
                                        <div class="col-md-6" style="margin-bottom: 15px;">
                                            <button class="btn btn-warning btn-block" data-toggle="modal"
                                                data-target="#exampleModalCenter" target="_blank"
                                                (click)="cambiarRevision()">Administrar archivo</button>
                                        </div>
                                        <div class="col-md-6" style="margin-bottom: 15px;">
                                            <button class="btn btn-primary btn-block"
                                                (click)='eliminarMultiplesArchivos()' target="_blank">Quitar
                                                archivo</button>
                                        </div>
                                        <div class="col-md-12">
                                            <select class="form-select lista-archivos" multiple
                                                style="width: 100%; height: 40vh;"
                                                *ngIf="files?.length else elseArchivos">
                                                <option *ngFor="let json of jsonLista; let i = index;"
                                                    [attr.data-index]="i">{{json["name"] ? json["name"] : json["ruta"]}}
                                                </option>
                                            </select>
                                            <ng-template #elseArchivos>
                                                <select class="form-select" multiple style="width: 100%; height: 40vh;">
                                                    <option selected>Sin archivos</option>
                                                </select>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-2 offset-md-8">
                                <button type="button" class="btn btn-primary btn-block"
                                    [routerLink]="['/publicaciones/8/0']">Cancelar</button>
                            </div>
                            <div class="form-group col-md-2">
                                <button class="btn btn-success btn-block" role="button" (click)='create()'
                                    *ngIf="!publicacion.id else elseBlock"
                                    [disabled]="!formCliente.form.valid">Guardar</button>
                                <ng-template #elseBlock>
                                    <button class="btn btn-warning btn-block" role="button"
                                        (click)='update()'>Actualizar</button>
                                </ng-template>
                            </div>

                            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLongTitle">Administración de
                                                archivos</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div id="archivo" class="row">
                                                <p>Para ingresar la información de los videos, imágenes, documentos y
                                                    presentaciones, dar clic en el botón "Agregar archivos" o de lo
                                                    contrario dar clic en el botón "Cerrar".</p><br>
                                                <p style="font-size: 12px; color: #000000;"><span
                                                        class="obligatorio">*</span> ¿Si el video cargado desde facebook
                                                    live muestra error? Se recomienda subir una imagen y la dirección
                                                    enlace para abrirlo.</p>
                                                <div class="col-12" *ngFor="let archivo of jsonLista; let i = index"
                                                    [attr.data-index]="i">
                                                    <div class="row">
                                                        <div class="col-6" style="margin-bottom: 5px;"
                                                            *ngIf="(archivo['name'])">
                                                            <label for="tipo_archivo">Nombre:</label>
                                                            <input type="text" class="form-control"
                                                                id="nombre_archivo_{{i}}" value="{{archivo['name']}}"
                                                                (click)="cambiosRealizados()" disabled>
                                                        </div>
                                                        <div class="col-6" style="margin-bottom: 5px;"
                                                            *ngIf="!(archivo['name'])">
                                                            <label for="tipo_archivo">Dirección enlace:</label>
                                                            <input type="text" class="form-control"
                                                                id="direccion_archivo_{{i}}" value="{{archivo['ruta']}}"
                                                                (click)="cambiosRealizados()">
                                                        </div>
                                                        <div class="col-6" style="margin-bottom: 5px;">
                                                            <label for="tipo_archivo_{{i}}">Tipo:</label>
                                                            <select class="form-control" id="tipo_archivo_{{i}}"
                                                                value="{{archivo['tipo']}}"
                                                                (click)="cambiosRealizados()">
                                                                <option value="imagenes">Imagenes</option>
                                                                <option value="video">Video</option>
                                                                <option value="publicacion">Publicaciones</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-12" style="margin-bottom: 5px;"
                                                            *ngIf="!comparacionMIME(archivo['name'])">
                                                            <label for="titulo_archivo">Título:</label>
                                                            <input type="text" class="form-control"
                                                                id="titulo_archivo_{{i}}" value="{{archivo['titulo']}}"
                                                                (click)="cambiosRealizados()">
                                                        </div>
                                                        <div class="col-12" style="margin-bottom: 5px;"
                                                            *ngIf="comparacionMIME(archivo['name'])">
                                                            <label for="titulo_archivo">Dirección enlace:</label>
                                                            <input type="text" class="form-control"
                                                                id="titulo_archivo_{{i}}" value="{{archivo['titulo']}}"
                                                                (click)="cambiosRealizados()">
                                                        </div>
                                                        <div
                                                            style="margin-top: 15px; margin-bottom: 15px; border-top: 1px solid black; width: 100%;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="d-flex justify-content-center"
                                                    style="width: 100% !important; margin-bottom: 5px;">
                                                    <button id="cerrar" type="button" class="btn btn-secondary"
                                                        data-dismiss="modal"
                                                        style="width: max-content; margin-right: 5px;">Cerrar</button>
                                                    <button type="button" class="btn btn-primary"
                                                        (click)='mostrarCargaArchivos()'
                                                        style="width: max-content; margin-right: 5px;">Agregar
                                                        archivos</button>
                                                    <button type="button" class="btn btn-info"
                                                        (click)='mostrarCargaEnlace()'
                                                        style="width: max-content; margin-right: 5px;">Agregar
                                                        enlace</button>
                                                    <button type="button" class="btn btn-success"
                                                        *ngIf="guardarArchivos" (click)="guardarJSON()"
                                                        style="width: max-content; margin-right: 5px;">Guardar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Fin form -->
                </div>
            </div>
        </div><br>
    </div>
</div>