import { Component, OnInit } from '@angular/core';
import { Tema } from '../../../models/tema';
import { DependenciaService } from '../../../service/dependencias.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";

@Component({
  selector: 'app-formtemas',
  templateUrl: './formtemas.component.html',
  styleUrls: ['./formtemas.component.css']
})
export class FormtemasComponent implements OnInit {

  public tema: Tema = new Tema();
  public titulo: String = "Agregar nuevo tema de especialidad";

  constructor(private dependeciaService: DependenciaService,
    private activateRoute: ActivatedRoute,
    private router : Router) { }

  ngOnInit(): void {
    this.cargarTema()
    console.log("Que leches?");
  }

  cargarTema(): void{
    this.activateRoute.params.subscribe(
      (params) => {
        let id = params['id']
        if(id){
          this.titulo = "Editar tema de especialidad";
          this.dependeciaService.getTema(id)
          .subscribe((tema) => {            
            this.tema = tema;            
          })
        }
      }
    )
  }

  create(): void {
    this.dependeciaService.createTema(this.tema).subscribe(
      (tema) => {
        this.router.navigate(['/temas'])
        swal.fire('Nuevo Tema',`Tema: "${tema.nombre}" guardado con exito!`,'success' );
      }
    );
  }

  update(): void {
    this.dependeciaService.updateTema(this.tema).subscribe(
      (tema) => {
        this.router.navigate(['/temas']);
        swal.fire('Guardar tema',`El tema: "${tema.nombre}" se ha guardado con exito!`,'success' );
      }
    );
  }

} 
