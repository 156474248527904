import {AfterContentChecked, Component, OnInit} from '@angular/core';
import { LoginService } from '../../service/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import swal from "sweetalert2";

@Component({
  styleUrls: ['./header.component.css'],
  selector: 'app-header',
  templateUrl: './header.component.html' ,
})

export class HeaderComponent {
  title: string = 'VINCULACIÓN Y ALIANZAS OSC, IP, IES';

  constructor(public loginService: LoginService, private router: Router, private route: ActivatedRoute){}
 
  cerrarSesion():void{
    this.loginService.logout();
    swal.fire('Login', `Ha cerrado sesión con éxito`, 'success');
    this.router.navigate(['/login']);
  }

  goTo(section: string) {
    document.getElementById(section).scrollIntoView({behavior: "smooth", block:'start', inline:'nearest'})
  }
  // B.C. se agregó la siguiente clase: movementElementTop() antes no existía
  movementElementTop() {
    window.scrollTo(0, 0);
  }
}
