import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Dependencia } from '../../../models/dependecia';
import { Estado } from '../../../models/estados';
import { Municipio } from '../../../models/municipios';
import { Categoria } from '../../../models/categorias';
import { Tema } from '../../../models/tema';
import { DependenciaService } from '../../../service/dependencias.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";
import { FormsModule, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Usuario } from 'src/app/models/usuario';
import { UsuariosService } from 'src/app/service/usuarios.service';
import { Localidades } from 'src/app/models/localidades';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['../dependencias.component.css']
})
export class FormComponent implements OnInit {
  @Input() context: String;
  public dependencia: Dependencia = new Dependencia();
  public estados: Estado[];
  public categorias: Categoria[];
  public temas: Tema[];
  public temasDependencia: Tema[];
  public estado: Estado;
  public municipios: Municipio[];
  public municipio: Municipio;
  public titulo: String = "Registro";
  public checkBoxTemas: Object[] = [];
  public imgSrc: String;
  public file: File;
  public usuarios : Usuario[];
  public usuario : Usuario;
  public avisoPrivacidad : boolean = false;
  public terminosUtilizacion : boolean = false;
  public classFin: String;
  public localidades: Localidades[];
  public localidad: Localidades = new Localidades;

  public myForm: FormGroup = new FormGroup({
    file: new FormControl('', [Validators.required]),
   })

  constructor(private dependeciaService: DependenciaService,
    private usuariosService: UsuariosService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
  }

  ngOnInit() {
    if(this.activateRoute.data['value'].context === undefined){
      this.avisoPrivacidad = true;
      this.terminosUtilizacion = true;
    }
    
    this.dependeciaService.getCategorias().subscribe((categorias) => {
      this.categorias = categorias;
    })
    
    this.dependeciaService.getTemas().subscribe((temas) => {
      this.temas = temas;
      this.cargarDependencia();
    })

    this.dependeciaService.getEstados().subscribe((estados) => {
      this.estados = estados;
    })

    this.usuariosService.getUsuarios().subscribe((usuarios) => {
      this.usuarios = usuarios;
    })

  }

  nivelCargaDependencia(): boolean{
    return this.router.url != '/dependencias/form';
  }

  cargarMunicipios(): void {
    if (this.dependencia.estado !== undefined) {
      this.dependeciaService.getMunicipios(this.dependencia.estado).subscribe((municipios) => {
        this.municipios = municipios;
      });
    }
  }

  cargarLocalidades(): void {
    if(this.dependencia.municipio !== undefined){
      this.dependeciaService.getLocalidades(this.dependencia.municipio).subscribe((localidades) =>{
          this.localidades = localidades;
      })
    }
  }

  cargarDependencia(): void {
    this.activateRoute.params.subscribe(
      (params) => {
        let id = params['id']
        if (id) {
          this.titulo ="Editar dependencia"
          this.dependeciaService.getDependencia(id)
            .subscribe((dependencia) => {
              console.log(dependencia);
              this.dependencia = dependencia;
              this.checkBoxDataList();
              if (this.dependencia.municipio) {
                this.cargarMunicipios();
                this.municipio = this.dependencia.municipio;
              }
              if (this.estado) {
                this.cargarMunicipios();
                this.municipio = this.dependencia.municipio;
              }
              if(this.municipio){
                this.cargarLocalidades();
                this.localidad = this.dependencia.localidad;
              }
            })
        } else {
          this.checkBoxDataList();
        }
      }
    )
  }

  create(): void {
    this.dependeciaService.create(this.dependencia).subscribe(
      (dependencia) => {
       /*  this.dependencia = dependencia;
        if (this.file != null) {
          this.dependeciaService.subirFoto(this.file[0], this.dependencia.id).subscribe((dependencia) => {
          });
        } */
       // this.router.navigate(['/dependencias'])
        swal.fire('Nueva dependecia', `Dependencia: "${this.dependencia.nombre}" guardada con exito!`, 'success');
        this.classFin = "success";
        this.activateRoute.data['value'].context === undefined ? this.router.navigate(['/dependencias']) : this.router.navigate(['/registro']);

      }
    );


  }

  update(): void {
    console.log(this.dependencia);
    this.dependeciaService.update(this.dependencia).subscribe(
      (response) => {
        console.log(response);
        if (this.file != null) {
          this.dependeciaService.subirFoto(this.file[0], this.dependencia.id).subscribe((dependencia) => {
          });
        }
        //this.usuario.dependencia = this.dependencia;
        //this.usuariosService.update(this.usuario).subscribe();
        this.router.navigate(['/dependencias']);
        swal.fire('Dependencia guardada', `Dependencia: "${response.dependencia.nombre}" guardada con exito!`, 'success');
      }
    )
  }

  compararEstatdos(o1: Estado, o2: Estado): boolean {
    if ((o1 === undefined ||o1 === null) && (o2 === undefined || o2 === null)) {
      return true;
    }
    return o1 === undefined || o2 === undefined ? false : o1.id === o2.id;
  }

  compararMunicipios(o1: Municipio, o2: Municipio): boolean {
    if ((o1 === undefined || o1 === null) && ( o2 === undefined || o2 === null)) {
      return true;
    }
    return o1 === undefined || o2 === undefined ? false : o1.id === o2.id;
  }

  compararLocalidades(l1: Localidades, l2: Localidades): boolean {
    if ((l1 === undefined || l1 === null) && ( l2 === undefined || l2 === null)) {
      return true;
    }
    return l1 === undefined || l2 === undefined ? false : l1.id === l2.id;
  }

  compararUsuarios(user1: Usuario, user2: Usuario){
    return user1 === undefined || user2 === undefined ? false : user1.id === user2.id;
  }

  comparaCategorias(user1: Categoria, user2: Categoria){
    return user1 === undefined || user2 === undefined ? false : user1.id === user2.id;
  }

  checkBoxDataList(): void {
    this.temas.forEach((item: Tema) => {
      let temaSelected = this.dependencia.temas.find(x => x.id == item.id);
      let selected: boolean = (temaSelected === undefined) ? false : true;
      this.checkBoxTemas.push({ id: item.id, nombre: item.nombre, isSelected: selected });
    })
  }

  changeSelection(): void {
    this.fetchSelectedItems();
  }

  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      //const [file] = event.target.files;
      this.file = event.target.files;
      reader.readAsDataURL(this.file[0]);

      reader.onload = () => {

        this.imgSrc = reader.result as string;

        this.myForm.patchValue({
          fileSource: reader.result
        });

      };
    }
  }

  getImg(): void {
    console.log(this.dependencia.foto);
    console.log(this.dependencia.foto !== undefined || null);
    if (this.dependencia.foto !== null) {
      this.imgSrc = this.dependencia.foto;
    } else {
      console.log("--->");
      this.imgSrc = "assets/img/inmujeres.png";
    }
  }

  eventContribuyente(event: any): void{
   if(event.currentTarget.value === 'Y'){
    this.dependencia.contributyenteIguadad = true;
   }else{
    this.dependencia.contributyenteIguadad = false;
   }
  }

  isContribuyente(): boolean{
    return this.dependencia.contributyenteIguadad;
  }

  isNotContribuyente(): boolean{
    return !(this.dependencia.contributyenteIguadad);
  }

  eventDonataria(event: any): void{
    if(event.currentTarget.value === 'Y'){
     this.dependencia.donataria = true;
    }else{
     this.dependencia.donataria = false;
    }
   }

   isDonataria(): boolean{
     return this.dependencia.donataria;
   }

   isNotDonataria(): boolean{
     return (!this.dependencia.donataria);
   }

  fetchSelectedItems(): void {
    let selectedItems: Object[];
    selectedItems = this.checkBoxTemas.filter(value => value['isSelected']);
    selectedItems.forEach((item) => {
      if (!this.dependencia.temas.find(x => x.id === item['id'])) {
        let tema: Tema = new Tema();
        tema.id = item['id'];
        tema.nombre = item['nombre'];
        this.dependencia.temas.push(tema);
        console.log(this.dependencia.temas);
      }
      let unSelected = this.checkBoxTemas.filter(value => value['isSelected'] === false);
      unSelected.forEach((item) => {
        let index = this.dependencia.temas.findIndex((x) => x.id === item['id']);
        if (index !== -1) {
          this.dependencia.temas.splice(index, 1);
          console.log(this.dependencia.temas);
        }
      })
    })
  }
  // B.C. se agregó la siguiente clase: movementElementTop(). Antes no existía
  movementElementTop() {
    window.scrollTo(0, 0);
  }

}
