<div class="container">
    <div class="row">
        <a routerLink="/inicio/vinculacionesyalianzas" class="btn-flotante">Regresar</a>
        <br />
        <div class="col-sm-12 contentConvenio">
            <div class="container" *ngIf="this.activeFiles['0'].tab" id="pills-primer">
                <div class="container" data-toggle="modal" data-target="#lightbox">
                    <div class="container-fluid text-center" style="background-color: #59287f; color: white;">
                        <h3>CONVENIO UNACH</h3>
                    </div><br>
                    <div class="row">
                        <div class="container-fluid">
                            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                <ol class="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                                </ol>
                                <div class="carousel-inner">

                                    <div class="carousel-item active">
                                        <img class="d-block w-100" src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0726.JPG" alt="...">
                                    </div>

                                    <div class="carousel-item">
                                        <img src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0728.JPG" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0732.JPG" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0734.JPG" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0737.JPG" class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div><br>
                    <div class="container" style="text-align: justify;">
                        <h2>MESAS DE ARTICULACIÓN DE ACCIONES CON IES</h2>
                        <h3>Temática de la mesa: Convenio UNACH</h3>
                        <p class="font-weight-bold">Objetivo: El 18 de mayo del 2021 se llevó a cabo en las instalaciones del Instituto Nacional de las Mujeres, la firma del Convenio con la Universidad Autónoma de Chiapas (UNACH). 
                        </p>
                    </div><br>

                    <!--Circles-->
                    <div class="circles circles-pills mt-5">
                        <div class="col-md-3">
                            <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" (click)="tooglePagi($event)" aria-selected="true">
                                <div class="d-flex flex-column">
                                    <div class="rounded-circle purple mx-auto p-3">
                                        <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                    </div>
                                    <div class="text-center font-weight-bold text-purple p-2">
                                        <p>VIDEOS</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <!--<a class="circles ml-n5" id="img" (click)="toogleDocs($event)" (click)="tooglePagi($event)" aria-selected="true">-->
                            <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                <div class="d-flex flex-column">
                                    <div class="rounded-circle orange mx-auto p-3">
                                        <span class="glyphicon glyphicon-picture p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                    </div>
                                    <div class="text-center">
                                        <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                <div class="d-flex flex-column">
                                    <div class="rounded-circle green mx-auto p-3">
                                        <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                    </div>
                                    <div class="text-center">
                                        <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-3">
                            <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                <div class="d-flex flex-column">
                                    <div class="rounded-circle purple mx-auto p-3">
                                        <span class="glyphicon glyphicon-file p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                    </div>
                                    <div class="text-center font-weight-bold text-purple p-2">
                                        <p>PRESENTACIONES</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <!-- fin circles -->
                    <div class="col-md-12">
                        <div class="col-md-12" *ngIf="showVideo">
                            <h3 class="text-left">Videos</h3>
                            <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                            <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center" data-toggle="modal" data-target="#lightbox">
                                <!--<div class="col-md-12" *ngIf="paginaActiva == 1">
                                <div class="col-sm-3  my-5 text-center">
                                        <video class=" d-block w-100" controls>
                                            <source src="assets/Mesas-de-dialogo/Aportes-feministas/GRABACIÓN/telmex_0.mp4" class="h-100" type="video/mp4">
                                        </video>
                                    </div>
                                </div>-->
                                <div class="row justify-content-center" style="justify-content: center;">
                                    <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center" style="align-content: center;" id="pills-tab">
                                     <!--   <li class="nav-item active text-center">
                                            <a class="nav-link" id="1" (click)="tooglePagi($event)" aria-selected="true">
                                                <br> 1
                                            </a>
                                        </li>
                                    -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="showImg">
                            <h3 class="text-left">Imagenes</h3>
                            <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;"><br>
                            <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center" data-toggle="modal" data-target="#lightbox">
                                <div class="col-md-12" *ngIf="paginaActiva == 1">
                                    <div class="col-md-6">
                                        <img src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0726.JPG"
                                            class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2" data-target="#indicators" data-slide-to="0" alt="" />
                                    </div>
                                    <div class="col-md-6">
                                        <img src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0728.JPG"
                                            class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2" data-target="#indicators"data-slide-to="1" alt="" />
                                    </div>
                                   
                                </div>
                                <div class="col-md-12" *ngIf="paginaActiva == 2">
                                    <div class="col-md-6">
                                        <img src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0732.JPG"
                                            class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2" data-target="#indicators" data-slide-to="2" alt="" />
                                    </div>
                                    <div class="col-md-6">
                                        <img src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0734.JPG"
                                            class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2" data-target="#indicators" data-slide-to="3" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="paginaActiva == 3">
                                    <div class="col-md-6">
                                        <img src="assets/Convenios/CONVENIO-UNACH/FOTO/IMG_0737.JPG"
                                            class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2" data-target="#indicators" data-slide-to="4" alt="" />
                                    </div>
                                </div>

                                <div class="row justify-content-center" style="justify-content: center;">
                                    <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center" style="align-content: center;" id="pills-tab">
                                        <li class="nav-item active text-center">
                                            <a class="nav-link" id="1" (click)="tooglePagi($event)" aria-selected="true">
                                                <br> 1
                                            </a>
                                        </li>
                                        <li class="nav-item text-center">
                                            <a class="nav-link" id="2" (click)="tooglePagi($event)" aria-selected="false">
                                                <br> 2
                                            </a>
                                        </li>
                                        <li class="nav-item text-center">
                                            <a class="nav-link" id="3" (click)="tooglePagi($event)" aria-selected="false">
                                                <br> 3
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="showDoc">
                            <h3 class="text-left">Documentos</h3>
                            <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                            <div class="row d-flex flex-wrap align-items-start" data-toggle="modal" data-target="#lightbox">
                                <div class="col-sm-3 my-5 text-center">
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/ACTIVIDADES/Guia para fomentar en la comunidad universitaria el lenguaje incluyente y no sexista.pdf" target="_blank">
                                        <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #4D92DF;"></span>
                                    </a><br>
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/ACTIVIDADES/Guia para fomentar en la comunidad universitaria el lenguaje incluyente y no sexista.pdf" target="_blank">
                                        Guia para fomentar en la comunidad universitaria el lenguaje incluyente y no sexista
                                    </a>
                                </div>
                                <div class="col-sm-3 my-5 text-center">
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/ACTIVIDADES/Manual para prevenirWEB (1).pdf" target="_blank">
                                        <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #4D92DF;"></span>
                                    </a><br>
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/ACTIVIDADES/Manual para prevenirWEB (1).pdf" target="_blank">
                                        Manual para prevenirWEB
                                    </a>
                                </div>
                                <div class="col-sm-3 my-5 text-center">
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/CONVENIO/CONVENIO INMUJERES - UNACH.pdf" target="_blank">
                                        <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #4D92DF;"></span>
                                    </a><br>
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/CONVENIO/CONVENIO INMUJERES - UNACH.pdf" target="_blank">
                                        CONVENIO INMUJERES - UNACH
                                    </a>
                                </div>
                                <div class="col-sm-3 my-5 text-center">
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/RESEÑA/Reseña. Firma del convenio con la UNACH.pdf" target="_blank">
                                        <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #4D92DF;"></span>
                                    </a><br>
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/RESEÑA/Reseña. Firma del convenio con la UNACH.pdf" target="_blank">
                                        Reseña. Firma del convenio con la UNACH
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="showPre">
                            <h3 class="text-left">Presentaciones</h3>
                            <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                            <div class="row d-flex flex-wrap align-items-center" data-toggle="modal" data-target="#lightbox">
                                <div class="col-sm-3 my-5 text-center">
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/ACTIVIDADES/Presentación Resultados CIG-UNACH 2021.pdf" target="_blank">
                                        <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #db8f1b;"></span>
                                    </a><br>
                                    <a href="../../../assets/Convenios/CONVENIO-UNACH/ACTIVIDADES/Presentación Resultados CIG-UNACH 2021.pdf" target="_blank">
                                        Presentación Resultados
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
        </div>
    </div>
</div>
