import { Component, OnInit } from '@angular/core';
import { Publicacion } from '../../models/publicacion';
import { PublicacionesService } from '../../service/publicaciones.service';
import { LoginService } from '../../service/login.service';
import { ModalService } from './detalle/modal.service';
import { ActivatedRoute } from '@angular/router';
//import { tap } from 'rxjs/operators';
import swal from 'sweetalert2';
import { CarruselService } from '../../service/carrusel.service';
import { VideoService } from '../../service/video.service';
import { MultimediaService } from '../../service/multimedia.service';

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicacion.component.html',
  styleUrls: ['./publicacion.component.css'],
})
export class PublicacionComponent implements OnInit {
  publicaciones: Publicacion[];
  paginador: any;
  publicacionSeleccionado: Publicacion;
  busqueda: string;
  tipo: number = 1;
  page: number = 0;

  constructor(
    private publicacionesService: PublicacionesService,
    private carruselService: CarruselService,
    public modalService: ModalService,
    private activateRoute: ActivatedRoute,
    private videoService: VideoService,
    private multimediaService: MultimediaService,
    public loginService: LoginService
  ) {}

  ngOnInit() {
    this.activateRoute.paramMap.subscribe((params) => {
      this.tipo = +params.get('tipo'); // El signo + convierte el valor de params de string a number
      this.page = +params.get('page');
      if (!this.tipo) {
        this.tipo = 1;
      }
      if (!this.page) {
        this.page = 0;
      }
      this.paginador = this.tipo;
      this.obtenerPublicaciones();
    });

    this.modalService.notificarUpload.subscribe((publicacion) => {
      this.publicaciones = this.publicaciones.map((publicacionOriginal) => {
        if (publicacion.id == publicacionOriginal.id) {
          publicacionOriginal.imagen = publicacion.imagen;
        }
        return publicacionOriginal;
      });
    });
  }

  changetipo() {
    this.paginador = this.tipo;
    this.page = 0;
    this.obtenerPublicaciones();
  }

  obtenerPublicaciones() {
    this.publicacionesService
      .obtenerPublicacionesPage(this.tipo, this.page)
      .subscribe((response) => {
        this.publicaciones = response.content as Publicacion[];
        this.paginador = response;
      });
  }

  delete(publicacion: Publicacion, tipoPublicacion: string): void {
    // Recibir Publicacion
    var tipo: String =
      tipoPublicacion == 'blog'
        ? 'el blog'
        : tipoPublicacion == 'eventos'
        ? 'el evento'
        : tipoPublicacion == 'vinculacion'
        ? 'Vinculación'
        : tipoPublicacion == 'carrusel'
        ? 'Carrusel'
        : tipoPublicacion == 'video'
        ? 'Video'
        : 'la Multimedia';
    swal
      .fire({
        title: 'Eliminando registro',
        text: `¿Seguro que desea eliminar ${tipo}: "${publicacion.titulo}" ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!',
        cancelButtonText: 'No, cancelar!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (tipoPublicacion == 'carrusel') {
            this.carruselService
              .borrarCarrusel(publicacion.id)
              .subscribe(() => {
                this.publicaciones = this.publicaciones.filter(
                  (cli) => cli != publicacion
                );
                swal.fire(
                  '¡Registro eliminado!',
                  `Registro "${publicacion.titulo}" eliminada con éxito!`,
                  'success'
                );
              });
          }
          if (tipoPublicacion == 'video') {
            this.videoService.borrarVideo(publicacion.id).subscribe(() => {
              this.publicaciones = this.publicaciones.filter(
                (cli) => cli != publicacion
              );
              swal.fire(
                '¡Registro eliminado!',
                `Registro "${publicacion.titulo}" eliminada con éxito!`,
                'success'
              );
            });
          }
          if (tipoPublicacion == 'multimedia') {
            this.multimediaService.borrarMultimedia(publicacion.id).subscribe(() => {
              this.publicaciones = this.publicaciones.filter(
                (cli) => cli != publicacion
              );
              swal.fire(
                '¡Registro eliminado!',
                `Registro "${publicacion.titulo}" eliminada con éxito!`,
                'success'
              );
            });
          } else {
            this.publicacionesService
              .borrarPublicacion(publicacion.id)
              .subscribe(() => {
                this.publicaciones = this.publicaciones.filter(
                  (cli) => cli != publicacion
                );
                swal.fire(
                  '¡Registro eliminado!',
                  `Registro "${publicacion.titulo}" eliminada con éxito!`,
                  'success'
                );
              });
          }
        }
      });
  }

  abrilModal(publicacion: Publicacion) {
    this.publicacionSeleccionado = publicacion;
    this.modalService.abrilModal();
  }

  buscar() {
    this.publicacionesService
      .buscarPublicacionesByNombre(this.busqueda)
      .subscribe((publicaciones) => {
        if (publicaciones.length > 0) {
          this.publicaciones = publicaciones;
        }
      });
  }
}
