<div class="conteiner">
  <div class="row">
    <div class="col-md-12 pb-2">
      <div class="col-md-6 p-0">
        <input
          type="button"
          (click)="toogleElements($event)"
          class="btn btn-block btn-outline orangeButton mb-2 {{
            classNoticias
          }}"
          value="Noticias"
        />
        <!-- data-toggle="collapse" data-target="#news"  -->
      </div>
      <div class="col-md-6 p-0">
        <input
          type="button"
          (click)="toogleElements($event)"
          class="btn btn-block btn-outline purpleButton mb-2 {{ classEvents }}"
          value="Eventos"
        />
        <!-- data-toggle="collapse" data-target="#events"  -->
      </div>
      <!-- Div de NOTICIAS -->
      <div id="news" class="collapse collapse-horizontal {{ showNoticias }}">
        <h2 class="col-md-12 pb-3">Recientes</h2>
        <div class="row">
          <div
            class="col-md-3 text-center"
            *ngFor="let noticia of mesesNoticias; let i = index"
            [attr.data-index]="i"
          >
            <!--para mandar pantalla arriba o inicio (click)="movementNewsTop()"-->
            <a
              routerLink="/noticias/{{
                mesAnno(noticia.fechaPublicacion, '_')
              }}"
              (click)="movementNewsTop()"
            >
              <span
                class="glyphicon glyphicon-book p-3 {{ color(i) }}"
                aria-hidden="true"
                style="font-size: 100px"
              ></span>
            </a>
            <p class="text-center">
              {{ noticia.titulo }}<br />
              <!--para mandar pantalla arriba o inicio (click)="movementNewsTop()"-->
              <a
                routerLink="/noticias/{{
                  mesAnno(noticia.fechaPublicacion, '_')
                }}"
                (click)="movementNewsTop()"
                >{{ mesAnno(noticia.fechaPublicacion) }}</a
              >
            </p>
          </div>
        </div>
        <h2 class="col-md-12 pb-3">Histórico</h2>
        <div class="row">
          <div
            class="col-md-3 text-center"
            *ngFor="let noticia of mesesNoticiasHistorico; let i = index"
            [attr.data-index]="i"
          >
            <!--para mandar pantalla arriba o inicio (click)="movementNewsTop()"-->
            <a
              routerLink="/noticias/{{
                mesAnno(noticia.fechaPublicacion, '_')
              }}"
              (click)="movementNewsTop()"
            >
              <span
                class="glyphicon glyphicon-book p-3 {{ color(i) }}"
                aria-hidden="true"
                style="font-size: 100px"
              ></span>
            </a>
            <p class="text-center">
              {{ noticia.titulo }}<br />
              <!--para mandar pantalla arriba o inicio (click)="movementNewsTop()"-->
              <a
                routerLink="/noticias/{{
                  mesAnno(noticia.fechaPublicacion, '_')
                }}"
                (click)="movementNewsTop()"
                >{{ mesAnno(noticia.fechaPublicacion) }}</a
              >
            </p>
          </div>
        </div>
      </div>

      <!-- Div de Eventos -->
      <div id="events" class="collapse collapse-horizontal {{ showEventos }}">
        <div class="col-md-6 p-0">
          <input
            type="button"
            id="prox"
            (click)="toogleEventos($event)"
            class="btn btn-block btn-outline orangeButton mb-2 {{
              classEventosProx
            }}"
            value="Eventos próximos"
          />
          <!-- data-toggle="collapse" data-target="#news"  -->
        </div>
        <div class="col-md-6 p-0">
          <input
            type="button"
            id="pas"
            (click)="toogleEventos($event)"
            class="btn btn-block btn-outline purpleButton mb-2 {{
              classEventosPas
            }}"
            value="Eventos pasados"
          />
          <!-- data-toggle="collapse" data-target="#events"  -->
        </div>

        <div
          id="eventosProx"
          class="collapse collapse-horizontal {{ showEventosProx }}"
        >
          <div class="row">
            <div
              class="col-sm-6 pb-2 text-uppercase"
              *ngFor="let evento of eventosProximos; let i = index"
              [attr.data-index]="i"
            >
              <div class="card">
                <img
                  src="../../../{{ evento.imagen }}"
                  style="height: 282px"
                  class="card-img-top"
                  alt="img"
                />
                <div class="card-body">
                  <h5 class="card-title" style="height: 80px">
                    {{ evento.titulo }}, ({{ mesAnno(evento.fechaPublicacion) }})
                  </h5>
                  <a
                    href="{{ evento.contenido }}"
                    class="btn btn-primary pull-right"
                    target="_blank"
                    >Ver más</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="eventosPasados"
          class="collapse collapse-horizontal {{ showEventosPas }} p-0"
        >
          <div class="row">
            <div
              class="col-md-6 pb-2 text-uppercase"
              *ngFor="let evento of eventosAnteriores; let i = index"
              [attr.data-index]="i"
            >
              <div class="card">
                <img
                  src="../../../{{ evento.imagen }}"
                  style="height: 282px"
                  class="card-img-top"
                  alt="img"
                />
                <div class="card-body">
                  <h5 class="card-title" style="height: 80px">
                    {{ evento.titulo }}, ({{ mesAnno(evento.fechaPublicacion) }})
                  </h5>
                  <a
                    href="{{ evento.contenido }}"
                    class="btn btn-primary pull-right"
                    target="_blank"
                    >Ver más</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
