<div class="container galeria">
  <div class="panel py-0 m-0">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/inicio/multimedia">Multimedia</a>
      </li>
      <li class="breadcrumb-item active text-black" aria-current="page">
        Galería
      </li>
    </ol>
  </div>
  
  <a routerLink="/inicio/multimedia" class="btn-flotante">Regresar</a>
        <br />

  <!--secciones-->
  <div class="circles circles-pills mt-5">
    <!--seccion videos-->
    <div
      class="col-md-3"
      id="unotema"
      aria-selected="true"
      (click)="toogleTemas($event)"
    >
      <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
        <div class="d-flex flex-column">
          <div class="rounded-circle purple mx-auto p-3">
            <span
              class="glyphicon glyphicon-facetime-video p-3"
              aria-hidden="true"
              style="font-size: 55px; color: #ffffff"
            ></span>
          </div>
          <div class="text-center text-purple p-2">
            <p>VIDEOS</p>
          </div>
        </div>
      </a>
    </div>
    <!-- seccion fotos-->
    <div class="col-md-3">
      <a
        class="circles ml-n5"
        id="dostema"
        aria-selected="true"
        (click)="toogleTemas($event)"
      >
        <div class="d-flex flex-column">
          <div class="rounded-circle green mx-auto p-3">
            <span
              class="glyphicon glyphicon-picture p-3"
              aria-hidden="true"
              style="font-size: 55px; color: #ffffff"
            ></span>
          </div>
          <div class="text-center">
            <p class="text-green p-2">FOTOS</p>
          </div>
        </div>
      </a>
    </div>
    <!--seccion publicacion-->
    <div
      class="col-md-3"
      id="trestema"
      aria-selected="true"
      (click)="toogleTemas($event)"
    >
      <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
        <div class="d-flex flex-column">
          <div class="rounded-circle orange mx-auto p-3">
            <span
              class="glyphicon glyphicon-book p-3"
              aria-hidden="true"
              style="font-size: 55px; color: #ffffff"
            ></span>
          </div>
          <div class="text-center text-orange p-2">
            <p>PUBLICACIONES</p>
          </div>
        </div>
      </a>
    </div>
  </div>

  <!--Mostrar contenido-->
  <div class="col-md-12">
    <!-- videos-->
    <div class="col-md-12" *ngIf="temaUno">
      <h3 class="text-left">Videos</h3>
      <div
        *ngIf="showVideo"
        class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
        data-toggle="modal"
        data-target="#vidbox"
      >
        <!--<div class="col-12 col-md-6 col-lg-3">
                    <img src="assets/img/carrusel/c2.jpg" class="img img-thumbnail m-4" data-target="#vindicators" data-slide-to="1" alt="" />
                </div>-->
        <div class="col-md-12 text-center" *ngIf="paginaActiva == 1">
          <div class="col-md-6">
            <iframe
              src="https://www.youtube.com/embed/YExzMkGxiqM"
              style="border: none; 
              overflow: hidden; 
              width: 100%; 
              height: 270px;
              margin: 1vw; 
              border-radius: 15px; 
              box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
              "
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen="true"
            ></iframe>
            <p style="margin-top: 5px">Consejo Consultivo 2018-2021</p>
          </div>
          <div class="col-md-6">
            <iframe
              src="https://www.youtube.com/embed/hJ58hT1W6sQ"
              style="border: none; overflow: hidden; width: 100%; height: 270px;
              margin: 1vw; 
              border-radius: 15px; 
              box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen="true"
            ></iframe>
            <p style="margin-top: 5px">Consejo social Video 2021</p>
          </div>
        </div>
        <div class="col-md-12 text-center" *ngIf="paginaActiva == 2">
          <div class="col-md-6">
            <iframe
              src="https://www.youtube.com/embed/_2gX_fcVA9g"
              style="border: none; overflow: hidden; width: 100%; height: 270px;
              margin: 1vw; 
              border-radius: 15px; 
              box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen="true"
            ></iframe>
            <p style="margin-top: 5px">Las 3R’s del Trabajo de Cuidados</p>
          </div>
          <div class="col-md-6">
            <iframe
              src="https://www.youtube.com/embed/0MMmERlWF6I"
              style="border: none; overflow: hidden; width: 100%; height: 270px;
              margin: 1vw; 
              border-radius: 15px; 
              box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen="true"
            ></iframe>
            <p style="margin-top: 5px">MENSAJE PRESIDENTA INMUJERES</p>
          </div>
        </div>
        <div class="col-md-12 text-center" *ngIf="paginaActiva == 3">
          <div class="col-md-6">
            <iframe
              src="https://www.youtube.com/embed/hbK_EjYJgqU"
              style="border: none; overflow: hidden; width: 100%; height: 270px;
              margin: 1vw; 
              border-radius: 15px; 
              box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen="true"
            ></iframe>
            <p style="margin-top: 5px">MENSAJE SENADORA MALUMICHER</p>
          </div>
        </div>
        <div class="row justify-content-center" style="justify-content: center">
          <nav
            aria-label="..."
            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
            style="align-items: center"
          >
            <ul
              class="pagination pagination-lg align-items-center align-content-center justify-content-center"
              style="align-content: center"
              id="pills-tab"
            >
              <li class="nav-item active text-center">
                <a
                  class="nav-link"
                  id="1"
                  (click)="tooglePagi($event)"
                  aria-selected="true"
                >
                  <br />
                  1
                </a>
              </li>
              <li class="nav-item text-center">
                <a
                  class="nav-link"
                  id="2"
                  (click)="tooglePagi($event)"
                  aria-selected="false"
                >
                  <br />
                  2
                </a>
              </li>
              <li class="nav-item text-center">
                <a
                  class="nav-link"
                  id="3"
                  (click)="tooglePagi($event)"
                  aria-selected="false"
                >
                  <br />
                  3
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <!-- fotos icono carpetas-->
    <div class="col-md-12" *ngIf="temaDos">
      <h3 class="text-left">Fotos</h3>
      <div class="col-md-3">
        <!-- <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" (click)="tooglePagi($event)" aria-selected="true">-->
        <a
          class="circles ml-n5"
          id="imgUna"
          (click)="toogleDocs($event)"
          aria-selected="true"
        > 
        <!-- B.C. Se agregó la clase contfotos 
        <div class="d-flex flex-column"> -->
          <div class="d-flex flex-column contfotos">
            <div class="text-center font-weight-bold text-purple p-2">
              <span
                class="glyphicon glyphicon-folder-open p-3"
                aria-hidden="true"
                style="font-size: 100px; color: #283c7f"
              ></span>
            </div>
            Firma de Convenio Con La UNACH
          </div>
        </a>
      </div>
      <div class="col-md-3">
        <!-- <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" (click)="tooglePagi($event)" aria-selected="true">-->
        <a
          class="circles ml-n5"
          id="imgDos"
          (click)="toogleDocs($event)"
          aria-selected="true"
        >
          <div class="d-flex flex-column">
            <div class="text-center font-weight-bold text-purple p-2">
              <span
                class="glyphicon glyphicon-folder-open p-3"
                aria-hidden="true"
                style="font-size: 100px; color: #59287f"
              ></span>
            </div>
            Homenaje a Martha Sánchez Néstor
          </div>
        </a>
      </div>
      <div class="col-md-3">
        <!-- <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" (click)="tooglePagi($event)" aria-selected="true">-->
        <a
          class="circles ml-n5"
          id="imgTres"
          (click)="toogleDocs($event)"
          aria-selected="true"
        >
          <div class="d-flex flex-column">
            <div class="text-center font-weight-bold text-purple p-2">
              <span
                class="glyphicon glyphicon-folder-open p-3"
                aria-hidden="true"
                style="font-size: 100px; color: #297f28"
              ></span>
            </div>
            Nuevos consejos 2021 2024
          </div>
        </a>
      </div>
      <!-- fotos-->
      <div class="col-md-12" *ngIf="showImgUna">
        <h3 class="text-left">Firma de Convenio Con La UNACH</h3>
        <hr
          class="red"
          style="
            width: 35px;
            height: 5px;
            background-color: #b38e5d;
            position: absolute;
          "
        />
        <br />
        <div
          class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
          data-toggle="modal"
          data-target="#lightbox"
        >
          <div class="col-md-12" *ngIf="paginaActiva == 1">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/FIRMA-DE-CONVENIO-CON-LA-UNACH/Firma de Convenio con la UNACH Directoras Generales y Consejera Consultiva.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="0"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/FIRMA-DE-CONVENIO-CON-LA-UNACH/Firma de la Dra Nadine Gasman Zylbermann.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="1"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 2">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/FIRMA-DE-CONVENIO-CON-LA-UNACH/Firma del Convenio con la UNACH Asistentes.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="2"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/FIRMA-DE-CONVENIO-CON-LA-UNACH/Firma del Convenio con la UNACH Funcionariado asistente.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="3"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 3">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/FIRMA-DE-CONVENIO-CON-LA-UNACH/Firma del Convenio con la UNACH Lectura y comentarios previos.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="4"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/FIRMA-DE-CONVENIO-CON-LA-UNACH/Firma del Convenio con la UNACH.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="5"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 4">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/FIRMA-DE-CONVENIO-CON-LA-UNACH/Firma del Convenio presidenta del Inmujeres Dra Nadine Gasman Zylbermann.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="6"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/FIRMA-DE-CONVENIO-CON-LA-UNACH/Momento de la Firma del Convenio con la UNACH.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="7"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 5">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/FIRMA-DE-CONVENIO-CON-LA-UNACH/Momentos posteriores a la Firma del Convenio con la UNACH.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="8"
                alt=""
              />
            </div>
          </div>
          <div
            class="row justify-content-center"
            style="justify-content: center"
          >
            <nav
              aria-label="..."
              class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
              style="align-items: center"
            >
              <ul
                class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                style="align-content: center"
                id="pills-tab"
              >
                <li class="nav-item active text-center">
                  <a
                    class="nav-link"
                    id="1"
                    (click)="tooglePagi($event)"
                    aria-selected="true"
                  >
                    <br />
                    1
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="2"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    2
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="3"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    3
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="4"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    4
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="5"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    5
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="showImgDos">
        <h3 class="text-left">Homenaje a Martha Sánchez Néstor</h3>
        <hr
          class="red"
          style="
            width: 35px;
            height: 5px;
            background-color: #b38e5d;
            position: absolute;
          "
        />
        <br />
        <div
          class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
          data-toggle="modal"
          data-target="#lightbox"
        >
          <div class="col-md-12" *ngIf="paginaActiva == 1">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Detalles del Homenaje luctuoso.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="0"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Dra Nadine Gasman Zylbermann ofreciendo unas palabras en el Homenaje luctuoso.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="1"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 2">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Homenaje a Martha Sánchez Néstor Su familia con la presidenta del Inmujeres.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="2"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Homenaje a Martha Sánchez Néstor Su familia en las instalaciones del Instituto Nacional de las Mujeres.jpg"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="3"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 3">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Homenaje luctuoso a Martha Sánchez Néstor La Dra Nadine Gasman abrazando a la madre de Martha Sánchez Néstor.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="4"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Homenaje luctuoso a Martha Sánchez Néstor La Senadora Patricia Mercado ofreciendo unas palabras.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="5"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 4">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Homenaje luctuoso a Martha Sánchez Néstor Momento de la familia con la presidenta del Inmujeres.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="6"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Homenaje luctuoso a Martha Sánchez Néstor Momentos de la familia.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="7"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 5">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Homenaje luctuoso a Martha Sánchez Néstor Nadine Gasman y artistas invitados.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="10"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Homenaje luctuoso a Martha Sánchez Néstor Su padre ofreciendo unas palabras.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="9"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 6">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Homenaje-a-Martha-Sánchez-Néstor/Nadine Gasman Zylbermann en el Homenaje luctuoso a Martha Sánchez Néstor.JPG"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="10"
                alt=""
              />
            </div>
          </div>
          <div
            class="row justify-content-center"
            style="justify-content: center"
          >
            <nav
              aria-label="..."
              class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
              style="align-items: center"
            >
              <ul
                class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                style="align-content: center"
                id="pills-tab"
              >
                <li class="nav-item active text-center">
                  <a
                    class="nav-link"
                    id="1"
                    (click)="tooglePagi($event)"
                    aria-selected="true"
                  >
                    <br />
                    1
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="2"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    2
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="3"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    3
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="4"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    4
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="5"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    5
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="6"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    6
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="showImgTres">
        <h3 class="text-left">Nuevos consejos 2021 2024</h3>
        <hr
          class="red"
          style="
            width: 35px;
            height: 5px;
            background-color: #b38e5d;
            position: absolute;
          "
        />
        <br />
        <div
          class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
          data-toggle="modal"
          data-target="#lightbox"
        >
          <div class="col-md-12" *ngIf="paginaActiva == 1">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Nuevos-consejos-2021-2024/Apertura de la ceremonia de la renovación de los órganos auxiliares del Inmujeres.jpg"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="0"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Nuevos-consejos-2021-2024/Ceremonia de la renovación de los órganos auxiliares del Inmujeres.jpg"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="1"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 2">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Nuevos-consejos-2021-2024/Ceremonia de la renovación de los consejos social y consultivo del Inmujeres.jpg"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="2"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Nuevos-consejos-2021-2024/Ceremonia de renovación de los órganos auxiliares del Inmujeres. Palabras de la Dra. Nad.jpg"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="3"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-12" *ngIf="paginaActiva == 3">
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Nuevos-consejos-2021-2024/Momentos de la ceremonia de la renovación de los órganos auxiliares del Inmujeres.jpg"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="4"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <img
                src="assets/Multimedia/Fotos/Nuevos-consejos-2021-2024/Posterior a la ceremonia de la renovación de los órganos auxiliares del Inmujeres.jpg"
                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                data-target="#indicators"
                data-slide-to="5"
                alt=""
              />
            </div>
          </div>
          <div
            class="row justify-content-center"
            style="justify-content: center"
          >
            <nav
              aria-label="..."
              class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
              style="align-items: center"
            >
              <ul
                class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                style="align-content: center"
                id="pills-tab"
              >
                <li class="nav-item active text-center">
                  <a
                    class="nav-link"
                    id="1"
                    (click)="tooglePagi($event)"
                    aria-selected="true"
                  >
                    <br />
                    1
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="2"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    2
                  </a>
                </li>
                <li class="nav-item text-center">
                  <a
                    class="nav-link"
                    id="3"
                    (click)="tooglePagi($event)"
                    aria-selected="false"
                  >
                    <br />
                    3
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- publicaciones-->
    <div class="col-md-12" *ngIf="temaTres">
      <h3 class="text-left">Publicaciones</h3>
      <div
        *ngIf="showDoc"
        class="row d-flex flex-wrap align-items-center"
        data-toggle="modal"
        data-target="#vidbox"
      >
        <div class="col-sm-3 my-5 text-center">
          <a
            href="../../../assets/Multimedia/Publicaciones/Elementos Diagnosticos.pdf"
            target="_blank"
          >
            <span
              class="glyphicon glyphicon-book p-3"
              aria-hidden="true"
              style="font-size: 140px; color: #1b7729"
            ></span> </a
          ><br />
          <a
            href="../../../assets/Multimedia/Publicaciones/Elementos Diagnosticos.pdf"
            target="_blank"
          >
            Elementos Diagnosticos
          </a>
        </div>
        <div class="col-sm-3 my-5 text-center">
          <a
            href="../../../assets/Multimedia/Publicaciones/LIGA CEDOC.pdf"
            target="_blank"
          >
            <span
              class="glyphicon glyphicon-book p-3"
              aria-hidden="true"
              style="font-size: 140px; color: #335f91"
            ></span> </a
          ><br />
          <a
            href="../../../assets/Multimedia/Publicaciones/LIGA CEDOC.pdf"
            target="_blank"
          >
            LIGA CEDOC
          </a>
        </div>
        <div class="col-sm-3 my-5 text-center">
          <a
            href="../../../assets/Multimedia/Publicaciones/Memoria Foro ENC_digital.pdf"
            target="_blank"
          >
            <span
              class="glyphicon glyphicon-book p-3"
              aria-hidden="true"
              style="font-size: 140px; color: #71277d"
            ></span> </a
          ><br />
          <a
            href="../../../assets/Multimedia/Publicaciones/Memoria Foro ENC_digital.pdf"
            target="_blank"
          >
            Memoria Foro ENC_digital
          </a>
        </div>
        <div class="col-sm-3 my-5 text-center">
          <a
            href="../../../assets/Multimedia/Publicaciones/SituacionDeLasMujeresMexicanasEnTiemposDePandemia.pdf"
            target="_blank"
          >
            <span
              class="glyphicon glyphicon-book p-3"
              aria-hidden="true"
              style="font-size: 140px; color: #7d8e1f"
            ></span> </a
          ><br />
          <a
            href="http://cedoc.inmujeres.gob.mx/documentos_download/Dialogo%20Nacional%20Web.pdf"
            target="_blank"
          >
            Tiempos De Pandemia
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
