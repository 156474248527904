import { Injectable } from '@angular/core';
import { LoginService } from '../service/login.service';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import swal from "sweetalert2";
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, private router: Router){ }

    intercept(req: HttpRequest<any>, next: HttpHandler):
      Observable<HttpEvent<any>>{


        return next.handle(req).pipe(
          catchError(error =>{
            if(error.status === 401){ //El usuario no esta autenticado
              if(this.loginService.isAuthenticated()){ // Si el front tiene la sesion guardad en cache
                this.loginService.logout(); // Cerrar sesion
              }
              this.router.navigate(['/login']) //Redireccionar al login
            }

            if(error.status=== 403 ){ //El usuario esta autenticado pero no tiene los permisos o roles para ejecutar
              swal.fire('Acceso denegado',`Hola ${this.loginService.usuario.nombre} usted no tiene acceso a este recurso!`, 'warning' );
              this.router.navigate(['/dependencias']) //Redireccionar a la lista de dependecias
            }
            return throwError(error);
          })
        );
    }
}
