import { Component, OnInit, Input } from '@angular/core';
import { Dependencia} from '../../../models/dependecia';
import { DependenciaService } from '../../../service/dependencias.service';
import { ModalService} from './modal.service';
//import { ActivatedRoute } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
import swal from "sweetalert2";

@Component({
  selector: 'detalle-cliente',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {

  @Input() dependecia: Dependencia;
  titulo: string = "Detalle del cliente";
  fotoSeleccionado: File;
  progreso: number = 0;

  constructor(private dependeciasService: DependenciaService,
    //private activatedRoute: ActivatedRoute,
    public modalService: ModalService) { }

  ngOnInit(): void {
    /*this.activatedRoute.paramMap.subscribe(params =>{
      let id: number = +params.get('id');
      if(id){
        this.clienteService.getCliente(id).subscribe(cliente=>{
          this.cliente = cliente;
        });
      }
    })*/
  }

  seleccionarFoto(event:any){
    this.fotoSeleccionado = event.target.files[0];
    console.log(this.fotoSeleccionado);
    this.progreso = 0;
    if(this.fotoSeleccionado.type.indexOf('image') < 0){
      swal.fire("Error al seleccioanr imagen","El archivo debe ser de tipo imagen","error");
      this.fotoSeleccionado = null;
    }
  }

  subirFoto(){
    if(this.fotoSeleccionado){
      this.dependeciasService.subirFoto(this.fotoSeleccionado, this.dependecia.id).subscribe(
        event => {
          if(event.type === HttpEventType.UploadProgress){
            this.progreso = Math.round((event.loaded / event.total) * 100);
            console.log(this.progreso);
          }else if(event.type == HttpEventType.Response){
            let response: any = event.body;
            this.dependecia = response.cliente as Dependencia;
            this.modalService.notificarUpload.emit(this.dependecia);
            swal.fire('Cliente actualizado',`Cliente ${this.dependecia.nombre} actualizado con exito!`,'success' )
          }
          //this.cliente = cliente;
          //swal.fire('Cliente actualizado',`Cliente ${cliente.nombre} actualizado con exito!`,'success' )
        }
      );
    }else{
      swal.fire("Error upload","Debe seleccionar una foto","error");
    }
  }

  cerrarModal(){
    this.modalService.cerrarModal();
    this.fotoSeleccionado = null;
    this.progreso = 0;
  }

}
