import { Component, OnInit } from '@angular/core';

import { Eventos } from '../../models/eventos';
import { Noticias } from '../../models/noticias';
import { NoticiaService } from './../../service/noticias.service';
import { EventoService } from './../../service/eventos.service';
import { LoginService } from '../../service/login.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-eventos-destacados',
  templateUrl: './eventos-destacados.component.html',
  styleUrls: ['./eventos-destacados.component.css'],
})
export class EventosDestacadosComponent implements OnInit {
  public classNoticias: String = 'selectedButtonOrange';
  public classEvents: String;
  public showNoticias: String;
  public showEventos: String;
  public classEventosProx: String = 'selectedButtonOrange';
  public classEventosPas: String;
  public showEventosPas: String;
  public showEventosProx: String = 'show';
  public mesesNoticias: Noticias[] = [];
  public mesesNoticiasHistorico: Noticias[];
  public eventosProximos: Eventos[] = [];
  public eventosAnteriores: Eventos[] = [];

  constructor(
    private noticiaService: NoticiaService,
    private eventoService: EventoService,
    private activateRoute: ActivatedRoute,
    public loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.activateRoute.paramMap.subscribe((params) => {
      this.noticiaService.getMesesNoticia().subscribe((response) => {
        this.mesesNoticias = (response).slice(0, 3) as Noticias[];
        this.mesesNoticiasHistorico = response.slice(3, 7) as Noticias[];

        console.log(this.mesesNoticias.slice(3, response.length));
        console.log(response.length);
      });
    });
    // this.mesesNoticias.slice(0, 3);
    this.cargarProximosEventos();
    this.cargarAnterioresEventos();
  }

  cargarProximosEventos(): void {
    this.activateRoute.paramMap.subscribe((params) => {
      this.eventoService.getProximosEventos().subscribe((response) => {
        this.eventosProximos = response as Eventos[];
      });
    });
  }

  cargarAnterioresEventos(): void {
    this.activateRoute.paramMap.subscribe((params) => {
      this.eventoService.getAnterioresEventos().subscribe((response) => {
        this.eventosAnteriores = response as Eventos[];
      });
    });
  }

  color(id: number): String {
    switch (id % 3) {
      case 0:
        return 'greenSpan';
      case 1:
        return 'orangeSpan';
      case 2:
        return 'purpleSpan';
    }
  }

  mesAnno(fecha: string, conector: string): String {
    let conversor = new Date(fecha);
    let mes = conversor.toLocaleString('es', { month: 'long' });
    return mes + (conector != null ? conector : ' ') + conversor.getFullYear();
  }

  toogleElements(event: any): void {
    if (event.currentTarget.value === 'Noticias') {
      this.classEvents = '';
      this.classNoticias = 'selectedButtonOrange';
      this.showNoticias === 'show'
        ? (this.showNoticias = '')
        : (this.showNoticias = 'show');
      this.showEventos = '';
    } else if (event.currentTarget.value === 'Eventos') {
      this.classEvents = 'selectedButtonPurple';
      this.classNoticias = '';
      this.showEventos === 'show'
        ? (this.showEventos = '')
        : (this.showEventos = 'show');
      this.showNoticias = '';
    }
  }

  toogleEventos(event: any): void {
    if (event.currentTarget.id === 'prox') {
      this.cargarProximosEventos();
      this.classEventosPas = '';
      this.showEventosPas = '';
      this.showEventosProx === 'show'
        ? (this.showEventosProx = '')
        : (this.showEventosProx = 'show');
    } else {
      this.cargarAnterioresEventos();
      this.classEventosProx = '';
      this.classEventosPas = 'selectedButtonPurple';
      this.showEventosProx = '';
      this.showEventosPas === 'show'
        ? (this.showEventosPas = '')
        : (this.showEventosPas = 'show');
    }
  }

  /*scroll pantalla arriba o inicio*/
  movementNewsTop() {
    window.scrollTo(0, 0);
  }
}
