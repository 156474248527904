import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import { Publicacion } from 'src/app/models/publicacion';
import { PublicacionesService } from 'src/app/service/publicaciones.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-mesas-dialogo',
  templateUrl: './mesas-dialogo.component.html',
  styleUrls: ['./mesas-dialogo.component.css'],
})
export class MesasDialogoComponent implements OnInit {
  //public page!:number;

  publicacion: Publicacion;
  json: JSON;

  constructor(private activateRoute: ActivatedRoute, private publicacionesService: PublicacionesService, private sanitizer: DomSanitizer) {}
  public activeFiles: Object[] = [];
  public uno: Boolean;
  public dos: Boolean;
  public tres: Boolean;
  public cuatro: Boolean;
  public cinco: Boolean;
  public seis: Boolean;
  public siete: Boolean;
  public ocho: Boolean;
  public nueve: Boolean;
  public diez: Boolean;

  public paginaActiva: Number = 1;

  public showVideo: Boolean;
  public showImg: Boolean;
  public showDoc: Boolean;
  public showPre: Boolean;

  
  ngOnInit(): void {
    window.scrollTo(0, 0);
    // se modifico
    this.paginaActiva = 1;
    this.activateRoute.params.subscribe((params) => {
      let id = params['id'];
      switch (id) {
        case '1':
          this.activeFiles.push(
            { pill: 'active', tab: true },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' }
          );
          break;
        case '2':
          this.activeFiles.push(
            { pill: '', tab: '' },
            { pill: 'active', tab: true },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' }
          );
          break;
        case '3':
          this.activeFiles.push(
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: 'active', tab: true },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' }
          );
          break;
        case '4':
          this.activeFiles.push(
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: 'active', tab: true },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' }
          );
          break;
        case '5':
          this.activeFiles.push(
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: 'active', tab: true },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' }
          );
          break;
        case '6':
          this.activeFiles.push(
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: 'active', tab: true },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' }
          );
          break;
          case '7':
          this.activeFiles.push(
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: 'active', tab: true },
            { pill: '', tab: '' },
            { pill: '', tab: '' }
          );
          break;
          case '8':
          this.activeFiles.push(
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: 'active', tab: true },
            { pill: '', tab: '' },
          );
          break;

          case '9':
          this.activeFiles.push(
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: '', tab: '' },
            { pill: 'active', tab: true }
          );
          break;
      }
    });
  }

  toogleDocs($event: any): void {
    // se modifico
    this.paginaActiva = 1;
    switch ($event.currentTarget.id) {
      case 'vid':
        this.showVideo === true
          ? (this.showVideo = false)
          : (this.showVideo = true);
        this.showImg = false;
        this.showDoc = false;
        this.showPre = false;
        break;

      case 'img':
        this.showImg === true ? (this.showImg = false) : (this.showImg = true);
        this.showVideo = false;
        this.showDoc = false;
        this.showPre = false;
        break;

      case 'doc':
        this.showDoc === true ? (this.showDoc = false) : (this.showDoc = true);
        this.showVideo = false;
        this.showImg = false;
        this.showPre = false;
        break;

      case 'pre':
        this.showPre === true ? (this.showPre = false) : (this.showPre = true);
        this.showVideo = false;
        this.showImg = false;
        this.showDoc = false;
        break;
    }
  }

  URLseguro(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  toogleEvent($event: any): void {
    this.paginaActiva = $event.currentTarget.id;
    $('ul.pagination > li.nav-item').each(function () {
      $(this).removeClass('active');
    });
    $('#' + $event.currentTarget.id)
      .parent()
      .addClass('active');
    /*switch ($event.currentTarget.id) {
      case "1":
        this.uno === true ? this.uno = true : this.uno = true;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "2":
        this.dos === true ? this.dos = true : this.dos = true;
        this.uno = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "3":
        this.tres === true ? this.tres = true : this.tres = true;
        this.uno = false;
        this.dos = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "4":
        this.cuatro === true ? this.cuatro = true : this.cuatro = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "5":
        this.cinco === true ? this.cinco = true : this.cinco = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "6":
        this.seis === true ? this.seis = true : this.seis = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

        case "7":
          this.siete === true ? this.siete = true : this.siete = true;
          this.uno = false;
          this.dos = false;
          this.tres = false;
          this.cuatro = false;
          this.cinco = false;
          this.seis = false;
          this.ocho = false;
          this.nueve = false;
          this.diez = false;
          break;

          case "8":
            this.ocho === true ? this.ocho = true : this.ocho = true;
            this.uno = false;
            this.dos = false;
            this.tres = false;
            this.cuatro = false;
            this.cinco = false;
            this.seis = false;
            this.siete = false;
            this.nueve = false;
            this.diez = false;

            break;

            case "9":
        this.nueve === true ? this.nueve = true : this.nueve = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.diez = false;
        break;

        case "10":
        this.diez === true ? this.diez = true : this.diez = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        break;

      default:
        this.uno === true ? this.uno = true : this.uno = true;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;
    }*/
  }
}
