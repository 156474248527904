import { ChangeDetectionStrategy, Component, Input, OnInit,NO_ERRORS_SCHEMA,NgModule } from '@angular/core';
import { Publicacion } from '../../../models/publicacion';
import { PublicacionesService } from '../../../service/publicaciones.service';
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";
import { FormsModule, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Usuario } from 'src/app/models/usuario';
import { UsuariosService } from 'src/app/service/usuarios.service';
import { Localidades } from 'src/app/models/localidades';

@Component({
  selector: 'app-form',
  templateUrl: './publicacionform.component.html',
  styleUrls: ['../publicacion.component.css']
})
export class PublicacionFormComponent implements OnInit {
  @Input() context: String;
  public showForm: boolean = true;
  public publicacion: Publicacion = new Publicacion();
  public titulo: String = this.getTitle();
  
  public myForm: FormGroup = new FormGroup({
    file: new FormControl('', [Validators.required]),
  })

  constructor(private publicacionService: PublicacionesService,
    private usuariosService: UsuariosService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
      this.activateRoute.params.subscribe(
        (params) => {
          let tipo = params['tipo'];
          switch (tipo) {
            case 1:
              this.titulo = "Registro nuevo Blog";
              break;
            case 2:
              this.titulo = "Registro nuevo Evento";
              break;
            case 3:
              this.titulo = "Registro nueva Noticia";
              break;
            case 4:
                this.titulo = "Registro nueva Vinculación";
                break;
            default:
              break;
          }
        }
      )
  }

  ngOnInit() {
  }

  getTitle(): String{
    this.activateRoute.params.subscribe(
      (params) => {
        let tipo = params['tipo'];
        switch (tipo) {
          case 1:
            return "Registro nuevo Blog";
          case 2:
            return "Registro nuevo Evento";
          case 3:
            return "Registro nueva Noticia";
          case 4:
            return "Registro nueva Vinculación";
          default:
            return "Registro";
        }
      }
    )
    return "Registro";
  }

  create(): void {
    swal.fire('Nueva publicación', `Publicación guardada con exito!`, 'success');
    this.router.navigate(['/publicaciones']);
  }

  update(): void {
    swal.fire('Actualización de publicación', `Publicación actualizada con exito!`, 'success');
    this.router.navigate(['/publicaciones']);
  }

}
