<div class="container">
    <div class="col-md-12 contentBlog">
        <div class="panel py-0 m-0">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/inicio">Inicio</a></li>
                <li class="breadcrumb-item"><a routerLink="/inicio/vinculacionesyalianzas">Alianzas</a></li>
                <li class="breadcrumb-item active">Publicación</li>
            </ol>
        </div>

        <a href="javascript: history.go(-1)" class="btn-flotante">Regresar</a>
        <br />
        
        <div class="container" id="pills-primer">
            <div class="container" data-toggle="modal" data-target="#lightbox">
                <div class="container-fluid text-center" style="background-color: #0a766d; color: white;">
                    <h3>{{publicacion.titulo}}{{mesAnno(publicacion.fechaPublicacion,'')}}</h3>
                </div><br>
                <div class="row">
                    <div class="container-fluid">
                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img class="d-block w-100"
                                        src="{{publicacion.imagen}}"
                                        alt="...">
                                </div>    
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div><br>
                <div class="container" style="text-align: justify;">
                    <p [innerHTML]="json['texto']" class="font-weight-bold">
                    </p>
                </div><br>
    
                <!--Circles-->
                <div class="circles circles-pills mt-5">
                    <div class="col-md-3">
                        <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                            <div class="d-flex flex-column">
                                <div class="rounded-circle purple mx-auto p-3">
                                    <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                </div>
                                <div class="text-center font-weight-bold text-purple p-2">
                                    <p>VIDEOS</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-3">
                        <a class="circles ml-n5" id="img" (click)="toogleDocs($event)">
                            <div class="d-flex flex-column">
                                <div class="rounded-circle orange mx-auto p-3">
                                    <span class="glyphicon glyphicon-picture p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                </div>
                                <div class="text-center">
                                    <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-3">
                        <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                            <div class="d-flex flex-column">
                                <div class="rounded-circle green mx-auto p-3">
                                    <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                </div>
                                <div class="text-center">
                                    <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-3">
                        <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                            <div class="d-flex flex-column">
                                <div class="rounded-circle blue mx-auto p-3">
                                    <span class="glyphicon glyphicon-file p-3" aria-hidden="true" style="font-size: 55px; color: #ffffff;"></span>
                                </div>
                                <div class="text-center font-weight-bold text-blue p-2">
                                    <p>PRESENTACIONES</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <!-- fin circles -->
                <div class="col-md-12">
                    <div class="col-md-12" *ngIf="showVideo">
                        <!--autoplay-->
                        <h3 class="text-left">Videos</h3>
                        <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                        <div class="row d-flex flex-wrap align-items-start" data-toggle="modal" data-target="#lightbox">
                            <div class="{{archivo['tipo'] == 'video' ? 'col-12 col-md-6' : '' }}" *ngFor="let archivo of json['archivos']; let i = index" [attr.data-index]="i">
                               
                                <div *ngIf="archivo['tipo'] == 'video' && archivo['ruta'].substring(0,1) == '/' && !(archivo['titulo'].startsWith('http') || archivo['titulo'].startsWith('www'))">
                                    <video class="d-block w-100" 
                                    controls style="width: 100%; 
                                    height: 300px; 
                                    background: black; margin: 1vw; 
                                    border-radius: 15px; 
                                    box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                                    ">
                                            <source src="{{archivo['ruta']}}" class="h-100" type="video/mp4">
                                    </video>                                    
                                    {{archivo["titulo"]}}
                                </div>
                                <!---->
                                <div class="col-md-12" *ngIf="archivo['tipo'] == 'video' && archivo['ruta'].substring(0,1) != '/' && !(archivo['titulo'].startsWith('http') || archivo['titulo'].startsWith('www'))">
                                <div class="col-sm-12 my-5 text-center">
                                <iframe [src]="URLseguro(archivo['ruta'])" 
                                width="570" height="325" 
                                title="YouTube video player" 
                                frameborder="0" 
                                style='
                                    border: none;
                                    overflow: hidden;
                                    width: 100%;
                                    height: 270px;
                                    background: black; margin: 1vw; 
                                    border-radius: 15px; 
                                    box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);' 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen>
                                </iframe>
                                    {{archivo["titulo"]}}
                                </div>

                                <div *ngIf="(archivo['titulo'].startsWith('http') || archivo['titulo'].startsWith('www'))">
                                    <a href="{{archivo['titulo']}}" target="_blank">
                                        <img src="{{archivo['ruta']}}" style="width: 100%;" alt="img">
                                    </a>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="showImg">
                        <h3 class="text-left">Imagenes</h3>
                        <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;"><br>
                        <div class="row d-flex flex-wrap align-items-center" data-toggle="modal" data-target="#lightbox">
                            <!--Se modifico-->
                            <div class="{{archivo['tipo'] == 'imagenes' ? 'col-12 col-md-6 col-lg-4' : '' }}"
                                *ngFor="let archivo of json['archivos']; let i = index" [attr.data-index]="i">
                                <div *ngIf="archivo['tipo'] == 'imagenes'">
                                    <img src="{{archivo['ruta']}}" class="img img-thumbnail m-4" data-target="#indicators" data-slide-to="0"
                                        alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="showDoc">
                        <h3 class="text-left">Documentos</h3>
                        <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                        <div class="{{archivo['tipo'] == 'documentos' ? 'col-sm-3 my-5 text-center' : '' }}" *ngFor="let archivo of json['archivos']; let i = index" [attr.data-index]="i">
                            <div *ngIf="archivo['tipo'] == 'documentos'">
                                <a href="{{archivo['ruta']}}" target="_blank">
                                    <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #285887;"></span>
                                </a><br>
                                <a href="{{archivo['ruta']}}" target="_blank">
                                    {{archivo['titulo']}}
                                </a>
                            </div>
                        </div>                        
                    </div>
                    <div class="col-md-12" *ngIf="showPre">
                        <h3 class="text-left">Presentaciones</h3>
                        <hr class="red" style="width: 35px; height: 5px; background-color: #B38E5D; position: absolute;">
                        <div class="{{archivo['tipo'] == 'presentacion' ? 'col-sm-3 my-5 text-center' : '' }}" *ngFor="let archivo of json['archivos']; let i = index" [attr.data-index]="i">
                            <div *ngIf="archivo['tipo'] == 'presentacion'">
                                <a href="{{archivo['ruta']}}" target="_blank">
                                    <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #1f9385;"></span>
                                </a><br>
                                <a href="{{archivo['ruta']}}" target="_blank">
                                    {{archivo['titulo']}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
        </div>
    </div>
</div>