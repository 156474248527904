import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Video } from '../models/video';
import { URL_CONFIG } from '../store/config';
import { map, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  private urlEndPoint: string = `${URL_CONFIG}/api/videos`;
  private urlEndPointBase: string = `${URL_CONFIG}/api`;
  private ng: NgModule = new NgModule;

  constructor(private httpClient: HttpClient, private router: Router) { }

  obtenerVideo(): Observable<Video[]> {
  //  console.log("ENTRA A OBTENER VIDEO");
    var tipoS = '/video';
    return this.httpClient.get<Video[]>(this.urlEndPointBase+tipoS);
  }

  borrarVideo(id: number): Observable<any> {
   // console.log("ENTRA A BORRAAR VIDEO");
    return this.httpClient.delete<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al eliminar la publicacion', e.error.mensaje, 'error');
          }
          console.log(throwError(e));
          return throwError(e);
        }
      )
    );
  }

  obtenerVideos(id: string): Observable<Video> {
  //  console.log("ENTRA A OBTENER VIDEOS");
    return this.httpClient.get<Video>(`${this.urlEndPointBase}/videos/${id}`);
  }

  createVideo(video: Video): Observable<Video>{
  //  console.log("ENTRA A CREAR VIDEOS");
    return this.httpClient.post<Video>(this.urlEndPoint , video).pipe(
      map((response: any) => response as Video),
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            swal.fire('Error en el registro', e.error.mensaje,'error' );
            if(video.tipoPublicacion == "video"){
              this.router.navigate(['/publicaciones/video']);
            }

          }
          console.log(throwError(e));
          return throwError(e);
        }
      )
    );
  }

  subirArchivo(archivo: File, id: any, filename: string): Observable<HttpEvent<{}>>{
   // console.log("ENTRA A SUBIR ARCHIVO");
    let formData = new FormData();
    formData.append("file", archivo);
    formData.append("id", id);
    formData.append("filename", filename);

    const req = new HttpRequest('POST', `${this.urlEndPoint}/upload`, formData, {
      reportProgress: true
    });

    return this.httpClient.request(req).pipe(
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          //swal.fire('Error al guardar el archivo', e.error.mensaje, 'error');
        }
        console.log(throwError(e));
        return throwError(e);
      })
    );
  }

}
