import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { URL_HOSTNAME } from '../../../store/config';
import { Publicacion } from '../../../models/publicacion';
import { PublicacionesService } from '../../../service/publicaciones.service';
import { Video } from '../../../models/video';
import { VideoService } from '../../../service/video.service';


@Component({
  selector: 'app-form-video',
  templateUrl: './form-video.component.html',
  styleUrls: ['./form-video.component.css']
})
export class FormVideoComponent implements OnInit {
  @Input() context: String;
  public showForm: boolean = true;
  public publicacion: Publicacion = new Publicacion();
  public video: Video = new Video();
  public titulo: String = "Registro nuevo Video";
  public file: File;
  public fileName: string = null;
  public fileSelected: Boolean = false;
  public showFileButton: Boolean = true;

  public myForm: FormGroup = new FormGroup({
    file: new FormControl('', [Validators.required]),
  })

  constructor(private publicacionService: PublicacionesService,
    private videoService: VideoService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(
      (params) => {
        let id = params['id']
        if (id) {
          this.titulo = "Editar Video";
          this.cargarVideo(id);
        } else {
          this.titulo = "Alta de Video";
        }
      }
    )
  }

  cargarVideo(id: string){
  //  console.log("HEY HEY");
    //this.titulo ="Editar dependencia"
    this.videoService.obtenerVideos(id)
      .subscribe((video) => {
        video.fechaPublicacion = video.fechaPublicacion.substring(0,10);
        //video.contenido = URL_HOSTNAME+video.contenido;
        this.showFileButton = false;
        this.video = video;
      });
  }

 /* create(){
    console.log("pOR AQUI");
    swal.fire('Guardando',`Espere un momento` ,'info' );
    this.video.tipoPublicacion = "video";
    this.videoService.createVideo(this.video).subscribe((video => {
      // console.log(carrusel);
      this.video = video;
      this.videoService.subirArchivo(this.file, this.video.id, this.video.contenido).subscribe(
        (status) => {
          // console.log(">>>C");
          // console.log(status);
          swal.fire('Nuevo Video', `Video: "${this.video.titulo}" guardada con exito!`, 'success');
          this.router.navigate(['/publicaciones']);
        }
      );
    }))
  }*/
  create() {
  //  console.log("pOR AQUI");
    swal.fire('Guardando', 'Espere un momento', 'info');
    this.video.tipoPublicacion = "video";
  
    // Llamada al servicio para crear el video
    this.videoService.createVideo(this.video).subscribe(
      (video) => {
        this.video = video;
        swal.fire('Nuevo Video', `Video: "${this.video.titulo}" guardado con éxito!`, 'success');
        this.router.navigate(['/publicaciones']);
      },
      (error) => {
        // Manejar errores en la creación del video
      //  console.error('Error al crear el video:', error);
        swal.fire('Error', 'Hubo un error al guardar el video. Por favor, inténtelo de nuevo.', 'error');
      }
    );
  }
  

  update(){
   // console.log("Entro aqui");
    //swal.fire('Actualizando',`Espere un momento` ,'info' );
    if(this.fileSelected){
      this.video.contenido = this.fileName;
    }else{
      this.video.contenido = "no cambiar";
    }
    this.videoService.createVideo(this.video).subscribe((publicacion => {
      // console.log(publicacion);
      this.video = publicacion;
      if(this.fileSelected){
        this.videoService.subirArchivo(this.file, this.video.id, this.video.contenido).subscribe(
          (status) => {
            swal.fire('Video actualizado', `Video: "${this.video.titulo}" actualizado con exito!`, 'success');
            this.router.navigate(['/publicaciones']);
          }
        );
      }else{
        swal.fire('Video actualizada', `Video: "${this.video.titulo}" actualizado con exito!`, 'success');
        this.router.navigate(['/publicaciones']);
      }
    }))
  }

  showFileButtonEvent():void {
    this.showFileButton = this.showFileButton ? false : true;
  }
}
