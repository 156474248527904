import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Publicacion } from '../models/publicacion';
import { URL_CONFIG } from '../store/config';
import { map, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class PublicacionesService {

  private urlEndPoint: string = `${URL_CONFIG}/api/publicaciones`;
  private urlEndPointBase: string = `${URL_CONFIG}/api`;
  private ng: NgModule = new NgModule;

  constructor(private httpClient: HttpClient, private router: Router) { }

  obtenerPublicaciones(tipo: number=1): Observable<Publicacion[]> {
    var tipoS = tipo == 1 ? "/blogs" : tipo == 2 ? '/eventos' :  tipo == 3 ? '/noticias' : tipo == 4 ? '/vinculacion' : tipo==5 ? '/alianza' : tipo==6 ? '/carrusel' : tipo==7 ? '/video' : '/blogs';
    //@TODO Cambiar publicaciones a blogs 
    return this.httpClient.get<Publicacion[]>(this.urlEndPointBase+tipoS);
  }

  obtenerPublicacionesPage(tipo: number=1, page: number): Observable<any> {
    var tipoS = tipo == 1 ? "/blogs" : tipo == 2 ? '/eventos' : tipo == 3 ? '/noticias' : tipo == 4 ? '/vinculacion' : tipo==5 ? '/alianza' : tipo==6 ? '/carrusel' : tipo==7 ? '/video' : '/multimedia';
    return this.httpClient.get(this.urlEndPointBase+tipoS+'/page/' + page).pipe(
      map((response: any) => {
        (response.content as Publicacion[]).map((publicacion) => {
          let datePipe = new DatePipe('es-MX');

          publicacion.fechaPublicacion = datePipe.transform(publicacion.fechaPublicacion, 'EEEE dd, MMMM, yyyy');
          return publicacion;
        });
        return response;
      }),
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          swal.fire('Error al realizar la consulta  de la base des', e.error.mensaje, 'error');
        }
        return throwError(e);
      })
    );
  }

  subirImagen(archivo: File, id: any): Observable<HttpEvent<{}>> {
    let formData = new FormData();
    formData.append("file", archivo);
    formData.append("id", id);
    const req = new HttpRequest('POST', `${this.urlEndPoint}/upload`, formData, {
      reportProgress: true
    });

    return this.httpClient.request(req).pipe(
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          swal.fire('Error al ejecutar', e.error.mensaje, 'error');
        }
        return throwError(e);
      })
    );
  }

  obtenerPublicacion(id: string): Observable<Publicacion> {
    return this.httpClient.get<Publicacion>(`${this.urlEndPointBase}/noticias/${id}`);
  }

  obtenerEvento(id: string): Observable<Publicacion> {
    return this.httpClient.get<Publicacion>(`${this.urlEndPointBase}/eventos/${id}`);
  }

  obtenerBlog(id: string): Observable<Publicacion> {
    return this.httpClient.get<Publicacion>(`${this.urlEndPointBase}/blogs/${id}`);
  }

  obtenerVinculacion(id: string): Observable<Publicacion> {
    return this.httpClient.get<Publicacion>(`${this.urlEndPointBase}/vinculacion/${id}`);
  }

  obtenerAlianzas(id: string): Observable<Publicacion> {
    return this.httpClient.get<Publicacion>(`${this.urlEndPointBase}/alianza/${id}`);
  }
  
  obtenerVideos(id: string): Observable<Publicacion> {
    return this.httpClient.get<Publicacion>(`${this.urlEndPointBase}/video/${id}`);
  }

  obtenerPublicacionesLandingPage(): Observable<any> {
    return this.httpClient.get<Publicacion>(`${this.urlEndPointBase}/blogs`);
  }

  obtenerPublicacionesLandingsPages(): Observable<any> {
    return this.httpClient.get<Publicacion>(`${this.urlEndPointBase}/vinculacion`);
  }

  obtenerPublicacionesLandingsPage(): Observable<any> {
    return this.httpClient.get<Publicacion>(`${this.urlEndPointBase}/alianza`);
  }
  

  borrarPublicacion(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al eliminar la publicacion', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  buscarPublicacionesByNombre(nombre: string): Observable<any> {
    return this.httpClient.get(this.urlEndPoint + '/byNombre/' + nombre).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            if(nombre != ""){
              swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
            }
          }
          return throwError(e);
        }
      )
    );
  }  
  
  createNoticia(publicacion: Publicacion): Observable<Publicacion>{
    return this.httpClient.post<Publicacion>(this.urlEndPoint , publicacion).pipe(
      map((response: any) => response as Publicacion),
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            swal.fire('Error en el registro', e.error.mensaje,'error' );
            if(publicacion.tipoPublicacion == "noticias"){
              this.router.navigate(['/publicaciones/formnoticias']);
            }else if(publicacion.tipoPublicacion == "eventos"){
              this.router.navigate(['/publicaciones/formeventos']);
            }else if(publicacion.tipoPublicacion == "blog"){
              this.router.navigate(['/publicaciones/formblog']);
            }else if(publicacion.tipoPublicacion == "vinculacion"){
              this.router.navigate(['/publicaciones/formvinculacion']);
            }else if(publicacion.tipoPublicacion == "alianzas"){
              this.router.navigate(['/publicaciones/formalianza']);
            }else if(publicacion.tipoPublicacion == "carrusel"){
              this.router.navigate(['/publicaciones/carrusel']);
            }else if(publicacion.tipoPublicacion == "video"){
              this.router.navigate(['/publicaciones/video']);
            }
          }
          return throwError(e);
        }
      )
    );
  }

  subirArchivo(archivo: File, id: any, filename: string): Observable<HttpEvent<{}>>{
    let formData = new FormData();
    formData.append("file", archivo);
    formData.append("id", id);
    formData.append("filename", filename);

    const req = new HttpRequest('POST', `${this.urlEndPoint}/upload`, formData, {
      reportProgress: true
    });

    return this.httpClient.request(req).pipe(
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          //swal.fire('Error al guardar el archivo', e.error.mensaje, 'error');
        }
        console.log(throwError(e));
        return throwError(e);
      })
    );
  }

  correcionRutas(id: any): Observable<HttpEvent<{}>>{
    const req = new HttpRequest('GET', `${this.urlEndPoint}/correcion/`+id, {
    });
    return this.httpClient.request(req).pipe(catchError(e => {
        if (e.status != 401 && e.status != 403) {
          //swal.fire('Error al ejecutar', e.error.mensaje, 'error');
        }
        return throwError(e);
      }));
  }

  subirArchivos(archivo: File, id: any, filename: string): Observable<HttpEvent<{}>> {
    let formData = new FormData();
    formData.append("file", archivo);
    formData.append("id", id);
    formData.append("filename", filename);

    /**let httpHeaders = new HttpHeaders();
    let token = this.loginService.token;
    if(token != null){
      httpHeaders = httpHeaders.append('Authorization', 'Bearer '+token);
    }*/

    const req = new HttpRequest('POST', `${this.urlEndPoint}/upload`, formData, {
      reportProgress: true
    });

    return this.httpClient.request(req).pipe(
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          //swal.fire('Error al ejecutar', e.error.mensaje, 'error');
        }
        return throwError(e);
      })
    );
  }
}
