import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Carrusel } from '../models/carrusel';
import { URL_CONFIG } from '../store/config';
import { map, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class CarruselService {

  private urlEndPoint: string = `${URL_CONFIG}/api/carruseles`;
  private urlEndPointBase: string = `${URL_CONFIG}/api`;
  private ng: NgModule = new NgModule;

  constructor(private httpClient: HttpClient, private router: Router) { }

  obtenerCarrusel(): Observable<Carrusel[]> {
    var tipoS = '/carrusel';
    return this.httpClient.get<Carrusel[]>(this.urlEndPointBase+tipoS);
  }

  borrarCarrusel(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al eliminar la publicacion', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  obtenerCarrusels(id: string): Observable<Carrusel> {
    return this.httpClient.get<Carrusel>(`${this.urlEndPointBase}/carruseles/${id}`);
  }

  createCarrusel(carrusel: Carrusel): Observable<Carrusel>{
    return this.httpClient.post<Carrusel>(this.urlEndPoint , carrusel).pipe(
      map((response: any) => response as Carrusel),
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            swal.fire('Error en el registro', e.error.mensaje,'error' );
            if(carrusel.tipoPublicacion == "carrusel"){
              this.router.navigate(['/publicaciones/carrusel']);
            }

          }
          return throwError(e);
        }
      )
    );
  }

  subirArchivo(archivo: File, id: any, filename: string): Observable<HttpEvent<{}>>{
    let formData = new FormData();
    formData.append("file", archivo);
    formData.append("id", id);
    formData.append("filename", filename);

    console.log(formData);
    const req = new HttpRequest('POST', `${this.urlEndPoint}/upload`, formData, {
      reportProgress: true
    });

    return this.httpClient.request(req).pipe(
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          //swal.fire('Error al guardar el archivo', e.error.mensaje, 'error');
        }
        console.log(throwError(e));
        return throwError(e);
      })
    );
  }


}
