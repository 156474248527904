import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent implements OnInit {

  constructor(private activateRoute: ActivatedRoute) { }
  public activeFiles: Object[] = [];
  public temaUno: Boolean;
  public temaDos: Boolean;
  public temaTres: Boolean;
  public temaCuatro: Boolean;
  public showVideo: Boolean;
  public showImgUna: Boolean;
  public showImgDos: Boolean;
  public showImgTres: Boolean;
  public showDoc: Boolean;
  public showPre: Boolean;
  public uno: Boolean;
  public dos: Boolean;
  public tres: Boolean;
  public cuatro: Boolean;
  public cinco: Boolean;
  public seis: Boolean;
  public siete: Boolean;
  public ocho: Boolean;
  public nueve: Boolean;
  public diez: Boolean;
//  
  public paginaActiva: Number = 1;

  ngOnInit(): void {
    window.scrollTo(0, 0);
  // se modifico
  this.paginaActiva = 1;
    this.activateRoute.params.subscribe(
      (params) => {
        let id = params['id'];
        switch (id) {
          case '1':
            this.activeFiles.push(
//              { pill: "active", tab: "active show" },
              { pill: "active", tab: "active show" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" });
            break;
          case '2':
            this.activeFiles.push(
              { pill: "", tab: "" },
              { pill: "active", tab: "active show" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" });
            break;
          case '3':
            this.activeFiles.push(
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "active", tab: "active show" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" });
            break;
          case '4':
            this.activeFiles.push(
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "active", tab: "active show" },
              { pill: "", tab: "" },
              { pill: "", tab: "" });
            break;
          case '5':
            this.activeFiles.push(
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "active", tab: "active show" },
              { pill: "", tab: "" });
            break;
          case '6':
            this.activeFiles.push(
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "active", tab: "active show" });
            break;
          default:
            this.activeFiles.push(
              { pill: "active", tab: "active show" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" },
              { pill: "", tab: "" });
            break;
        }
      });

      /*this.activatedRoute.params.subscribe(
        (params) => {
          let id = params['id'];
          switch (id) {
            case '1':
              this.activeTemas.push(
                { pill: "active", tab: "active show" },
                { pill: "", tab: "" },
                { pill: "", tab: "" },
                { pill: "", tab: "" });
              break;
            case '2':
              this.activeTemas.push(
                { pill: "", tab: "" },
                { pill: "active", tab: "active show" },
                { pill: "", tab: "" },
                { pill: "", tab: "" });
              break;
            case '3':
              this.activeTemas.push(
                { pill: "", tab: "" },
                { pill: "", tab: "" },
                { pill: "active", tab: "active show" },
                { pill: "", tab: "" });
              break;
            case '4':
              this.activeTemas.push(
                { pill: "", tab: "" },
                { pill: "", tab: "" },
                { pill: "", tab: "" },
                { pill: "active", tab: "active show" });
              break;
          }
        });*/
  }

  toogleTemas($event: any): void {
     // se modifico
     this.paginaActiva = 1;
     switch ($event.currentTarget.id) {
      case "unotema":
        this.temaUno === true ? this.temaUno = true : this.temaUno = true;
        this.temaDos = false;
        this.temaTres = false;
        this.temaCuatro = false;
        break;

      case "dostema":
        this.temaDos === true ? this.temaDos = false : this.temaDos = true;
        this.temaUno = false;
        this.temaTres = false;
        this.temaCuatro = false;
        break;

      case "trestema":
        this.temaTres === true ? this.temaTres = false : this.temaTres = true;
        this.temaUno = false;
        this.temaDos = false;
        this.temaCuatro = false;
        break;

      case "4":
        this.temaCuatro === true ? this.temaCuatro = false : this.temaCuatro = true;
        this.temaUno = false;
        this.temaDos = false;
        this.temaTres = false;
        break;
    }
  }

  toogleDocs($event: any): void {
    // se modifico
    this.paginaActiva = 1;
    switch ($event.currentTarget.id) {
      case "vid":
        this.showVideo === true ? this.showVideo = false : this.showVideo = true;
        this.showImgUna = false;
        this.showImgDos = false;
        this.showImgTres = false;
        this.showDoc = false;
        this.showPre = false;
        break;

        case "imgUna":
          this.showImgUna === true ? this.showImgUna = false : this.showImgUna = true;
          this.showImgDos = false;
          this.showImgTres = false;  
          this.showVideo = false;
          this.showDoc = false;
          this.showPre = false;
          break;
          case "imgDos":
            this.showImgDos === true ? this.showImgDos = false : this.showImgDos = true;
            this.showImgUna = false;
            this.showImgTres = false;    
            this.showVideo = false;
            this.showDoc = false;
            this.showPre = false;
            break;
            case "imgTres":
              this.showImgTres === true ? this.showImgTres = false : this.showImgTres = true;
              this.showImgUna = false;
              this.showImgDos = false;
              this.showVideo = false;
              this.showDoc = false;
              this.showPre = false;
              break;
            
      case "doc":
        this.showDoc === true ? this.showDoc = false : this.showDoc = true;
        this.showVideo = false;
        this.showImgUna = false;
        this.showImgDos = false;
        this.showImgTres = false;
        this.showPre = false;
        break;

      case "pre":
        this.showPre === true ? this.showPre = false : this.showPre = true;
        this.showVideo = false;
        this.showImgUna = false;
        this.showImgDos = false;
        this.showImgTres = false;
        this.showDoc = false;
        break;
    }

  }

  tooglePagi($event: any): void {
    this.paginaActiva = $event.currentTarget.id;
    $("ul.pagination > li.nav-item").each(function(){  
      $(this).removeClass("active");
    });
    $("#"+$event.currentTarget.id).parent().addClass("active");

    /*  switch ($event.currentTarget.id) {
      case "1":
        this.uno === true ? this.uno = true : this.uno = true;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "2":
        this.dos === true ? this.dos = true : this.dos = true;
        this.uno = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "3":
        this.tres === true ? this.tres = true : this.tres = true;
        this.uno = false;
        this.dos = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "4":
        this.cuatro === true ? this.cuatro = true : this.cuatro = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "5":
        this.cinco === true ? this.cinco = true : this.cinco = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "6":
        this.seis === true ? this.seis = true : this.seis = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

      case "7":
        this.siete === true ? this.siete = true : this.siete = true;
        this.uno = false;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;

        case "8":
          this.ocho === true ? this.ocho = true : this.ocho = true;
          this.uno = false;
          this.dos = false;
          this.tres = false;
          this.cuatro = false;
          this.cinco = false;
          this.seis = false;
          this.siete = false;
          this.nueve = false;
          this.diez = false;
          break;
          case "9":
            this.nueve === true ? this.nueve = true : this.nueve = true;
            this.uno = false;
            this.dos = false;
            this.tres = false;
            this.cuatro = false;
            this.cinco = false;
            this.seis = false;
            this.siete = false;
            this.ocho = false;
            this.diez = false;
            break;
            case "10":
              this.diez === true ? this.diez = true : this.diez = true;
              this.uno = false;
              this.dos = false;
              this.tres = false;
              this.cuatro = false;
              this.cinco = false;
              this.seis = false;
              this.siete = false;
              this.ocho = false;
              this.nueve = false;
              break;
                
          


      default:
        this.uno === true ? this.uno = true : this.uno = true;
        this.dos = false;
        this.tres = false;
        this.cuatro = false;
        this.cinco = false;
        this.seis = false;
        this.siete = false;
        this.ocho = false;
        this.nueve = false;
        this.diez = false;
        break;
    }
  */
  }

}
