import { Component, OnInit } from '@angular/core';
import { Usuario }  from '../../models/usuario';
import { UsuariosService } from '../../service/usuarios.service';
import { LoginService} from '../../service/login.service';
import  swal  from 'sweetalert2';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  usuarios: Usuario[];
  paginador: any;
  busqueda: string;

  constructor(private usuarioService: UsuariosService,
    public loginService: LoginService) { }

  ngOnInit(): void {
    this.usuarioService.getUsuarios().subscribe(usuarios => {
      this.usuarios = usuarios;
    })
  }

  delete(usuario: Usuario): void{
    swal.fire({
      title: 'Eliminando Usuario',
      text: `¿Seguro que desea eliminar al usuario: "${usuario.nombre}" ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.usuarioService.delete(usuario.id).subscribe(
          () => {
            this.usuarios = this.usuarios.filter((user) => user != usuario)
            swal.fire(
              'Usuario eliminado!',
              `Usuario: ""${usuario.nombre}" eliminado con éxito!`,
              'success'
            )
          }
        )
      }
    })
  }

  buscar(){
    this.usuarioService.getUsuarioByNombre(this.busqueda).subscribe(
      (usuarios) => {
          this.usuarios = usuarios;
      })
    }
  

}
