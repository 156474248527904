import { ChangeDetectionStrategy, Component, Input, OnInit,NO_ERRORS_SCHEMA,NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import swal from "sweetalert2";
import { FormsModule, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Multimedia } from '../../../models/multimedia';
import { PublicacionesService } from '../../../service/publicaciones.service';
import { MultimediaService } from '../../../service/multimedia.service';
import { Publicacion } from '../../../models/publicacion';
import { MultimediaContenido } from '../../../models/multimedia-contenido';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { URL_HOSTNAME } from '../../../store/config';
import * as $ from 'jquery';

@Component({
  selector: 'app-form-multimedia',
  templateUrl: './form-multimedia.component.html',
  styleUrls: ['./form-multimedia.component.css']
})
export class FormMultimediaComponent implements OnInit {
  @Input() context: String;
  public showForm: boolean = true;
  public publicacion: Multimedia = new Multimedia();
  public blogcontenido: MultimediaContenido = new MultimediaContenido;
  public titulo: String;
  public file: File;
  public files: File[];
  public imgSrc: String;
  public Editor = ClassicEditor;
  public contenido: string;
  public archivosSeleccionados: Object;
  
  public fileName: string = null;
  public fileSelected: Boolean = false;
  public showFileButton: Boolean = true;

  public jsonLista = [];
  public guardarArchivos: boolean = false;
  public revisarMIME: boolean = true;

  public myForm: FormGroup = new FormGroup({
    file: new FormControl('', [Validators.required]),
  })

  constructor(private publicacionService: PublicacionesService,
    private multimediaService: MultimediaService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.publicacion.tipoPublicacion = "multimedia";
    this.files = [];
    this.activateRoute.params.subscribe((params) => {
      let id = params['id'];
      if(id){
        this.titulo = "Actualización del Multimedia ";
        this.publicacion.id = id;
        this.multimediaService.obtenerMultimedia(id).subscribe((publicacion) => {
          this.publicacion = publicacion;
          this.blogcontenido = JSON.parse(this.publicacion.contenido);
          this.jsonLista =  this.blogcontenido.archivos;
          for(let i of this.blogcontenido.archivos){
            /**console.log(window.location.origin + i.ruta);*/
            fetch(window.location.origin + i.ruta)
            .then(res => res.blob())
            .then(blob => {
              let objectURL = URL.createObjectURL(blob);              
              /**console.log( i.ruta.substring( i.ruta.lastIndexOf('/') + 1 ) );*/
              this.files.push(new File([blob], i.ruta.split("/").pop(), {
                type: blob.type,
              }));
            });
          }
          this.contenido = this.blogcontenido.texto;
          let fecha = this.publicacion.fechaPublicacion.substring(0,10); 
          this.publicacion.fechaPublicacion = fecha;
          /**console.log(this.publicacion.id);*/
          this.showFileButton = false;
          console.log(this.files);
        });
      }else{
        this.titulo = "Registrar una nueva Multimedia";
        /**console.log(this.publicacion.id);*/
      }
    });    
  }

  create() {
    swal.fire('Guardando',`Espere un momento` ,'info' );
    this.blogcontenido.subtitulo = "";
    this.blogcontenido.texto = this.contenido;
    this.blogcontenido.archivos = this.jsonLista;
    this.publicacion.contenido = JSON.stringify(this.blogcontenido);

    this.multimediaService.createMultimedia(this.publicacion).subscribe((publicacion) => {
      this.publicacion = publicacion; 
      for(let file of this.files){        
        this.multimediaService.subirArchivo(file, this.publicacion.id, "/assets/Multimedia/<carpeta>/"+file.name).subscribe((http_code) => {
          console.log(http_code);
        });
      }
      this.multimediaService.correcionRutas(this.publicacion.id).subscribe((reset) => {});
          swal.fire('Nuevo Multimedia', `Multimedia: "${this.publicacion.titulo}" guardada con exito!`, 'success');       
          this.router.navigate(['/publicaciones/8/0']);
    });
  }

  update(): void {
    swal.fire('Actualizando',`Espere un momento` ,'info' );
    
    // this.publicacion.categoria = null;
    this.blogcontenido.imagen_principal = this.publicacion.imagen;
    this.blogcontenido.subtitulo = "";
    this.blogcontenido.texto = this.contenido;
    this.blogcontenido.archivos = this.jsonLista; 
    this.publicacion.contenido = JSON.stringify(this.blogcontenido);
    console.log(this.publicacion.contenido);
    
    this.multimediaService.createMultimedia(this.publicacion).subscribe((publicacion) => {
      console.log("***********************************")
      console.log(publicacion)   
      this.multimediaService.correcionRutas(this.publicacion.id).subscribe((reset) => {});        
      if(this.files.length){
        for(let file of this.files){            
          this.multimediaService.subirArchivo(file, this.publicacion.id, "/assets/Multimedia/<carpeta>/"+file.name).subscribe((http_code) => {
            console.log(http_code);
          });
        }  
      }
      if(this.publicacion){
        swal.fire('Multimedia actualizado', `Multimedia: "${this.publicacion.titulo}" guardado con exito!`, 'success');
      }else{
        swal.fire('Nuevo Multimedia', `Multimedia: "${this.publicacion.titulo}" guardado con exito!`, 'success');
      }
      this.router.navigate(['/publicaciones/8/0']);
    });
  }

  cancelar(): void{
    this.router.navigate(['/publicaciones']);
  }


  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      //const [file] = event.target.files;
      this.file = event.target.files[0];
      reader.readAsDataURL(this.file);
      
      reader.onload = () => {
        this.fileSelected = true;
        this.fileName = this.file.name;

        if(this.publicacion.tipoPublicacion == "multimedia"){
          this.publicacion.imagen = "/assets/Multimedia/" + this.fileName;
        }

        /*this.myForm.patchValue({
          fileSource: reader.result
        });*/
      };
    }
  }

  showFileButtonEvent():void {
    //this.showFileButton = this.showFileButton ? false : true;
    this.showFileButton = !this.showFileButton;
  }

  mostrarCargaArchivos(): void{    
    document.getElementById("multiFile").click();
  }

  mostrarCargaEnlace(): void{
    this.jsonLista.push({});
    this.guardarArchivos = true;
  }

  cambiosRealizados(): void{
    this.guardarArchivos = true;
  }

  cambiarRevision(): void{    
    this.revisarMIME = true;
  }

  comparacionMIME(nombre): boolean{
    var encontrado = false;
    for(let file of this.files){
      if(file.name == nombre){
        encontrado = /^image/.test(file.type);
        break;
      }
    }
    return encontrado;
  }

  agregarMultiplesArchivos(event): void{
    const lector = new FileReader();
    var tamannoAnterior = this.files.length;
    if(event.target.files){
      for(let file of event.target.files){
        this.files.push(file);
      }
      var archivoJSON = {};  
      for(var index = tamannoAnterior; index < this.files.length; index++){
        archivoJSON = {};
        archivoJSON["ruta"] = "/assets/Multimedia/<carpeta>/"+this.files[index].name;
        archivoJSON["name"] = this.files[index].name;
        if(/^image/.test(this.files[index].type)){
          archivoJSON["tipo"] = "imagenes";
        }else if(/^video/.test(this.files[index].type)){
          archivoJSON["tipo"] = "video";
        }else if(/^application\/pdf/.test(this.files[index].type)){
          archivoJSON["tipo"] = "publicacion";
        }else{
          archivoJSON["tipo"] = "publicacion";
        }
        this.jsonLista.push(archivoJSON);
      }
      this.guardarArchivos = this.jsonLista.length > 0;
    }
  }
  
  eliminarMultiplesArchivos():void{
    var listaArchivos = document.getElementsByClassName("lista-archivos")[0] as HTMLSelectElement;
    var listaSeleccionado = 0;
    while(listaSeleccionado != -1){      
      listaSeleccionado = listaArchivos.selectedIndex;
      listaArchivos.children[listaSeleccionado].remove();
      this.jsonLista.splice(listaSeleccionado,1);
    }
  }

  guardarJSON(): void{
    var archivoJSON = {};
    var nuevoJSONLista = [];
    for(var index = 0; index < $("#archivo").children().length - 1; index ++){
      if($("#nombre_archivo_"+index).val()){
        archivoJSON["ruta"] = "/assets/Multimedia/<carpeta>/" + $("#nombre_archivo_"+index).val();
        archivoJSON["name"] = $("#nombre_archivo_"+index).val();     
      }else{
        archivoJSON["ruta"] = $("#direccion_archivo_"+index).val();
      }
      archivoJSON["tipo"] = $("#tipo_archivo_"+index).val();
      archivoJSON["titulo"] = $("#titulo_archivo_"+index).val();
      nuevoJSONLista.push(archivoJSON);
      archivoJSON = {};
    }
    this.jsonLista = nuevoJSONLista;
    $("#cerrar").click();
  } 
}
