import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Estado } from '../../models/estados';
import { Municipio } from '../../models/municipios';
import { Dependencia } from '../../models/dependecia';
import { Categoria } from '../../models/categorias';
import { DependenciaService } from '../../service/dependencias.service';
import { colors } from '../util/colors';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AvisosService } from 'src/app/service/avisos.service';
import { Avisos } from 'src/app/models/avisos';
import { EventoService } from 'src/app/service/eventos.service';
import { NoticiaService } from 'src/app/service/noticias.service';
import { Eventos } from 'src/app/models/eventos';
import { Noticias } from 'src/app/models/noticias';
import { Tema } from 'src/app/models/tema';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent implements OnInit {

  @Input() categoria: Categoria;
  @Output() avisos = new EventEmitter<Avisos[]>();
  @Output() eventos = new EventEmitter<Eventos[]>();
  @Output() noticias= new EventEmitter<Noticias>();
  @Output() dependencias = new EventEmitter<Dependencia>();

  public estados: Estado[];
  public estado: Estado;
  public municipios: Municipio[];
  public municipio: Municipio;
  public nombreForm: FormGroup;
  public organizaciones: Dependencia[];
  public temas: Tema[];
  public tema: Tema;

  //public dependencias: Dependencia[];
  //public categoria: Categoria;

  constructor(private dependeciaService: DependenciaService,
    private avisosService: AvisosService,
    private eventosService: EventoService,
    private noticiaService: NoticiaService,
    private formBuilder: FormBuilder) {
      this.nombreForm = new FormGroup({
        nombre : new FormControl('')
     });
     }

  ngOnInit(): void {
    this.cargarEstados();
    this.cargarTemas();
  }

  compararEstados(o1: Estado, o2: Estado): boolean {
    if ((o1 === undefined ||o1 === null) && (o2 === undefined || o2 === null)) {
      return true;
    }
    return o1 === undefined || o2 === undefined ? false : o1.id === o2.id;
  }

  compararMunicipios(o1: Municipio, o2: Municipio): boolean {
    if ((o1 === undefined || o1 === null) && ( o2 === undefined || o2 === null)) {
      return true;
    }
    return o1 === undefined || o2 === undefined ? false : o1.id === o2.id;
  }

  cargarTemas(): void {
    this.dependeciaService.getTemas().subscribe((temas)=>{
      this.temas = temas;
    })
  }
  cargarEstados(): void {
    this.dependeciaService.getEstados().subscribe((estados) => {
      this.estados = estados
    })
    this.cargarDependencias();
  }

  cargarDependencias(): void {
    this.dependeciaService.getDependenciaByCategoria(this.categoria).subscribe((dependencias) => {
      this.organizaciones = dependencias;
     /*  this.dependencias.map(function (dependencia: Dependencia) {
        dependencia.color = colors.getRandomColor();
      }) */
    })
  }
  cargarMunicipios(): void {
    if (this.estado !== undefined) {
      this.dependeciaService.getMunicipios(this.estado).subscribe((municipios) => {
        this.municipio = undefined
        this.municipios = municipios
      });
    }
  }

  limpiar(): void{
    this.estado = undefined;
    this.municipio = undefined;
    this.tema = undefined;
    this.nombreForm.get('nombre').setValue("");
  }

  buscar(): void {
    if(this.nombreForm.get('nombre').value.length > 0){
        this.dependeciaService.getDependenciasByNombre(this.nombreForm.get('nombre').value).subscribe((dependencias) => {
          this.dependeciaService
          .getDependenciasByNombre(this.nombreForm.get('nombre').value)
          .subscribe((dependencias) => {
              dependencias.map(function (dependencia: Dependencia) {
                dependencia.color = colors.getRandomColor();
              })
              this.dependencias.emit(dependencias);
          });
        })
    }else if(this.tema !== undefined){
        this.dependeciaService
        .getDependenciasByTema(this.tema, this.estado, this.municipio, this.categoria)
        .subscribe((dependencias) => {
            dependencias.map(function (dependencia: Dependencia) {
              dependencia.color = colors.getRandomColor();
            })
            this.dependencias.emit(dependencias);
        });
    }else if (this.municipio !== undefined) {
      this.dependeciaService.getDependenciaByMunicipio(this.municipio, this.categoria).subscribe((dependencias) => {
        dependencias.map(function (dependencia: Dependencia) {
          dependencia.color = colors.getRandomColor();
        })
        this.dependencias.emit(dependencias);
      })
    } else if (this.estado != undefined){
      this.dependeciaService.getDependenciaByEstado(this.estado, this.categoria).subscribe((dependencias) => {
        dependencias.map(function (dependencia: Dependencia) {
          dependencia.color = colors.getRandomColor();
        })
        this.dependencias.emit(dependencias);
      })
    }

/*
      this.eventosService.getEventosByFiltro(this.estado, this.categoria , this.municipio, this.nombreForm.get('nombre').value).subscribe((eventos) => {
      this.eventos.emit(eventos);
  })
 */
 }
}
