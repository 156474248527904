import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/usuario';
import swal from "sweetalert2";
import { LoginService } from '../../service/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  usuario: Usuario;

  constructor(public loginService: LoginService, private router: Router) {
    this.usuario = new Usuario();
  }

  ngOnInit(): void {
    if(this.loginService.isAuthenticated()){
      swal.fire("Login", `Hola ${this.loginService.usuario.nombre} ya está autenticado!`, 'info');
      this.router.navigate(['/dependencias']);
    }
  }

  login():void{
    if(this.usuario.email == null || this.usuario.password == null){
      swal.fire('Error Login', 'Correo electrónico ó contraseña vacías','error' );
    }
    this.loginService.login(this.usuario).subscribe(
      response=>{
        console.log(response)

        this.loginService.guardarUsuario(response);
        let usuario = this.loginService.usuario;
        this.router.navigate(["/bienvenida"]);
        swal.fire('Login', `Hola ${usuario.nombre}, ha iniciado sesión con éxito`, 'success');
      },
      error => {
        if(error.status == 400){
          swal.fire('Error Login', 'Correo electrónico ó contraseña son incorrectas!','error' );
        }
      }
    )
  }

}
