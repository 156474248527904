import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import swal from "sweetalert2";
import { URL_CONFIG } from '../store/config';
import { map, catchError } from 'rxjs/operators';
import { Dependencia } from '../models/dependecia';
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  private urlEndPoint: string = URL_CONFIG + '/api/usuarios';

  constructor(private http: HttpClient, private router: Router) { }


  getUsuarios(): Observable<Usuario[]>{
    return this.http.get<Usuario[]>(this.urlEndPoint).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
            this.router.navigate(['/dependecias'])
          }
          return throwError(e);
        }
      ));
  }
  
  save(user :Usuario): Observable<Usuario>{
    return this.http.post<Usuario>(this.urlEndPoint , user).pipe(
      map((response: any) => response.User as Usuario),
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
            this.router.navigate(['/usuarios']);
          }
          return throwError(e);
        }
      )
    );
  }

  update(user :Usuario): Observable<Usuario>{
    return this.http.put<Usuario>(`${this.urlEndPoint}/${user.id}`, user).pipe(
      map((response: any) => response.dependencia as Usuario),
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
            this.router.navigate(['/usuarios']);
          }
          return throwError(e);
        }
      )
    );
  }

  getUsuario(id:number): Observable<Usuario>{
    return this.http.get<Usuario>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
            this.router.navigate(['/dependecias'])
          }
          return throwError(e);
        }
      ));
  }

  delete(id: number): Observable<any>{
    return this.http.delete<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            console.log(e.error);
            swal.fire('Error al eliminar al usuario', e.error.mensaje,'error' );
            return throwError(e);
          }
        }
      )
    );
  }

  getUsuarioByDependencia(id:number): Observable<Usuario>{
    return this.http.get<Usuario>(`${this.urlEndPoint}/usuarioByDependencia/${id}`).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){}
          return throwError(e);
        }
      ));
  }

  getUsuarioByNombre(nombre: string): Observable<any> {
    let data = new FormData();
    data.append("nombre", nombre);
    return this.http.post(this.urlEndPoint + "/byNombre" , data).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){}
          return throwError(e);
        }
      )
    );
  }


}
