<div class="container">
  <div class="row">
    <div class="col-sm-12 contentMesa">
      <div class="container text-center">
        <h2>Mesas de Articulación de Acciones con OSC</h2>
        <br />
      </div>
      <a routerLink="/inicio/vinculacionesyalianzas" class="btn-flotante">Regresar</a>
      <br />

      <!-- primer apartado  mesasDialogo/1-->
      <div
        class="container"
        *ngIf="this.activeFiles['0'].tab"
        id="pills-primer"
      >
        <div class="container" data-toggle="modal" data-target="#lightbox">
          <div
            class="container-fluid text-center"
            style="background-color: #59287f; color: white"
          >
            <h3>Violencia y acceso a la justicia</h3>
          </div>
          <br />
          <div class="row">
            <div class="container-fluid">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="3"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="4"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/1.jpg"
                      alt="..."
                    />
                  </div>

                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/2.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/3.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/4.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/5.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div class="container" style="text-align: justify">
            <p class="font-weight-bold">
              Objetivo: Promover la articulación entre organizaciones de la
              sociedad civil y las dependencias del gobierno federal que
              trabajan en contra de la discriminación y violencia contra las
              mujeres, niñas y adolescentes, a través de mesas de diálogo y
              reflexión que permitan el fortalecimiento de las actividades que
              están desarrollando para dar cumplimiento a la línea de acción
              puntual 4.7.3 del PROIGUALDAD 2020-2024.
            </p>
          </div>
          <br />

          <!--Circles-->
          <div class="circles circles-pills mt-5">
            <div class="col-md-3">
              <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle purple mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-facetime-video p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-purple p-2">
                    <p>VIDEOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                class="circles ml-n5"
                id="img"
                (click)="toogleEvent($event)"
                aria-selected="true"
                (click)="toogleDocs($event)"
              >
                <div class="d-flex flex-column">
                  <div class="rounded-circle orange mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-picture p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle green mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-folder-open p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle blue mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-file p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-blue p-2">
                    <p>PRESENTACIONES</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- fin circles -->
          <div class="col-md-12">
            <div class="col-md-12" *ngIf="showVideo">
              <h3 class="text-left">Videos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center my-5 text-center"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F2658625271110615%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                    <p>
                      Diálogo y Articulación de acciones con organizaciones de
                      la Sociedad civil
                    </p>
                  </div>
                  <!--div class="col-md-6">
                  <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                </div-->
                </div>
                <!--div class="col-md-12" *ngIf="paginaActiva == 2">
                <div class="col-md-6">
                  <iframe
                  src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                  style="
                    border: none;
                    overflow: hidden;
                    width: 100%;
                    height: 270px;
                  "
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  allowFullScreen="true"
                ></iframe>
                </div>
                <div class="col-md-6">
                  <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                </div>
              </div-->

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <!--li class="nav-item text-center">
                      <a
                        class="nav-link"
                        id="2"
                        (click)="toogleEvent($event)"
                        aria-selected="false"
                      >
                        <br />
                        2
                      </a>
                    </li-->
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="showImg">
              <h3 class="text-left">Imagenes</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <!-- <div class="col-12 col-md-6 col-lg-4">-->
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/1.jpg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/2.jpg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/3.jpg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/4.jpg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 3">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/5.jpg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/Invitación/Captura.PNG"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>
                <!--se modifico--->

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          2
                        </a>
                      </li>

                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="3"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          3
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showDoc">
              <h3 class="text-left">Documentos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Programa/Programa Tema 1 Violencia de género y acceso a la justicia. 4 d e Oct..pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Programa/Programa Tema 1 Violencia de género y acceso a la justicia. 4 d e Oct..pdf"
                    target="_blank"
                  >
                    Programa Tema 1 Violencia y acceso a la justicia
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                    target="_blank"
                  >
                    Reseña Tema 1 Violencia y Acceso a la Justicia
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showPre">
              <h3 class="text-left">Presentaciones</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Brigada callejera. ELVIRA MADRID.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Brigada callejera. ELVIRA MADRID.pdf"
                    target="_blank"
                  >
                    Brigada callejera. ELVIRA MADRID
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Dra. Iris Rocío Santillán..pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Dra. Iris Rocío Santillán..pdf"
                    target="_blank"
                  >
                    Dra. Iris Rocío Santillán
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/PRESENTACION OCNF.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/PRESENTACION OCNF.pdf"
                    target="_blank"
                  >
                    PRESENTACION OCNF
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Segundo apartado mesasDialogo/2-->
      <div
        class="container"
        *ngIf="this.activeFiles['1'].tab"
        id="pills-segundo"
      >
        <div class="container" data-toggle="modal" data-target="#lightbox">
          <div
            class="container-fluid text-center"
            style="background-color: #a0bf38; color: white"
          >
            <h3>Salud y bienestar para mujeres, niñas y adolescentes</h3>
          </div>
          <br />
          <div class="row">
            <div class="container-fluid">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <!--<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>-->
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="assets/Mesas-de-dialogo/Salud-y-bienestar/FOTOGRAFÍAS/FLYER/Dialogo proigualdad OSC invitacion_Zoom.jpg"
                      alt="..."
                    />
                  </div>
                  <!--<div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/2.jpg" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/3.jpg" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/4.jpg" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/5.jpg" class="d-block w-100" alt="..." />
                                    </div>-->
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div class="container" style="text-align: justify">
            <p class="font-weight-bold">
              Objetivo: Promover la articulación entre organizaciones de la
              sociedad civil y las dependencias del gobierno federal que
              trabajan en contra de la discriminación y violencia contra las
              mujeres, niñas y adolescentes, a través de mesas de diálogo y
              reflexión que permitan el fortalecimiento de las actividades que
              están desarrollando para dar cumplimiento a la línea de acción
              puntual 4.7.3 del PROIGUALDAD 2020-2024.
            </p>
            <br />
            <p class="font-weight-bold">
              Introducción: <br />
              El día 4 de octubre de 2021, en las instalaciones del Instituto
              Nacional de las Mujeres, se realizó la primera mesa de
              articulación de acciones con organizaciones de la sociedad civil,
              teniendo como marco el Programa Nacional de Igualdad 2020 - 2024
              (PROIGUALDAD), documento que establece la ruta que, a nivel
              nacional orienta la conformación y articulación de las políticas
              públicas para impulsar la igualdad entre mujeres y hombres en los
              tres niveles de gobierno.
              <br />
              Este programa establece en algunas de sus líneas de acción,
              acciones específicas orientadas a promover la vinculación con
              otros actores sociales y sectores de la sociedad. para encontrar
              vías de articulación de proyectos y trabajo conjunto que puedan
              tener un impacto positivo en las condiciones de bienestar,
              seguridad y justicia para las mujeres, adolescentes y niñas que
              vivan en nuestro país.
            </p>
          </div>
          <br />

          <!--Circles-->
          <div class="circles circles-pills mt-5">
            <div class="col-md-3">
              <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle purple mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-facetime-video p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-purple p-2">
                    <p>VIDEOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                class="circles ml-n5"
                id="img"
                (click)="toogleEvent($event)"
                aria-selected="true"
                (click)="toogleDocs($event)"
              >
                <div class="d-flex flex-column">
                  <div class="rounded-circle orange mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-picture p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle green mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-folder-open p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle blue mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-file p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-blue p-2">
                    <p>PRESENTACIONES</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- fin circles -->
          <div class="col-md-12">
            <div class="col-md-12" *ngIf="showVideo">
              <h3 class="text-left">Videos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center my-5 text-center"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F2658625271110615%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                    <p>Salud y bienestar para mujeres, niñas y adolescentes</p>
                  </div>
                  <!--div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                  </div-->
                </div>
                <!--div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                  </div>
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                  </div>
                </div-->

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <!--li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          2
                        </a>
                      </li-->
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showImg">
              <h3 class="text-left">Imagenes</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-content-center justify-content-center align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <!--   <div class="col-md-12" *ngIf="uno"> -->
                  <!--  <div class="col-12 col-md-6 col-lg-4">-->
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Salud-y-bienestar/FOTOGRAFÍAS/FLYER/Dialogo proigualdad OSC invitacion_Zoom.jpg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showDoc">
              <h3 class="text-left">Documentos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Salud-y-bienestar/PROGRAMA/Programa Tema 2 Salud y Bienestar. 4 de octubre.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Salud-y-bienestar/PROGRAMA/Programa Tema 2 Salud y Bienestar. 4 de octubre.pdf"
                    target="_blank"
                  >
                    Programa Tema 2 Salud y Bienestar. 4 de octubre
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Salud-y-bienestar/RESEÑA/Reseña Mesa 2 Salud y bienestar. 4 de octubre.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Salud-y-bienestar/RESEÑA/Reseña Mesa 2 Salud y bienestar. 4 de octubre.pdf"
                    target="_blank"
                  >
                    Reseña Mesa 2 Salud y bienestar. 4 de octubre
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showPre">
              <h3 class="text-left">Presentaciones</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Salud-y-bienestar/PRESENTACIONES/DRA. MÓNICA JASIS. ESTADO DE DD SS Y REP MUJERES Y NIÑAS 2021.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Salud-y-bienestar/PRESENTACIONES/DRA. MÓNICA JASIS. ESTADO DE DD SS Y REP MUJERES Y NIÑAS 2021.pdf"
                    target="_blank"
                  >
                    DRA. MÓNICA JASIS. ESTADO DE DD SS Y REP MUJERES Y NIÑAS
                    2021
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Tercer apartado mesasDialogo/3-->
      <div
        class="container"
        *ngIf="this.activeFiles['2'].tab"
        id="pills-tercer"
      >
        <div class="container" data-toggle="modal" data-target="#lightbox">
          <div
            class="container-fluid text-center"
            style="background-color: #db8f1b; color: white"
          >
            <h3>Participación política y ciudadana de las mujeres</h3>
          </div>
          <br />
          <div class="row">
            <div class="container-fluid">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="assets/Mesas-de-dialogo/Participación-política/INVITACIÓN/Invitación Dialogo proigualdad OSC 2_Videoconferencia Telmex.jpg"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/Captura.PNG"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/Captura 2.PNG"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/Captura 3.PNG"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div class="container" style="text-align: justify">
            <p class="font-weight-bold">
              Objetivo: Promover la articulación entre organizaciones de la
              sociedad civil y las dependencias del gobierno federal que
              trabajan en contra de la discriminación y violencia contra las
              mujeres, niñas y adolescentes, a través de mesas de diálogo y
              reflexión que permitan el fortalecimiento de las actividades que
              están desarrollando para dar cumplimiento a la línea de acción
              puntual 4.7.3 del PROIGUALDAD 2020-2024.
            </p>
          </div>
          <br />

          <!--Circles-->
          <div class="circles circles-pills mt-5">
            <div class="col-md-3">
              <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle purple mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-facetime-video p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-purple p-2">
                    <p>VIDEOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                class="circles ml-n5"
                id="img"
                (click)="toogleEvent($event)"
                aria-selected="true"
                (click)="toogleDocs($event)"
              >
                <div class="d-flex flex-column">
                  <div class="rounded-circle orange mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-picture p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle green mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-folder-open p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle blue mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-file p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-blue p-2">
                    <p>PRESENTACIONES</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- fin circles -->
          <div class="col-md-12">
            <div class="col-md-12" *ngIf="showVideo">
              <h3 class="text-left">Videos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center my-5 text-center"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F603232344160581%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                    <p>Participación política y ciudadana de las Mujeres</p>
                  </div>
                  <!--div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                  </div-->
                </div>
                <!--div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                  </div>
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                  </div>
                </div-->

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <!--li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          2
                        </a>
                      </li-->
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showImg">
              <h3 class="text-left">Imagenes</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-content-center justify-content-center align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <!--    <div class="col-md-12" *ngIf="uno"> -->
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/Captura.PNG"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/Captura 2.PNG"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/Captura 3.PNG"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img1.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 3">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img2.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img3.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 4">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img4.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img5.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 5">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img6.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img7.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 6">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img8.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img8-5.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 7">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img9.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img10.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-md-12" *ngIf="paginaActiva == 8">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img11.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/img12.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          2
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="3"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          3
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="4"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          4
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="5"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          5
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="6"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          6
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="7"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          7
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="8"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          8
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showDoc">
              <h3 class="text-left">Documentos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Participación-política/PROGRAMA/Programa Mesa Participación política. 11 nov.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Participación-política/PROGRAMA/Programa Mesa Participación política. 11 nov.pdf"
                    target="_blank"
                  >
                    Programa Mesa Participación política. 11 de noviembre
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Participación-política/RESEÑA/Reseña Mesa Participación Política.11 de noviembre.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Participación-política/RESEÑA/Reseña Mesa Participación Política.11 de noviembre.pdf"
                    target="_blank"
                  >
                    Reseña Mesa Participación Política. 11 de noviembre
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showPre">
              <h3 class="text-left">Presentaciones</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Participación-política/PRESENTACIONES/ANABEL LÓPEZ SÁNCHEZ.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Participación-política/PRESENTACIONES/ANABEL LÓPEZ SÁNCHEZ.pdf"
                    target="_blank"
                  >
                    ANABEL LÓPEZ SÁNCHEZ
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Participación-política/PRESENTACIONES/RED CHIAPAS POR LA PARIDAD EFECTIVA_2.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Participación-política/PRESENTACIONES/RED CHIAPAS POR LA PARIDAD EFECTIVA_2.pdf"
                    target="_blank"
                  >
                    RED CHIAPAS POR LA PARIDAD EFECTIVA_2
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Cuarto apartado mesasDialogo/4-->
      <div
        class="container"
        *ngIf="this.activeFiles['3'].tab"
        id="pills-cuarto"
      >
        <div class="container" data-toggle="modal" data-target="#lightbox">
          <div
            class="container-fluid text-center"
            style="background-color: #59287f; color: white"
          >
            <h3>Aportes feministas para un Sistema Nacional de Cuidados</h3>
          </div>
          <br />
          <div class="row">
            <div class="container-fluid">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <!--<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>-->
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="assets/Mesas-de-dialogo/Aportes-feministas/FLYER/Invitación Dialogo proigualdad OSC 3_Videoconferencia Telmex.jpg"
                      alt="..."
                    />
                  </div>
                  <!--<div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/Captura 2.PNG" class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/Mesas-de-dialogo/Participación-política/FOTOGRAFÍAS/Fotografías1/Captura 3.PNG" class="d-block w-100" alt="..." />
                                    </div>-->
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div class="container" style="text-align: justify">
            <p class="font-weight-bold">
              Objetivo: Partir de un diagnóstico general, así como del horizonte
              de acción de las organizaciones de la sociedad civil participantes
              a fin de identificar propuestas de acción que permitan sentar las
              bases de articulación entre organizaciones de la sociedad civil y
              dependencias del gobierno federal para coadyuvar al Objetivo
              Prioritario 2 del PROIGUALDAD 2020-2024.
            </p>
          </div>
          <br />

          <!--Circles-->
          <div class="circles circles-pills mt-5">
            <div class="col-md-3">
              <a
                class="circles ml-n5"
                id="vid"
                (click)="toogleEvent($event)"
                aria-selected="true"
                (click)="toogleDocs($event)"
              >
                <div class="d-flex flex-column">
                  <div class="rounded-circle purple mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-facetime-video p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-purple p-2">
                    <p>VIDEOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                class="circles ml-n5"
                id="img"
                (click)="toogleEvent($event)"
                aria-selected="true"
                (click)="toogleDocs($event)"
              >
                <div class="d-flex flex-column">
                  <div class="rounded-circle orange mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-picture p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle green mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-folder-open p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle blue mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-file p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-blue p-2">
                    <p>PRESENTACIONES</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- fin circles -->
          <div class="col-md-12">
            <div class="col-md-12" *ngIf="showVideo">
              <h3 class="text-left">Videos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center my-5 text-center"
              >
                <!--    <div class="col-md-12" *ngIf="uno"> -->
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <!-- <div class="col-md-6">
                    <video class="d-block w-100" controls>
                      <source
                        src="assets/Mesas-de-dialogo/Aportes-feministas/GRABACIÓN/telmex_0.mp4"
                        
                        type="video/mp4"
                        style="border: none;
                        overflow: hidden; width: 100%; height: 270px
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        "
                      />
                    </video>
                    <p>Video de la presidenta</p>
                  </div> -->
                  <div class="col-md-6">
                    <video controls
                      src="assets/Mesas-de-dialogo/Aportes-feministas/GRABACIÓN/telmex_0.mp4"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></video>
                    <p>Video de la presidenta</p>
                  </div>
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                    <p>
                      Perspectivas feministas para un Sistema Nacional de
                      Cuidados
                    </p>
                  </div>
                </div>
                <div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <iframe
                      src="https://www.youtube.com/embed/CUzEZHMOI04"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                    <p style="margin-top: 5px">Mensaje Marta Ferreyra</p>
                  </div>
                </div>

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          2
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showImg">
              <h3 class="text-left">Imagenes</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-content-center justify-content-center align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Aportes-feministas/FLYER/Invitación Dialogo proigualdad OSC 3_Videoconferencia Telmex.jpg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showDoc">
              <h3 class="text-left">Documentos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Programa/Programa Mesa Cuidados  30 _Nov.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Programa/Programa Mesa Cuidados  30 _Nov.pdf"
                    target="_blank"
                  >
                    Programa Mesa Cuidados 30 Nov
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Reseña/Reseña Mesa Cuidados 30_ Nov.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Reseña/Reseña Mesa Cuidados 30_ Nov.pdf"
                    target="_blank"
                  >
                    Reseña Mesa Cuidados 30 Nov
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showPre">
              <h3 class="text-left">Presentaciones</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Cuidados_Marta Ferreyra.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Cuidados_Marta Ferreyra.pdf"
                    target="_blank"
                  >
                    Cuidados Martha Ferreyra
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Dra. Gabriela Rios-Cazares-G_DH-Cuidado_Nov302021.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Dra. Gabriela Rios-Cazares-G_DH-Cuidado_Nov302021.pdf"
                    target="_blank"
                  >
                    Dra. Gabriela Rios Cazares G DH Cuidado 30 Nov 2021
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Lulú Casares ONU Mujeres. Cuidados INMUJERES 30 NOV 2021.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Lulú Casares ONU Mujeres. Cuidados INMUJERES 30 NOV 2021.pdf"
                    target="_blank"
                  >
                    Lulú Casares ONU Mujeres. Cuidados INMUJERES 30 NOV 2021
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Mesa de articulación Perspectivas feministas para un SNDC_Susana González GENDES.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Mesa de articulación Perspectivas feministas para un SNDC_Susana González GENDES.pdf"
                    target="_blank"
                  >
                    Mesa de articulación Perspectivas feministas para un SNDC
                    Susana González GENDES
                  </a>
                </div>
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Uso de tiempo y trabajo no remunerado. Edith Pacheco.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Aportes-feministas/Presentaciones expertas/Uso de tiempo y trabajo no remunerado. Edith Pacheco.pdf"
                    target="_blank"
                  >
                    Uso de tiempo y trabajo no remunerado. Edith Pacheco
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--quinto apartado mesasDialogo/5-->
      <div
        class="container"
        *ngIf="this.activeFiles['4'].tab"
        id="pills-quinto"
      >
        <div class="container" data-toggle="modal" data-target="#lightbox">
          <div
            class="container-fluid text-center"
            style="background-color: #59287f; color: white"
          >
            <h3>
              Conversatorio sobre masculinidades alternativas y no violentas
            </h3>
          </div>
          <br />
          <div class="row">
            <div class="container-fluid">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="3"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="4"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/slider/Captura.PNG"
                      alt="..."
                    />
                  </div>
                  <!--div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/2.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/3.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/4.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/5.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div-->
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div class="container" style="text-align: justify">
            <p class="font-weight-bold">Objetivo:</p>
          </div>
          <br />

          <!--Circles-->
          <div class="circles circles-pills mt-5">
            <div class="col-md-3">
              <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle purple mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-facetime-video p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-purple p-2">
                    <p>VIDEOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                class="circles ml-n5"
                id="img"
                (click)="toogleEvent($event)"
                aria-selected="true"
                (click)="toogleDocs($event)"
              >
                <div class="d-flex flex-column">
                  <div class="rounded-circle orange mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-picture p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle green mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-folder-open p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle blue mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-file p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-blue p-2">
                    <p>PRESENTACIONES</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- fin circles -->
          <div class="col-md-12">
            <div class="col-md-12" *ngIf="showVideo">
              <h3 class="text-left">Videos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center my-5 text-center"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <iframe
                    src="https://drive.google.com/file/d/1kF2Mwk07GYOPDpvEa6NvjjdXkvW0R2yq/preview"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                      margin: 1vw; 
                      border-radius: 15px; 
                      box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                  <p>Conversatorio sobre masculinidades alternativas y no violentas</p>
                  <p style="font-size: small;">Inmujeres México</p>
                  </div>
                  <!-- <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                  </div> -->
                </div>
                <!-- <div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                  </div>
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                  </div>
                </div> -->

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <!--li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          2
                        </a>
                      </li-->
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="showImg">
              <h3 class="text-left">Imagenes</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M1.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M2.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M3.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M4.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 3">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M5.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M6.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 4">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M7.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M8.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 5">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M9.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Conversatorio_masculinidades/FOTOS/M10.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          2
                        </a>
                      </li>

                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="3"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          3
                        </a>
                      </li>

                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="4"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          4
                        </a>
                      </li>

                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="5"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          5
                        </a>
                      </li>

                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showDoc">
              <h3 class="text-left">Documentos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Conversatorio_masculinidades/documentos/Programa_5Julio version final.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Conversatorio_masculinidades/documentos/Programa_5Julio version final.pdf"
                    target="_blank"
                  >
                    Formación de Masculinidades No Violentas
                  </a>
                </div>
                <!--div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                    target="_blank"
                  >
                    Reseña Tema 1 Violencia y Acceso a la Justicia
                  </a>
                </div-->
              </div>
            </div>
            <div class="col-md-12" *ngIf="showPre">
              <h3 class="text-left">Presentaciones</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Conversatorio_masculinidades/presentaciones/CONVERSATORIO SOBRE MASCULINIDADES.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Conversatorio_masculinidades/presentaciones/CONVERSATORIO SOBRE MASCULINIDADES.pdf"
                    target="_blank"
                  >
                    Conversatorio sobre Masculinidades
                  </a>
                </div>
                <!--div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Dra. Iris Rocío Santillán..pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Dra. Iris Rocío Santillán..pdf"
                    target="_blank"
                  >
                    Dra. Iris Rocío Santillán
                  </a>
                </div -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--sexto apartado mesasDialogo/6-->
      <div class="container" *ngIf="this.activeFiles['5'].tab" id="pills-sexto">
        <div class="container" data-toggle="modal" data-target="#lightbox">
          <div
            class="container-fluid text-center"
            style="background-color: #59287f; color: white"
          >
            <h3>Violencia política</h3>
          </div>
          <br />
          <div class="row">
            <div class="container-fluid">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="3"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="4"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="assets/Mesas-de-dialogo/violencia_Politica/slider/ViolenciaPoliticaGenero.jpeg"
                      alt="..."
                    />
                  </div>

                  <!--div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/2.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/3.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/4.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="assets/Mesas-de-dialogo/Violencia-y-acceso/FOTOS/5.jpg"
                      class="d-block w-100"
                      alt="..."
                    />
                  </div-->
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div class="container" style="text-align: justify">
            <p class="font-weight-bold">
              Antecedentes: <br />
              En el marco de las líneas de acción 4.7.3, 4.7.4 y 5.2.3 del
              Programa de Trabajo PROIGUALDAD 2020-2024, cuyo cumplimiento es
              compromiso de la Secretaría de Gobernación, a través de la
              Subsecretaría de Desarrollo Democrático, Participación Social y
              Asuntos Religiosos, así como en seguimiento a las Mesas de Diálogo
              con OSC realizadas durante los meses de noviembre y diciembre de
              2021, en conjunto con INMUJERES e INDESOL, se propone llevar a
              cabo un conversatorio en materia de violencia política en razón de
              género, en el marco deldel Día Internacional de la Ciudadanía de
              las Mujeres.
            </p>
          </div>
          <br />

          <!--Circles-->
          <div class="circles circles-pills mt-5">
            <div class="col-md-3">
              <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle purple mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-facetime-video p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-purple p-2">
                    <p>VIDEOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                class="circles ml-n5"
                id="img"
                (click)="toogleEvent($event)"
                aria-selected="true"
                (click)="toogleDocs($event)"
              >
                <div class="d-flex flex-column">
                  <div class="rounded-circle orange mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-picture p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle green mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-folder-open p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle blue mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-file p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-blue p-2">
                    <p>PRESENTACIONES</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- fin circles -->
          <div class="col-md-12">
            <div class="col-md-12" *ngIf="showVideo">
              <h3 class="text-left">Videos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center my-5 text-center"
              >
                <!--div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                  </div>
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                  </div>
                </div-->
                <!--div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                  </div>
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                  </div>
                </div-->

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <!--li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          2
                        </a>
                      </li-->
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="showImg">
              <h3 class="text-left">Imagenes</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/violencia_Politica/FOTOS/V1.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/violencia_Politica/FOTOS/V2.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/violencia_Politica/FOTOS/V3.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/violencia_Politica/FOTOS/V4.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <div class="col-md-12" *ngIf="paginaActiva == 3">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/violencia_Politica/FOTOS/V5.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/violencia_Politica/FOTOS/V6.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>
                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          2
                        </a>
                      </li>

                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="3"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          3
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-md-12" *ngIf="showDoc">
              <h3 class="text-left">Documentos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/violencia_Politica/documentos/Programa Violencia política_22 agosto SEGOB_STJG.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/violencia_Politica/documentos/Programa Violencia política_22 agosto SEGOB_STJG.pdf"
                    target="_blank"
                  >
                    Violencia Política en razón de género: avances y asuntos
                    pendientes
                  </a>
                </div>
                <!--div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #4d92df"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                    target="_blank"
                  >
                    Reseña Tema 1 Violencia y Acceso a la Justicia
                  </a>
                </div-->
              </div>
            </div>
            <div class="col-md-12" *ngIf="showPre">
              <h3 class="text-left">Presentaciones</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <div
                class="row d-flex flex-wrap align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/violencia_Politica/presentaciones/Plantilla Kyra Lideresas Comunitarias_.pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/violencia_Politica/presentaciones/Plantilla Kyra Lideresas Comunitarias_.pdf"
                    target="_blank"
                  >
                    Plantilla KYRA Lideresas Comunitarias
                  </a>
                </div>
                <!--div class="col-sm-3 my-5 text-center">
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Dra. Iris Rocío Santillán..pdf"
                    target="_blank"
                  >
                    <span
                      class="glyphicon glyphicon-book p-3"
                      aria-hidden="true"
                      style="font-size: 140px; color: #db8f1b"
                    ></span> </a
                  ><br />
                  <a
                    href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Presentaciones/Dra. Iris Rocío Santillán..pdf"
                    target="_blank"
                  >
                    Dra. Iris Rocío Santillán
                  </a>
                </div-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--septimo apartado mesasDialogo/7-->
      <div class="container" *ngIf="this.activeFiles['6'].tab" id="pills-septimo">
        <div class="container" data-toggle="modal" data-target="#lightbox">
          <div
            class="container-fluid text-center"
            style="background-color: #19845b; color: white"
          >
            <h3>Docudebate (2023)</h3>
          </div>
          <br />
          <div class="row">
            <div class="container-fluid">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="3"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="4"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="assets/Mesas-de-dialogo/Docudebate/FLYER/flyer_docudebate.jpeg"
                      alt="..."
                    />
                  </div>
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div class="container" style="text-align: justify">
            <p class="font-weight-bold">
              Docudebate: Mirada sobre la violencia contra las mujeres y las niñas. <br />
              En coordinación con la Subsecretaría de Desarrollo Democrático, Participación Social y Asuntos 
              Religiosos de la SEGOB y la Coordinación Sectorial de Igualdad de Género y Programas Transversales 
              de la Secretaría de Educación Pública, se llevó a cabo el 1° de diciembre en las instalaciones del 
              “Centro SEP”, el Docu-debate: “Mirada sobre la violencia contra las mujeres y las niñas”, en el marco 
              de los 16 días de activismo y, de las actividades de implementación de la acción específica 4.7.3 del 
              PROIGUALDAD. Se proyectaron los documentales: “Mujeres Luchando por la Justicia” de Wilma 
              Gómez Luengo y “Las irreverentes feministas” de Maricarmen de Lara Rangel. El objetivo del docu-debate fue Intercambiar opiniones sobre la problemática de la violencia contra las mujeres y las 
              niñas y la violación a sus derechos humanos, así como sobre la importancia de los documentales 
              como recurso tecnológico y social que contribuye a la lucha de la violencia contra las mujeres y las 
              niñas y al acceso a la justicia. Se transmitió en vivo a través de la Plataforma de SEP-Aprende. Se 
              contó con la participación en total de 40 personas de forma presencial: 28 mujeres y 12 hombres.
            </p>
          </div>
          <br />

          <!--Circles-->
          <div class="circles circles-pills mt-5">
            <div class="col-md-3">
              <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle purple mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-facetime-video p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-purple p-2">
                    <p>VIDEOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                class="circles ml-n5"
                id="img"
                (click)="toogleEvent($event)"
                aria-selected="true"
                (click)="toogleDocs($event)"
              >
                <div class="d-flex flex-column">
                  <div class="rounded-circle orange mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-picture p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle green mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-folder-open p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle blue mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-file p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-blue p-2">
                    <p>PRESENTACIONES</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- fin circles -->


          <div class="col-md-12">
            <div class="col-md-12" *ngIf="showVideo">
              <h3 class="text-left">Videos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center my-5 text-center"
              >
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/OwRA4t41EXI" 
                    style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                      "
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <p>Docudebate "Miradas sobre la violencia contra las mujeres y las niñas"</p>
                    <p style="font-size: small;">INMUJERES México</p>
                  </div>
                  <div class="col-sm-6 text-center">
                    <a
                      href="https://www.facebook.com/InmujeresMx/videos/672283667802630"
                      target="_blank"
                    >
                      <img
                        src="assets/Mesas-de-dialogo/Docudebate/FLYER/flyer_docudebate.jpeg"
                        width="560" 
                        height="315"
                        style="border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " 
                        alt="..."
                      />
                    </a>
                    <p>Documental: Docudebate: Mirada sobre la violencia contra las mujeres y niñas"</p>
                    <p style="font-size: small;">INMUJERES México</p>
                  </div>
                  <!-- <div class="col-md-6">
                    <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F672283667802630%2F&show_text=false&width=560&t=0" 
                    width="560" 
                    height="314" 
                    style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                        margin: 1vw; 
                        border-radius: 15px; 
                        box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                      "
                    scrolling="no" 
                    frameborder="0" 
                    allowfullscreen="true" 
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                    allowFullScreen="true"
                    ></iframe>
                    
                  </div> -->
                </div>
                <!-- <div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                  </div>
                  <div class="col-md-6">
                    <iframe
                      src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 100%;
                        height: 270px;
                      "
                      scrolling="no"
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      allowFullScreen="true"
                    ></iframe>
                  </div>
                </div> -->

                <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <!-- <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="false"
                        >
                          <br />
                          2
                        </a> -->
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div class="col-md-12" *ngIf="showImg">
              <h3 class="text-left">Imagenes</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-content-center justify-content-center align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                <!--    <div class="col-md-12" *ngIf="uno"> -->
                <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp1.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp2.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>

                <!--    <div class="col-md-12" *ngIf="dos"> -->
                  <div class="col-md-12" *ngIf="paginaActiva == 2">
                    <div class="col-md-6">
                      <img
                        src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp3.jpeg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp4.jpeg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <!--    <div class="col-md-12" *ngIf="tres"> -->
                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                      <div class="col-md-6">
                        <img
                          src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp5.jpeg"
                          class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                          data-target="#indicators"
                          data-slide-to="0"
                          alt=""
                        />
                      </div>
                      <div class="col-md-6">
                        <img
                          src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp6.jpeg"
                          class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                          data-target="#indicators"
                          data-slide-to="0"
                          alt=""
                        />
                      </div>
                    </div>


                  <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          2
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="3"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          3
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
            </div>    
          </div>


          <div class="col-md-12" *ngIf="showDoc">
            <h3 class="text-left">Documentos</h3>
            <hr
              class="red"
              style="
                width: 35px;
                height: 5px;
                background-color: #b38e5d;
                position: absolute;
              "
            />
            <div
              class="row d-flex flex-wrap align-items-center"
              data-toggle="modal"
              data-target="#lightbox"
            >
              <div class="col-sm-3 my-5 text-center">
                <a
                  href="../../../assets/Mesas-de-dialogo/Docudebate/PROGRAMA/FT_docudebate.pdf"
                  target="_blank"
                >
                  <span
                    class="glyphicon glyphicon-book p-3"
                    aria-hidden="true"
                    style="font-size: 140px; color: #4d92df"
                  ></span> </a
                ><br />
                <a
                  href="../../../assets/Mesas-de-dialogo/Docudebate/PROGRAMA/FT_docudebate.pdf"
                  target="_blank"
                >
                  Programa Tema 1 Violencia y acceso a la justicia
                </a>
              </div>
              <!-- <div class="col-sm-3 my-5 text-center">
                <a
                  href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                  target="_blank"
                >
                  <span
                    class="glyphicon glyphicon-book p-3"
                    aria-hidden="true"
                    style="font-size: 140px; color: #4d92df"
                  ></span> </a
                ><br />
                <a
                  href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                  target="_blank"
                >
                  Reseña Tema 1 Violencia y Acceso a la Justicia
                </a>
              </div> -->
            </div>
          </div>
          <div class="col-md-12" *ngIf="showPre">
            <h3 class="text-left">Presentaciones</h3>
            <hr
              class="red"
              style="
                width: 35px;
                height: 5px;
                background-color: #b38e5d;
                position: absolute;
              "
            />
            <div
              class="row d-flex flex-wrap align-items-center"
              data-toggle="modal"
              data-target="#lightbox"
            >
              <div class="col-sm-3 my-5 text-center">
                <a
                  href="../../../assets/Mesas-de-dialogo/Docudebate/PROGRAMA/Programa_1_dic.pdf"
                  target="_blank"
                >
                  <span
                    class="glyphicon glyphicon-book p-3"
                    aria-hidden="true"
                    style="font-size: 140px; color: #e99c02b3"
                  ></span> </a
                ><br />
                <a
                  href="../../../assets/Mesas-de-dialogo/Docudebate/PROGRAMA/Programa_1_dic.pdf"
                  target="_blank"
                >
                Docudebate: 
                "Mirada sobre la violencia contra las mujeres y niñas"                
                </a>
              </div>
              
            </div>
          </div>

        </div>
      </div>
      <br />
    </div>
    <!-- FIN septimo apartado mesasDialogo/7-->

    <!--OCTAVO apartado mesasDialogo/8-->
    <div class="container" *ngIf="this.activeFiles['7'].tab" id="pills-octavo">
      <div class="container" data-toggle="modal" data-target="#lightbox">
        <div
          class="container-fluid text-center"
          style="background-color: #12418c; color: white"
        >
          <h3>SEP Y SALUD (2023)</h3>
        </div>
        <br />
        <div class="row">
          <div class="container-fluid">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="3"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="4"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    class="d-block w-100"
                    src="assets/Mesas-de-dialogo/SEP_SALUD/FLYER/INVITACION28.png"
                    alt="..."
                  />
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
        <br />
        <div class="container" style="text-align: justify">
          <p class="font-weight-bold">
            Objetivo: Promover el intercambio de experiencias y
            conocimientos entre dependencias públicas,
            universidades, organizaciones sociales e
            instituciones privadas, de manera que se puedan
            generar y fortalecer espacios de diálogo sobre temas
            de salud y calidad de vida de las mujeres, las
            adolescentes y las niñas,
          </p>
        </div>
        <br />

        <!--Circles-->
        <div class="circles circles-pills mt-5">
          <div class="col-md-3">
            <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
              <div class="d-flex flex-column">
                <div class="rounded-circle purple mx-auto p-3">
                  <span
                    class="glyphicon glyphicon-facetime-video p-3"
                    aria-hidden="true"
                    style="font-size: 55px; color: #ffffff"
                  ></span>
                </div>
                <div class="text-center font-weight-bold text-purple p-2">
                  <p>VIDEOS</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-3">
            <a
              class="circles ml-n5"
              id="img"
              (click)="toogleEvent($event)"
              aria-selected="true"
              (click)="toogleDocs($event)"
            >
              <div class="d-flex flex-column">
                <div class="rounded-circle orange mx-auto p-3">
                  <span
                    class="glyphicon glyphicon-picture p-3"
                    aria-hidden="true"
                    style="font-size: 55px; color: #ffffff"
                  ></span>
                </div>
                <div class="text-center">
                  <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-3">
            <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
              <div class="d-flex flex-column">
                <div class="rounded-circle green mx-auto p-3">
                  <span
                    class="glyphicon glyphicon-folder-open p-3"
                    aria-hidden="true"
                    style="font-size: 55px; color: #ffffff"
                  ></span>
                </div>
                <div class="text-center">
                  <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-3">
            <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
              <div class="d-flex flex-column">
                <div class="rounded-circle blue mx-auto p-3">
                  <span
                    class="glyphicon glyphicon-file p-3"
                    aria-hidden="true"
                    style="font-size: 55px; color: #ffffff"
                  ></span>
                </div>
                <div class="text-center font-weight-bold text-blue p-2">
                  <p>PRESENTACIONES</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <!-- fin circles -->


        <div class="col-md-12">
          <div class="col-md-12" *ngIf="showVideo">
            <h3 class="text-left">Videos</h3>
            <hr
              class="red"
              style="
                width: 35px;
                height: 5px;
                background-color: #b38e5d;
                position: absolute;
              "
            />
            <br />
            <div
              class="row d-flex flex-wrap align-items-center align-content-center justify-content-center my-5 text-center"
            >
            <div class="col-md-12" *ngIf="paginaActiva == 1">
              <div class="col-md-6">
                <iframe 
                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FIngenio.mediaMX%2Fvideos%2F166927722768253%2F&show_text=false&width=560&t=0" 
                width="560" 
                height="314" 
                style="border:none;
                    overflow:hidden;
                    width: 100%;
                    height: 270px;
                    margin: 1vw; 
                    border-radius: 15px; 
                    box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                  " 
                scrolling="no" 
                frameborder="0" 
                allowfullscreen="true" 
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowFullScreen="true"></iframe>
                <p>Seminario de Acción por la salud de las mujeres</p>
                <p style="font-size: small;">Ingenio</p>
              </div>
              <!-- <div class="col-md-6">
                <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F672283667802630%2F&show_text=false&width=560&t=0" 
                width="560" 
                height="314" 
                style="
                    border: none;
                    overflow: hidden;
                    width: 100%;
                    height: 270px;
                    margin: 1vw; 
                    border-radius: 15px; 
                    box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                  "
                scrolling="no" 
                frameborder="0" 
                allowfullscreen="true" 
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                allowFullScreen="true"
                ></iframe>
                <p>Docudebate: Mirada sobre la violencia contra las mujeres y niñas"</p>
                <p style="font-size: small;">INMUJERES México</p>
              </div> -->
            </div>
            <!-- <div class="col-md-12" *ngIf="paginaActiva == 2">
              <div class="col-md-6">
                <iframe
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                style="
                  border: none;
                  overflow: hidden;
                  width: 100%;
                  height: 270px;
                "
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen="true"
              ></iframe>
              </div>
              <div class="col-md-6">
                <iframe
                  src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                  style="
                    border: none;
                    overflow: hidden;
                    width: 100%;
                    height: 270px;
                  "
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  allowFullScreen="true"
                ></iframe>
              </div>
            </div> -->

            <div
              class="row justify-content-center"
              style="justify-content: center"
            >
              <nav
                aria-label="..."
                class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                style="align-items: center"
              >
                <ul
                  class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                  style="align-content: center"
                  id="pills-tab"
                >
                  <li class="nav-item active text-center">
                    <a
                      class="nav-link"
                      id="1"
                      (click)="toogleEvent($event)"
                      aria-selected="true"
                    >
                      <br />
                      1
                    </a>
                  </li>
                  <li class="nav-item text-center">
                    <!-- <a
                      class="nav-link"
                      id="2"
                      (click)="toogleEvent($event)"
                      aria-selected="false"
                    >
                      <br />
                      2
                    </a> -->
                  </li>
                </ul>
              </nav>
              </div>
            </div>
          </div>

          <div class="col-md-12" *ngIf="showImg">
            <h3 class="text-left">Imagenes</h3>
            <hr
              class="red"
              style="
                width: 35px;
                height: 5px;
                background-color: #b38e5d;
                position: absolute;
              "
            />
            <br />
            <div
              class="row d-flex flex-wrap align-content-center justify-content-center align-items-center"
              data-toggle="modal"
              data-target="#lightbox"
            >
              <!--    <div class="col-md-12" *ngIf="uno"> -->
              <!-- <div class="col-md-12" *ngIf="paginaActiva == 1">
                <div class="col-md-6">
                  <img
                    src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp1.jpeg"
                    class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                    data-target="#indicators"
                    data-slide-to="0"
                    alt=""
                  />
                </div>
                <div class="col-md-6">
                  <img
                    src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp2.jpeg"
                    class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                    data-target="#indicators"
                    data-slide-to="0"
                    alt=""
                  />
                </div>
              </div> -->

              <!--    <div class="col-md-12" *ngIf="dos"> -->
                <!-- <div class="col-md-12" *ngIf="paginaActiva == 2">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp3.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp4.jpeg"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div> -->

                <!--    <div class="col-md-12" *ngIf="tres"> -->
                  <!-- <div class="col-md-12" *ngIf="paginaActiva == 3">
                    <div class="col-md-6">
                      <img
                        src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp5.jpeg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Mesas-de-dialogo/Docudebate/FOTOS/EVENTOS_2023/WhatsApp6.jpeg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div> -->


                <div
                class="row justify-content-center"
                style="justify-content: center"
              >
                <nav
                  aria-label="..."
                  class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                  style="align-items: center"
                >
                  <ul
                    class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                    style="align-content: center"
                    id="pills-tab"
                  >
                    <li class="nav-item active text-center">
                      <a
                        class="nav-link"
                        id="1"
                        (click)="toogleEvent($event)"
                        aria-selected="true"
                      >
                        <br />
                        1
                      </a>
                    </li>
                    <!-- <li class="nav-item text-center">
                      <a
                        class="nav-link"
                        id="2"
                        (click)="toogleEvent($event)"
                        aria-selected="true"
                      >
                        <br />
                        2
                      </a>
                    </li>
                    <li class="nav-item text-center">
                      <a
                        class="nav-link"
                        id="3"
                        (click)="toogleEvent($event)"
                        aria-selected="true"
                      >
                        <br />
                        3
                      </a>
                    </li> -->
                  </ul>
                </nav>
              </div>
          </div>    
        </div>


        <div class="col-md-12" *ngIf="showDoc">
          <h3 class="text-left">Documentos</h3>
          <hr
            class="red"
            style="
              width: 35px;
              height: 5px;
              background-color: #b38e5d;
              position: absolute;
            "
          />
          <div
            class="row d-flex flex-wrap align-items-center"
            data-toggle="modal"
            data-target="#lightbox"
          >
            <!-- <div class="col-sm-3 my-5 text-center">
              <a
                href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Programa/Programa Tema 1 Violencia de género y acceso a la justicia. 4 d e Oct..pdf"
                target="_blank"
              >
                <span
                  class="glyphicon glyphicon-book p-3"
                  aria-hidden="true"
                  style="font-size: 140px; color: #4d92df"
                ></span> </a
              ><br />
              <a
                href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/Programa/Programa Tema 1 Violencia de género y acceso a la justicia. 4 d e Oct..pdf"
                target="_blank"
              >
                Programa Tema 1 Violencia y acceso a la justicia
              </a>
            </div> -->
            <!-- <div class="col-sm-3 my-5 text-center">
              <a
                href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                target="_blank"
              >
                <span
                  class="glyphicon glyphicon-book p-3"
                  aria-hidden="true"
                  style="font-size: 140px; color: #4d92df"
                ></span> </a
              ><br />
              <a
                href="../../../assets/Mesas-de-dialogo/Violencia-y-acceso/RESEÑA/Reseña Tema 1 Violencia y Acceso a la Justicia. 4 de octubre.pdf"
                target="_blank"
              >
                Reseña Tema 1 Violencia y Acceso a la Justicia
              </a>
            </div> -->
          </div>
        </div>
        <div class="col-md-12" *ngIf="showPre">
          <h3 class="text-left">Presentaciones</h3>
          <hr
            class="red"
            style="
              width: 35px;
              height: 5px;
              background-color: #b38e5d;
              position: absolute;
            "
          />
          <div
            class="row d-flex flex-wrap align-items-center"
            data-toggle="modal"
            data-target="#lightbox"
          >
            <div class="col-sm-3 my-5 text-center">
              <a
                href="../../../assets/Mesas-de-dialogo/SEP_SALUD/PROGRAMA/PROGRAMA_25_FINAL.pdf"
                target="_blank"
              >
                <span
                  class="glyphicon glyphicon-book p-3"
                  aria-hidden="true"
                  style="font-size: 140px; color: #2d44f0a7"
                ></span> </a
              ><br />
              <a
                href="../../../assets/Mesas-de-dialogo/SEP_SALUD/PROGRAMA/PROGRAMA_25_FINAL.pdf"
                target="_blank"
              >
              Auditorio del Instituto Nacional de Medicina
              Genómica de la Secretaría de Salud
              </a>
            </div>

            <div class="col-sm-3 my-5 text-center">
              <a
                href="../../../assets/Mesas-de-dialogo/SEP_SALUD/PROGRAMA/Programa_26_mayo_Seminario.pdf"
                target="_blank"
              >
                <span
                  class="glyphicon glyphicon-book p-3"
                  aria-hidden="true"
                  style="font-size: 140px; color: #2d44f0a7"
                ></span> </a
              ><br />
              <a
                href="../../../assets/Mesas-de-dialogo/SEP_SALUD/PROGRAMA/Programa_26_mayo_Seminario.pdf"
                target="_blank"
              >
              Seminario de Acción por la Salud de las Mujeres 
              25 y 26 de Mayo 2023
              </a>
            </div>
            
          </div>
        </div>

      </div>
    </div>
    <br />
  </div>
  <!-- FIN OCTAVO apartado mesasDialogo/8-->

      <!--NOVENO apartado mesasDialogo/8-->
      <div class="container" *ngIf="this.activeFiles['8'].tab" id="pills-noveno">
        <div class="container" data-toggle="modal" data-target="#lightbox">
          <div
            class="container-fluid text-center"
            style="background-color: #2da577; color: white"
          >
            <h3>Mesa de diálogo La participación social de las mujeres con una mirada al arte</h3>
          </div>
          <br />
          <div class="row">
            <div class="container-fluid">
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-ride="carousel"
              >
                <ol class="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="3"
                  ></li>
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="4"
                  ></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      class="d-block w-100"
                      src="assets/Mesas-de-dialogo/Mirada al Arte/FLYER/Arte debate_2-1.png"
                      alt="..."
                    />
                  </div>
                </div>
                <a
                  class="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
          <br />
          <div class="container" style="text-align: justify">
            <h2>Objetivo:</h2>
            <p class="font-weight-bold">
                Generar a través de mesas de diálogo y de trabajo, un espacio de reflexión y análisis sobre la 
                participación social de las mujeres, quienes demandan la transformación de su realidad,
                garantizando el goce de sus derechos humanos y mejorar sus vidas comunitarias a través de 
                las expresiones artísticas e ideológicas
            </p>
          </div>
          <br />
  
          <!--Circles-->
          <div class="circles circles-pills mt-5">
            <div class="col-md-3">
              <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle purple mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-facetime-video p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-purple p-2">
                    <p>VIDEOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a
                class="circles ml-n5"
                id="img"
                (click)="toogleEvent($event)"
                aria-selected="true"
                (click)="toogleDocs($event)"
              >
                <div class="d-flex flex-column">
                  <div class="rounded-circle orange mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-picture p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle green mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-folder-open p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center">
                    <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-3">
              <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                <div class="d-flex flex-column">
                  <div class="rounded-circle blue mx-auto p-3">
                    <span
                      class="glyphicon glyphicon-file p-3"
                      aria-hidden="true"
                      style="font-size: 55px; color: #ffffff"
                    ></span>
                  </div>
                  <div class="text-center font-weight-bold text-blue p-2">
                    <p>PRESENTACIONES</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- fin circles -->
  
  
          <div class="col-md-12">
            <div class="col-md-12" *ngIf="showVideo">
              <h3 class="text-left">Videos</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-items-center align-content-center justify-content-center my-5 text-center"
              >
              <div class="col-md-12" *ngIf="paginaActiva == 1">
                <!-- <div class="col-md-6">
                  <iframe 
                  src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FIngenio.mediaMX%2Fvideos%2F166927722768253%2F&show_text=false&width=560&t=0" 
                  width="560" 
                  height="314" 
                  style="border:none;
                      overflow:hidden;
                      width: 100%;
                      height: 270px;
                      margin: 1vw; 
                      border-radius: 15px; 
                      box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                    " 
                  scrolling="no" 
                  frameborder="0" 
                  allowfullscreen="true" 
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                  allowFullScreen="true"></iframe>
                  <p>Seminario de Acción por la salud de las mujeres</p>
                  <p style="font-size: small;">Ingenio</p>
                </div> -->
                <!-- <div class="col-md-6">
                  <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F672283667802630%2F&show_text=false&width=560&t=0" 
                  width="560" 
                  height="314" 
                  style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                      margin: 1vw; 
                      border-radius: 15px; 
                      box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                    "
                  scrolling="no" 
                  frameborder="0" 
                  allowfullscreen="true" 
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                  allowFullScreen="true"
                  ></iframe>
                  <p>Docudebate: Mirada sobre la violencia contra las mujeres y niñas"</p>
                  <p style="font-size: small;">INMUJERES México</p>
                </div> -->
              </div>
              <!-- <div class="col-md-12" *ngIf="paginaActiva == 2">
                <div class="col-md-6">
                  <iframe
                  src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                  style="
                    border: none;
                    overflow: hidden;
                    width: 100%;
                    height: 270px;
                  "
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  allowFullScreen="true"
                ></iframe>
                </div>
                <div class="col-md-6">
                  <iframe
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F300283698500391%2F&show_text=false"
                    style="
                      border: none;
                      overflow: hidden;
                      width: 100%;
                      height: 270px;
                    "
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen="true"
                  ></iframe>
                </div>
              </div> -->
  
              <div
                class="row justify-content-center"
                style="justify-content: center"
              >
                <nav
                  aria-label="..."
                  class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                  style="align-items: center"
                >
                  <ul
                    class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                    style="align-content: center"
                    id="pills-tab"
                  >
                    <li class="nav-item active text-center">
                      <a
                        class="nav-link"
                        id="1"
                        (click)="toogleEvent($event)"
                        aria-selected="true"
                      >
                        <br />
                        1
                      </a>
                    </li>
                    <li class="nav-item text-center">
                      <!-- <a
                        class="nav-link"
                        id="2"
                        (click)="toogleEvent($event)"
                        aria-selected="false"
                      >
                        <br />
                        2
                      </a> -->
                    </li>
                  </ul>
                </nav>
                </div>
              </div>
            </div>
  
            <div class="col-md-12" *ngIf="showImg">
              <h3 class="text-left">Imagenes</h3>
              <hr
                class="red"
                style="
                  width: 35px;
                  height: 5px;
                  background-color: #b38e5d;
                  position: absolute;
                "
              />
              <br />
              <div
                class="row d-flex flex-wrap align-content-center justify-content-center align-items-center"
                data-toggle="modal"
                data-target="#lightbox"
              >
                   <!-- <div class="col-md-12" *ngIf="uno"> -->
                 <div class="col-md-12" *ngIf="paginaActiva == 1">
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Mirada al Arte/FOTOS/A1.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                  <div class="col-md-6">
                    <img
                      src="assets/Mesas-de-dialogo/Mirada al Arte/FOTOS/A2.png"
                      class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                      data-target="#indicators"
                      data-slide-to="0"
                      alt=""
                    />
                  </div>
                </div>
  
                <!-- <div class="col-md-12" *ngIf="dos"> -->
                  <div class="col-md-12" *ngIf="paginaActiva == 2">
                    <div class="col-md-6">
                      <img
                        src="assets/Mesas-de-dialogo/Mirada al Arte/FOTOS/A3.png"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Mesas-de-dialogo/Mirada al Arte/FOTOS/A4.png"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>
  
                     <!-- <div class="col-md-12" *ngIf="tres"> -->
                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                      <div class="col-md-6">
                        <img
                          src="assets/Mesas-de-dialogo/Mirada al Arte/FOTOS/A5.png"
                          class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                          data-target="#indicators"
                          data-slide-to="0"
                          alt=""
                        />
                      </div>
                      <div class="col-md-6">
                        <img
                          src="assets/Mesas-de-dialogo/Mirada al Arte/FOTOS/A6.png"
                          class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                          data-target="#indicators"
                          data-slide-to="0"
                          alt=""
                        />
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="paginaActiva == 4">
                      <div class="col-md-6">
                        <img
                          src="assets/Mesas-de-dialogo/Mirada al Arte/FOTOS/A7.png"
                          class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                          data-target="#indicators"
                          data-slide-to="0"
                          alt=""
                        />
                      </div>
                      <!-- <div class="col-md-6">
                        <img
                          src="assets/Mesas-de-dialogo/Mirada al Arte/FOTOS/A6.png"
                          class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                          data-target="#indicators"
                          data-slide-to="0"
                          alt=""
                        />
                      </div> -->
                    </div>
  
  
                  <div
                  class="row justify-content-center"
                  style="justify-content: center"
                >
                  <nav
                    aria-label="..."
                    class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                    style="align-items: center"
                  >
                    <ul
                      class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                      style="align-content: center"
                      id="pills-tab"
                    >
                      <li class="nav-item active text-center">
                        <a
                          class="nav-link"
                          id="1"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          1
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="2"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          2
                        </a>
                      </li>
                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="3"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          3
                        </a>
                      </li>

                      <li class="nav-item text-center">
                        <a
                          class="nav-link"
                          id="4"
                          (click)="toogleEvent($event)"
                          aria-selected="true"
                        >
                          <br />
                          4
                        </a>
                      </li>

                    </ul>
                  </nav>
                </div>
            </div>    
          </div>
  
  
          <div class="col-md-12" *ngIf="showDoc">
            <h3 class="text-left">Documentos</h3>
            <hr
              class="red"
              style="
                width: 35px;
                height: 5px;
                background-color: #b38e5d;
                position: absolute;
              "
            />
            <div
              class="row d-flex flex-wrap align-items-center"
              data-toggle="modal"
              data-target="#lightbox"
            >
              <div class="col-sm-3 my-5 text-center">
                <a
                  href="assets/Mesas-de-dialogo/Mirada al Arte/PROGRAMA/Mujer_ 28 febrero.pdf"
                  target="_blank"
                >
                  <span
                    class="glyphicon glyphicon-book p-3"
                    aria-hidden="true"
                    style="font-size: 140px; color: #4d92df"
                  ></span> </a
                ><br />
                <a
                  href="assets/Mesas-de-dialogo/Mirada al Arte/PROGRAMA/Mujer_ 28 febrero.pdf"
                  target="_blank"
                >
                Ficha Técnica Mesa Dialogo Dia de la Mujer - 28 febrero
                </a>
              </div>
              <div class="col-sm-3 my-5 text-center">
                <a
                  href="assets/Mesas-de-dialogo/Mirada al Arte/PROGRAMA/Mesa de diálogo_7 de marzo.pdf"
                  target="_blank"
                >
                  <span
                    class="glyphicon glyphicon-book p-3"
                    aria-hidden="true"
                    style="font-size: 140px; color: #4d92df"
                  ></span> </a
                ><br />
                <a
                  href="assets/Mesas-de-dialogo/Mirada al Arte/PROGRAMA/Mesa de diálogo_7 de marzo.pdf"
                  target="_blank"
                >
                  Programa Mesa de diálogo - 7 de marzo
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="showPre">
            <h3 class="text-left">Presentaciones</h3>
            <hr
              class="red"
              style="
                width: 35px;
                height: 5px;
                background-color: #b38e5d;
                position: absolute;
              "
            />
            <div
              class="row d-flex flex-wrap align-items-center"
              data-toggle="modal"
              data-target="#lightbox"
            >
              <!-- <div class="col-sm-3 my-5 text-center">
                <a
                  href="../../../assets/Mesas-de-dialogo/SEP_SALUD/PROGRAMA/PROGRAMA_25_FINAL.pdf"
                  target="_blank"
                >
                  <span
                    class="glyphicon glyphicon-book p-3"
                    aria-hidden="true"
                    style="font-size: 140px; color: #2d44f0a7"
                  ></span> </a
                ><br />
                <a
                  href="../../../assets/Mesas-de-dialogo/SEP_SALUD/PROGRAMA/PROGRAMA_25_FINAL.pdf"
                  target="_blank"
                >
                Auditorio del Instituto Nacional de Medicina
                Genómica de la Secretaría de Salud
                </a>
              </div> -->
  
              <!-- <div class="col-sm-3 my-5 text-center">
                <a
                  href="../../../assets/Mesas-de-dialogo/SEP_SALUD/PROGRAMA/Programa_26_mayo_Seminario.pdf"
                  target="_blank"
                >
                  <span
                    class="glyphicon glyphicon-book p-3"
                    aria-hidden="true"
                    style="font-size: 140px; color: #2d44f0a7"
                  ></span> </a
                ><br />
                <a
                  href="../../../assets/Mesas-de-dialogo/SEP_SALUD/PROGRAMA/Programa_26_mayo_Seminario.pdf"
                  target="_blank"
                >
                Seminario de Acción por la Salud de las Mujeres 
                25 y 26 de Mayo 2023
                </a>
              </div> -->
              
            </div>
          </div>
  
        </div>
      </div>
      <br />
    </div>
    <!-- FIN NOVENO apartado mesasDialogo/8-->

  </div>
</div>
<div class="col-md-12"></div>
