<!-- <div class="container mt-2">
    <div *ngFor="let dependencia of dependencias; let i = index">
      <div class="card mb-3 text-white"  [style.background-color]="dependencia.color" >
        <div class="row " [ngClass]="{'flex-row-reverse': i % 2 !== 0}" >
          <div class="col-md-5" >
              <img src="./assets/img/large_electas2.jpg"  alt="..." class="img-fluid w-100">
          </div>
          <div class="col-md-7" >
            <div class="card-body p-5 w-100 h-100"  >
              <h5 class="card-title">{{dependencia.nombre}}</h5>
              <p class="card-text"><span class="glyphicon glyphicon-pushpin" aria-hidden="true"></span> <strong> Ubicación:</strong>  {{dependencia.municipio.estado.nombre}} - {{dependencia.municipio.nombre}}</p>
              <p class="card-text"><span class="glyphicon glyphicon-tags" aria-hidden="true"></span> <strong> Objeto social:</strong>  {{dependencia.objetoSocial | slice:0:200 }}...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

<!--   <div class="container mt-2">  -->
<!--       <div class="container" *ngIf="defult">
 -->

<div class="container-fluid" *ngIf="defult">
  <div class="card mb-3 text-white" [@leftEase]>
    <div class="row">
      <div class="col-md-6 green p-0">
        <img src="assets/img/collage_4.jpeg" alt="..." class="w-100 h-100" />
        <div class="d-lg-flex justify-content-center">
          <div class="d-lg-flex align-items-center">
            <!-- <div class="btn-group btnCategorias" role="group"> -->
            <div class="btn-group btnCategorias" role="group">
              <button
                id="mesas"
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Actividades de vinculación
              </button>
              <ul class="dropdown-menu" role="menu" aria-labelledby="mesas">
                <li>
                  <a
                    id="temas"
                    type="button"
                    class="dropdown"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    [disabled]
                  ></a>
                  <ul
                    class="dropdown-submenu"
                    role="menu"
                    aria-labelledby="temas"
                  >
                    <!-- <li>
                      <a class="dropdown-item" routerLink="/mesasDialogo/7">
                        Docudebate (2023)</a
                      >
                    </li>

                    <li>
                      <a class="dropdown-item" routerLink="/mesasDialogo/9">
                        La participación social de las mujeres con una mirada al arte (2023)</a
                      >
                    </li>

                    <li>
                      <a class="dropdown-item" routerLink="/mesasDialogo/8">
                        SEP y salud (2023)</a
                      >
                    </li> -->

                    <li  *ngFor="let publicacion of publicacionesOSC; let indice = index;" [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                      <a class="dropdown-item" routerLink="/publicacion-vinculacion/{{publicacion.id}}">
                        {{publicacion.titulo}}{{(mesAnno(publicacion.fechaPublicacion))}}</a
                      >
                    </li>

                    <!-- <hr class="dropdown-divider col-lg-12">
                    <ol for="id-history">
                      <p class="hp col-lg-12  nav-item"
                        >Historico</p
                      >
                    </ol>
                    <ul id="id-history">
                      <li>
                        <a class="dropdown-item" routerLink="/mesasDialogo/1">
                          Violencia y acceso a la justicia (2022)</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/mesasDialogo/2">
                          Salud y bienestar para mujeres, niñas y adolescentes (2022)</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/mesasDialogo/3"
                          >Participación política y ciudadana de las mujeres (2022)</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/mesasDialogo/4">
                          Aportes feministas para un Sistema Nacional de
                          Cuidados (2022)</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/mesasDialogo/5"
                          >Conversatorio sobre masculinidades alternativas y no
                          violentas (2022)</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" routerLink="/mesasDialogo/6"
                          >Violencia política (2022)</a
                        >
                      </li>
                    </ul> -->
                  </ul>
                </li>
                <!--  <li><a href="#">Opción 2</a></li> -->
              </ul>
            </div>
            <div class="btn-group btnCategorias" role="group">
              <button
                id="alianzas"
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Alianzas
              </button>
              <ul class="dropdown-menu" role="menu" aria-labelledby="mesas">
                <li>
                  <a
                    id="temas"
                    type="button"
                    class="dropdown"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    [disabled]
                  ></a>
                  <ul
                    class="dropdown-submenu"
                    role="menu"
                    aria-labelledby="temas"
                  >
                    <li>
                      <a class="dropdown-item" routerLink="/alianzas/1">
                        Foros consejo consultivo (2023)
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" routerLink="/alianzas/2">
                        Foros consejo social (2023)
                      </a>
                    </li>
                  </ul>
                </li>
                <!--  <li><a href="#">Opción 2</a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 green text-center">
        <div class="card-body p-5 w-100 h-100">
          <div class="d-flex h-100 justify-content-center">
            <div class="align-self-center">
              <h5 class="card-title text-white">OSC</h5>
              <p class="card-text text-white">
                Organizaciones de la Sociedad Civil
              </p>
              <button
                type="button"
                class="boton greenl font-weight-bold"
                routerLink="/organizaciones/1/OSC"
              >
                VER MÁS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3 text-white" [@leftEase]>
    <div class="row flex-row-reverse">
      <div class="col-md-6 my-auto orange p-0">
        <!--         <img src="assets/img/collage_2.jpeg" alt="..." class="w-100 h-100"> -->
        <img src="assets/img/Light_Orange.jpg" alt="..." class="w-100 h-100" />
        <div class="d-lg-flex justify-content-center">
          <div class="d-lg-flex align-items-center">
            <div class="btn-group btnCategorias" role="group">
              <button
                id="mesas"
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Actividades de vinculación
              </button>
              <ul class="dropdown-menu" role="menu" aria-labelledby="mesas">
                <li>
                  <a
                    id="temas"
                    type="button"
                    class="dropdown"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    [disabled]
                  ></a>
                  <ul
                    class="dropdown-submenu"
                    role="menu"
                    aria-labelledby="temas"
                  >
                    <!-- <li>
                      <a class="dropdown-item" routerLink="/exporta/1"
                        >Mujer Exporta
                      </a>
                    </li> -->

                    <li  *ngFor="let publicacion of publicacionesIP; let indice = index;" [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                      <a class="dropdown-item" routerLink="/publicacion-vinculacion/{{publicacion.id}}">
                        {{publicacion.titulo}}{{(mesAnno(publicacion.fechaPublicacion))}}</a
                      >
                    </li>

                  </ul>
                </li>
                <!--  <li><a href="#">Opción 2</a></li> -->
              </ul>
            </div>
            <div class="btn-group btnCategorias" role="group">
              <button
                id="alianzas"
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Alianzas
              </button>
              <ul class="dropdown-menu" role="menu" aria-labelledby="mesas">
                <li>
                  <a
                    id="temas"
                    type="button"
                    class="dropdown"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    [disabled]
                  ></a>
                  <ul
                    class="dropdown-submenu"
                    role="menu"
                    aria-labelledby="temas"
                  >
                    <li>
                      <a class="dropdown-item" routerLink=""> </a>
                    </li>
                    <li  *ngFor="let publicacion of alianzaIP; let indice = index;" [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                      <a class="dropdown-item" routerLink="/publicacion-alianza/{{publicacion.id}}">
                        {{publicacion.titulo}}{{(mesAnno(publicacion.fechaPublicacion))}}</a
                      >
                    </li>
                  </ul>
                </li>
                <!--  <li><a href="#">Opción 2</a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 orange text-center">
        <div class="card-body p-5 w-100 h-100">
          <div class="d-flex h-100 justify-content-center">
            <div class="align-self-center">
              <h5 class="card-title text-white">IP</h5>
              <p class="card-text text-white">Iniciativa Privada</p>
              <button
                type="button"
                class="boton oran font-weight-bold"
                routerLink="/organizaciones/2/IP"
              >
                VER MÁS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3 text-white" [@leftEase]>
    <div class="row">
      <div class="col-md-6 my-auto purple p-0">
        <img src="assets/img/collage_1.jpeg" alt="..." class="w-100 h-100" />
        <div class="d-lg-flex justify-content-center">
          <div class="d-lg-flex align-items-center">
            <div class="btn-group btnCategorias" role="group">
              <button
                id="mesas"
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Actividades de vinculación
              </button>
              <ul class="dropdown-menu" role="menu" aria-labelledby="mesas">
                <li>
                  <a
                    id="temas"
                    type="button"
                    class="dropdown"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    [disabled]
                  ></a>
                  <ul
                    class="dropdown-submenu"
                    role="menu"
                    aria-labelledby="temas"
                  >
                    <!-- <li>
                      <a class="dropdown-item" routerLink=""> </a>
                    </li> -->

                    
                    <li  *ngFor="let publicacion of publicacionesIES; let indice = index;" [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                      <a class="dropdown-item" routerLink="/publicacion-vinculacion/{{publicacion.id}}">
                        {{publicacion.titulo}}{{(mesAnno(publicacion.fechaPublicacion))}}</a
                      >
                    </li>


                  </ul>
                </li>
                <!--  <li><a href="#">Opción 2</a></li> -->
              </ul>
            </div>
            <div class="btn-group btnCategorias" role="group">
              <button
                id="alianzas"
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Alianzas
              </button>
              <ul class="dropdown-menu" role="menu" aria-labelledby="mesas">
                <li>
                  <a
                    id="temas"
                    type="button"
                    class="dropdown"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    [disabled]
                  ></a>
                  <ul
                    class="dropdown-submenu"
                    role="menu"
                    aria-labelledby="temas"
                  >
                    <!-- <li>
                      <a class="dropdown-item" routerLink="/convenios/1"
                        >CONVENIO UNACH
                      </a>
                    </li> -->

                    <li  *ngFor="let publicacion of alianzaIES; let indice = index;" [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                      <a class="dropdown-item" routerLink="/publicacion-alianza/{{publicacion.id}}">
                        {{publicacion.titulo}}{{(mesAnno(publicacion.fechaPublicacion))}}</a
                      >
                    </li>

                  </ul>
                </li>
                <!--  <li><a href="#">Opción 2</a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 purple text-center p-0" [style.background-color]>
        <div class="card-body p-5 w-100 h-100">
          <div class="d-flex h-100 justify-content-center">
            <div class="align-self-center">
              <h5 class="card-title text-white">IES</h5>
              <p class="card-text text-white">
                Instituciones de Educación Superior
              </p>
              <button
                type="button"
                class="boton purp font-weight-bold"
                routerLink="/organizaciones/3/IES"
              >
                VER MÁS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container" *ngIf="!defult">
      <div *ngFor="let dependencia of dependencias; let i = index " class="card mb-3 text-white" [style.background-color]="dependencia.color" [@leftEase]>
        <div class="row " [ngClass]="{'flex-row-reverse': i % 2 !== 0}">
          <div class="col-md-6 imgCard">
              <img [src]="sanitizer.bypassSecurityTrustUrl(this.dependencia.foto)"  alt="..." class="w-100 h-100">
          </div>
          <div class="col-md-6" >
            <div class="card-body p-5 w-100 h-100" >
              <h5 class="card-title">{{dependencia.nombre}}</h5>
              <p class="card-text"><span class="glyphicon glyphicon-pushpin" aria-hidden="true"></span> <strong> Ubicación:</strong>  {{dependencia.municipio.estado.nombre}} - {{dependencia.municipio.nombre}}</p>
              <p class="card-text"><span class="glyphicon glyphicon-tags" aria-hidden="true"></span> <strong> Objeto social:</strong>  {{dependencia.objetoSocial | slice:0:200 }}...</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="!this.finCards">
        <mat-spinner mode="indeterminate" diameter="50" class="mx-auto"></mat-spinner>
      </div>
    </div> -->
