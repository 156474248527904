import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from '../models/role';
import swal from "sweetalert2";
import { URL_CONFIG } from '../store/config';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private urlEndPoint: string = URL_CONFIG + '/api/roles/';

  constructor(private http: HttpClient, private router: Router) { }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.urlEndPoint).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
            this.router.navigate(['/dependecias'])
          }
          return throwError(e);
        }
      ));
  }
}
