
<div class="container-fluid users">
    <div class="container mt-5" >
        <h3>{{ titulo }}</h3>
        <hr class="red">
        <form [formGroup] ="usuariosForm" >
            <div class="form-group">
                <label>Nombre:</label>
                <input type="text"  class="form-control"  formControlName ="nombre" required/>
            </div>
            <div class="form-group">
                <label>Apellidos</label>
                <input type="text"  class="form-control" formControlName ="apellido" required/>
            </div>
            <div class="form-group">
                <label>Correo</label>
                <input type="email"  class="form-control" formControlName ="email" required/>
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password"  class="form-control" formControlName ="password" required/>
            </div>
            <div class="form-group">
                <label>Usuario</label>
                <input type="text"  class="form-control" formControlName ="username" required/>
            </div>
            <div class="form-group">
                <div class="col-md-12 p-0">
                    <div class="col-md-4 p-0" *ngFor="let rol of temasFormArray.controls; let i = index">
                        <label formArrayName="roles" >
                            <input type="checkbox" [formControlName]="i">
                            {{roles[i].nombre}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group mt-3">
                <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                    <button class="btn btn-primary m-2 float-right"
                        *ngIf="!usuario else elseBlock" [disabled]="!usuariosForm.valid" (click) ="submit()">Guardar</button>
                    <ng-template #elseBlock>
                        <button class="btn btn-primary m-2 float-right" (click) = "update()" [disabled]="!usuariosForm.valid">Guardar</button>
                    </ng-template>
                    <button type="button" class="btn btn-danger float-right m-2" [routerLink]="['/usuarios']">Cancelar</button>
                </div>
            </div>
        </form>
    </div>
</div>

