<div class="container content">
    <h3>Catálogo de temas de especialidad</h3>
    <hr class="red">
    <div class="row">
        <div class="container p-0">
            <div class="col-md-6">
                <input type="text" [(ngModel)] = "busqueda" class="mt-2 form-control input-group-lg" >
            </div>
            <div class="col-md-3">
                <button type="button" class="btn btn-info btn-block" (click) ="buscar()">Buscar</button>
            </div>
            <div class="col-md-3 "> <!-- col-md-offset-9 -->
                <button *ngIf="loginService.hasRoles('ROLE_ADMIN')" type="button" class="btn btn-rounded btn-success"
                    [routerLink]="['/temas/form']">Agregar Tema</button>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-12">
            <div *ngIf="temas?.length != 0"> </div>
            <div class="alert alert-info" *ngIf="temas?.length == 0">
                ¡ No hay registros que mostrar !
            </div>
            <div class="table-responsive">
                <table class="table table-bordered" *ngIf="temas?.length>0">
                    <thead>
                        <tr class="table table-bordered" >
                            <th>Descripción</th>
                            <th colspan="2">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tema of temas; let i = index" [attr.data-index]="i">
                            <td>{{ tema.nombre }}</td>
                            <td class="text-center">
                                <button type="button" name="button" [routerLink]="['/temas/form',tema.id]"
                                    class="btn btn-warning btn-sm">
                                    <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="loginService.hasRoles('ROLE_ADMIN')">
                                <button type="button" name="button" (click)="delete(tema)"
                                    class="btn btn-primary btn-sm">
                                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <app-paginador *ngIf="paginador" [paginador]="paginador"></app-paginador>
        </div>
    </div>
</div>