<!--<detalle-cliente *ngIf="dependeciaSeleccionado" [dependecia]="dependeciaSeleccionado"></detalle-cliente>-->
<div class="container content">
  <h3>Catálogo de usuarios</h3>
  <hr class="red">
  <div class="row">
    <div class="container p-0">
      <div class="col-md-6">
        <input type="text" class="mt-2 form-control input-group-lg" [(ngModel)] = "this.busqueda">
      </div>
      <div class="col-md-3">
        <button type="button" class="btn btn-info btn-block" (click) ="buscar()"> Buscar</button>
      </div>
      <div class="col-md-3 ">
        <button *ngIf="loginService.hasRoles('ROLE_ADMIN')" type="button" class="btn btn-rounded btn-success"
          [routerLink]="['/usuarios/form']">Agregar usuario</button>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-info" *ngIf="usuarios?.length == 0">
        No hay registros en la base de datos!
      </div>
      <div class=" table table-responsive">
        <table class="table table-bordered" *ngIf="usuarios?.length>0">
          <thead>
            <tr class="table table-bordered">
              <!--<th>#</th>-->
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Correo electrónico</th>
              <th>Roles</th>
              <th colspan="2">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let usuario of usuarios; let i = index" [attr.data-index]="i">
              <!--[routerLink]="['/clientes/ver',cliente.id]"-->
              <!--<td>
                  {{ (i + 1) }}
                  <!--<img *ngIf="cliente?.foto" (click)="abrilModal(cliente)" src="http://localhost:8080/api/uploads/img/{{cliente.foto}}"
                  alt="{{cliente.foto}}" class="img-thumbnail rounded" style="width: 64px; cursor: pointer;">
                  <img *ngIf="!cliente?.foto" (click)="abrilModal(cliente)" src="http://localhost:8080/images/no-usuario.png"
                  alt="Sin foto" class="img-thumbnail rounded" style="width: 64px; cursor: pointer;">
                </td>-->
              <td>{{ usuario.nombre }}</td>
              <td>{{ usuario.apellido }}</td>
              <td>{{ usuario.email}}</td>
              <td>
                <ul *ngFor="let rol of usuario.roles">
                  <li>{{rol.nombre.toString().split("_")[1]}}</li>
                </ul>
              </td>
              <td class="text-center">
                <button type="button" name="button" [routerLink]="['/usuarios/form',usuario.id]"
                  class="btn btn-warning btn-sm">
                  <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                </button>
              </td>
              <td class="text-center" *ngIf="loginService.hasRoles('ROLE_ADMIN')">
                <button type="button" name="button" (click)="delete(usuario)" class="btn btn-primary btn-sm">
                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <paginator-nav *ngIf="paginador" [paginador]="paginador"></paginator-nav>
    </div>
  </div>
</div>