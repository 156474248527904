<ul *ngIf="paginas?.length > 0 " class="pagination">

  <li class="page-item" *ngIf="paginador.number > 0">
    <a class="page-link" [routerLink]="['/dependencias/page', paginador.number-1]">&laquo;</a>
  </li>
  <li [ngClass]="paginador.first?'disabled page-item':'page-item'">
    <a class="page-link" [routerLink]="['/dependencias/page', 0]">Primera</a>
  </li>

  <li *ngFor="let pagina of paginas" class="page-item" [ngClass]="pagina-1 == paginador.number?'active':''">
    <span *ngIf="pagina-1 == paginador.number" class="page-link">{{pagina}}</span>
      <a *ngIf="pagina-1 != paginador.number" [routerLink] = "['/dependencias/page', pagina-1 ]" class="page-link">{{pagina}}</a>
  </li>

  <li [ngClass]="paginador.last?'disabled page-item':'page-item'">
    <a class="page-link" [routerLink]="['/dependencias/page', paginador.totalPages-1]">Última</a>
  </li>
  <li class="page-item" *ngIf="paginador.number < paginador.totalPages-1">
    <a class="page-link" [routerLink]="['/dependencias/page', paginador.number+1]">&raquo;</a>
  </li>
</ul>
