import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
//import { CLIENTES } from './clientes.json';
import { Dependencia } from '../models/dependecia';
import { Estado } from '../models/estados';
import { Municipio } from '../models/municipios';
import { Categoria } from '../models/categorias';
import { Tema } from '../models/tema';
import { Observable, throwError } from 'rxjs';
//import { of } from 'rxjs/';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { URL_CONFIG } from '../store/config';
import swal from "sweetalert2";
import { Localidades } from '../models/localidades';




@Injectable()
export class DependenciaService {

  private urlEndPoint: string = URL_CONFIG + '/api/dependencias';

  //private httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, private router: Router) { }

  /*private agregarAuthorizationHeader(){
    let token = this.loginService.token;
    if(token != null){
      return this.httpHeaders.append('Authorization', 'Bearer '+token);
    }
    return this.httpHeaders;
  }*/

  /*private isNoAutorizado(error:any): boolean{
    if(error.status === 401){

      if(this.loginService.isAuthenticated()){
        this.loginService.logout();
      }
      this.router.navigate(['/login'])
      return true;
    }

    if(error.status=== 403 ){
      swal.fire('Acceso denegado',`Hola ${this.loginService.usuario.nombre} usted no tiene acceso a este recurso!`, 'warning' );
      this.router.navigate(['/dependencias'])
      return true;
    }

    return false;
  }*/

  getEstados(): Observable<Estado[]> {
    return this.http.get<Estado[]>(this.urlEndPoint + '/estados').pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  getCategorias(): Observable<Categoria[]> {
    return this.http.get<Categoria[]>(this.urlEndPoint + '/categorias').pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  getTemas(): Observable<Tema[]> {
    return this.http.get<Tema[]>(this.urlEndPoint + '/temas').pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  findByTemas(buscar: string):Observable<any> {
    let data = new FormData();
    data.append("nombre", buscar);
    return this.http.post(this.urlEndPoint + '/temas/findByNombre', data).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
          }
          return throwError(e);
        }
      ));
  }
  obtenerTemas(pagina: string, tamanio: string): Observable<any> {
    return this.http.get<any>(`${this.urlEndPoint}/temas/todos?pagina=${pagina}&tamanio=${tamanio}`);
  }

  getTema(id:number): Observable<Tema>{
    return this.http.get<Tema>(this.urlEndPoint + '/temas/' + id).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
            this.router.navigate(['/temas'])
          }
          return throwError(e);
        }
      ));
  }

  createTema(tema: Tema): Observable<any> {
    return this.http.post(this.urlEndPoint + '/temas/', tema).pipe(
      map((response: any) => response.tema as Tema),
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  updateTema(tema: Tema): Observable<Tema> {
    return this.http.put<any>(`${this.urlEndPoint}/temas/${tema.id}`, tema).pipe(
      map((response: any) => response.tema as Tema),
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  deleteTema(tema: Tema): Observable<any> {
    return this.http.delete<any>(`${this.urlEndPoint}/temas/${tema.id}`).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al eliminar el tema', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  getMunicipios(estado: Estado): Observable<any> {
    return this.http.post(this.urlEndPoint + '/municipios/', estado).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  getDependencias(page: number): Observable<any> {
    //return of(CLIENTES);
    return this.http.get(this.urlEndPoint + '/page/' + page).pipe(
      /*tap( (response: any) => {
        (response.content as Dependencia[]).forEach(dependencia => {
          console.log(dependencia.nombre);
        });
      }),*/
      map((response: any) => {
        (response.content as Dependencia[]).map((dependencia) => {
          dependencia.nombre = dependencia.nombre.toUpperCase();
          let datePipe = new DatePipe('es-MX');

          dependencia.createAt = datePipe.transform(dependencia.createAt, 'EEEE dd, MMMM, yyyy');
          return dependencia;
        });
        return response;
      }),
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          swal.fire('Error al realizar la consulta  de la base des', e.error.mensaje, 'error');
        }
        return throwError(e);
      })
    );
  }

  getAllDependencias(): Observable<any> {
    return this.http.get<Dependencia>(`${this.urlEndPoint}/`).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
            this.router.navigate(['/dependecias'])
          }
          return throwError(e);
        }
      ));
  }

  getDependenciasLimit(categoria: Categoria[]): Observable<any> {
    return this.http.post(this.urlEndPoint + "/bycategoria/limit/", categoria).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  create(dependencia: Dependencia): Observable<Dependencia> {
    return this.http.post(this.urlEndPoint, dependencia).pipe(
      map((response: any) => response.dependencia as Dependencia),
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al ejecutar', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  getDependencia(id: number): Observable<Dependencia> {
    return this.http.get<Dependencia>(`${this.urlEndPoint}/${id}`).pipe(
      map((response: any) => response as Dependencia),
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
            this.router.navigate(['/dependecias'])
          }
          return throwError(e);
        }
      ));
  }

  getDependenciaByCategoria(categoria: Categoria): Observable<any> {
    return this.http.post(this.urlEndPoint + '/bycategoria/', categoria).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  getDependenciaByCategoriaPage(page: number, categoria: Categoria): Observable<any> {
    return this.http.post(this.urlEndPoint + '/categoria/page/', [categoria, page]).pipe(
      map((response: any) => response),
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  getDependenciaByEstado(estado: Estado, categoria: Categoria): Observable<any> {
    return this.http.post(this.urlEndPoint + '/byestado/', [estado, categoria]).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
            //this.router.navigate(['/dependecias'])
          }
          return throwError(e);
        }
      ));
  }

  getDependenciaByMunicipio(municipio: Municipio, categoria: Categoria): Observable<any> {
    return this.http.post(this.urlEndPoint + '/bymunicipiocategoria/', [municipio, categoria]).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  getDependenciasByTema(tema: Tema, estado: Estado, municipio : Municipio, categoria: Categoria): Observable<any> {
    let elementos = [tema, estado, municipio, categoria];
    return this.http.post(this.urlEndPoint + '/byTema/', elementos).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
            console.log(e)
          }
          return throwError(e);
        }
      )
    );
  }

  getDependenciasByNombre(nombre: string): Observable<any> {
    let data = new FormData();
    data.append("nombre", nombre);
    return this.http.post(this.urlEndPoint + '/byNombre', data).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  getDependenciasByFiltro(estado: Estado, municipio : Municipio, nombre: string): Observable<any> {
    let data = new FormData();
    data.append("nombre", nombre);
    data.append("estado", JSON.stringify(estado));
    data.append("municipio", JSON.stringify(municipio));
    return this.http.post(this.urlEndPoint + '/byFiltro', data).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
          }
          return throwError(e);
        }
      )
    );
  }

  update(dependencia: Dependencia): Observable<any>{
    return this.http.put<any>(`${this.urlEndPoint}/${dependencia.id}`,dependencia).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al ejecutar', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al eliminar la dependencia', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  subirFoto(archivo: File, id: any): Observable<HttpEvent<{}>> {
    let formData = new FormData();
    formData.append("file", archivo);
    formData.append("id", id);

    /**let httpHeaders = new HttpHeaders();
    let token = this.loginService.token;
    if(token != null){
      httpHeaders = httpHeaders.append('Authorization', 'Bearer '+token);
    }*/

    const req = new HttpRequest('POST', `${this.urlEndPoint}/upload`, formData, {
      reportProgress: true
    });

    return this.http.request(req).pipe(
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          swal.fire('Error al ejecutar', e.error.mensaje, 'error');
        }
        return throwError(e);
      })
    );
  }

  getImage(foto: string): Observable<any> {
    return this.http.get(`${URL_CONFIG}/api/uploads/img/${foto}`, { responseType: 'blob' }).pipe(
      //map((response: any) => response),
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          swal.fire('Error al ejecutar', e.error.mensaje, 'error');
        }
        return throwError(e);
      }));
  }

  getLocalidades(municipio: Municipio): Observable<any> {
    return this.http.post(this.urlEndPoint + '/localidades/', municipio).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }
}
