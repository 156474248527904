<detalle-cliente *ngIf="publicacionSeleccionado" [publicacion]="publicacionSeleccionado"></detalle-cliente>
<div class="container content">
    <h3>Publicaciones</h3>
    <hr class="red">
    <div class="row">
        <div class="container p-0'">
            <div class="col-md-4 mt-2" style="margin-top: 5px;">
                <label>Tipo:&nbsp;&nbsp;</label>
                <button *ngIf="tipo==1 else elseTogleBlog" class="btn btn-sm btn-success"><b>Blogs</b></button>
                <ng-template #elseTogleBlog>
                    <button class="btn btn-sm btn-flat" [routerLink]="['/publicaciones/1/0']"><b>Blogs</b></button>
                </ng-template>
                <button *ngIf="tipo==2 else elseTogleEvento" class="btn btn-sm btn-success"><b>Eventos</b></button>
                <ng-template #elseTogleEvento>
                    <button class="btn btn-sm btn-flat" [routerLink]="['/publicaciones/2/0']"><b>Eventos</b></button>
                </ng-template>
                <button *ngIf="tipo==3 else elseTogleNoticia" class="btn btn-sm btn-success"><b>Noticias</b></button>
                <ng-template #elseTogleNoticia>
                    <button class="btn btn-sm btn-flat" [routerLink]="['/publicaciones/3/0']"><b>Noticias</b></button>
                </ng-template>
                <button *ngIf="tipo==4 else elseTogleVinculacion" class="btn btn-sm btn-success"><b>Vinculación</b></button>
                <ng-template #elseTogleVinculacion>
                    <button class="btn btn-sm btn-flat" [routerLink]="['/publicaciones/4/0']"><b>Vinculación</b></button>
                </ng-template>
                <button *ngIf="tipo==5 else elseTogleAlianzas" class="btn btn-sm btn-success"><b>Alianzas</b></button>
                <ng-template #elseTogleAlianzas>
                    <button class="btn btn-sm btn-flat" [routerLink]="['/publicaciones/5/0']"><b>Alianzas</b></button>
                </ng-template>
                <button *ngIf="tipo==6 else elseTogleCarrusel" class="btn btn-sm btn-success"><b>Carrusel</b></button>
                <ng-template #elseTogleCarrusel>
                    <button class="btn btn-sm btn-flat" [routerLink]="['/publicaciones/6/0']"><b>Carrusel</b></button>
                </ng-template>
                <button *ngIf="tipo==7 else elseTogleVideo" class="btn btn-sm btn-success"><b>Video</b></button>
                <ng-template #elseTogleVideo>
                    <button class="btn btn-sm btn-flat" [routerLink]="['/publicaciones/7/0']"><b>Video</b></button>
                </ng-template>
                <button *ngIf="tipo==8 else elseTogleMultimedia" class="btn btn-sm btn-success"><b>Multimedia</b></button>
                <ng-template #elseTogleMultimedia>
                    <button class="btn btn-sm btn-flat" [routerLink]="['/publicaciones/8/0']"><b>Multimedia</b></button>
                </ng-template>
            </div>
            <div class="col-md-4">
                <input type="text" [(ngModel)]="busqueda" class="mt-2 form-control input-group-lg">
            </div>

            <div class="col-md-2">
                <button type="button" class="btn btn-info btn-block" (click)="buscar()">Buscar</button>
            </div>

            <div class="col-md-2">
                <button id="opciones-crear" *ngIf="loginService.hasRoles('ROLE_ADMIN')" type="button" class="btn btn-rounded btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    Agregar publicación
                </button>
                <ul class="dropdown-menu" role="menu" aria-labelledby="opciones-crear">
                    <li>
                        <ul class="dropdown-submenu" role="menu" aria-labelledby="temas">
                            <li><a class="dropdown-item" [routerLink]="['/publicaciones/formblog']">Blog</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/publicaciones/formeventos']">Evento</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/publicaciones/formnoticias']">Noticia</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/publicaciones/formvinculacion']">Vinculación</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/publicaciones/formalianza']">Alianzas</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/publicaciones/formcarrusel']">Carrusel</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/publicaciones/formvideo']">Video</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/publicaciones/formultimedia']">Multimedia</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-12">
            <div class="alert alert-info" *ngIf="publicaciones?.length == 0">
                No hay registros en la base de datos!
            </div>
            <div class="table-responsive">                
                <table class="table table-bordered" *ngIf="publicaciones?.length>0">
                    <thead>
                        <tr class="table table-bordered">
                            <!--<th>#</th>-->
                            <th>Nombre</th>
                            <th>Fecha</th>
                            <!--th>Tipo</th-->
                            <th colspan="2">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let publicacion of publicaciones; let i = index" [attr.data-index]="i">
                            <td>{{ publicacion.titulo }}</td>
                            <td>{{ publicacion.fechaPublicacion }}</td>
                            <!--td>tipo</td-->
                            <td class="text-center" *ngIf="loginService.hasRoles('ROLE_ADMIN')">
                                <button type="button" name="button" routerLink="/publicaciones/editar/form{{publicacion.tipoPublicacion}}/{{publicacion.id}}" class="btn btn-warning btn-sm">
                                    <span class="glyphicon glyphicon-pencil" aria-hidden="true"></span>
                                </button>
                            </td>
                            <td class="text-center" *ngIf="loginService.hasRoles('ROLE_ADMIN')">
                                <button type="button" name="button" (click)="delete(publicacion, publicacion.tipoPublicacion)" class="btn btn-primary btn-sm">
                                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <paginator-blog-nav *ngIf="tipo==1 && paginador" [paginador]="paginador"></paginator-blog-nav>
            <paginator-evento-nav *ngIf="tipo==2 && paginador" [paginador]="paginador"></paginator-evento-nav>
            <paginator-noticia-nav *ngIf="tipo==3 && paginador" [paginador]="paginador"></paginator-noticia-nav>
            <paginator-vinculacion-nav *ngIf="tipo==4 && paginador" [paginador]="paginador"></paginator-vinculacion-nav>
            <paginator-alianza-nav *ngIf="tipo==5 && paginador" [paginador]="paginador"></paginator-alianza-nav>
            <paginator-carrusel-nav *ngIf="tipo==6 && paginador" [paginador]="paginador"></paginator-carrusel-nav>
            <paginator-video-nav *ngIf="tipo==7 && paginador" [paginador]="paginador"></paginator-video-nav>
            <paginator-multimedia-nav *ngIf="tipo==8 && paginador" [paginador]="paginador"></paginator-multimedia-nav>
        </div>
    </div>
</div>
