import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Multimedia } from '../models/multimedia';
import { URL_CONFIG } from '../store/config';
import { map, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class MultimediaService {
  private urlEndPoint: string = `${URL_CONFIG}/api/multimedia`;
  private urlEndPointBase: string = `${URL_CONFIG}/api`;

  constructor(private httpClient: HttpClient, private router: Router) {}

  obtenerMultimedias(tipo: number = 1): Observable<Multimedia[]> {
    var tipoS = tipo == 1 ? '/multimedia' : '/multimedia';
    //@TODO Cambiar publicaciones a blogs
    return this.httpClient.get<Multimedia[]>(this.urlEndPointBase + tipoS);
  }

  obtenerMultimedia(id: string): Observable<Multimedia> {
    return this.httpClient.get<Multimedia>(
      `${this.urlEndPointBase}/multimedia/${id}`
    );
  }

  borrarMultimedia(id: number): Observable<any> {
    // console.log("ENTRA A BORRAAR VIDEO");
    return this.httpClient.delete<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError((e) => {
        if (e.status != 401 && e.status != 403) {
          swal.fire(
            'Error al eliminar la Multimedia',
            e.error.mensaje,
            'error'
          );
        }
        console.log(throwError(e));
        return throwError(e);
      })
    );
  }

  createMultimedia(publicacion: Multimedia): Observable<Multimedia>{
    return this.httpClient.post<Multimedia>(this.urlEndPoint , publicacion).pipe(
      map((response: any) => response as Multimedia),
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403){
            swal.fire('Error en el registro', e.error.mensaje,'error' );
            if(publicacion.tipoPublicacion == "multimedia"){
              this.router.navigate(['/publicaciones/multimedia']);
            }
          }
          return throwError(e);
        }
      )
    );
  }

  correcionRutas(id: any): Observable<HttpEvent<{}>>{
    const req = new HttpRequest('GET', `${this.urlEndPoint}/correcion/`+id, {
    });
    return this.httpClient.request(req).pipe(catchError(e => {
        if (e.status != 401 && e.status != 403) {
          //swal.fire('Error al ejecutar', e.error.mensaje, 'error');
        }
        return throwError(e);
      }));
  }

  subirArchivo(archivo: File, id: any, filename: string): Observable<HttpEvent<{}>>{
    let formData = new FormData();
    formData.append("file", archivo);
    formData.append("id", id);
    formData.append("filename", filename);

    const req = new HttpRequest('POST', `${this.urlEndPoint}/uploads`, formData, {
      reportProgress: true
    });

    return this.httpClient.request(req).pipe(
      catchError(e => {
        if (e.status != 401 && e.status != 403) {
          //swal.fire('Error al guardar el archivo', e.error.mensaje, 'error');
        }
        console.log(throwError(e));
        return throwError(e);
      })
    );
  }

}
