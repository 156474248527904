import { Municipio } from "./municipios";
import { Estado } from "./estados";

export class Localidades{

  id: number;
  cp: number;
  nombre: string;
  tipoLocalidad: string;
  municipio: Municipio;
  estado: Estado;

}
