import { LOCALE_ID, NgModule, NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
//import {NgxPaginationModule} from 'ngx-pagination'; // se modifico import pagination
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component'
//import { FooterComponent } from './footer/footer.component';
import { DirectivaComponent } from './components/directiva/directiva.component';
import { DependenciasComponent } from './components/dependencias/dependencias.component'
import { DependenciaService } from './service/dependencias.service';
import { ContactoService } from './service/contactos.service';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormComponent } from './components/dependencias/formulario/form.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PaginatorBlogComponent } from './components/paginator-blog/paginator-blog.component';
import { PaginatorVinculacionComponent } from './components/paginator-vinculacion/paginator-vinculacion.component';
import { PaginatorEventoComponent } from './components/paginator-evento/paginator-evento.component';
import { PaginatorNoticiaComponent } from './components/paginator-noticia/paginator-evento.component';
import { PaginatorAlianzaComponent } from './components/paginator-alianza/paginator-alianza.component';
import { PaginatorCarruselComponent } from './components/paginator-carrusel/paginator-carrusel.component';
import { PaginatorVideoComponent } from './components/paginator-video/paginator-video.component';
import { PaginatorMultimediaComponent } from './components/paginator-multimedia/paginator-multimedia.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
//import { MatNativeDateModule  } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DetalleComponent } from './components/dependencias/detalle/detalle.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { ContactosComponent } from './components/contactos/contactos.component';
import { OscComponent } from './components/osc/osc.component';
import { LoginComponent } from './components/login/login.component';
import { RoleGuard } from './guards/role.guard';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { CuentaComponent } from './components/usuarios/cuenta/cuenta.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { TemasComponent } from './components/temas/temas.component';
import { CardsDependenciasComponent } from './components/cards-dependencias/cards-dependencias.component';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormtemasComponent } from './components/temas/formtemas/formtemas.component';
import { FormUsuariosComponent } from './components/usuarios/form-usuarios/form-usuarios.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CardsBlogComponent } from './components/cards-blog/card-blog.component';
import { PublicacionComponent } from './components/publicaciones/publicacion.component';
import { PublicacionFormComponent } from './components/publicaciones/formulario/publicacionform.component';
import { PublicacionFormBlogComponent } from './components/publicaciones/form-blog/publicacionformblog.component';
import { PublicacionFormNoticiaComponent } from './components/publicaciones/form-noticias/publicacionformnoticia.component';
import { PublicacionFormEventoComponent } from './components/publicaciones/form-eventos/publicacionformevento.component';
import { BienvenidaComponent } from './components/bienvenida/bienvenida.component';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { PrensaComponent } from './components/prensa/prensa.component';
import { PublicacionBlogComponent } from './components/publicacion-blog/publicacion-blog.component';
import { ListaPublicacionesComponent } from './components/lista-publicaciones/lista-publicaciones.component';
import { PaginadorComponent } from './components/paginador/paginador.component';
import { AvisosService } from './service/avisos.service';
import { EventoService } from './service/eventos.service';
import { NoticiaService } from './service/noticias.service';
import { EventosDestacadosComponent } from './components/eventos-destacados/eventos-destacados.component';
import { MesasDialogoComponent } from './components/mesas-dialogo/mesas-dialogo.component';
import { NoticiasDetailComponent } from './components/noticias-detail/noticias-detail.component';
import { ConveniosComponent } from './components/convenios/convenios.component';
import { MujerExportaComponent } from './components/mujer-exporta/mujer-exporta.component';
import { PublicacionformvinculacionComponent } from './components/publicaciones/publicacionformvinculacion/publicacionformvinculacion.component';
import { PublicacionVinculacionComponent } from './components/publicacion-vinculacion/publicacion-vinculacion.component';
import { AlianzaComponent } from './components/alianza/alianza.component';
import { AlianzasComponent } from './components/alianzas/alianzas.component';
import { FormAlianzaComponent } from './components/publicaciones/form-alianza/publicacionformalianza.component';
import { FormCarruselComponent } from './components/publicaciones/form-carrusel/form-carrusel.component';
import { FormVideoComponent } from './components/publicaciones/form-video/form-video.component';
import { FormMultimediaComponent } from './components/publicaciones/form-multimedia/form-multimedia.component';
import { GaleryComponent } from './components/galery/galery.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe'; // Ajusta la ruta según tu estructura



registerLocaleData(localeEs, 'es-MX');

const routes: Routes = [
  { path: 'inicio', component: InicioComponent },
  { path: 'inicio/:seccion', component: InicioComponent },
  { path: 'publicacion', component: PublicacionBlogComponent },
  { path: 'publicacion/:id', component: PublicacionBlogComponent },
  { path: 'publicaciones/historico', component: ListaPublicacionesComponent },
  { path: 'organizaciones/:id/:nombre', component: OscComponent },
  { path: 'publicaciones', component: PublicacionComponent },
  { path: 'publicaciones/:tipo/:page', component: PublicacionComponent },
  { path: 'publicaciones/form/:tipo', component: PublicacionFormComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'publicaciones/formblog', component: PublicacionFormBlogComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'publicaciones/editar/formblog/:id', component: PublicacionFormBlogComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'publicaciones/formnoticias', component: PublicacionFormNoticiaComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'publicaciones/editar/formnoticias/:id', component: PublicacionFormNoticiaComponent },
  { path: 'publicaciones/formeventos', component: PublicacionFormEventoComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'publicaciones/editar/formeventos/:id', component: PublicacionFormEventoComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'dependencias/page/:page', component: DependenciasComponent },
  { path: 'dependencias', component: DependenciasComponent },
  { path: 'dependencias/form', component: FormComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'dependencias/form/:id', component: FormComponent },
  { path: 'usuarios/page/:page', component: UsuariosComponent },
  { path: 'usuarios', component: UsuariosComponent },
  { path: 'usuarios/form', component: FormUsuariosComponent },
  { path: 'usuarios/form/:id', component: FormUsuariosComponent },
  { path: 'usuarios/cuenta/:id', component: CuentaComponent },
  { path: 'contactos', component: ContactosComponent },
  { path: 'login', component: LoginComponent },
  { path: 'temas', component: TemasComponent },
  { path: "temas/form", component: FormtemasComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'temas/:pagina', component: TemasComponent },
  { path: 'galería', component: GaleriaComponent },
  { path: 'galery', component: GaleryComponent },
  { path: 'galería/:id', component: GaleriaComponent },
  { path: 'registro', component: FormComponent, data: {context: "public"} },
  { path: 'temas/form/:id', component: FormtemasComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'bienvenida', component: BienvenidaComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'mesasDialogo', component: MesasDialogoComponent },
  { path: 'mesasDialogo/:id', component: MesasDialogoComponent},
  { path: 'alianza', component: AlianzaComponent },
  { path: 'alianza/:id', component: AlianzaComponent},
  { path: 'alianzas', component: AlianzasComponent },
  { path: 'alianzas/:id', component: AlianzasComponent},
  { path: 'convenios', component: ConveniosComponent},
  { path: 'convenios/:id', component: ConveniosComponent},
  { path: 'publicaciones/formvinculacion', component: PublicacionformvinculacionComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' }},  
  { path: 'publicaciones/formalianza', component: FormAlianzaComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' }},
  { path: 'publicaciones/formcarrusel', component: FormCarruselComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' }},
  { path: 'publicaciones/editar/formcarrusel/:id', component: FormCarruselComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' }},
  { path: 'publicaciones/formvideo', component: FormVideoComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' }},
  { path: 'publicaciones/editar/formvideo/:id', component: FormVideoComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' }},
  { path: 'publicaciones/formultimedia', component: FormMultimediaComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' }},
  { path: 'publicaciones/editar/formmultimedia/:id', component: FormMultimediaComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' }},
  { path: 'publicaciones/editar/formvinculacion/:id', component: PublicacionformvinculacionComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'publicaciones/editar/formalianza/:id', component: FormAlianzaComponent, canActivate: [RoleGuard], data: { role: 'ROLE_ADMIN' } },
  { path: 'publicacion-vinculacion/:id', component: PublicacionVinculacionComponent },
  { path: 'publicacion-alianza/:id', component: AlianzaComponent },
  // se modifico
  { path: 'exporta', component: MujerExportaComponent},
  { path: 'exporta/:id', component: MujerExportaComponent},
  { path: 'noticias/:mes', component: NoticiasDetailComponent},
  { path: '**', redirectTo: '/inicio', pathMatch: 'full' },
  //{path: 'clientes/ver/:id', component: DetalleComponent}
]
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    //FooterComponent,
    DirectivaComponent,
    DependenciasComponent,
    FormComponent,
    PaginatorComponent,
    PaginatorBlogComponent,
    PaginatorVinculacionComponent,
    PaginatorEventoComponent,
    PaginatorNoticiaComponent,
    PaginatorAlianzaComponent,
    PaginatorCarruselComponent,
    PaginatorMultimediaComponent, 
    PaginatorVideoComponent,
    DetalleComponent,
    InicioComponent,
    ContactosComponent,
    OscComponent,
    LoginComponent,
    UsuariosComponent,
    CuentaComponent,
    TemasComponent,
    CardsDependenciasComponent,
    BuscadorComponent,
    FormtemasComponent,
    FormUsuariosComponent,
    PublicacionComponent,
    PublicacionFormBlogComponent,
    PublicacionFormEventoComponent,
    PublicacionFormNoticiaComponent,
    CardsBlogComponent,
    BienvenidaComponent,
    GaleriaComponent,
    PrensaComponent,
    PublicacionBlogComponent,
    ListaPublicacionesComponent,
    EventosDestacadosComponent,
    MesasDialogoComponent,
    NoticiasDetailComponent,
    ConveniosComponent,
    MujerExportaComponent,
    PublicacionformvinculacionComponent,
    PublicacionVinculacionComponent,
    AlianzaComponent,
    FormAlianzaComponent,
    AlianzasComponent,
    FormCarruselComponent,
    FormVideoComponent,
    FormMultimediaComponent,
    GaleryComponent,
    SafeUrlPipe,
  ],
  imports: [
    //NgxPaginationModule, // se modifico
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot(routes, { useHash: true }),
    CKEditorModule
  ],
  exports: [RouterModule],
  providers: [DependenciaService,
    AvisosService,
    EventoService,
    NoticiaService,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
