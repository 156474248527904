import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Contacto } from 'src/app/models/contacto';
import { Estado } from 'src/app/models/estados';
import { Municipio } from 'src/app/models/municipios';
import { ContactoService } from 'src/app/service/contactos.service';
import swal from "sweetalert2";

declare let Email: any;

@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.css'],
  providers: [ContactoService] 
})

export class ContactosComponent implements OnInit {

  @Input() context: String;
  public contacto: Contacto = new Contacto();
  public estados: Estado[];
  public estado: Estado;
  public municipios: Municipio[];
  public classFin: String;

  constructor(private contactoService: ContactoService,
    private router: Router,
    private activateRoute: ActivatedRoute) { }

  public classFormulario: String = "selectedButtonPurple";
  public showFormulario: String;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    console.log(this.activateRoute.data['value'].context);
    this.contactoService.getEstados().subscribe((estados) => {
      this.estados = estados
    })
  }

  cargarMunicipios(): void {
    if (this.estado !== undefined) {
      this.contactoService.getMunicipios(this.estado).subscribe((municipios) => {
        this.municipios = municipios
      });
    }
  }

   create(): void {
    this.contactoService.create(this.contacto).subscribe(
      (contacto) => {
       /*  this.dependencia = dependencia;
        if (this.file != null) {
          this.dependeciaService.subirFoto(this.file[0], this.dependencia.id).subscribe((dependencia) => {
          });
        }*/
       // this.router.navigate(['/dependencias'])
       swal.fire('Nuevo contacto', `Contacto: "${this.contacto.nombre}" guardada con exito!`, 'success').then(function(){
          window.location.reload();
       });
       this.classFin = "success";
       this.activateRoute.data['value'].context === undefined ? this.router.navigate(['/contactos']) : this.router.navigate(['/contactos']);
      }
    );
  }

  compararEstatdos(o1: Estado, o2: Estado): boolean {
    if ((o1 === undefined ||o1 === null) && (o2 === undefined || o2 === null)) {
      return true;
    }
    return o1 === undefined || o2 === undefined ? false : o1.id === o2.id;
  }

  compararMunicipios(o1: Municipio, o2: Municipio): boolean {
    if ((o1 === undefined || o1 === null) && ( o2 === undefined || o2 === null)) {
      return true;
    }
    return o1 === undefined || o2 === undefined ? false : o1.id === o2.id;
  }

  toogleElements(event: any): void{
    if(event.currentTarget.value === "Formulario"){
      this.classFormulario = ""
      this.showFormulario === "show" ? this.showFormulario = "" : this.showFormulario = "show";
    } 
  }
// Original
/*  eventGenero(event: any): void{
    if(event.currentTarget.value === 'f'){
     this.contacto.genero = true;
    }else{
     this.contacto.genero = false;
    }
   }
*/
// se modifico
eventGenero(event: any): void{
  if(event.currentTarget.value === 'f'){
   this.contacto.genero = 0;
  }
  else if (event.currentTarget.value === 'm'){
    this.contacto.genero = 1;
  }
  else{
   this.contacto.genero = 2;
  }
 }
}
