<div class="container">
    <div class="row">
        <div class="col-sm-12 contentAlianza">
            <div class="container text-center">
                <h2>Órganos auxiliares del INMUJERES</h2>
                <br />
            </div>
            <a routerLink="/inicio/vinculacionesyalianzas" class="btn-flotante">Regresar</a>
            <br />

            <!-- primer apartado-->
            <div class="container" *ngIf="this.activeFiles['0'].tab" id="pills-primer">
                <div class="container" data-toggle="modal" data-target="#lightbox">
                    <div class="container-fluid text-center" style="background-color: #59287f; color: white">
                        <h3>Foro consejo consultivo</h3>
                    </div>
                    <br />
                    <div class="row justify-content-center">
                        <div class="d-grid gap-2 col-12 mx-auto">
                            <button id="alianzas" type="button" class="btn btn-primary dropdown-toggle"
                                data-toggle="dropdown" aria-expanded="false">
                                Temas
                            </button>
                            <ul class="dropdown-menu" role="menu" aria-labelledby="alianzas">
                                <!-- <li>
                                    <a class="dropdown-item" data-toggle="dropdown" id="seisTema" aria-selected="true"
                                        (click)="toogleTemas($event)">Las mujeres y las infancias migrantes: los
                                        cuidados en contextos fronterizos hoy (2023)</a>
                                </li> -->

                                <li  *ngFor="let publicacion of consultivoPublicacion; let indice = index;" [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                                    <a class="dropdown-item" routerLink="/publicacion-alianza/{{publicacion.id}}">
                                      {{publicacion.titulo}}{{(mesAnno(publicacion.fechaPublicacion))}}</a
                                    >
                                </li>

                                <!-- <hr class="dropdown-divider">
                                <ol for="id-history">
                                    <p class="hp col-lg-12 nav-item">Historico</p>
                                </ol> -->
                                <!-- <ul id="id-history">
                                    <li>
                                        <a class="dropdown-item" data-toggle="dropdown" id="cincoTema"
                                            aria-selected="true" (click)="toogleTemas($event)">Conversatorio I.
                                            Violencia contra mujeres periodistas en
                                            México (2022)</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-toggle="dropdown" id="cuatroTema"
                                            aria-selected="true" (click)="toogleTemas($event)">Conversatorio II. Consejo
                                            Consultivo. Derechos de las
                                            Mujeres Indígenas y Rurales (2022)</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-toggle="dropdown" id="tresTema"
                                            aria-selected="true" (click)="toogleTemas($event)">Conversatorio III. C.
                                            Consultivo. LGAMVLV (2022)</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-toggle="dropdown" id="dosTema"
                                            aria-selected="true" (click)="toogleTemas($event)">Autonomía económica.
                                            Nuevo León (2022)</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-toggle="dropdown" id="unoTema"
                                            aria-selected="true" (click)="toogleTemas($event)">Dialogo Nacional
                                            (2020)</a>
                                    </li>
                                </ul> -->
                            </ul>
                        </div>
                    </div>
                    <br />

                    <!--tema 1-->
                    <div class="tab-pane" *ngIf="temaUno">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div style="width: 100%; height: 400px; overflow: hidden">
                                                <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PHOTO-2020-11-10-12-24-20.jpg"
                                                    class="d-block" alt="..." style="height: 460px; margin: auto" />
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/1.PNG"
                                                class="d-block w-100" alt="..." />
                                        </div>

                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/2.PNG"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/3.PNG"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/4.PNG"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                HACIA EL FORTALECIMIENTO DE UNA POLÍTICA NACIONAL DE IGUALDAD
                                ANTE EL COVID-19 Y EL POSTCOVID
                            </h2>
                            <h3>INMUJERES y Consejo Consultivo del INMUJERES</h3>
                            <p class="font-weight-bold">
                                Objetivo: Analizar a través de un diálogo entre actoras
                                estratégicas, los posibles escenarios determinados por la
                                pandemia ocasionada por el SARS-CoV-2, para proponer estrategias
                                realistas en relación con el avance de la Agenda 2030, en
                                concordancia con el marco del PROIGUALDAD y las metas del
                                INMUJERES
                            </p>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <!--<a class="circles ml-n5" id="img" (click)="toogleDocs($event)"  (click)="tooglePagi($event)" aria-selected="true">-->
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F3376681199047179%2F&show_text=false"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Diálogo Nacional INMujeres 1</p>
                                            <p style="font-size: small;">Inmujeres México</p>
                                        </div>
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F3558769294217513%2F&show_text=false"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Diálogo Nacional INMujeres 2</p>
                                            <p style="font-size: small;">Inmujeres México</p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <!--  <div class="col-md-12" *ngIf="uno"> -->
                                    <!--se modifico-->
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PHOTO-2020-11-10-12-24-20.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/1.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-0"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 2">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/2.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/3.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/4.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="2" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        2
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="3" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        3
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PROGRAMA/PROGRAMA DIALOGO NACIONAL Participantes.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PROGRAMA/PROGRAMA DIALOGO NACIONAL Participantes.pdf"
                                            target="_blank">
                                            PROGRAMA DIALOGO NACIONAL Participantes
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/INFORME/Informe Consejo C  Diálogo Nal  12 y 13 de Nov 2020 .pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/INFORME/Informe Consejo C  Diálogo Nal  12 y 13 de Nov 2020 .pdf"
                                            target="_blank">
                                            Informe Consejo C Diálogo Nal 12 y 13 de Nov 2020
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox">
                                    <!--    <div class="col-sm-3 my-5 text-center">
                                            <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/ANEXO 3 LINK.pdf" target="_blank">
                                                <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #db8f1b;"></span>
                                            </a><br>
                                            <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/ANEXO 3 LINK.pdf" target="_blank">
                                                ANEXO 3
                                            </a>
                                        </div>
                                       -->
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MartaFerreyraPresentacion.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MartaFerreyraPresentacion.pdf"
                                            target="_blank">
                                            1.1 Marta Ferreyra Presentación
                                        </a>
                                    </div>

                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Presentacion_DialogoNacional_LCPF.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Presentacion_DialogoNacional_LCPF.pdf"
                                            target="_blank">
                                            1.2 Presentación Diálogo Nacional LCPF
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/SLopez_Cuidados_y_pobreza_en_tiempos_de_Pandemia.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/SLopez_Cuidados_y_pobreza_en_tiempos_de_Pandemia.pdf"
                                            target="_blank">
                                            1.3 Cuidados y pobreza en tiempos de pandemia
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Patricia_Uribe_Presentacion.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Patricia_Uribe_Presentacion.pdf"
                                            target="_blank">
                                            3.1 Patricia Uribe Presentación
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MONICA_JASIS_PRESENTACION.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MONICA_JASIS_PRESENTACION.pdf"
                                            target="_blank">
                                            3.2 Monica Jasis Presentación
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Alejandra_Mtnz_Presentacion.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Alejandra_Mtnz_Presentacion.pdf"
                                            target="_blank">
                                            3.3 Alejandra Mtnz Presentación
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MARIA_DE_LA_LUZ_Mesa_4_Presentacion.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MARIA_DE_LA_LUZ_Mesa_4_Presentacion.pdf"
                                            target="_blank">
                                            4.1 Maria De La Luz Presentación Mesa 4
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/PRESENTACION_ANABEL_mesa_4.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/PRESENTACION_ANABEL_mesa_4.pdf"
                                            target="_blank">
                                            4.2 Anabel Presentación Mesa 4
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- INICIO tema 6-->
                    <div class="tab-pane" *ngIf="temaSeis">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <!-- <div style="width: 100%; height: 400px; overflow: hidden"> -->
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/POSTER/Poster_11.jpg"
                                                alt="..." class="d-block w-100" />
                                            <!-- </div> -->
                                        </div>
                                        <!-- <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/1.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div> -->

                                        <!-- <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/2.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/3.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/4.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div> -->
                                    </div>
                                    <!-- <a
                    class="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Next</span>
                  </a> -->
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                Las mujeres y las infancias migrantes: los cuidados en contextos fronterizos hoy
                            </h2>
                            <!-- <h3>INMUJERES y Consejo Consultivo del INMUJERES</h3> -->
                            <p class="font-weight-bold">
                                Con el propósito de aportar conocimientos y
                                experiencias para impulsar acciones que permitan que las mujeres y las infancias
                                migrantes
                                estén en el centro de la construcción de políticas públicas en materia de cuidados, el
                                Instituto Nacional de las Mujeres (Inmujeres), a través de su Consejo Consultivo, en
                                colaboración con el Colegio de la Frontera Norte (El Colef).
                            </p>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <!--<a class="circles ml-n5" id="img" (click)="toogleDocs($event)"  (click)="tooglePagi($event)" aria-selected="true">-->
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Felcolef%2Fvideos%2F962340158343931%2F&show_text=false&width=560&t=0"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Foro: Las mujeres y las infancias migrantes</p>
                                            <p style="font-size: small;">El Colef - El Colegio de la Frontera Norte</p>
                                        </div>
                                        <!-- <div class="col-sm-6 my-5 text-center">
                      <iframe
                        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Felcolef%2Fvideos%2F962340158343931%2F&show_text=false&width=560&t=0"
                        style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        "
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe>
                      <p>Foro: Las mujeres y las infancias migrantes</p>
                      <p style="font-size: small;">El Colef - El Colegio de la Frontera Norte</p>
                    </div> -->
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <!--  <div class="col-md-12" *ngIf="uno"> -->
                                    <!--se modifico-->
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro1.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro2.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-0"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 2">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro3.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro4.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro5.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>

                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro6.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 4">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro7.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>

                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro8.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 5">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro9.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>

                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro10.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 6">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro11.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>

                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro12.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 7">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro13.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>

                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro14.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 8">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro15.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>

                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro16.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 9">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro17.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>

                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro18.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 10">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro19.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>

                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro20.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 11">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro21.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>

                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/FOTOS/Foro22.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="2" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        2
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="3" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        3
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="4" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        4
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="5" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        5
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="6" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        6
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="7" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        7
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="8" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        8
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="9" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        9
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="10" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        10
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="11" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        11
                                                    </a>
                                                </li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/DOCUMENTOS/Programa DEFINITIVO FORO TIJUANA 7 JULIO 2023 _1327.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/INFANCIAS MIGRANTES/DOCUMENTOS/Programa DEFINITIVO FORO TIJUANA 7 JULIO 2023 _1327.pdf"
                                            target="_blank">
                                            Programa DEFINITIVO FORO TIJUANA
                                        </a>
                                    </div>
                                    <!-- <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/INFORME/Informe Consejo C  Diálogo Nal  12 y 13 de Nov 2020 .pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #4d92df"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/INFORME/Informe Consejo C  Diálogo Nal  12 y 13 de Nov 2020 .pdf"
                      target="_blank"
                    >
                      Informe Consejo C Diálogo Nal 12 y 13 de Nov 2020
                    </a>
                  </div> -->
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox">
                                    <!--    <div class="col-sm-3 my-5 text-center">
                                            <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/ANEXO 3 LINK.pdf" target="_blank">
                                                <span class="glyphicon glyphicon-book p-3" aria-hidden="true" style="font-size: 140px; color: #db8f1b;"></span>
                                            </a><br>
                                            <a href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/ANEXO 3 LINK.pdf" target="_blank">
                                                ANEXO 3
                                            </a>
                                        </div>
                                       -->
                                    <!-- <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MartaFerreyraPresentacion.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MartaFerreyraPresentacion.pdf"
                      target="_blank"
                    >
                      1.1 Marta Ferreyra Presentación
                    </a>
                  </div>

                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Presentacion_DialogoNacional_LCPF.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Presentacion_DialogoNacional_LCPF.pdf"
                      target="_blank"
                    >
                      1.2 Presentación Diálogo Nacional LCPF
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/SLopez_Cuidados_y_pobreza_en_tiempos_de_Pandemia.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/SLopez_Cuidados_y_pobreza_en_tiempos_de_Pandemia.pdf"
                      target="_blank"
                    >
                      1.3 Cuidados y pobreza en tiempos de pandemia
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Patricia_Uribe_Presentacion.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Patricia_Uribe_Presentacion.pdf"
                      target="_blank"
                    >
                      3.1 Patricia Uribe Presentación
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MONICA_JASIS_PRESENTACION.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MONICA_JASIS_PRESENTACION.pdf"
                      target="_blank"
                    >
                      3.2 Monica Jasis Presentación
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Alejandra_Mtnz_Presentacion.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/Alejandra_Mtnz_Presentacion.pdf"
                      target="_blank"
                    >
                      3.3 Alejandra Mtnz Presentación
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MARIA_DE_LA_LUZ_Mesa_4_Presentacion.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MARIA_DE_LA_LUZ_Mesa_4_Presentacion.pdf"
                      target="_blank"
                    >
                      4.1 Maria De La Luz Presentación Mesa 4
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/PRESENTACION_ANABEL_mesa_4.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/PRESENTACION_ANABEL_mesa_4.pdf"
                      target="_blank"
                    >
                      4.2 Anabel Presentación Mesa 4
                    </a>
                  </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- fin tema 6 -->

                    <!-- tema 2-->
                    <div class="tab-pane" *ngIf="temaDos">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <!--div style="width: 100%; height: 400px; overflow: hidden"-->
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/slider/InvitacionForoAutonomiaEconomica_1.png"
                                                class="d-block w-100" alt="..." />
                                            <!--/div-->
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/slider/MamparaElectronicaAutonomia Economica_2.png"
                                                class="d-block w-100" alt="..." />
                                        </div>

                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/slider/Mampara Electronica Autonomia Economica_3.png"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/slider/MamparaElectronicaAutonomiaEconomica_4.png"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>FORO: Autonomía Económica. NUEVO LEÓN</h2>
                            <h3>INMUJERES y Consejo Consultivo del INMUJERES</h3>
                            <p class="font-weight-bold">
                                Objetivo General: Generar un espacio de análisis y reflexión
                                para identificar los factores determinantes de la autonomía
                                económica hacia el avance de la igualdad sustantiva.
                            </p>
                            <p class="font-weight-bold">
                                <em> Objetivo específico 1.</em> Identificar y reconocer las
                                mejores prácticas que impactan en el empoderamiento y la
                                autonomía económica de las mujeres a partir de la experiencia de
                                distintas actoras/es políticos y sociales.
                            </p>
                            <p class="font-weight-bold">
                                <em> Objetivo específico 2.</em> Analizar los componentes
                                determinantes del impacto de la violencia de género en el
                                empoderamiento y la autonomía económica de las mujeres.
                            </p>
                            <p class="font-weight-bold">
                                <em> Objetivo específico 3.</em> Reflexionar la importancia de
                                la educación mediática e informacional para el acceso y
                                generación del conocimiento en torno a la autonomía económica y
                                la prevención de la violencia hacia las mujeres y niñas.
                            </p>
                            <br />
                            <ul>
                                <li>
                                    <p class="font-weight-bold">
                                        Lugar: Auditorio de la Facultad de Economía de la UANL
                                    </p>
                                </li>
                                <li>
                                    <p class="font-weight-bold">30 de agosto de 2022</p>
                                </li>
                            </ul>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe src="https://www.youtube.com/embed/WOhjvOBt3CM" style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Foro Contribución de la autonomía económica para la igualdad sustantiva
                                            </p>
                                            <p style="font-size: small;">Inmujeres México</p>
                                        </div>

                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe src="https://www.youtube.com/embed/p89b_miGE_g" style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Foro Contribución de la autonomía económica para la igualdad sustantiva.
                                                Panel 3.</p>
                                            <p style="font-size: small;">Inmujeres México</p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N1.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N2.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-0"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 2">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N7.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N8.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N10.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N12.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 4">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N13.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N14.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 5">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N16.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/img/N19.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="2" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        2
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="3" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        3
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="4" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        4
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="5" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        5
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/documentos/PROGRAMA.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Autonomia economica Nuevo Leon/documentos/PROGRAMA.pdf"
                                            target="_blank">
                                            Autonomía Económica Nuevo León
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <!--div
                  class="row d-flex flex-wrap align-items-center"
                  data-toggle="modal"
                  data-target="#lightbox"
                >
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MartaFerreyraPresentacion.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PRESENTACIONES/MartaFerreyraPresentacion.pdf"
                      target="_blank"
                    >
                      1.1 Marta Ferreyra Presentación
                    </a>
                  </div>
                </div-->
                            </div>
                        </div>
                    </div>
                    <!-- tema 3 Conversatorio III-->
                    <div class="tab-pane" *ngIf="temaTres">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <!--div style="width: 100%; height: 400px; overflow: hidden"-->
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio iii/slider/III Conversatorio Consejo Consultivo_FB.png"
                                                class="d-block w-100" alt="..." />
                                            <!--/div-->
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio iii/slider/III Conversatorio Consejo Consultivo_TW.png"
                                                class="d-block w-100" alt="..." />
                                        </div>

                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio iii/slider/OBS_III Conversatorio Consejo Consultivo.png"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>Conversatorio III. C. Consultivo. LGAMVLV</h2>
                            <h3>INMUJERES y Consejo Consultivo del INMUJERES</h3>
                            <p class="font-weight-bold">
                                ANTECEDENTES:<br />
                                La Ley fue publicada en DOF el 1 de febrero de 2007. Se lee en
                                su ARTÍCULO 1.
                                <em>“La presente ley tiene por objeto establecer la coordinación
                                    entre la Federación, las entidades federativas, el Distrito
                                    Federal y los municipios para
                                    <b> prevenir, sancionar y erradicar </b>
                                    la violencia contra las mujeres, así como los principios y
                                    modalidades para
                                    <b>garantizar su acceso a una vida libre de violencia que
                                        favorezca su desarrollo y bienestar conforme a los
                                        principios de igualdad y de no discriminación, así como para
                                        garantizar la democracia, el desarrollo integral y
                                        sustentable que fortalezca la soberanía y el régimen
                                        democrático
                                    </b>
                                    establecidos en la Constitución Política de los Estados Unidos
                                    Mexicanos”.
                                </em>
                                <br />
                                Hemos pasado por la una emergencia de salud mundial de SARS
                                covid, el aumento de los feminicidios y transfeminicidios, las
                                desapariciones con fines de trata, el aumento del desempleo y el
                                aumento de la pobreza, asuntos de estado que hoy nos conmina a
                                analizar los avances y los retos de la instrumentación de la
                                ley.
                            </p>
                            <br />
                            <p class="font-weight-bold">
                                Objetivo General: Realizar un conversatorio para identificar los
                                avances y retos de la instrumentación de la LGAMVLV, haciendo un
                                análisis de las recomendaciones de los organismos de derechos
                                humanos de las mujeres para la igualdad entre hombres y mujeres
                                y una vida libre de violencia.
                            </p>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F1064319397588912%2F&show_text=false&width=560&t=0"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>
                                                Análisis de Avances y Retos en la Instrumentación de la
                                                Ley General de Acceso de las Mujeres
                                            </p>
                                            <p style="font-size: small;">Inmujeres México</p>
                                        </div>
                                        <!--div class="col-sm-6 my-5 text-center"-->
                                        <!--iframe
                        src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F3558769294217513%2F&show_text=false"
                        style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                        "
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe-->
                                        <!--/div-->
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <!--div
                  class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                  data-toggle="modal"
                  data-target="#lightbox"
                >
                  <div class="col-md-12" *ngIf="paginaActiva == 1">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PHOTO-2020-11-10-12-24-20.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/1.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-0"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 2">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/2.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/3.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 3">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/4.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    class="row justify-content-center"
                    style="justify-content: center"
                  >
                    <nav
                      aria-label="..."
                      class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                      style="align-items: center"
                    >
                      <ul
                        class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                        style="align-content: center"
                        id="pills-tab"
                      >
                        <li class="nav-item active text-center">
                          <a
                            class="nav-link"
                            id="1"
                            (click)="tooglePagi($event)"
                            aria-selected="true"
                          >
                            <br />
                            1
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="2"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            2
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="3"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            3
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div-->
                            </div>

                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/documentos/PROGRAMA_III_Consersatorio.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/documentos/PROGRAMA_III_Consersatorio.pdf"
                                            target="_blank">
                                            PROGRAMA III Consersatorio C.C
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/documentos/Objetivo_Conversatorio_III.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #288a5e"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/documentos/Objetivo_Conversatorio_III.pdf"
                                            target="_blank">
                                            Objetivo del Conversatorio III
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/publicaciones/PRESENTACION LIC_ANABEL LOPEZ PPT AVANCES Y RETOS LGAMVLV.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/publicaciones/PRESENTACION LIC_ANABEL LOPEZ PPT AVANCES Y RETOS LGAMVLV.pdf"
                                            target="_blank">
                                            PRESENTACIÓN LIC. ANABEL LÓPEZ PPT avances y retos LGAMVLV
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/publicaciones/PRESENTACION MTRA_IMELDA MARRUFO NAVA CONVERSATORIO LGAMVLV.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/publicaciones/PRESENTACION MTRA_IMELDA MARRUFO NAVA CONVERSATORIO LGAMVLV.pdf"
                                            target="_blank">
                                            PRESENTACIÓN MTRA. IMELDA M, Consersatorio LGAMVLV
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/publicaciones/PRESENTACION MTRA_JULIA ESCLANTE Avances legislativos feminicidio transfeminicidio.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio iii/publicaciones/PRESENTACION MTRA_JULIA ESCLANTE Avances legislativos feminicidio transfeminicidio.pdf"
                                            target="_blank">
                                            PRESENTACIÓN MTRA. JULIA E., Feminicidio Transfeminicidio
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- tema 4 Conversatorio ii-->
                    <div class="tab-pane" *ngIf="temaCuatro">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <!--div style="width: 100%; height: 400px; overflow: hidden"-->
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio ii/slider/EventoDerechosMujeresIndIgenas_TW.png"
                                                class="d-block w-100" alt="..." />
                                            <!--/div-->
                                        </div>
                                        <!--div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/1.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>

                    <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/2.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/3.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/4.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div-->
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                Conversatorio II. Consejo Consultivo. Derechos de las Mujeres
                                Indígenas y Rurales
                            </h2>
                            <h3>INMUJERES y Consejo Consultivo del INMUJERES</h3>
                            <p class="font-weight-bold">
                                Objetivo: Reflexionar sobre las condiciones actuales en las que
                                se encuentran las mujeres Indígenas y Rurales, los avances y los
                                retos inmediatos para el logro de la autonomía económica,
                                posesión de tierra y territorio y acceso a derechos políticos,
                                factores indispensables para garantizar el ejercicio de sus
                                derechos humanos.
                            </p>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F480499500809105%2F&show_text=false&width=560&t=0"
                                                width="560" height="314" style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>
                                                Conversatorio II. Derechos de las Mujeres Indígenas y
                                                Rurales
                                            </p>
                                            <p style="font-size: small;">
                                                Inmujeres México
                                            </p>
                                        </div>
                                        <!--div class="col-sm-6 my-5 text-center">
                      <iframe
                        src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F3558769294217513%2F&show_text=false"
                        style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                        "
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe>
                    </div-->
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <!--div
                  class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                  data-toggle="modal"
                  data-target="#lightbox"
                >
                  <div class="col-md-12" *ngIf="paginaActiva == 1">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/PHOTO-2020-11-10-12-24-20.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/1.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-0"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 2">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/2.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/3.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 3">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/4.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    class="row justify-content-center"
                    style="justify-content: center"
                  >
                    <nav
                      aria-label="..."
                      class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                      style="align-items: center"
                    >
                      <ul
                        class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                        style="align-content: center"
                        id="pills-tab"
                      >
                        <li class="nav-item active text-center">
                          <a
                            class="nav-link"
                            id="1"
                            (click)="tooglePagi($event)"
                            aria-selected="true"
                          >
                            <br />
                            1
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="2"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            2
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="3"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            3
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div-->
                            </div>

                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio ii/documentos/Programa  II Conversatorio Derechos.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio ii/documentos/Programa  II Conversatorio Derechos.pdf"
                                            target="_blank">
                                            Programa II Conversatorio Derechos de las Mujeres
                                            Indígenas y Rurales
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio ii/publicaciones/Propiedad intelectual colectiva.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio ii/publicaciones/Propiedad intelectual colectiva.pdf"
                                            target="_blank">
                                            Irma Pineda. Propiedad intelectual colectiva UNPFII2022
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio ii/publicaciones/Lideresas Comunitarias.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #1ba1db"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio ii/publicaciones/Lideresas Comunitarias.pdf"
                                            target="_blank">
                                            Kyra Núñez de León. Lideresas Comunitarias
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- tema 5-->
                    <div class="tab-pane" *ngIf="temaCinco">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <!--div style="width: 100%; height: 400px; overflow: hidden"-->
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/slider/InvitacionMujeresPeriodistas.png"
                                                class="d-block w-100" alt="..." />
                                            <!--/div-->
                                        </div>
                                        <!--div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/1.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>

                    <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/2.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/3.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        src="assets/Alianzas/CONSULTIVO/DIÁLOGO NACIONAL/FOTOS/4.PNG"
                        class="d-block w-100"
                        alt="..."
                      />
                    </div-->
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                Conversatorio I. Violencia contra mujeres periodistas en México
                            </h2>
                            <h3>INMUJERES y Consejo Consultivo del INMUJERES</h3>
                            <p class="font-weight-bold">
                                Objetivo: Discutir las causas de la violencia contra las mujeres
                                en los medios de comunicación, incluyendo feminicidios de
                                mujeres periodistas, e identificar mecanismos y medidas de
                                prevención, atención y erradicación, así como acceso a la
                                justicia para las víctimas.
                            </p>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://dtic-my.sharepoint.com/personal/mesadeayuda_inmujeres_gob_mx/_layouts/15/stream.aspx?id=%2Fpersonal%2Fmesadeayuda%5Finmujeres%5Fgob%5Fmx%2FDocuments%2FDatos%20adjuntos%2Ftelmex%5F0%28218%29%2Emp4&ga=1"
                                                width="560" height="400" style="
                          border:none;
                          overflow:hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Violencia contra mujeres periodistas en México</p>
                                        </div>
                                        <!--div class="col-sm-6 my-5 text-center">
                      <iframe
                        src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F3558769294217513%2F&show_text=false"
                        style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                        "
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe>
                    </div-->
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM1.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM2.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-0"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 2">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM3.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM4.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM5.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM6.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 4">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM7.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM8.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 5">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM9.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/CONSULTIVO/Conversatorio i/Mujeres periodistas/VM10.png"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="2" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        2
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="3" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        3
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="4" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        4
                                                    </a>
                                                </li>

                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="5" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        5
                                                    </a>
                                                </li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio i/documentos/conversatorio i Violencia contra mujeres .pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio i/documentos/conversatorio i Violencia contra mujeres .pdf"
                                            target="_blank">
                                            Programa Coversatorio Violencia contra mujeres periodistas
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio i/documentos/Conversatorio sobre violencia contra mujeres periodistas.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #764ddf"></span> </a><br />
                                        <a href="../../../assets/Alianzas/CONSULTIVO/Conversatorio i/documentos/Conversatorio sobre violencia contra mujeres periodistas.pdf"
                                            target="_blank">
                                            Programa Coversatorio Violencia contra mujeres periodistas
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <!--div
                  class="row d-flex flex-wrap align-items-center"
                  data-toggle="modal"
                  data-target="#lightbox"
                >
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/Conversatorio i/publicaciones/"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/CONSULTIVO/Conversatorio i/publicaciones/"
                      target="_blank"
                    >
                      Marta Ferreyra Presentación
                    </a>
                  </div>
                </div-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <!--Segundo apartado Foro consejo social-->

            <div class="container" *ngIf="this.activeFiles['1'].tab" id="pills-segundo">
                <div class="container" data-toggle="modal" data-target="#lightbox">
                    <div class="container-fluid text-center" style="background-color: #a0bf38; color: white">
                        <h3>Foro consejo social</h3>
                    </div>
                    <br />
                    <div class="row justify-content-center">
                        <div class="d-grid gap-2 col-12 mx-auto">
                            <button id="alianzas" type="button" class="btn btn-primary dropdown-toggle"
                                data-toggle="dropdown" aria-expanded="false">
                                Temas
                            </button>
                            <ul class="dropdown-menu" role="menu" aria-labelledby="alianzas">
                                <!-- <li>
                                    <a class="dropdown-item" data-toggle="dropdown" id="cincoTema" aria-selected="true"
                                        (click)="toogleTemas($event)">Hostigamiento Sexual y Acoso Sexual en las
                                        Instituciones de Educación Superior (2023)</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" data-toggle="dropdown" id="seisTema" aria-selected="true"
                                        (click)="toogleTemas($event)">Foro Nacional de Acceso a la Justicia para las
                                        Mujeres (2023)</a>
                                </li> -->

                                <li  *ngFor="let publicacion of socialPublicacion; let indice = index;" [ngClass]="{'col-lg-12': indice == 0, 'col-lg-12': indice != 0}">
                                    <a class="dropdown-item" routerLink="/publicacion-alianza/{{publicacion.id}}">
                                      {{publicacion.titulo}}{{(mesAnno(publicacion.fechaPublicacion))}}</a
                                    >
                                </li>

                                <!-- <hr class="dropdown-divider">
                                <ol for="id-history">
                                    <p class="hp col-lg-12 nav-item">Historico</p>
                                </ol>
                                <ul id="id-history">
                                    <li>
                                        <a class="dropdown-item" data-toggle="dropdown" id="cuatroTema"
                                            (click)="toogleTemas($event)">Matrimonios forzados. Tlapa de Comonfort
                                            Guerrero (2022)</a>
                                    </li>
                                    <li>
                                        ><a class="dropdown-item" data-toggle="dropdown" id="unoTema"
                                            (click)="toogleTemas($event)">Hacia una realidad sobre el Sistema Nacional
                                            de Cuidados.
                                            Durango (2022)</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-toggle="dropdown" id="dosTema"
                                            (click)="toogleTemas($event)">Territorios en clave feminista: Ciudades,
                                            comunidades y
                                            entornos seguros y saludables para todas. Nayarit (2022)</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-toggle="dropdown" id="tresTema"
                                            (click)="toogleTemas($event)">Violencia contra las mujeres en México. “Paz y
                                            justicia:
                                            hasta que la vida digna para las mujeres se haga costumbre”.
                                            Sinaloa(2022)</a>
                                    </li>
                                </ul> -->
                            </ul>
                        </div>
                    </div>
                    <br />

                    <!-- INICIO DE TEMA UNO  -->
                    <div class="tab-pane" *ngIf="temaUno">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img class="d-block w-100"
                                                src="assets/Alianzas/SOCIAL/FORO DURANGO/Foro Durango/Foro Sistema de Cuidados-01.jpg"
                                                alt="..." />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                Foro: Hacia una realidad sobre el Sistema Nacional de Cuidados
                            </h2>
                            <h3>Consejo Social del Instituto Nacional de las Mujeres</h3>
                            <p class="font-weight-bold">
                                El Consejo Social como Órgano Auxiliar del Instituto Nacional de
                                las Mujeres INM, organizó en junio de 2019, un primer Foro para
                                coadyuvar al diálogo entre autoridades, la academia y las
                                organizaciones pro derecho al cuidado para conocer las
                                diferentes experiencias y hacer propuestas para lo que sería la
                                Estrategia Nacional de Cuidados.
                            </p>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <!-- <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" (click)="tooglePagi($event)" aria-selected="true">-->
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <a href="https://www.facebook.com/CEDHDgo/videos/1804229993121016/"
                                                target="_blank">
                                                <img src="/assets/Alianzas/SOCIAL/FORO DURANGO/Foro Durango/video.png"
                                                    style="border:none;
                          overflow:hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                          " alt="..." />
                                            </a>
                                            <p>Foro: Avanzando hacia una realidad sobre el Sistema Nacional de Cuidados
                                            </p>
                                            <p style="font-size: small;">Comisión Estatal de Derechos Humanos de
                                                Durango.</p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <!-- se modifico-->
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO DURANGO/Foro Durango/Foro Sistema de Cuidados-01.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-start">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Foro Durango/INFORME. FORO DURANGO .pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Foro Durango/INFORME. FORO DURANGO .pdf"
                                            target="_blank">
                                            Informe Foro Durango
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/Malú Micher. Estatus y Legislación del Sistema Nacional de Cuidados.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/Malú Micher. Estatus y Legislación del Sistema Nacional de Cuidados.pdf"
                                            target="_blank">
                                            Malú Micher. Estatus y Legislación del Sistema Nacional de
                                            Cuidados
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/Marta Clara Ferreyra.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/Marta Clara Ferreyra.pdf"
                                            target="_blank">
                                            Marta Clara Ferreyra
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/María Dolores Romero Cota.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/María Dolores Romero Cota.pdf"
                                            target="_blank">
                                            María Dolores Romero Cota
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/PRESENTACION DIPUTADA YOLANDA DE LA TORRE (2) (3) (1).pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/PRESENTACION DIPUTADA YOLANDA DE LA TORRE (2) (3) (1).pdf"
                                            target="_blank">
                                            PRESENTACION DIPUTADA YOLANDA DE LA TORRE
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- FIN DE TEMA UNO  -->


                    <!-- tema dos-->
                    <div class="tab-pane" *ngIf="temaDos">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                                        <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/00-Rueda de prensa/1.jpg"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/00-Rueda de prensa/2.jpg"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/00-Rueda de prensa/3.jpg"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/00-Rueda de prensa/4.jpg"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/5.jpg"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/6.jpg"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/7.jpg"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/8.jpeg"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                Territorios en clave feminista: Ciudades, comunidades y entornos
                                seguros y saludables para todas
                            </h2>
                            <h3>Consejo Social del Instituto Nacional de las Mujeres</h3>
                            <p class="font-weight-bold">
                                Objetivo: Aportar al diálogo para la reconstrucción de entornos
                                urbanos y rurales seguros y saludables para las mujeres, niñas y
                                adolescentes; identificando las problemáticas de inseguridad y
                                violencias en las comunidades, colonias, barrios, casas y
                                espacios en los que gestionan su vida, lo que les genera
                                restricciones en la autonomía, bienestar y vulneran sus derechos
                                a la integridad, seguridad, paz, libertad y movilidad.
                            </p>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F323527939465441%2F&show_text=false"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Geografías de Paz</p>
                                        </div>
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F832619960995043%2F&show_text=false"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Territorios en clave feminista</p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/00-Rueda de prensa/1.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/00-Rueda de prensa/2.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="1" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 2">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/00-Rueda de prensa/3.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="3" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/00-Rueda de prensa/4.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="4" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/5.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="6" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/6.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="7" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 4">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/7.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="9" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/8.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="10" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 5">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/9.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="12" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/10.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="13" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 6">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/11.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="15" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/12.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="16" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 7">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/13.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="18" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/14.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="19" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 8">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 1/15.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 1/16.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 9">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 1/17.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 1/18.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 10">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 2/19.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 2/20.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="2" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        2
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="3" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        3
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="4" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        4
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="5" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        5
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="6" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        6
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="7" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        7
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="8" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        8
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="9" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        9
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="10" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        10
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/NOTA-CONCEPTUAL/NOTA CONCEPTUAL FORO NAYARIT.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/NOTA-CONCEPTUAL/NOTA CONCEPTUAL FORO NAYARIT.pdf"
                                            target="_blank">
                                            NOTA CONCEPTUAL FORO NAYARIT
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/INFORME/INFORME. Foro Nayarit.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/INFORME/INFORME. Foro Nayarit.pdf"
                                            target="_blank">
                                            Informe Foro Nayarit
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PROGRAMA/PROGRAMA. FORO NAYARIT.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PROGRAMA/PROGRAMA. FORO NAYARIT.pdf"
                                            target="_blank">
                                            PROGRAMA. FORO NAYARIT
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PRESENTACIONES/EUGENIA FLORES. RED DE MUJERES TEJIENDO....pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PRESENTACIONES/EUGENIA FLORES. RED DE MUJERES TEJIENDO....pdf"
                                            target="_blank">
                                            EUGENIA FLORES.RED DE MUJERES TEJIENDO
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PRESENTACIONES/Oscar Gasca_INEGI_08.07.2021.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PRESENTACIONES/Oscar Gasca_INEGI_08.07.2021.pdf"
                                            target="_blank">
                                            Oscar Gasca_INEGI_08.07.2021
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PRESENTACIONES/Presentación ALBA_CONAVIM.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #db8f1b"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PRESENTACIONES/Presentación ALBA_CONAVIM.pdf"
                                            target="_blank">
                                            Presentación ALBA_CONAVIM
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--tema tres-->
                    <div class="tab-pane" *ngIf="temaTres">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img src="assets/Alianzas/SOCIAL/FORO SINALOA/Fotos/21.jpg"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                Foro: Violencia contra las mujeres en México. “Paz y justicia:
                                hasta que la vida digna para las mujeres se haga costumbre”
                            </h2>
                            <h3>Consejo Social del Instituto Nacional de las Mujeres</h3>
                            <p class="font-weight-bold">
                                Objetivo: Impulsar el dialogo entre las organizaciones de la
                                sociedad civil, academia y gobierno para generar propuestas y
                                avanzar en el abatimiento de los tipos y modalidades de
                                violencia contra las mujeres, niñas y adolescentes, preservando
                                su dignidad e integridad, que coadyuve en el fortalecimiento del
                                marco institucional para garantizar el derecho a una vida libre
                                de violencia.
                            </p>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1"></div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab"></ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO SINALOA/Fotos/21.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="21" alt="" />
                                        </div>
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO SINALOA/PROGRAMA/PROGRAMA FORO SINALOA 08_11_21 .pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO SINALOA/PROGRAMA/PROGRAMA FORO SINALOA 08_11_21 .pdf"
                                            target="_blank">
                                            Programa Foro Sinaloa 08 11 21
                                        </a>
                                    </div>
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO SINALOA/RESEÑA/Foro de Culiacán Reseña.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO SINALOA/RESEÑA/Foro de Culiacán Reseña.pdf"
                                            target="_blank">
                                            Foro de Culiacán Reseña
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox"></div>
                            </div>
                        </div>
                    </div>
                    <!--tema cuatro-->
                    <div class="tab-pane" *ngIf="temaCuatro">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img src="assets/Alianzas/SOCIAL/FORO GUERRERO/SLIDER/FLYER.PNG"
                                                class="d-block w-100" alt="..." />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                Foro: Diálogo con Mujeres IndígenasPerspectivas, avances y retos
                                para la erradicación de la práctica de uniones tempranas
                                forzadas. Tlapa Guerrero
                            </h2>
                            <h3>Consejo Social del Instituto Nacional de las Mujeres</h3>
                            <p class="font-weight-bold">
                                Objetivo: Reflexionar sobre lascondiciones actuales enlas que se
                                encuentranlas mujeres Indígenas y Rurales, los avances y los
                                retosinmediatospara su autonomía económica,posesión de tierra y
                                territorio,y acceso a derechos políticose intelectuales,
                                factores indispensables para el logro de la igualdad en México.
                            </p>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F480499500809105%2F&show_text=false&width=560&t=0"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Derechos de las Mujeres Indígenas y Rurales</p>
                                        </div>
                                        <!--div class="col-sm-6 my-5 text-center">
                      <iframe
                        src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F832619960995043%2F&show_text=false"
                        style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                        "
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe>
                    </div -->
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO GUERRERO/FOTOS/G1.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO GUERRERO/FOTOS/G2.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="1" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 2">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO GUERRERO/FOTOS/G3.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="3" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO GUERRERO/FOTOS/G4.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="4" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO GUERRERO/FOTOS/G5.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="6" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO GUERRERO/FOTOS/G6.jpeg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="7" alt="" />
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12" *ngIf="paginaActiva == 4">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/7.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="9"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/8.jpeg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="10"
                        alt=""
                      />
                    </div>
                  </div> -->
                                    <!-- <div class="col-md-12" *ngIf="paginaActiva == 5">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/01-Inauguracion/9.jpeg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="12"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/10.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="13"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12" *ngIf="paginaActiva == 6">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/11.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="15"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/12.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="16"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12" *ngIf="paginaActiva == 7">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/13.jpeg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="18"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Conferencias/14.jpeg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="19"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 8">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 1/15.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 1/16.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12" *ngIf="paginaActiva == 9">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 1/17.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 1/18.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 10">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 2/19.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO NAYARIT/VARIOS/Panel 2/20.jpg"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div> -->

                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="2" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        2
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="3" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        3
                                                    </a>
                                                </li>
                                                <!-- <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="4"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            4
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="5"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            5
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="6"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            6
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="7"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            7
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="8"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            8
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="9"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            9
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="10"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            10
                          </a>
                        </li> -->
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO GUERRERO/DOCUMENTOS/Programa de Mano Tlapa_ 20_07 F.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO GUERRERO/DOCUMENTOS/Programa de Mano Tlapa_ 20_07 F.pdf"
                                            target="_blank">
                                            Programa Conversatorio Virtual II. Derechos de las Mujeres
                                            Indígenas y Rurales
                                        </a>
                                    </div>

                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO GUERRERO/DOCUMENTOS/Foro Tlapa 22 julio, Anexo Recomendaciones.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #1868c4"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO GUERRERO/DOCUMENTOS/Foro Tlapa 22 julio, Anexo Recomendaciones.pdf"
                                            target="_blank">
                                            Anexo Recomendaciones
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox">
                                    <!--div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PRESENTACIONES/EUGENIA FLORES. RED DE MUJERES TEJIENDO....pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO NAYARIT/PRESENTACIONES/EUGENIA FLORES. RED DE MUJERES TEJIENDO....pdf"
                      target="_blank"
                    >
                      EUGENIA FLORES.RED DE MUJERES TEJIENDO
                    </a>
                  </div-->
                                </div>
                            </div>
                        </div>
                        <!--FIN CIRCLE-->
                    </div>
                    <!--fin tema cuatro-->

                    <!-- INICIO DE TEMA UNO  -->
                    <div class="tab-pane" *ngIf="temaCinco">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img class="d-block w-100"
                                                src="assets/Alianzas/SOCIAL/FORO TABASCO/FLYER/F2.png" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img class="d-block w-100"
                                                src="assets/Alianzas/SOCIAL/FORO TABASCO/FLYER/F1.png" alt="..." />
                                        </div>
                                        <div class="carousel-item">
                                            <img class="d-block w-100"
                                                src="assets/Alianzas/SOCIAL/FORO TABASCO/FLYER/F3.png" alt="..." />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                Hostigamiento Sexual y Acoso Sexual en las Instituciones de Educación Superior. Acceso a
                                la Justicia
                                Buenas Prácticas y Modelos de Atención
                            </h2>
                            <h3>Consejo Social del Instituto Nacional de las Mujeres</h3>
                            <p class="font-weight-bold">
                                Objetivos
                            </p>
                            <ul>
                                <li>
                                    Socializar las acciones y resultados de las autoridades jurisdiccionales y no
                                    jurisdiccionales, tendiente a facilitar el acceso a la justicia en las conductas o
                                    actos de acoso sexual y hostigamiento sexual.
                                </li>
                                <li>
                                    Identificar buenas prácticas que nos sirvan para prevenir conductas de hostigamiento
                                    sexual y acoso sexual en el
                                    ámbito académico y promover una cultura institucional de igualdad de género y un
                                    clima laboral libre de violencia
                                </li>
                                <li>
                                    Definir mecanismos para orientar y, en su caso, brindar acompañamiento
                                    especializado, ante las autoridades
                                    competentes a la presunta víctima de hostigamiento sexual y acoso sexual, a fin de
                                    garantizar la no revictimización y el acceso a la justicia.
                                </li>
                                <li>
                                    Contribuir a la erradicación de la impunidad que propicia la ocurrencia del
                                    hostigamiento sexual y acoso sexual
                                </li>
                            </ul>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <!-- <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" (click)="tooglePagi($event)" aria-selected="true">-->
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <!-- <div class="col-sm-6 my-5 text-center">
                      <iframe
                        src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F3376681199047179%2F&show_text=false"
                        style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        "
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe>
                      <p>Diálogo Nacional INMujeres 1</p>
                      <p style="font-size: small;">Inmujeres México</p>
                    </div> -->
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F124995577197910%2F&show_text=false&width=560&t=0"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Foro “Acoso Sexual y Hostigamiento Sexual en las Instituciones de
                                                Educación Superior, acciones para el acceso a la justicia: buenas
                                                prácticas y modelos de atención”</p>
                                            <p style="font-size: small;">Inmujeres México</p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <!-- se modifico-->
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H2.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H5.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 2">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H6.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H7.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H8.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H9.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 4">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H10.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H11.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 5">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H12.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H13.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 6">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H24.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H25.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 7">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H16.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H17.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 8">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H18.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H19.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 9">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H20.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H21.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 10">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H22.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H23.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="2" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        2
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="3" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        3
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="4" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        4
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="5" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        5
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="6" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        6
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="7" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        7
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="8" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        8
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="9" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        9
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="10" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        10
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-start">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/Inmujeres_comunicadoASTabasco.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/Inmujeres_comunicadoASTabasco.pdf"
                                            target="_blank">
                                            Inmujeres comunicado Tabasco
                                        </a>
                                    </div>

                                    <div class="col-sm-3 my-5 text-start">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/Tabasco-15-03-23.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #2f6bb0"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/Tabasco-15-03-23.pdf"
                                            target="_blank">
                                            Programa final Foro Tabasco
                                        </a>
                                    </div>

                                    <div class="col-sm-3 my-5 text-start">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/TALLER-150323VF.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #1757a0"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/TALLER-150323VF.pdf"
                                            target="_blank">
                                            Programa Taller Tabasco
                                        </a>
                                    </div>

                                    <div class="col-sm-3 my-5 text-start">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/UJAT_INMUJERES.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #0057bb"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/UJAT_INMUJERES.pdf"
                                            target="_blank">
                                            RELATORIA DEL FORO UJAT INMUJERES
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-center">
                                        <a href="assets/Alianzas/SOCIAL/FORO TABASCO/PRESENTACIONES/Tabasco_2023_MMCC_vf.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #ab6c0d"></span> </a><br />
                                        <a href="assets/Alianzas/SOCIAL/FORO TABASCO/PRESENTACIONES/Tabasco_2023_MMCC_vf.pdf"
                                            target="_blank">
                                            Directrices Tabasco 2023
                                        </a>
                                    </div>
                                    <!-- <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/Marta Clara Ferreyra.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/Marta Clara Ferreyra.pdf"
                      target="_blank"
                    >
                      Marta Clara Ferreyra
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/María Dolores Romero Cota.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/María Dolores Romero Cota.pdf"
                      target="_blank"
                    >
                      María Dolores Romero Cota
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/PRESENTACION DIPUTADA YOLANDA DE LA TORRE (2) (3) (1).pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/PRESENTACION DIPUTADA YOLANDA DE LA TORRE (2) (3) (1).pdf"
                      target="_blank"
                    >
                      PRESENTACION DIPUTADA YOLANDA DE LA TORRE
                    </a>
                  </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- FIN TEMA UNO  -->


                    <!-- INICIO DE TEMA DOS  -->
                    <div class="tab-pane" *ngIf="temaSeis">
                        <div class="row">
                            <div class="container-fluid">
                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active">
                                        </li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <img class="d-block w-100"
                                                src="assets/Alianzas/SOCIAL/FORO PUEBLA/FLYER/Back-Digital.png"
                                                alt="..." />
                                        </div>
                                        <!-- <div class="carousel-item">
                      <img
                        class="d-block w-100"
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FLYER/F1.png"
                        alt="..."
                      />
                    </div>
                    <div class="carousel-item">
                      <img
                        class="d-block w-100"
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FLYER/F3.png"
                        alt="..."
                      />
                    </div> -->
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                                        data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                                        data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container" style="text-align: justify">
                            <h2>
                                Foro Nacional de Acceso a la Justicia para las Mujeres
                            </h2>
                            <h3>Consejo Social del Instituto Nacional de las Mujeres</h3>
                            <p class="font-weight-bold">
                                Objetivos
                            </p>
                            <ul>
                                <li>
                                    Socializar las buenas prácticas en los Tribunales Superiores de Justicia Estatales
                                    que puedan ser replicados en donde no existan
                                </li>
                                <li>
                                    Generar un espacio de reflexión y diálogo feminista en torno a las propuestas más
                                    innovadoras y propositivas para buscar el acceso a la justicia.
                                </li>
                            </ul>
                        </div>
                        <br />

                        <!--Circles-->
                        <div class="circles circles-pills mt-5">
                            <div class="col-md-3">
                                <!-- <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" (click)="tooglePagi($event)" aria-selected="true">-->
                                <a class="circles ml-n5" id="vid" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle purple mx-auto p-3">
                                            <span class="glyphicon glyphicon-facetime-video p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-purple p-2">
                                            <p>VIDEOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="img" (click)="toogleDocs($event)" aria-selected="true">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle orange mx-auto p-3">
                                            <span class="glyphicon glyphicon-picture p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-orange font-weight-bold p-2">IMAGENES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="doc" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle green mx-auto p-3">
                                            <span class="glyphicon glyphicon-folder-open p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center">
                                            <p class="text-green font-weight-bold p-2">DOCUMENTOS</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-md-3">
                                <a class="circles ml-n5" id="pre" (click)="toogleDocs($event)">
                                    <div class="d-flex flex-column">
                                        <div class="rounded-circle blue mx-auto p-3">
                                            <span class="glyphicon glyphicon-file p-3" aria-hidden="true"
                                                style="font-size: 55px; color: #ffffff"></span>
                                        </div>
                                        <div class="text-center font-weight-bold text-blue p-2">
                                            <p>PRESENTACIONES</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- fin circles -->
                        <div class="col-md-12">
                            <div class="col-md-12" *ngIf="showVideo">
                                <h3 class="text-left">Videos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FIgualdadGobPue%2Fvideos%2F171519759187098%2F&show_text=false&width=560&t=0"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Foro Nacional de Acceso a la Justicia para las Mujeres</p>
                                            <p style="font-size: small;">Secretaría de Igualdad Sustantiva</p>
                                        </div>
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F240167988757572%2F&show_text=false&width=560&t=0"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Foro Nacional de Acceso a la Justicia para las Mujeres</p>
                                            <p style="font-size: small;">Inmujeres México</p>
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 2">
                                        <div class="col-sm-6 my-5 text-center">
                                            <iframe
                                                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F255539577054933%2F&show_text=false&width=560&t=0"
                                                style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        " scrolling="no" frameborder="0" allowfullscreen="true"
                                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                                allowFullScreen="true"></iframe>
                                            <p>Foro Nacional de Acceso a la Justicia para las Mujeres</p>
                                            <p style="font-size: small;">Inmujeres México</p>
                                        </div>
                                        <!-- <div class="col-sm-6 my-5 text-center">
                      <iframe
                      src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FInmujeresMx%2Fvideos%2F240167988757572%2F&show_text=false&width=560&t=0"
                        style="
                          border: none;
                          overflow: hidden;
                          width: 100%;
                          height: 270px;
                          margin: 1vw; 
                          border-radius: 15px; 
                          box-shadow: 0 15px 19px 0 rgba(0, 0, 0, 0.479), 0 20px 53px 0 rgba(0,0,0,0.19);
                        "
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe>
                      <p>Foro Nacional de Acceso a la Justicia para las Mujeres</p>
                      <p style="font-size: small;">Inmujeres México</p>
                    </div> -->
                                    </div>

                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="2" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        2
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" *ngIf="showImg">
                                <h3 class="text-left">Imagenes</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <br />
                                <div class="row d-flex flex-wrap align-items-center align-content-center justify-content-center"
                                    data-toggle="modal" data-target="#lightbox">
                                    <!-- se modifico-->
                                    <div class="col-md-12" *ngIf="paginaActiva == 1">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO PUEBLA/FOTOS/J1.JPG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO PUEBLA/FOTOS/J2.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 2">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO PUEBLA/FOTOS/J3.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO PUEBLA/FOTOS/J4.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 3">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO PUEBLA/FOTOS/J5.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO PUEBLA/FOTOS/J6.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 4">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO PUEBLA/FOTOS/J7.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO PUEBLA/FOTOS/J8.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="paginaActiva == 5">
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO PUEBLA/FOTOS/J9.jpg"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <img src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H10.PNG"
                                                class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                                                data-target="#indicators" data-slide-to="0" alt="" />
                                        </div>
                                    </div>

                                    <!-- <div class="col-md-12" *ngIf="paginaActiva == 6">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H24.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H25.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 7">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H16.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H17.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 8">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H18.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H19.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 9">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H20.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H21.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="col-md-12" *ngIf="paginaActiva == 10">
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H22.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                    <div class="col-md-6">
                      <img
                        src="assets/Alianzas/SOCIAL/FORO TABASCO/FOTOS/H23.PNG"
                        class="img img-thumbnail m-4 w-100 h-100 ng-tns-c123-2"
                        data-target="#indicators"
                        data-slide-to="0"
                        alt=""
                      />
                    </div>
                  </div> -->

                                    <div class="row justify-content-center" style="justify-content: center">
                                        <nav aria-label="..."
                                            class="align-items-center align-content-center col-12 col-md-12 col-lg-12"
                                            style="align-items: center">
                                            <ul class="pagination pagination-lg align-items-center align-content-center justify-content-center"
                                                style="align-content: center" id="pills-tab">
                                                <li class="nav-item active text-center">
                                                    <a class="nav-link" id="1" (click)="tooglePagi($event)"
                                                        aria-selected="true">
                                                        <br />
                                                        1
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="2" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        2
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="3" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        3
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="4" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        4
                                                    </a>
                                                </li>
                                                <li class="nav-item text-center">
                                                    <a class="nav-link" id="5" (click)="tooglePagi($event)"
                                                        aria-selected="false">
                                                        <br />
                                                        5
                                                    </a>
                                                </li>
                                                <!-- <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="6"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            6
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="7"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            7
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="8"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            8
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="9"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            9
                          </a>
                        </li>
                        <li class="nav-item text-center">
                          <a
                            class="nav-link"
                            id="10"
                            (click)="tooglePagi($event)"
                            aria-selected="false"
                          >
                            <br />
                            10
                          </a>
                        </li> -->
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showDoc">
                                <h3 class="text-left">Documentos</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-start" data-toggle="modal"
                                    data-target="#lightbox">
                                    <div class="col-sm-3 my-5 text-start">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO PUEBLA/DOCUMENTOS/Ficha Técnica Foro Puebla STJG 18_05 con ajustes .pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #4d92df"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO PUEBLA/DOCUMENTOS/Ficha Técnica Foro Puebla STJG 18_05 con ajustes .pdf"
                                            target="_blank">
                                            Ficha Técnica Foro Puebla
                                        </a>
                                    </div>

                                    <div class="col-sm-3 my-5 text-start">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO PUEBLA/DOCUMENTOS/Inmujeres comunicado.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #2f6bb0"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO PUEBLA/DOCUMENTOS/Inmujeres comunicado.pdf"
                                            target="_blank">
                                            Inmujeres comunicado
                                        </a>
                                    </div>

                                    <div class="col-sm-3 my-5 text-start">
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO PUEBLA/DOCUMENTOS/Programa con imágen 23_05_23_1120.pdf"
                                            target="_blank">
                                            <span class="glyphicon glyphicon-book p-3" aria-hidden="true"
                                                style="font-size: 140px; color: #1757a0"></span> </a><br />
                                        <a href="../../../assets/Alianzas/SOCIAL/FORO PUEBLA/DOCUMENTOS/Programa con imágen 23_05_23_1120.pdf"
                                            target="_blank">
                                            Programa Taller Puebla
                                        </a>
                                    </div>

                                    <!-- <div class="col-sm-3 my-5 text-start">
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/UJAT_INMUJERES.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #0057bb"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO TABASCO/DOCUMENTOS/UJAT_INMUJERES.pdf"
                      target="_blank"
                    >
                    RELATORIA DEL FORO UJAT INMUJERES
                    </a>
                  </div> -->

                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="showPre">
                                <h3 class="text-left">Presentaciones</h3>
                                <hr class="red" style="
                    width: 35px;
                    height: 5px;
                    background-color: #b38e5d;
                    position: absolute;
                  " />
                                <div class="row d-flex flex-wrap align-items-center" data-toggle="modal"
                                    data-target="#lightbox">
                                    <!-- <div class="col-sm-3 my-5 text-center">
                    <a
                      href="assets/Alianzas/SOCIAL/FORO TABASCO/PRESENTACIONES/Tabasco_2023_MMCC_vf.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #ab6c0d"
                      ></span> </a
                    ><br />
                    <a
                      href="assets/Alianzas/SOCIAL/FORO TABASCO/PRESENTACIONES/Tabasco_2023_MMCC_vf.pdf"
                      target="_blank"
                    >
                        Directrices Tabasco 2023
                    </a>
                  </div> -->
                                    <!-- <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/Marta Clara Ferreyra.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/Marta Clara Ferreyra.pdf"
                      target="_blank"
                    >
                      Marta Clara Ferreyra
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/María Dolores Romero Cota.pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/María Dolores Romero Cota.pdf"
                      target="_blank"
                    >
                      María Dolores Romero Cota
                    </a>
                  </div>
                  <div class="col-sm-3 my-5 text-center">
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/PRESENTACION DIPUTADA YOLANDA DE LA TORRE (2) (3) (1).pdf"
                      target="_blank"
                    >
                      <span
                        class="glyphicon glyphicon-book p-3"
                        aria-hidden="true"
                        style="font-size: 140px; color: #db8f1b"
                      ></span> </a
                    ><br />
                    <a
                      href="../../../assets/Alianzas/SOCIAL/FORO DURANGO/Presentaciones/PRESENTACION DIPUTADA YOLANDA DE LA TORRE (2) (3) (1).pdf"
                      target="_blank"
                    >
                      PRESENTACION DIPUTADA YOLANDA DE LA TORRE
                    </a>
                  </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- FIN DE TEMA DOS  -->
                </div>
            </div>
            <br />
        </div>
    </div>
</div>
<div class="col-md-12"></div>