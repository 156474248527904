import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Role } from 'src/app/models/role';
import { Usuario } from 'src/app/models/usuario';
import { RolesService } from 'src/app/service/roles.service';
import { UsuariosService } from 'src/app/service/usuarios.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-form-usuarios',
  templateUrl: './form-usuarios.component.html',
  styleUrls: ['./form-usuarios.component.css']
})
export class FormUsuariosComponent implements OnInit {
  usuariosForm : FormGroup;
  public usuario: Usuario;
  roles: Role[];
  titulo: String = "Agregar Usuario";

  constructor(private activatedRoute: ActivatedRoute,
    private usuarioService: UsuariosService,
    private router : Router,
    private rolesService: RolesService,
    private formBuilder: FormBuilder )  { 
      this.usuariosForm = this.formBuilder.group({
        roles: new FormArray([]),
        nombre : new FormControl('', Validators.required),
      apellido: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required)
    })
    //this.ngOnInit
    this.rolesService.getRoles().subscribe((roles => {
      this.roles = roles;
      this.addCheckBoxes();
    }))
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      (params) => {
        let id = params['id'];
        if(id){

          this.usuarioService.getUsuario(id)
          .subscribe((usuario) => {            
            this.usuario = usuario;
            this.titulo = "Editar Usuario";

            this.usuariosForm.setValue({nombre: this.usuario.nombre,
            apellido: this.usuario.apellido,
            email: this.usuario.email,
            password: this.usuario.password,
            username: this.usuario.username,
            roles: this.usuario.roles
          });
          })
          console.log(this.usuariosForm);
        }
      }
    )
  }

    get temasFormArray(): FormArray {
      return this.usuariosForm.controls.roles as FormArray;
    }

    private addCheckBoxes() : void {
      this.roles.forEach(() => {this.temasFormArray.push(new FormControl(false))});
    }

    submit(): void {
      const selectedOrderIds = this.usuariosForm.value.roles
      .map((checked, i) => checked ? this.roles[i] : null)
      .filter(v => v !== null);
      console.log(selectedOrderIds);
      this.usuario = new Usuario();
      this.usuario.nombre = this.usuariosForm.get('nombre').value;
      this.usuario.apellido = this.usuariosForm.get('apellido').value;
      this.usuario.email = this.usuariosForm.get('email').value;
      this.usuario.password = this.usuariosForm.get('password').value;
      this.usuario.username =this.usuariosForm.get('username').value;
      this.usuario.roles = selectedOrderIds;
      this.usuarioService.save(this.usuario).subscribe((usuario => {
        this.router.navigate(['/usuarios']);
        swal.fire('Usuario guardado',`El usuario  ${usuario.nombre} se ha guardado correctamente` ,'success' );
      }))
    }

    update(): void {
      const selectedOrderIds = this.usuariosForm.value.roles
      .map((checked, i) => checked ? this.roles[i] : null)
      .filter(v => v !== null);
      this.usuario.nombre = this.usuariosForm.get('nombre').value;
      this.usuario.apellido = this.usuariosForm.get('apellido').value;
      this.usuario.email = this.usuariosForm.get('email').value;
      this.usuario.password = this.usuariosForm.get('password').value;
      this.usuario.username =this.usuariosForm.get('username').value;
      this.usuario.roles = selectedOrderIds; 
      this.usuarioService.update(this.usuario).subscribe((usuario) => {
        this.router.navigate(['/usuarios']);
        swal.fire('Usuario guardado',`El usuario  ${usuario.nombre} se ha guardado correctamente` ,'success');
      })
    }

    
}
