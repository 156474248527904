import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Contacto } from '../models/contacto';
import { Estado } from '../models/estados';
import { Municipio } from '../models/municipios';

import { Observable, throwError } from 'rxjs';

import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { URL_CONFIG } from '../store/config';
import swal from "sweetalert2";

@Injectable()
export class ContactoService {
    private urlEndPoint: string = URL_CONFIG + '/api/contactos';
  constructor(private http: HttpClient, private router: Router) { }

  getEstados(): Observable<Estado[]> {
    let headers = new Headers();
    return this.http.get<Estado[]>(this.urlEndPoint + '/estados', {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    }).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  getMunicipios(estado: Estado): Observable<any> {
    return this.http.post(this.urlEndPoint + '/municipios/', estado).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  getAllContactos(): Observable<any> {
    return this.http.get<Contacto>(`${this.urlEndPoint}/`).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
            this.router.navigate(['/contactos'])
          }
          return throwError(e);
        }
      ));
  }

  create(contacto: Contacto): Observable<Contacto> {
    return this.http.post(this.urlEndPoint, contacto).pipe(
      map((response: any) => response.contacto as Contacto),
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al ejecutar', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      ));
  }

  getContacto(id: number): Observable<Contacto> {
    return this.http.get<Contacto>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
            this.router.navigate(['/contactos'])
          }
          return throwError(e);
        }
      ));
  }
  getContactoByEstado(estado: Estado): Observable<any> {
    return this.http.post(this.urlEndPoint + '/byestado/', [estado]).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
            //this.router.navigate(['/dependecias'])
          }
          return throwError(e);
        }
      ));
  }

  getContactoByMunicipio(municipio: Municipio): Observable<any> {
    return this.http.post(this.urlEndPoint + '/bymunicipio/', municipio).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  getContactosByNombre(nombre: string): Observable<any> {
    let data = new FormData();
    data.append("nombre", nombre);
    return this.http.post(this.urlEndPoint + '/byNombre', data).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }

  getContactosByFiltro(estado: Estado, municipio : Municipio, nombre: string): Observable<any> {
    let data = new FormData();
    data.append("nombre", nombre);
    data.append("estado", JSON.stringify(estado));
    data.append("municipio", JSON.stringify(municipio));
    return this.http.post(this.urlEndPoint + '/byFiltro', data).pipe(
      catchError(
        (e) => {
          if(e.status != 401 && e.status != 403) {
            swal.fire('Error al realizar la consulta', e.error.mensaje,'error' );
          }
          return throwError(e);
        }
      )
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(
        (e) => {
          if (e.status != 401 && e.status != 403) {
            swal.fire('Error al eliminar el contacto', e.error.mensaje, 'error');
          }
          return throwError(e);
        }
      )
    );
  }
}