import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarruselService } from '../../service/carrusel.service';
import { VideoService } from '../../service/video.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit , AfterContentChecked{
  public verMas: boolean = false;
  public carruselesInicio = [];
  public carruseles = [];
  public videosInicio = [];
  public videos = [];
  constructor(private route: ActivatedRoute, private carruselService: CarruselService, private videoService: VideoService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.obtenerCarrusel();
    this.obtenerVideo();
    if (!this.route.snapshot.paramMap.has('seccion')) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  ngAfterContentChecked(): void {

    if (this.route.snapshot.paramMap.has('seccion')) {
      document.getElementById(this.route.snapshot.paramMap.get('seccion')).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
  
  toogle(){
    this.verMas = !this.verMas;
  }

  obtenerCarrusel(): void {
      this.carruselService.obtenerCarrusel().subscribe((carrusel) => {
        let activos = [];
        for(let activo of carrusel){
          if(activo.activo === 1){
            activos.push(activo);
          }
        }
        this.carruselesInicio = activos.slice(0,1);
        this.carruseles = activos.slice(1,activos.length);
        // console.log(this.categorias);
      })
      
    }

    obtenerVideo(): void {
      this.videoService.obtenerVideo().subscribe((video) => {
        let activos = [];
        for(let activo of video){
          if(activo.activo === 1){
            activos.push(activo);
          }
        }
        this.videosInicio = activos.slice(0,1);
     
        this.videos = activos.slice(1,activos.length);
        // console.log(this.categorias);
      })
      
    }

    URLseguro(url: string): SafeResourceUrl {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

}
