import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../service/login.service';
import swal from "sweetalert2";


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private loginService: LoginService,
  private router: Router){
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      if(!this.loginService.isAuthenticated()){
        this.router.navigate(['/login']);
        return true;
      }

      
      if(this.isTokenExpirado()){
        this.loginService.logout();
        this.router.navigate(['/login']);
        return true;
      }

      console.log("ROLES");
      
      let role = route.data['role'] as string;
      console.log(role);
      if(this.loginService.hasRoles(role)){
        return true;
      }
      swal.fire('Acceso denegado',`Hola ${this.loginService.usuario.nombre} usted no tiene acceso a este recurso!`, 'warning' );
      this.router.navigate(['/dependencias']);
    return false;
  }

  isTokenExpirado(): boolean{
    let token = this.loginService.token;
    let payload = this.loginService.obtenerDatosToken(token);    
    let now = new Date().getTime() / 1000;
    console.log(payload.exp);
    console.log(now);
    if(payload.exp < now){
      return true;
    }
    return false;
  }

}
