import { Component, OnInit } from '@angular/core';
import { Tema } from '../../models/tema';
import { LoginService } from '../../service/login.service';
import { DependenciaService } from './../../service/dependencias.service';
import swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-temas',
  templateUrl: './temas.component.html',
  styleUrls: ['./temas.component.css']
})
export class TemasComponent implements OnInit {

  public paginador: any;
  public temas: Tema[];
  public busqueda: string;

  constructor(public dependeciaService: DependenciaService,
    public loginService: LoginService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.has('pagina')) {
      this.dependeciaService.obtenerTemas(this.activatedRoute.snapshot.paramMap.get('pagina'), '10').subscribe((resultados) => {
        this.temas = resultados.content;
        this.paginador = resultados;
      });
    } else {
      this.dependeciaService.obtenerTemas('0', '10').subscribe((resultados) => {
        this.temas = resultados.content;
        this.paginador = resultados;
      });
    }
  }

  delete(tema: Tema): void {
    swal.fire({
      title: 'Eliminando tema',
      text: `¿Seguro que desea eliminar el tema: "${tema.nombre}" ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dependeciaService.deleteTema(tema).subscribe(
          () => {
            this.temas = this.temas.filter((cli) => cli != tema)
            swal.fire(
              'Tema eliminado!',
              `Tema eliminado con éxito!`,
              'success'
            )
          }
        )
      }
    })
  }
  buscar(){
    console.log(this.busqueda);
    this.dependeciaService.findByTemas(this.busqueda).subscribe(
    (temas) => {
      if (temas == temas) {
        this.temas =temas;
      } else {
        
      }
    })
  }

}
