import { Component,  OnInit } from '@angular/core';
import { Dependencia } from '../../models/dependecia';
import { Categoria } from '../../models/categorias';
import { DependenciaService } from '../../service/dependencias.service';
import { ActivatedRoute } from '@angular/router';
import { colors } from '../util/colors';
import { Subject } from "rxjs";
import { HostListener } from '@angular/core';
import { Avisos } from 'src/app/models/avisos';
import { Eventos } from 'src/app/models/eventos';
import { Noticias } from 'src/app/models/noticias';


@Component({
  selector: 'app-osc',
  templateUrl: './osc.component.html',
  styleUrls: ['./osc.component.css']
})

export class OscComponent implements OnInit {
  public color: String;
  public titulo: String;
  public page: number = 0;
  public dependencias: Dependencia[];
  public avisos: Avisos[];
  public eventos: Eventos[];
  public noticias: Noticias[];
  public categoria: Categoria;

  constructor(private dependeciaService: DependenciaService,
    private activateRoute: ActivatedRoute) {
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    console.log( document.body.offsetHeight -window.innerHeight  );
    let scrollOffset = e.srcElement.children[0].scrollTop;
    console.log("window scroll: ", scrollOffset);
  }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.activateRoute.params.subscribe(
      (params) => {
        let id = params['id'];
        let nombre = params['nombre'];
        switch (id) {
          case '1':
            this.titulo = "Organizaciones de la Sociedad Civil";
            this.page = 0;
            break;
          case '2':
            this.titulo = "Iniciativa Privada";
            this.page = 0;
            break;
          case '3':
            this.titulo = "Instituciones de Educación Superior";
            this.page = 0;
            break;
        }
        this.categoria = { "id": id, "nombre": nombre };
        //this.cargarDependencias();
       // this.dependencias = [];
      });
  }

  setAvisos(avisos: Avisos[]): void {
    this.avisos = avisos;
  }

  setEventos(eventos: Eventos[]) {
    this.eventos = eventos;
  }

  setNoticias(noticias: Noticias[]) {
    this.noticias = noticias;
  }

  setDependencias(dependencias: Dependencia[]): void{
    this.dependencias = dependencias;
  }

  cargarAllDependencias(): void {
    this.titulo = "Todas las organizaciones";
    this.dependeciaService.getAllDependencias().subscribe((dependencias) => {
      this.dependencias = dependencias;
    })
  }

  cargarDependencias(): void {
    this.dependeciaService.getDependenciaByCategoria(this.categoria).subscribe((dependencias) => {
      this.dependencias = dependencias;
     /*  this.dependencias.map(function (dependencia: Dependencia) {
        dependencia.color = colors.getRandomColor();
      }) */
    })
  }

  checkthis(e){
    console.log(e.target.offsetHeight ,
                e.target.scrollHeight ,
                e.target.scrollTop,
                e.target.offsetHeight);
    if(e.target.scrollHeight < e.target.scrollTop+e.target.offsetHeight) {
      console.log("bottom");
    }
  }
}

