<div class="container" id="addUser">
    <h3>{{this.titulo}}</h3><!-- @TODO hacerlo dinamico al editar -->
    <hr class="red">
    <div class="row">

        <div class="col-md-12">
            <div class="container">
                <div class="col-md-12">
                    <!-- form registro -->
                    <div>
                        <form #formCliente="ngForm">
                            <div class="form-group col-md-9">
                                <label for="titulo">Titulo:  <span class="obligatorio">*</span></label>
                                <div>
                                    <input type="text" class="form-control" [(ngModel)]="publicacion.titulo" name="titulo">
                                </div>
                            </div>
                            <!--div class="form-group col-md-3">
                                <label for="telefono">Archivo/Documento:  <span class="obligatorio">*</span></label>
                                <button type="button" class="btn btn-info btn-block">Subir</button>
                            </div-->
                            <div class="form-group col-md-3">
                                <label for="date">Fecha:  <span class="obligatorio">*</span></label>
                                <div>
                                    <input type="date" class="form-control" [(ngModel)]="publicacion.fechaPublicacion" name="date">
                                </div>
                            </div>
                            <div  *ngIf="showFileButton else elseBlockButton" class="form-group col-md-6">
                                <label for="">Documento (PDF)</label>
                                <form [formGroup]="myForm">
                                    <input type="file" class="form-control" id="file" formControlName="file" requiredFileType="doc/pdf" (change)="onFileSelected($event)">
                                </form>
                            </div>
                            <ng-template #elseBlockButton>
                                <div class="form-group col-md-6">
                                    <div class="col-md-6">
                                        <a href="{{publicacion.contenido}}" class="btn btn-info btn-block" target="_blank">Ver archivo</a>
                                    </div>
                                    <div class="col-md-6">
                                        <button class="btn btn-warning btn-block" (click)='showFileButtonEvent()' target="_blank">Cambiar archivo</button>
                                    </div>
                                </div>
                            </ng-template>
                            <div class="form-group col-md-6">
                            </div>
                            <div class="form-group col-md-2 offset-md-8">
                                <button type="button" class="btn btn-primary btn-block" [routerLink]="['/publicaciones/3/0']">Cancelar</button>
                            </div>
                            <div class="form-group col-md-2">
                                <button class="btn btn-success btn-block" role="button" (click)='create()' *ngIf="!publicacion.id else elseBlock" [disabled]="!formCliente.form.valid">Guardar</button>
                                <ng-template #elseBlock>
                                    <button class="btn btn-warning btn-block" role="button" (click)='update()'>Actualizar</button>
                                </ng-template>
                            </div>
                        </form>
                    </div>
                    <!-- Fin form -->
                </div>
            </div>
        </div>
    </div>
</div>
