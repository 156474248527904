import { UsuariosService } from '../../../service/usuarios.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Usuario }  from '../../../models/usuario';
import { Component, OnInit  } from '@angular/core';
import swal from "sweetalert2";

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.css']
})
export class CuentaComponent implements OnInit {

  public usuario: Usuario = new Usuario();
  public password2: string = '';
  public password: string = '';
  public classFin: String;

  constructor(private usuarioService: UsuariosService, private router: Router, private activateRoute: ActivatedRoute) { 
  }

  ngOnInit(): void {
    this.cargarUsuario();
  }

  create():void{
    if(this.password == this.password2 && this.password != ""){
      this.usuario.password = this.password;
      console.log(this.usuario.password);
      console.log(this.password);
      this.usuarioService.update(this.usuario).subscribe((usuario) => {
        swal.fire('Usuario Actualizado', `Usuario: "${this.usuario.username}" guardada con exito!`, 'success');
        this.classFin = "success";
        this.activateRoute.data['value'].context === undefined ? this.router.navigate(['/bienvenida']) : this.router.navigate(['/bienvenida']);
      });
    }else{
      if(this.password != ""){
        swal.fire('Error en la contraseña', 'La contraseña no coincide o es incorrecta', 'error');
        this.classFin = "error";
      }else{
        this.usuarioService.update(this.usuario).subscribe((usuario) => {
          swal.fire('Usuario Actualizado', `Usuario: "${this.usuario.username}" guardada con exito!`, 'success');
          this.classFin = "success";
          this.activateRoute.data['value'].context === undefined ? this.router.navigate(['/bienvenida']) : this.router.navigate(['/bienvenida']);
        });
      }
    }    
  }

  cargarUsuario():void{
    this.activateRoute.params.subscribe(
      (params => {
        let id = params['id']
        if(id){
          this.usuarioService.getUsuario(id).subscribe(usuario => {
            this.usuario = usuario;
          })
        }
      })
    );
  }

}
